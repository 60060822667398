import { useEffect } from "react";

type Args<T> = {
    hot: boolean;
    startLoading: () => void;
    stopLoading: () => void;
    fetchData: (signal: AbortSignal) => Promise<T>;
    onSuccess: (result: T) => unknown;
    onError: (string) => unknown;
};

export function useApi<T = unknown>(args: Args<T>): void {
    const { hot, startLoading, stopLoading, fetchData, onSuccess, onError } = args;

    useEffect(() => {
        if (hot) {
            startLoading();

            const abortCtrl = new AbortController();

            fetchData(abortCtrl.signal)
                .then((data) => {
                    onSuccess(data);
                    stopLoading();
                })
                .catch((error) => {
                    // When the AbortController is aborted, the error is a DOMException.
                    // This is fine and doesn't interfere with anything.
                    if (!(error instanceof DOMException) || (error as DOMException).code !== DOMException.ABORT_ERR) {
                        return onError(error.message);
                    }
                });

            return () => {
                abortCtrl.abort();
                stopLoading();
            };
        }
    }, [hot, startLoading, stopLoading, fetchData, onSuccess, onError]);
}
