import * as React from "react";
import Panel from "../../../layout/Panel";
import EttLog from "../../../shared/EttLog";
import { EttReportButton } from "./EttReportButton";
import useWorkHubState from "../../../state/useWorkHubState";

export default function EttPanel() {
    const { employeeInfo: { today: { ettLog } } } = useWorkHubState();

    return <>
        <Panel.Caption text="Zeiterfassung" />
        <Panel className="WorkHub__EttStatusPanel">
            {ettLog.length > 0 && <>
                <Panel.Section>
                    <Panel.SectionTitle text="Protokoll" />
                    <Panel.SubPanel>
                        <EttLog log={ettLog} />
                    </Panel.SubPanel>
                </Panel.Section>
            </>}

            <Panel.Section>
                <EttReportButton />
            </Panel.Section>
        </Panel>
    </>;
}
