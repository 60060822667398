import * as React from "react";
import { createContext, useContext, useEffect, useReducer } from "react";
import { LoadingError } from "../../../lib/errors/LoadingError";
import { isPresent, underscoreToCamelCase } from "../../../lib/object/objects";
import { useEmployeeState } from "../factsheet/state";
import { Filters } from "../../scheduler/cursor/editors/new-employee-week-editor/Filters";

type EmployeeValue = {
    id: string;
    firstName: string;
    lastName: string;
    activeWorkplaceUnitIds: string[]
};

export type State = {
    loading: boolean;
    loadingError?: Error;
    selectedStatus: "active" | "inactive";
    selectedEmployeeId: string | null;
    query: string;
    unitId: string | null;
    employees: EmployeeValue[];
    filteredEmployees: EmployeeValue[];
};

const initialState: State = {
    loading: true,
    selectedStatus: "active",
    selectedEmployeeId: null,
    query: "",
    unitId: null,
    employees: [],
    filteredEmployees: []
};

type InitEmployeesAction = {
    type: "initEmployees";
    payload: State["employees"];
};

type SelectEmployeeAction = {
    type: "selectEmployee";
    payload: {
        employeeId: State["selectedEmployeeId"];
        status: State["selectedStatus"];
    };
};

type SelectStatusAction = {
    type: "selectStatus";
    payload: State["selectedStatus"];
};

type FilterByQueryAction = {
    type: "filterByQuery";
    payload: {
        query: string;
    };
};

type FilterByUnitAction = {
    type: "filterByUnit";
    payload: {
        unitId: string;
    };
};


type LoadingErrorAction = {
    type: "loadingError";
    payload: Error;
};

type Action =
    InitEmployeesAction
    | LoadingErrorAction
    | SelectEmployeeAction
    | SelectStatusAction
    | FilterByQueryAction
    | FilterByUnitAction;


type Filters = {
    query: string,
    unitId: string | null
};

function filterEmployees(employees: EmployeeValue[], filters: Filters): EmployeeValue[] {
    let filteredEmployees = employees;
    if (filters.query) {
        filteredEmployees = filterEmployeesByQuery(filters.query, filteredEmployees);
    }
    if (filters.unitId) {
        filteredEmployees = filterEmployeesByUnitId(filters.unitId, filteredEmployees);
    }
    return filteredEmployees;
}

function filterEmployeesByQuery(query: string, employees: EmployeeValue[]): EmployeeValue[] {
    if (query === "") {
        return employees;
    }

    query = query.toLowerCase();

    const terms = query.split(" ");

    // Find all employees that match all terms in their full name (ie. lastName + firstName)
    return employees.filter((employee) => {
        const fullName = `${employee.lastName} ${employee.firstName}`.toLowerCase();
        return terms.every((term) => fullName.includes(term));
    });
}

function filterEmployeesByUnitId(unitId: string, employees: EmployeeValue[]): EmployeeValue[] {
    if (null === unitId) {
        return employees;
    }

    return employees.filter((employee) => {
        return employee.activeWorkplaceUnitIds.some((activeWorkplaceUnitId) => {
            return activeWorkplaceUnitId === unitId;
        });
    });
}

export function reducer(state: State, action: Action) {
    console.debug("🔵 Layout Action", action);
    switch (action.type) {
        case "initEmployees":
            return {
                ...state,
                loading: false,
                employees: action.payload,
                filteredEmployees: filterEmployees(action.payload, {
                    query: state.query,
                    unitId: state.unitId
                })
            };
        case "selectEmployee":
            return {
                ...state,
                selectedEmployeeId: action.payload.employeeId,
                selectedStatus: action.payload.status
            };
        case "selectStatus":
            return { ...state, selectedStatus: action.payload };
        case "loadingError":
            return { ...state, loading: false, loadingError: action.payload };
        case "filterByQuery":
            return {
                ...state,
                query: action.payload.query,
                filteredEmployees: filterEmployees(state.employees, {
                    query: action.payload.query,
                    unitId: state.unitId
                })
            };
        case "filterByUnit":
            return {
                ...state,
                unitId: action.payload.unitId,
                filteredEmployees: filterEmployees(state.employees, {
                    query: state.query,
                    unitId: action.payload.unitId
                })
            };
        default:
            throw new Error();
    }
}

// TODO: Replace with the following line once getStorageKey is merged.
// export const unitIdStorageKey = getStorageKey("employee-manager", "unit-id");
export const unitIdStorageKey = "brix:employee-manager:unit-id";

const LayoutContext = createContext<{
    state: State;
    dispatch: React.Dispatch<Action>;
}>({ state: null, dispatch: null });

export function LayoutContextProvider({ children }: { children: React.ReactNode }) {
    const unitIdFromStorage = localStorage.getItem(unitIdStorageKey);
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        unitId: isPresent(unitIdFromStorage) ? JSON.parse(unitIdFromStorage) : initialState.unitId
    });

    useEmployeeList(state, dispatch);
    useSelectedEmployee(state, dispatch);

    if (state.loadingError) {
        return <LoadingError />;
    }

    return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>;
}

function useEmployeeList(state: State, dispatch: React.Dispatch<Action>) {
    useEffect(() => {
        let isCancelled = false;

        fetch(`/api/employee_manager/employees?status=${state.selectedStatus}`)
            .then((response) => response.json())
            .then((employees) => {
                if (!isCancelled) {
                    dispatch({
                        type: "initEmployees",
                        payload: employees.data.map((e) => underscoreToCamelCase(e)) as State["employees"]
                    });
                }
            })
            .catch((error) => {
                if (!isCancelled) {
                    dispatch({ type: "loadingError", payload: error });
                }
            });
        return () => {
            isCancelled = true;
        };
    }, [state.selectedStatus]);
}

function useSelectedEmployee(state: State, dispatch: React.Dispatch<Action>) {
    const { employee } = useEmployeeState();

    useEffect(() => {
        if (!employee?.id) {
            return;
        }

        dispatch({
            type: "selectEmployee",
            payload: {
                employeeId: employee.id,
                status: employee.active ? "active" : "inactive"
            }
        });
    }, [employee?.id]);
}

export function useLayoutState() {
    const { state } = useContext(LayoutContext);
    return state;
}

export function useLayoutDispatch() {
    const { dispatch } = useContext(LayoutContext);
    return dispatch;
}
