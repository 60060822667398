import * as React from "react";
import { Department } from "../../../types";
import SkillList from "./SkillList";

type SelectedDepartmentProps = {
    department: Department;
};

export function SelectedDepartment(props: SelectedDepartmentProps) {
    const {
        department: { primary_skills: departmentPrimarySkills, name: departmentName },
    } = props;

    return (
        <div className="SchedulerEmployeeWeekEditor__Department">
            <div className="SchedulerEmployeeWeekEditor__Department__Label">Planungsbereich:</div>
            <div className="SchedulerEmployeeWeekEditor__Department__Value">{departmentName}</div>
            {departmentPrimarySkills.length > 0 && (
                <div className="SchedulerEmployeeWeekEditor__Department__PrimarySkills">
                    <SkillList skills={departmentPrimarySkills.map((skill) => ({ ...skill, isPrimary: true }))} />
                </div>
            )}
        </div>
    );
}
