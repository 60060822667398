import * as React from "react";
import { PersonalSchedule } from "../../../../state/types/personal-schedule";
import DeploymentsList from "./DeploymentsList";
import DayOfWeek from "./DayOfWeek";
import Balances from "./Balances";
import { conditionalClassNames } from "../../../../../../lib/dom-utils/class-names";

type DayProps = {
    day: PersonalSchedule["days"][number];
}

export default function Day(props: DayProps) {
    const { day: { date, deployments, balances } } = props;

    const classes = conditionalClassNames({
        "WorkHub__WeeklyDeploymentsList__Day": true,
        "WorkHub__WeeklyDeploymentsList__Day--empty": !deployments.length
    }).join(" ");

    return (
        <div className={classes}>
            <DayOfWeek date={date} />
            <DeploymentsList deployments={deployments} />
            <Balances balances={balances} />
        </div>
    );
}
