import * as React from "react";
import { EttReportDay, EttReportPresence } from "../../../../state/types/ett-report";
import TimeFrame from "../../../../shared/TimeFrame";
import Duration from "../../../../shared/Duration";
import { secondsToHuman } from "../../../../../../lib/datetime/time";

type PresencesTableProps = {
    workHours: EttReportDay["workHours"],
    breakHours: EttReportDay["breakHours"],
    presences: EttReportPresence[]
}

export default function PresencesTable(props: PresencesTableProps) {
    const { presences, breakHours, workHours } = props;

    return (
        <div className="WorkHub__EttReport__Day__PresencesTable">
            <div className="WorkHub__EttReport__Day__PresencesTable__Header">
                <div className="WorkHub__EttReport__Day__PresencesTable__Header__PresenceHeader">
                    <span>Anwesend</span>
                    <Duration colorTheme={"indigo"}>
                        <span>{secondsToHuman(workHours * 3_600, { seconds: false })} h</span>
                    </Duration>
                </div>
                <div className="WorkHub__EttReport__Day__PresencesTable__Header__BreakHeader">
                    <span>Pause</span>
                    <Duration colorTheme={"slate"}>
                        <span>{secondsToHuman(breakHours * 3_600, { seconds: false })} h</span>
                    </Duration>
                </div>
            </div>
            <div className="WorkHub__EttReport__Day__PresencesTable__Body">
                {presences.map((presence, presenceIdx) => (
                    <div key={presenceIdx}
                         className="WorkHub__EttReport__Day__Presence">
                        <div className="WorkHub__EttReport__Day__Presence__PresenceTimes">
                            <TimeFrame startTime={presence.startTime} endTime={presence.endTime} />
                            <Duration colorTheme="indigo">
                                {secondsToHuman(presence.presenceHours * 3_600, { seconds: false })} h
                            </Duration>
                        </div>
                        <div className="WorkHub__EttReport__Day__Presence__Breaks">
                            {presence.breaks.map((breakData, breakIdx) => (
                                <div key={breakIdx} className="WorkHub__EttReport__Day__Presence__Breaks__Break">
                                    <TimeFrame startTime={breakData.startTime} endTime={breakData.endTime} />
                                    <Duration colorTheme="slate">
                                        {secondsToHuman(breakData.breakHours * 3_600, { seconds: false })} h
                                    </Duration>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
