import * as React from "react";

type PublicHolidayNoteProps = {
    name: string;
};

export function PublicHolidayNote(props: PublicHolidayNoteProps) {
    const { name } = props;

    return (
        <div className="SchedulerHeader__DailyNotes__Note SchedulerHeader__DailyNotes__Note--public-holiday">
            {name}
        </div>
    );
}
