import * as React from "react";
import Panel from "../../../layout/Panel";
import { PersonalSchedule } from "../../../state/types/personal-schedule";
import Duration from "../../../shared/Duration";

type WeeklyDeploymentListSumsProps = {
    personalSchedule: PersonalSchedule
}

export default function WeeklyDeploymentListSums(props: WeeklyDeploymentListSumsProps) {
    const { personalSchedule } = props;

    return (
        <Panel.SubPanel className={"WorkHub__PersonalSchedule__WeeklyDeploymentListSums"}>
            <div className="WorkHub__PersonalSchedule__WeeklyDeploymentListSums__Figure">
                <div className="WorkHub__PersonalSchedule__WeeklyDeploymentListSums__Figure__Label">
                    Arbeitszeit
                </div>
                <Duration
                    colorTheme="indigo"
                    className="WorkHub__PersonalSchedule__WeeklyDeploymentListSums__Figure__Value"
                >
                    {personalSchedule.balances.humanWorkDuration} h
                </Duration>
            </div>
            <div className="WorkHub__PersonalSchedule__WeeklyDeploymentListSums__Figure">
                <div className="WorkHub__PersonalSchedule__WeeklyDeploymentListSums__Figure__Label">
                    Pausenzeit
                </div>
                <Duration
                    colorTheme="slate"
                    className="WorkHub__PersonalSchedule__WeeklyDeploymentListSums__Figure__Value"
                >
                    {personalSchedule.balances.humanBreakDuration} h
                </Duration>
            </div>
        </Panel.SubPanel>
    );
}
