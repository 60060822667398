import * as React from "react";
import { useContext } from "react";

import { DeploymentSlot as DeploymentSlotType, EmployeeWeek } from "../../../types";
import EmployeeWeekRowDay from "./day/EmployeeWeekRowDay";
import ErrorBoundary from "../../../../../lib/errors/ErrorsBoundary";
import EmployeeBalances from "./EmployeeBalances";
import { CursorPositionContext } from "../../../cursor/CursorPositionContext";
import { CursorAnchorSetter } from "../../../cursor/CursorNavigation";
import TableRowHeader from "../../../table/TableRowHeader";
import TableRow from "../../../table/TableRow";
import TableRowBody from "../../../table/TableRowBody";
import TableRowHeaderText from "../../../table/TableRowHeaderText";

type EmployeeWeekRowProps = {
    employeeWeek: EmployeeWeek;
    setCursorAnchorElement: CursorAnchorSetter;
    renderRow: number;
    slot: DeploymentSlotType;
};

function isUnsaved(employeeWeek: EmployeeWeek): boolean {
    let deploymentsCount = 0;
    for (const dow in employeeWeek.days) {
        for (const deployment of employeeWeek.days[dow].deployments) {
            deploymentsCount++;
            if (!deployment.id) {
                return true;
            }
        }
    }
    return deploymentsCount === 0;
}

export default function EmployeeWeekRow(props: EmployeeWeekRowProps) {
    const { slot, employeeWeek, setCursorAnchorElement, renderRow } = props;

    const classNames = ["SchedulerEmployeeWeek", `SchedulerEmployeeWeek--${slot.department.color}`];
    if (isUnsaved(employeeWeek)) {
        classNames.push("SchedulerEmployeeWeek--unsaved");
    }

    const { cursorPosition } = useContext(CursorPositionContext);
    if (cursorPosition?.row === renderRow) {
        classNames.push("SchedulerEmployeeWeek--cursor");
    }

    return (
        <TableRow className={classNames.join(" ")} role="region" aria-label={employeeWeek.employee.name}>
            <ErrorBoundary>
                <TableRowHeader className="SchedulerEmployeeWeek__EmployeeInfo">
                    <div className="SchedulerEmployeeWeek__Name">
                        <TableRowHeaderText>
                            <a href={`/employee_manager/${employeeWeek.employee.id}`} data-turbolinks="false">
                                {employeeWeek.employee.name}
                            </a>
                        </TableRowHeaderText>
                    </div>
                    <EmployeeBalances employeeId={employeeWeek.employee.id} />
                </TableRowHeader>
                <TableRowBody>
                    {Object.keys(employeeWeek.days).map((dow, idx) => (
                        <EmployeeWeekRowDay
                            key={dow}
                            slot={slot}
                            day={employeeWeek.days[dow]}
                            employeeId={employeeWeek.employee.id}
                            setCursorAnchorElement={setCursorAnchorElement}
                            renderRow={renderRow}
                            renderCol={idx}
                        />
                    ))}
                </TableRowBody>
            </ErrorBoundary>
        </TableRow>
    );
}
