import * as React from "react";

type LoadingProps = {
    loadingText?: string;
    theme?: "indigo" | "teal";
};

export default function Loading(props: LoadingProps) {
    const { loadingText = "Laden...", theme = "indigo" } = props;

    const className = ["Loading", `Loading--${theme}`].join(" ");

    return (
        <div className="Loading__Wrapper">
            <div className={className}>
                <LoadingSpinner theme={theme} />
                <div className="Loading__Text">{loadingText}</div>
            </div>
        </div>
    );
}

type LoadingSpinnerProps = {
    theme?: "indigo" | "teal";
};

export function LoadingSpinner(props: LoadingSpinnerProps) {
    const { theme = "indigo" } = props;

    const className = ["Loading__Spinner", `Loading__Spinner--${theme}`].join(" ");

    return <div className={className}></div>;
}
