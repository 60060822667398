import * as React from "react";
import Icon from "../../../../lib/icon/Icon";
import { useTimeSheetFreezingWarning } from "./useTimeSheetFreezingWarning";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import { formatDate } from "../../../../lib/datetime/date-format";
import { KeyFigure } from "../../../../lib/key-figure/KeyFigure";
import { pluralize } from "../../../../lib/string/string";
import { WidgetLoading } from "../WidgetLoading";

export function TimeSheetFreezingWarning() {
    const { timeSheetFreezingWarning, loading, loadingFailed } = useTimeSheetFreezingWarning();

    return (
        <Kitchensink.Item>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Nächste Festschreibung</Kitchensink.ItemBodyTitle>
                <Kitchensink.ItemBodyPart>
                    {loadingFailed && (
                        <div className="Badge Badge--warning">
                            <Icon type="error" /> Fehler beim Laden
                        </div>
                    )}
                    {!loadingFailed && loading && <WidgetLoading height={67} />}
                    {!loadingFailed && !loading && (
                        <>
                            <div className="mb-3">
                                <small>
                                    Am <KeyFigure>{formatDate(timeSheetFreezingWarning.nextImmutableDate)}</KeyFigure>{" "}
                                    (noch{" "}
                                    <KeyFigure>
                                        {timeSheetFreezingWarning.daysBeforeNextImmutableDate}{" "}
                                        {pluralize(timeSheetFreezingWarning.daysBeforeNextImmutableDate, "Tag", "Tage")}
                                    </KeyFigure>
                                    ) werden alle Zeitkontoeinträge bis einschließlich{" "}
                                    <KeyFigure>{formatDate(timeSheetFreezingWarning.lastFrozenDate)}</KeyFigure>{" "}
                                    festgeschrieben.
                                </small>
                            </div>
                            <Kitchensink.ItemBodyLinkList>
                                <a href="/time_sheets" className="Button Button--ghost">
                                    Zeitkonto kontrollieren
                                </a>
                            </Kitchensink.ItemBodyLinkList>
                        </>
                    )}
                </Kitchensink.ItemBodyPart>
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}
