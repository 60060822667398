import * as React from "react";
import { useContext } from "react";

import { Department } from "../../types";
import { BalancesContext } from "../SlotsController";

type DepartmentBalancesProps = {
    department: Department;
};

export default function DepartmentBalances(props: DepartmentBalancesProps) {
    const { department } = props;

    const { departmentBalances } = useContext(BalancesContext);
    const departmentBalance = departmentBalances && departmentBalances[department.id];

    const invalidEl = <div className="SchedulerDeploymentSlot__Balances__Balance__Loading">Lade...</div>;
    const invalidated = departmentBalance?.invalidated;
    const planHoursValue = invalidated ? invalidEl : departmentBalance?.human_plan_hours ?? "...";
    const performedWorkHoursValue = invalidated ? invalidEl : departmentBalance?.human_performed_work_hours ?? "...";

    return (
        <div className="MetricsList">
            <div className={`Metric Metric--${department.color}`}>
                <div className="Metric__Label">Plan</div>
                <div className="Metric__Value">{planHoursValue}</div>
            </div>
            <div className={`Metric Metric--${department.color}`}>
                <div className="Metric__Label">Ist</div>
                <div className="Metric__Value">{performedWorkHoursValue}</div>
            </div>
        </div>
    );
}
