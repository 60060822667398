import * as React from "react";
import { useEffect, useState } from "react";

function move(tabs: TabbedContentDefinition, currentTabKey: string, step: number): string {
    const keys = Object.keys(tabs);
    const currentIndex = keys.indexOf(currentTabKey);

    if (-1 === currentIndex) {
        throw new Error(`Invalid tab: ${currentTabKey}`);
    }

    let nextIndex = currentIndex + step;

    if (nextIndex < 0) {
        nextIndex = keys.length - 1;
    } else if (nextIndex >= keys.length) {
        nextIndex = 0;
    }

    return keys[nextIndex];
}

type TabbedContentDefinition = {
    [key: string]: {
        label: string;
        element: JSX.Element;
    };
};

type TabbedContentProps = {
    tabs: TabbedContentDefinition;
    initialTab: string;
};

export default function TabbedContent({ tabs, initialTab }: TabbedContentProps): JSX.Element {
    const [activeTab, setActiveTab] = useState<string>(initialTab);

    useEffect(() => {
        setActiveTab(initialTab);
    }, [initialTab]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.shiftKey && event.key === "ArrowLeft") {
                setActiveTab((activeTab) => move(tabs, activeTab, -1));
                event.stopPropagation();
                event.preventDefault();
            } else if (event.shiftKey && event.key === "ArrowRight") {
                setActiveTab((activeTab) => move(tabs, activeTab, +1));
                event.stopPropagation();
                event.preventDefault();
            }
        };
        document.body.addEventListener("keydown", handleKeyDown);
        return () => document.body.removeEventListener("keydown", handleKeyDown);
    }, []);

    return (
        <div>
            <nav className="SchedulerCursor__TabBar" role="tablist">
                {Object.keys(tabs).map((tabKey) => {
                    const isActive = tabKey === activeTab;
                    const classNames = ["SchedulerCursor__TabBar__Item"];
                    if (isActive) {
                        classNames.push("SchedulerCursor__TabBar__Item--Active");
                    }
                    return (
                        <div
                            role="tab"
                            aria-label={tabs[tabKey].label}
                            key={tabKey}
                            onClick={() => setActiveTab(tabKey)}
                            className={classNames.join(" ")}
                        >
                            {tabs[tabKey].label}
                        </div>
                    );
                })}
            </nav>
            <div className="TabbedContent__Content">{tabs[activeTab].element}</div>
        </div>
    );
}
