import * as React from "react";
import { useEffect, useState } from "react";
import Button from "../../../../../lib/button/Button";
import { EmployeeNote } from "../../../slots/types";
import TextField from "../../../../../lib/form/text/TextField";

type EmployeeNoteFormProps = {
    value: EmployeeNote;
    onSubmit: (attrs: EmployeeNote) => unknown;
    onDelete?: () => unknown;
    busy: boolean;
    placeholder?: string;
};

export default function EmployeeNoteForm(props: EmployeeNoteFormProps) {
    const { value, busy, onSubmit, onDelete, placeholder } = props;
    const [attrs, setAttrs] = useState<EmployeeNote | null>(null);

    useEffect(() => {
        if (null !== value) {
            setAttrs(value);
        }
    }, [value]);

    const handleChange = (textValue) => {
        setAttrs({ ...attrs, text: textValue });
    };
    const handleSubmit = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        attrs && !busy && onSubmit(attrs);
    };

    return (
        <form className="d-flex gap-1" onSubmit={handleSubmit}>
            <TextField
                onChange={handleChange}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.stopPropagation();
                        handleSubmit(e);
                    }
                }}
                value={attrs?.text ?? ""}
                placeholder={placeholder}
                disabled={busy}
                autoFocus={true}
            />
            <Button
                aria-label="Speichern"
                title="Speichern"
                size="sm"
                icon="apply"
                theme="primary"
                disabled={busy}
                onClick={handleSubmit}
            />
            {onDelete && (
                <Button
                    aria-label="Löschen"
                    title="Löschen"
                    type="button"
                    size="sm"
                    icon="delete"
                    theme="light"
                    disabled={busy}
                    onClick={() => !busy && onDelete()}
                />
            )}
            <input type="submit" value="Speichern" style={{ display: "none" }} />
        </form>
    );
}
