import { timeToPercent } from "./time";
import { firstBusinessHour, lastBusinessHour } from "../../../../deployment-bar/deployment-times";
import { ReportDay } from "../../types";

type BarData = {
    startTime: string,
    endTime: string
    startPercent: number,
    endPercent: number
};

export function useDayBarsData(day: ReportDay) {
    const presences: BarData[] = day.presences.map(presence => {
        return {
            startTime: presence.startTime,
            endTime: presence.endTime,
            startPercent: timeToPercent(presence.startTime),
            endPercent: timeToPercent(presence.endTime, true)
        };
    });

    const breaks: BarData[] = day.presences.flatMap(presence => {
        return presence.breaks.map(breakData => {
            return {
                startTime: breakData.startTime,
                endTime: breakData.endTime,
                startPercent: timeToPercent(breakData.startTime),
                endPercent: timeToPercent(breakData.endTime, true)
            };
        });
    });

    const ticks: BarData[] = [];

    for (let h = firstBusinessHour + 1; h < lastBusinessHour; h++) {
        ticks.push({
            startTime: `${h}:00`,
            endTime: `${h + 1}:00`,
            startPercent: timeToPercent(`${h}:00`),
            endPercent: timeToPercent(`${h + 1}:00`, true)
        });
    }

    return {
        presences,
        breaks,
        ticks
    };
}
