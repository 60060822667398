import * as React from "react";
import { InputHTMLAttributes, useMemo, useRef } from "react";
import { mergeTimeOfDayParts, padTimePart, timeStringToObject } from "../../datetime/time";

type TimeOfDayFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    value: string;
    onChange: (value: string) => unknown;
    onKeyDown?: (event: KeyboardEvent) => unknown;
    disabled?: boolean;
    readOnly?: boolean;
};

const TimeOfDayField = (props: TimeOfDayFieldProps) => {
    const { value, onChange, onKeyDown = null, disabled = false, readOnly = false, ...otherProps } = props;

    const time = useMemo(() => {
        if (!value) {
            return null;
        }
        return timeStringToObject(value);
    }, [value]);

    const hoursRef = useRef();
    const minutesRef = useRef();

    const handleChange = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const result = mergeTimeOfDayParts(hoursRef.current?.value, minutesRef.current?.value);
        onChange(result);
    };

    return (
        <div
            className={`d-inline-flex border rounded py-1 px-2 ${readOnly ? "bg-light" : "bg-white"}`}
            role="group"
            style={{ width: "95px" }}
            {...otherProps}
        >
            <div style={{ flex: "0 0 40px", paddingRight: "5px" }}>
                <input
                    ref={hoursRef}
                    type="text"
                    className={`${readOnly ? "text-muted" : ""}`}
                    style={{ width: "100%", textAlign: "right", border: 0, backgroundColor: "transparent" }}
                    value={time?.hours ?? ""}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    aria-label="Stunden"
                    readOnly={readOnly}
                    disabled={disabled}
                />
            </div>
            <div className="text-muted">:</div>
            <div style={{ flex: "0 0 30px" }}>
                <input
                    ref={minutesRef}
                    type="text"
                    className={`${readOnly ? "text-muted" : ""}`}
                    style={{ width: "100%", textAlign: "right", border: 0, backgroundColor: "transparent" }}
                    value={time ? padTimePart(time.minutes) : ""}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    aria-label="Minuten"
                    readOnly={readOnly}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

TimeOfDayField.displayName = "DurationField";

export default TimeOfDayField;
