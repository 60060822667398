import * as React from "react";
import { DEmployeeRow } from "../types";
import { joinClasses } from "../../../lib/dom-utils/class-names";
import { formatDays, formatHours } from "../../../lib/datetime/datetime";
import AbsenceCertificationStatus from "./AbsenceCertificationStatus";
import AbsenceApprovalStatus from "./AbsenceApprovalStatus";

type DayProps = {
    data?: DEmployeeRow["days"][string];
};

export default function Day(props: DayProps) {
    const { data } = props;

    const totalCreditedDays = React.useMemo(() => {
        if (undefined === data) {
            return 0;
        }
        return data.absences.reduce((total, absence) => total + Number(absence.credited_days), 0);
    }, [data]);

    return (
        <div
            className={joinClasses(
                "AbsenceReport__Day",
                (undefined === data || data.absences.length === 0) && "AbsenceReport__Day--empty",
                totalCreditedDays === 0 && "AbsenceReport__Day--no-credited-days"
            )}
        >
            {undefined === data ? (
                <></>
            ) : (
                data.absences.map((absence, idx) => {
                    const AbsenceComponent = absence.can_edit ? "a" : "div";

                    const componentProps: { href?: string } = {};
                    if ("a" == AbsenceComponent) {
                        componentProps.href = absence.edit_url;
                    }

                    return (
                        <AbsenceComponent
                            {...componentProps}
                            key={idx}
                            className={joinClasses(
                                "AbsenceReport__Day__Absence",
                                absence.credit && "AbsenceReport__Day__Absence--credited",
                                `AbsenceReport__Day__Absence--${absence.category}`
                            )}
                        >
                            <div className="AbsenceReport__Day__Absence__Name">{absence.name}</div>
                            <div className="AbsenceReport__Day__Absence__StatusIcons">
                                <AbsenceApprovalStatus approved={absence.approved} />
                                <AbsenceCertificationStatus status={absence.certification_status} />
                            </div>
                            <div className="AbsenceReport__Day__Absence__Hours">
                                {formatDays(Number(absence.credited_days))}
                            </div>
                            <div className="AbsenceReport__Day__Absence__Hours">
                                {formatHours(Number(absence.hours))} h
                            </div>
                        </AbsenceComponent>
                    );
                })
            )}
        </div>
    );
}
