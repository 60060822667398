import * as React from "react";
import { dayOfWeekShortNameMap } from "../../../../../../lib/datetime/date-format";
import { DateTime } from "luxon";

type DayOfWeekProps = {
    date: DateTime
}

export default function DayOfWeek(props: DayOfWeekProps) {
    const { date } = props;

    const dayName = dayOfWeekShortNameMap[date.weekday];
    const shortDate = date.toFormat("dd.MM");

    return (
        <div className="WorkHub__EttReport__Day__Header__DayOfWeek">
            <div className="WorkHub__EttReport__Day__Header__DayOfWeek__DayName">
                {dayName}
            </div>
            <div className="WorkHub__EttReport__Day__Header__DayOfWeek__Date">
                {shortDate}
            </div>
        </div>
    );
}
