import * as React from "react";

const SchoolWeekFieldSetHeader = () => {
    return (
        <div className="list-group-item d-flex justify-content-start align-items-center gap-2 bg-light">
            <div className="fw-bold" style={{ flex: "0 0 150px" }}>
                Tag
            </div>
            <div className="fw-bold" style={{ flex: "0 0 200px" }}>
                Abgerechnete Stunden
            </div>
            <div className="fw-bold" style={{ flex: "1 1 100%" }}>
                Abwesenheitszeit
            </div>
        </div>
    );
};

SchoolWeekFieldSetHeader.displayName = "SchoolWeekFieldSetHeader";

export default SchoolWeekFieldSetHeader;
