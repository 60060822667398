import { TimeSheetFreezingWarningApiResponse } from "./api";

type TimeSheetFreezingWarning = {
    nextImmutableDate: string;
    daysBeforeNextImmutableDate: number;
    lastFrozenDate: string;
};

type TimeSheetFreezingWarningState = {
    timeSheetFreezingWarning: TimeSheetFreezingWarning | null;
    loading: boolean;
    loadingFailed: boolean | null;
};

export const initialState: TimeSheetFreezingWarningState = {
    timeSheetFreezingWarning: null,
    loading: true,
    loadingFailed: null,
};

export function reducer(draft: TimeSheetFreezingWarningState, action) {
    switch (action.type) {
        case "load": {
            draft.loading = true;
            draft.loadingFailed = null;
            return;
        }
        case "loadedJson": {
            const json: TimeSheetFreezingWarningApiResponse = action.payload;
            draft.timeSheetFreezingWarning = {
                lastFrozenDate: json.data.last_frozen_date,
                nextImmutableDate: json.data.next_immutable_date,
                daysBeforeNextImmutableDate: json.data.days_before_next_immutable_date,
            };
            draft.loading = false;
            draft.loadingFailed = false;
            return;
        }
        case "loadingFailed": {
            draft.loading = false;
            draft.loadingFailed = true;
            return;
        }
    }
    throw Error("Unknown action: " + action.type);
}
