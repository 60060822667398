import * as React from "react";

import { Day } from "../../../../types";
import DeploymentListItem from "./DeploymentListItem";
import { CursorAnchorSetter } from "../../../../cursor/CursorNavigation";

type DeploymentListProps = {
    day: Day;
    setCursorAnchorElement: CursorAnchorSetter;
    renderRow: number;
    renderCol: number;
    renderSubRowStart: number;
};
export default function DeploymentList(props: DeploymentListProps) {
    const { renderSubRowStart, day, setCursorAnchorElement, renderRow, renderCol } = props;
    return (
        <div className="SchedulerDeploymentList">
            {day.deployments.map((deployment, idx) => {
                return (
                    <DeploymentListItem
                        key={deployment.id || deployment.key}
                        deployment={deployment}
                        setCursorAnchorElement={setCursorAnchorElement}
                        renderRow={renderRow}
                        renderCol={renderCol}
                        renderSubRow={renderSubRowStart + idx}
                    />
                );
            })}
        </div>
    );
}
