import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Icon from "../../icon/Icon";
import Button from "../../button/Button";
import { conditionalClassNames } from "../../dom-utils/class-names";

type FileUploadProps = React.HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean;
    onChange: (value: string) => unknown;
};

function FileUpload(props: FileUploadProps) {
    const { disabled, onChange, ...divProps } = props;
    const [file, setFile] = useState<File | null>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const fileInputRef = useRef(null);
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        const uploadFile = () => {
            if (!file) return;

            const formData = new FormData();
            formData.append("file", file);

            const xhr = new XMLHttpRequest();

            setProgress(0);
            xhr.open("POST", "/api/temp_file_uploads", true);

            xhr.upload.addEventListener("progress", (e) => {
                if (e.lengthComputable) {
                    const percentComplete = (e.loaded / e.total) * 100;
                    setProgress(percentComplete);
                }
            });

            xhr.onload = async () => {
                if (xhr.status === 422) {
                    const jsonResponse = JSON.parse(xhr.response);
                    setUploadError(jsonResponse.errors?.file?.[0] ?? "Ungültige Datei");
                } else if (xhr.status === 201) {
                    const jsonResponse = JSON.parse(xhr.response);
                    onChange(jsonResponse.data.id);
                    setProgress(100);
                    setUploadError(null);
                } else {
                    setUploadError("Upload fehlgeschlagen.");
                }
            };

            xhr.onerror = () => {
                setUploadError(`Fehler: ${xhr.status}`);
            };

            xhr.send(formData);
        };

        uploadFile();
    }, [file]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
    };

    const openFileDialog = () => {
        fileInputRef.current.click();
    };

    const classNames = conditionalClassNames({
        FileUpload: true,
        "FileUpload--dragover": isDragOver,
        "FileUpload--disabled": disabled,
    }).join(" ");

    function handleDelete() {
        setFile(null);
        onChange("");
    }

    return (
        <div className={classNames} {...divProps}>
            <input type="file" ref={fileInputRef} onChange={handleFileChange} disabled={disabled} />
            <div
                className="FileUpload__Dropzone"
                onClick={openFileDialog}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {file ? (
                    <>
                        <FilePreview
                            file={file}
                            progress={progress}
                            hasError={null !== uploadError}
                            onDelete={handleDelete}
                        />
                        <div className="FileUpload__FileName">{file.name}</div>
                    </>
                ) : (
                    <div className="FileUpload__EmptyMessage">
                        <Button
                            caption="Datei auswählen"
                            size="sm"
                            type="button"
                            icon="file-upload"
                            theme="secondary"
                            disabled={disabled}
                        />{" "}
                        <span>oder Drag & Drop zum Hochladen</span>
                    </div>
                )}

                {uploadError && <div className="FileUpload__Error">{uploadError}</div>}
            </div>
        </div>
    );
}

type FilePreviewProps = {
    file: File;
    progress: number | null;
    hasError: boolean;
    onDelete: () => unknown;
};

function FilePreview(props: FilePreviewProps) {
    const { file, progress, onDelete, hasError } = props;

    const [preview, setPreview] = useState(null);

    const uploaded = file && progress === 100;

    useEffect(() => {
        if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    }, [file]);

    function handleDelete(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();
        onDelete();
    }

    const classNames = conditionalClassNames({
        FileUploadPreview: true,
        "FileUploadPreview--not-uploaded": !uploaded,
        "FileUploadPreview--error": hasError,
        "FileUploadPreview--upload-complete": progress === 100,
    }).join(" ");

    return (
        <div className={classNames}>
            {file && (
                <div className="FileUploadPreview__Thumb">
                    {preview ? <img src={preview} alt="Vorschau" /> : <Icon type="file" />}
                </div>
            )}
            {null !== progress && progress < 100 && (
                <div className="FileUploadPreview__Progress" style={{ width: `${progress}%` }} />
            )}
            {uploaded && onDelete && (
                <Button
                    theme="light"
                    className="FileUploadPreview__Delete"
                    icon="delete"
                    size="sm"
                    onClick={handleDelete}
                />
            )}
        </div>
    );
}

export default FileUpload;
