import * as React from "react";
import { createContext, useCallback, useEffect } from "react";
import { useAccessTokenExchange, UseAccessTokenExchangeProps } from "./useAccessTokenExchange";
import useWorkHubState from "../state/useWorkHubState";
import useWorkHubDispatch from "../state/useWorkHubDispatch";
import LoginPanel from "./LoginPanel";
import ExchangeErrorPanel from "./ExchangeErrorPanel";

import { EmployeeInfo } from "../state/types/employee-info";

export const AuthenticationContext = createContext({
    accessToken: null as string | null,
    employeeInfo: null as EmployeeInfo | null,
    onLogout: (() => null) as () => unknown,
    secondsLeft: null as number | null
});

type AuthenticationProps = {
    children?: React.ReactNode;
};

export function Authentication(props: AuthenticationProps) {
    const { children } = props;

    const {
        auth: { token: authToken },
        access: { token: accessToken, exchangeError, secondsLeft },
        employeeInfo
    } = useWorkHubState();
    const dispatch = useWorkHubDispatch();

    const onExchangeSuccess: UseAccessTokenExchangeProps["onSuccess"] = useCallback(
        ({ accessToken, employeeInfo, expiresIn }) => {
            dispatch({
                type: "finishTokenExchange", payload: {
                    accessToken: accessToken,
                    employeeInfo: employeeInfo,
                    expiresIn: expiresIn
                }
            });
        }, [dispatch]);

    const onExchangeError: UseAccessTokenExchangeProps["onError"] = useCallback(
        (error: string) => {
            dispatch({ type: "failTokenExchange", payload: { error } });
        }, [dispatch]);

    const onLogout: UseAccessTokenExchangeProps["onExpired"] = useCallback(
        () => {
            dispatch({ type: "logout" });
            document.location.reload();
        }, [dispatch]);

    const onExpired = useCallback(() => () => null, []);

    useAccessTokenExchange(authToken, {
        onSuccess: onExchangeSuccess,
        onError: onExchangeError,
        onExpired: onExpired
    });

    useEffect(() => {
        if (secondsLeft && secondsLeft > 0) {
            const interval = setInterval(() => {
                dispatch({ type: "updateAccessTimeout" });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [secondsLeft]);

    useEffect(() => {
        if (exchangeError) {
            const timeout = setTimeout(document.location.reload, 2500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [exchangeError]);

    return (
        <>
            {exchangeError && <ExchangeErrorPanel />}
            {!exchangeError && !authToken && <LoginPanel />}
            {accessToken && (
                <AuthenticationContext.Provider value={{ accessToken, employeeInfo, onLogout, secondsLeft }}>
                    {children}
                </AuthenticationContext.Provider>
            )}
        </>
    );
}
