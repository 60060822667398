import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { conditionalClassNames } from "../../../dom-utils/class-names";

function splitValue(value): [string, string, string] {
    // Value *should* be in the form YYYY-MM-DD.
    // But it is handled gracefully if it is not. That means:
    //    - If there are more than 3 parts, the last parts are ignored.
    //    - If there are less than 3 parts, the missing parts are filled with 0.
    //    - If there are empty parts, they are filled with an empty string.
    //    - It is possible to pass different digit numbers for the year, month and day. E.g. 2021-1-1 or 999-1-5.
    //
    // Examples:
    //     console.log(splitValue("2022-09-22"));   // Output: ["2022", "09", "22"]
    //     console.log(splitValue("2021-1-1"));     // Output: ["2021", "1", "1"]
    //     console.log(splitValue("999-1-5"));      // Output: ["999", "1", "5"]
    //     console.log(splitValue("2022-09"));      // Output: ["2022", "09", ""]
    //     console.log(splitValue("--"));           // Output: ["", "", ""]
    //     console.log(splitValue("---"));          // Output: ["", "", ""]
    //     console.log(splitValue("-1-"));          // Output: ["", "1", ""]
    //     console.log(splitValue("abcd-ef-gh"));   // Output: ["", "", ""]

    const result: [string, string, string] = ["", "", ""];

    const parts = value.split("-");

    for (let i = 0; i < 3; i++) {
        // Use the part if it exists, otherwise it will be an empty string (from the initialized array).
        if (parts[i] !== undefined) {
            // Only include numbers. If the part is not a number, it will remain an empty string.
            if (/^\d+$/.test(parts[i])) {
                result[i] = parts[i];
            }
        }
    }

    return result;
}

function useCompositeBlur(onBlur: () => unknown) {
    const [focus, setFocus] = useState(false);
    const blurTimeout = useRef(null);

    useEffect(() => {
        return () => {
            if (blurTimeout.current) {
                clearTimeout(blurTimeout.current);
            }
        };
    }, []);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocus(true);
        e.currentTarget?.select();
        if (blurTimeout.current) {
            clearTimeout(blurTimeout.current);
            blurTimeout.current = null;
        }
    };

    const handleBlur = () => {
        setFocus(false);
        blurTimeout.current = setTimeout(() => {
            if (onBlur) {
                onBlur();
            }
        }, 0);
    };

    return { focus, handleBlur, handleFocus };
}

type CompositeInputProps = React.HTMLAttributes<HTMLDivElement> & {
    value: string;
    onChange: (value: string) => unknown;
    onBlur?: () => unknown;
    disabled?: boolean;
    invalid?: boolean;
};

export function CompositeDateInput(props: CompositeInputProps) {
    const { value, onChange, onBlur, disabled, invalid = false, ...divProps } = props;

    const [year, month, day] = splitValue(value);

    const handleChange = (partName, value) => {
        // Ignore non numeric values
        if (!/^\d*$/.test(value)) {
            return;
        }

        const valueNum = Number(value);
        if (partName === "day" && (value.length > 1 || valueNum > 3)) {
            monthRef?.current?.focus();
        } else if (partName === "month" && (value.length > 1 || valueNum > 1)) {
            yearRef?.current?.focus();
        }

        const parts = [year, month, day];
        const partIdx = ["year", "month", "day"].indexOf(partName);
        parts[partIdx] = value;
        const newValue = parts.join("-");

        onChange(newValue);
    };

    const { focus, handleFocus, handleBlur } = useCompositeBlur(onBlur);

    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);

    const classNames = conditionalClassNames({
        CompositeDateInput: true,
        "CompositeDateInput--focus": focus,
        "CompositeDateInput--disabled": disabled,
        "CompositeDateInput--invalid": invalid,
    });

    return (
        <div className={classNames.join(" ")} {...divProps}>
            <input
                ref={dayRef}
                className="CompositeDateInput__Input CompositeDateInput__Input--day form-control"
                type="text"
                value={day}
                placeholder="T"
                maxLength={2}
                aria-label="Tag"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => handleChange("day", e.currentTarget.value)}
                disabled={disabled}
            />
            <span className="CompositeDateInput__Separator" />
            <input
                ref={monthRef}
                className="CompositeDateInput__Input CompositeDateInput__Input--month form-control"
                type="text"
                value={month}
                placeholder="M"
                maxLength={2}
                aria-label="Monat"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => handleChange("month", e.currentTarget.value)}
                disabled={disabled}
            />
            <span className="CompositeDateInput__Separator" />
            <input
                ref={yearRef}
                className="CompositeDateInput__Input CompositeDateInput__Input--year form-control"
                type="text"
                value={year}
                placeholder="J"
                maxLength={4}
                aria-label="Jahr"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => handleChange("year", e.currentTarget.value)}
                disabled={disabled}
            />
        </div>
    );
}
