import { conditionalClassNames, joinClasses } from "../dom-utils/class-names";
import * as React from "react";

export function DataTable2(props: React.HTMLAttributes<HTMLTableElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("DataTable2", divProps.className);
    return <table {...divProps}>{children}</table>;
}

export function DataTable2Head(props: React.HTMLAttributes<HTMLTableSectionElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("DataTable2__Head", divProps.className);
    return <thead {...divProps}>{children}</thead>;
}

DataTable2.Head = DataTable2Head;

export function DataTable2HeadRow(props: React.HTMLAttributes<HTMLTableRowElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("DataTable2__Head__Row", divProps.className);
    return <tr {...divProps}>{children}</tr>;
}

DataTable2.HeadRow = DataTable2HeadRow;

export function DataTable2HeadCell(
    props: {
        align?: "left" | "center" | "right";
    } & React.HTMLAttributes<HTMLTableHeaderCellElement>
) {
    const { children, ...otherProps } = props;
    otherProps.className = joinClasses("DataTable2__Head__Cell", otherProps.className);
    return (
        <DataTable2Cell as="th" {...otherProps}>
            {children}
        </DataTable2Cell>
    );
}

DataTable2.HeadCell = DataTable2HeadCell;

export function DataTable2Body(props: React.HTMLAttributes<HTMLTableSectionElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("DataTable2__Body", divProps.className);
    return <tbody {...divProps}>{children}</tbody>;
}

DataTable2.Body = DataTable2Body;

export function DataTable2Row(props: React.HTMLAttributes<HTMLTableRowElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("DataTable2__Row", divProps.className);
    return <tr {...divProps}>{children}</tr>;
}

DataTable2.Row = DataTable2Row;

export function DataTable2Cell(
    props: {
        as?: "th" | "td";
        align?: "left" | "center" | "right";
        primary?: boolean;
    } & React.HTMLAttributes<HTMLTableDataCellElement>
) {
    const { as = "td", align = "left", primary = false, children, ...elProps } = props;
    elProps.className = joinClasses(
        ...conditionalClassNames({
            DataTable2__Cell: true,
            [`DataTable2__Cell--align-${align}`]: true,
            [`DataTable2__Cell--primary`]: primary,
        }),
        elProps.className
    );
    return React.createElement(as, elProps, children);
}

DataTable2.Cell = DataTable2Cell;
