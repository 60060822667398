import * as React from "react";
import Button from "../../../shared/Button";
import useEttStatusTransition from "./useEttStatusTransition";
import { checkIn } from "../../../api/ett-statuses";

type CheckedOutActionProps = Record<string, never>;
export default function CheckedOutActions(_props: CheckedOutActionProps) {
    const transitionCheckIn = useEttStatusTransition(checkIn);

    return (
        <Button colorTheme="rose" onClick={transitionCheckIn} block={true}>
            Einchecken
        </Button>
    );
}
