import React from "react";
import Cell from "../cell";
import cellWidths from "../cell-widths";
import NumericValue from "../numeric-value";
import { AdjustmentInfo } from "./adjustment-info";
import PropTypes from "prop-types";

export default function WeekSummaryRow({ summary }) {
    return (
        <tr style={{ borderBottom: "2px solid black" }}>
            <Cell center={true}>
                <div className="fw-bold">SUMME</div>
            </Cell>
            {
                <>
                    {[1, 2, 3, 4, 5, 6, 7].map((wday) => (
                        <Cell
                            key={wday}
                            style={{
                                width: cellWidths.hoursExtended,
                                borderLeft: wday === 1 ? "2px solid #ccc" : null,
                            }}
                            numeric={true}
                        >
                            <NumericValue
                                label={`Geleistete Arbeitsstunden Wochentag ${wday}`}
                                humanValue={summary.days[wday].human.performed_work_hours}
                                robotValue={summary.days[wday].performed_work_hours}
                            />
                            <br />
                            <NumericValue
                                label={`Angerechnete Arbeitsstunden Wochentag ${wday}`}
                                humanValue={summary.days[wday].human.credited_work_hours}
                                robotValue={summary.days[wday].credited_work_hours}
                            />
                            {summary.days[wday].adjustment_hours !== "0.0" && (
                                <>
                                    <br />
                                    <AdjustmentInfo
                                        robot={summary.days[wday].adjustment_hours}
                                        human={summary.days[wday].human.adjustment_hours}
                                    />
                                </>
                            )}
                        </Cell>
                    ))}
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        <NumericValue
                            label="Bezahlte Abwesenheiten"
                            humanValue={summary.week.human.paid_absent_hours}
                            robotValue={summary.week.paid_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        <NumericValue
                            label="Unbezahlte Abwesenheiten"
                            humanValue={summary.week.human.unpaid_absent_hours}
                            robotValue={summary.week.unpaid_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        <NumericValue
                            label="Abwesenheiten mit Stundengutschrift"
                            humanValue={summary.week.human.credited_absent_hours}
                            robotValue={summary.week.credited_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        <NumericValue
                            label="Abwesenheiten ohne Stundengutschrift"
                            humanValue={summary.week.human.uncredited_absent_hours}
                            robotValue={summary.week.uncredited_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        <NumericValue
                            label="Geleistete Arbeitsstunden"
                            humanValue={summary.week.human.performed_work_hours}
                            robotValue={summary.week.performed_work_hours}
                        />
                        <br />
                        <NumericValue
                            label="Angerechnete Arbeitsstunden"
                            humanValue={summary.week.human.final_credited_work_hours}
                            robotValue={summary.week.final_credited_work_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Soll-Arbeitsstunden"
                            humanValue={summary.week.human.target_hours}
                            robotValue={summary.week.target_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Differenz"
                            humanValue={summary.week.human.diff_hours}
                            robotValue={summary.week.diff_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Saldo Vorwoche"
                            humanValue={summary.week.human.prev_week_balance}
                            robotValue={summary.week.prev_week_balance}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Saldo aktuelle Woche"
                            humanValue={summary.week.human.this_week_balance}
                            robotValue={summary.week.this_week_balance}
                        />
                    </Cell>
                </>
            }
            <Cell style={{ width: cellWidths.actions, borderLeft: "2px solid #ccc" }} className="text-end" />
        </tr>
    );
}

WeekSummaryRow.propTypes = {
    children: PropTypes.any,
    summary: PropTypes.any,
};
