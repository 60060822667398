import { DataContextType } from "../DataContext";
import {
    deleteAbsenceFromSlots,
    deleteDeploymentFromSlots,
    deleteEmployeeNote as deleteEmployeeNoteFromState,
    deleteScheduleDailyNote,
    insertAbsenceIntoSlots,
    insertDeploymentIntoSlots,
    insertEmployeeNote as insertEmployeeNoteIntoState,
    insertEmployeeWeekIntoSlots,
    insertScheduleDailyNote,
    updateAbsenceIntoSlots,
    updateDeploymentColorInSlots,
    updateDeploymentInSlots,
    updateDeploymentSequence,
    updateDeploymentSequences,
    updateEmployeeNote as updateEmployeeNoteInState,
    updateScheduleDailyNote,
} from "../reducers";
import {
    ApiResult,
    DayOfWeek,
    DeploymentSequenceMap,
    EmployeeNote,
    EmployeeNotesDirectory,
    UpdateApiResult,
} from "../types";
import {
    Absence,
    CreateAbsenceReponseData,
    CustomItemColor,
    DeploymentSequence,
    DeploymentSlot,
    EmployeeDayConditions,
    Schedule,
    ScheduleDailyNote,
    ScheduleDailyNotesMap,
} from "../../types";
import { Dispatch, SetStateAction } from "react";

export function buildDataContextValue(
    schedule: Schedule,
    slots: DeploymentSlot[] | null,
    setSlots: Dispatch<SetStateAction<DeploymentSlot[]>>,
    deploymentSequences: DeploymentSequenceMap | null,
    setDeploymentSequences: Dispatch<SetStateAction<DeploymentSequenceMap>>,
    employeeNotesDirectory: EmployeeNotesDirectory | null,
    setEmployeeNotesDirectory: Dispatch<SetStateAction<EmployeeNotesDirectory>>,
    scheduleDailyNotes: ScheduleDailyNotesMap | null,
    setScheduleDailyNotes: Dispatch<SetStateAction<ScheduleDailyNotesMap>>
): DataContextType {
    return {
        newDeployment: (departmentId, employeeId, dow) => {
            const values = { start_time: "08:00", end_time: "16:00", warnings: [] };
            setSlots(insertDeploymentIntoSlots(slots, departmentId, employeeId, dow, values));
        },

        createDeployment: (departmentId, employeeId, dow, values) => {
            return new Promise<UpdateApiResult>((resolve) => {
                const options = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", Accept: "application/json" },
                    body: JSON.stringify({
                        deployment: {
                            department_id: departmentId,
                            employee_id: employeeId,
                            day_of_week: dow,
                            start_time: values.start_time,
                            end_time: values.end_time,
                        },
                    }),
                };
                fetch(`/api/scheduler/schedules/${schedule.id}/deployments`, options)
                    .then((response) => response.json())
                    .then((apiResult: UpdateApiResult) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setSlots((slots) =>
                                insertDeploymentIntoSlots(slots, departmentId, employeeId, dow, {
                                    id: apiResult.data.id,
                                    start_time: apiResult.data.start_time,
                                    end_time: apiResult.data.end_time,
                                    deployment_sequence_id: apiResult.data.deployment_sequence_id,
                                    warnings: apiResult.warnings,
                                    edit_url: apiResult.data.edit_url,
                                })
                            );
                            setDeploymentSequences((deploymentSequences) =>
                                updateDeploymentSequences(
                                    deploymentSequences,
                                    apiResult.affected_deployment_sequences,
                                    apiResult.deleted_deployment_sequence_ids
                                )
                            );

                            // Insert new GlobalDeploymentInfo into every Day of the employee
                            const department = slots.find(
                                (slot) => slot.department.id === apiResult.data.department_id
                            )?.department;
                            setSlots((slots) => {
                                for (const slot of slots) {
                                    for (const employeeWeek of slot.employee_weeks) {
                                        if (employeeWeek.employee.id === employeeId) {
                                            const day = employeeWeek.days[apiResult.data.day_of_week];
                                            day.global_deployments = [
                                                ...day.global_deployments,
                                                {
                                                    id: apiResult.data.id,
                                                    department_name: department?.name ?? "Unbekannt",
                                                    start_time: apiResult.data.start_time,
                                                    end_time: apiResult.data.end_time,
                                                    unit_name: schedule.unit.name,
                                                },
                                            ];
                                        }
                                    }
                                }
                                return slots;
                            });
                        }
                        resolve(apiResult);
                    });
            });
        },

        updateDeployment: (id, values) => {
            // Replace color right away for snappiness – update optimistically.
            if (values.color) {
                setSlots(updateDeploymentColorInSlots(slots, id, values.color));
            }

            return new Promise<UpdateApiResult>((resolve) => {
                const options = {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json", Accept: "application/json" },
                    body: JSON.stringify({
                        deployment: {
                            start_time: values.start_time,
                            end_time: values.end_time,
                            color: values.color,
                        },
                    }),
                };
                fetch(`/api/scheduler/schedules/${schedule.id}/deployments/${id}`, options)
                    .then((response) => response.json())
                    .then((apiResult: UpdateApiResult) => {
                        resolve(apiResult);
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setSlots((slots) =>
                                updateDeploymentInSlots(
                                    slots,
                                    id,
                                    {
                                        id: apiResult.data.id,
                                        color: apiResult.data.color,
                                        start_time: apiResult.data.start_time,
                                        end_time: apiResult.data.end_time,
                                        deployment_sequence_id: apiResult.data.deployment_sequence_id,
                                        warnings: apiResult.warnings,
                                    },
                                    apiResult.deployment_sequence_assignments
                                )
                            );
                            setDeploymentSequences((deploymentSequences) =>
                                updateDeploymentSequences(
                                    deploymentSequences,
                                    apiResult.affected_deployment_sequences,
                                    apiResult.deleted_deployment_sequence_ids
                                )
                            );

                            // Update GlobalDeploymentInfo in every Day of the employee
                            setSlots((slots) => {
                                for (const slot of slots) {
                                    for (const employeeWeek of slot.employee_weeks) {
                                        if (employeeWeek.employee.id === apiResult.data.employee_id) {
                                            const day = employeeWeek.days[apiResult.data.day_of_week];
                                            const globalDeployment = day.global_deployments.find(
                                                (globalDeployment) => globalDeployment.id === apiResult.data.id
                                            );
                                            globalDeployment.start_time = apiResult.data.start_time;
                                            globalDeployment.end_time = apiResult.data.end_time;
                                        }
                                    }
                                }
                                return slots;
                            });
                        }
                    });
            });
        },

        deleteDeployment: (id) => {
            return new Promise<UpdateApiResult | null>((resolve) => {
                if (null !== id) {
                    const options = {
                        method: "DELETE",
                        headers: { Accept: "application/json" },
                    };
                    fetch(`/api/scheduler/schedules/${schedule.id}/deployments/${id}`, options)
                        .then((response) => response.json())
                        .then((apiResult: UpdateApiResult) => {
                            if (!apiResult.errors || apiResult.errors.length === 0) {
                                setSlots((slots) =>
                                    deleteDeploymentFromSlots(slots, id, apiResult.deployment_sequence_assignments)
                                );
                                setDeploymentSequences((deploymentSequences) =>
                                    updateDeploymentSequences(
                                        deploymentSequences,
                                        apiResult.affected_deployment_sequences,
                                        apiResult.deleted_deployment_sequence_ids
                                    )
                                );

                                // Delete GlobalDeploymentInfo from every Day of the employee
                                setSlots((slots) => {
                                    for (const slot of slots) {
                                        for (const employeeWeek of slot.employee_weeks) {
                                            if (employeeWeek.employee.id === apiResult.data.employee_id) {
                                                const day = employeeWeek.days[apiResult.data.day_of_week];
                                                const globalDeploymentIndex = day.global_deployments.findIndex(
                                                    (globalDeployment) => globalDeployment.id === apiResult.data.id
                                                );
                                                day.global_deployments.splice(globalDeploymentIndex, 1);
                                            }
                                        }
                                    }
                                    return slots;
                                });
                            }
                            resolve(apiResult);
                        });
                } else {
                    resolve(null);
                }
            });
        },

        newEmployeeWeek: (departmentId, employee) => {
            return new Promise<void>((resolve) => {
                fetch(`/api/scheduler/schedules/${schedule.id}/employees/${employee.id}/conditions`)
                    .then((response) => response.json())
                    .then((conditions: EmployeeDayConditions) => {
                        setSlots(insertEmployeeWeekIntoSlots(slots, departmentId, employee, conditions));
                        resolve();
                    });
            });
        },

        updateDeploymentSequence: (id, values) => {
            return new Promise<ApiResult<DeploymentSequence>>((resolve) => {
                const options = {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json", Accept: "application/json" },
                    body: JSON.stringify({
                        deployment_sequence: {
                            manual_break_seconds: values.manual_break_seconds,
                        },
                    }),
                };
                fetch(`/api/scheduler/schedules/${schedule.id}/deployment_sequences/${id}`, options)
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<DeploymentSequence>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setDeploymentSequences(
                                updateDeploymentSequence(deploymentSequences, {
                                    id: apiResult.data.id,
                                    manual_break_seconds: apiResult.data.manual_break_seconds,
                                    auto_break_seconds: apiResult.data.auto_break_seconds,
                                    break_seconds: apiResult.data.break_seconds,
                                })
                            );
                        }
                        resolve(apiResult);
                    });
            });
        },

        createAbsence: (employeeId, absenceReasonId, dow) => {
            return new Promise((resolve) => {
                return fetch(`/api/scheduler/schedules/${schedule.id}/absences`, {
                    method: "POST",
                    headers: { Accept: "application/json", "Content-Type": "application/json" },
                    body: JSON.stringify({
                        absence: {
                            employee_id: employeeId,
                            absence_reason_id: absenceReasonId,
                            dow: dow,
                        },
                    }),
                })
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<CreateAbsenceReponseData>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            const absence = apiResult.data.absence;
                            const dows = apiResult.data.days_covered;

                            let nextSlots = slots;
                            nextSlots = updateAbsenceIntoSlots(nextSlots, absence, employeeId, dows);
                            nextSlots = insertAbsenceIntoSlots(nextSlots, absence, employeeId, dows);
                            if (apiResult.data.deleted_absence) {
                                nextSlots = deleteAbsenceFromSlots(nextSlots, apiResult.data.deleted_absence);
                            }

                            setSlots(nextSlots);
                        }
                        resolve(apiResult);
                    });
            });
        },

        destroyAbsence: (absenceId: Absence["id"]) => {
            return new Promise((resolve) => {
                return fetch(`/api/scheduler/schedules/${schedule.id}/absences/${absenceId}`, {
                    method: "DELETE",
                    headers: { Accept: "application/json" },
                })
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<Absence>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setSlots(deleteAbsenceFromSlots(slots, absenceId));
                        }
                        resolve(apiResult);
                    });
            });
        },

        createEmployeeNote: (employeeId, dow, text) => {
            return new Promise((resolve) => {
                return fetch(`/api/scheduler/schedules/${schedule.id}/employee_notes`, {
                    method: "POST",
                    headers: { Accept: "application/json", "Content-Type": "application/json" },
                    body: JSON.stringify({
                        employee_note: {
                            employee_id: employeeId,
                            dow: dow,
                            text: text,
                        },
                    }),
                })
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<EmployeeNote>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setEmployeeNotesDirectory(
                                insertEmployeeNoteIntoState(employeeNotesDirectory, employeeId, dow, apiResult.data)
                            );
                        }
                        resolve(apiResult);
                    });
            });
        },

        updateEmployeeNote: (employeeNoteId, values) => {
            return new Promise<ApiResult<EmployeeNote>>((resolve) => {
                const options = {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json", Accept: "application/json" },
                    body: JSON.stringify({
                        employee_note: values,
                    }),
                };
                fetch(`/api/scheduler/schedules/${schedule.id}/employee_notes/${employeeNoteId}`, options)
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<EmployeeNote>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setEmployeeNotesDirectory(
                                updateEmployeeNoteInState(employeeNotesDirectory, employeeNoteId, {
                                    text: apiResult.data.text,
                                    color: apiResult.data.color,
                                })
                            );
                        }
                        resolve(apiResult);
                    });
            });
        },

        destroyEmployeeNote: (employeeNoteId) => {
            return new Promise((resolve) => {
                return fetch(`/api/scheduler/schedules/${schedule.id}/employee_notes/${employeeNoteId}`, {
                    method: "DELETE",
                    headers: { Accept: "application/json" },
                })
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<EmployeeNote>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setEmployeeNotesDirectory(
                                deleteEmployeeNoteFromState(employeeNotesDirectory, employeeNoteId)
                            );
                        }
                        resolve(apiResult);
                    });
            });
        },

        createScheduleDailyNote: (dayOfWeek: DayOfWeek, text: string, color: CustomItemColor) => {
            return new Promise((resolve) => {
                return fetch(`/api/scheduler/schedules/${schedule.id}/schedule_daily_notes`, {
                    method: "POST",
                    headers: { Accept: "application/json", "Content-Type": "application/json" },
                    body: JSON.stringify({
                        schedule_daily_note: {
                            day_of_week: dayOfWeek,
                            text: text,
                            color: color,
                        },
                    }),
                })
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<ScheduleDailyNote>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setScheduleDailyNotes(insertScheduleDailyNote(scheduleDailyNotes, apiResult.data));
                        }
                        resolve(apiResult);
                    });
            });
        },

        updateScheduleDailyNote: (scheduleDailyNoteId, values) => {
            return new Promise<ApiResult<ScheduleDailyNote>>((resolve) => {
                const options = {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json", Accept: "application/json" },
                    body: JSON.stringify({
                        schedule_daily_note: values,
                    }),
                };
                fetch(`/api/scheduler/schedules/${schedule.id}/schedule_daily_notes/${scheduleDailyNoteId}`, options)
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<ScheduleDailyNote>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setScheduleDailyNotes(
                                updateScheduleDailyNote(scheduleDailyNotes, scheduleDailyNoteId, {
                                    text: apiResult.data.text,
                                    color: apiResult.data.color,
                                })
                            );
                        }
                        resolve(apiResult);
                    });
            });
        },

        destroyScheduleDailyNote: (scheduleDailyNoteId) => {
            return new Promise((resolve) => {
                return fetch(`/api/scheduler/schedules/${schedule.id}/schedule_daily_notes/${scheduleDailyNoteId}`, {
                    method: "DELETE",
                    headers: { Accept: "application/json" },
                })
                    .then((response) => response.json())
                    .then((apiResult: ApiResult<ScheduleDailyNote>) => {
                        if (!apiResult.errors || apiResult.errors.length === 0) {
                            setScheduleDailyNotes(deleteScheduleDailyNote(scheduleDailyNotes, scheduleDailyNoteId));
                        }
                        resolve(apiResult);
                    });
            });
        },
    };
}
