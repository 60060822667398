import { EmployeesApiResponse } from "./api";

type Employee = {
    id: string;
    name: string;
};
type EmployeesState = {
    employeeOptions: Record<Employee["id"], Employee["name"]> | null;
    loading: boolean;
    loadingFailed: boolean | null;
};

export const initialState: EmployeesState = {
    employeeOptions: null,
    loading: true,
    loadingFailed: null,
};

export function reducer(draft: EmployeesState, action) {
    switch (action.type) {
        case "load": {
            draft.loading = true;
            draft.loadingFailed = null;
            return;
        }
        case "loadedJson": {
            const json: EmployeesApiResponse = action.payload;
            draft.employeeOptions = json.data.reduce((acc, employee) => {
                acc[employee.id] = employee.name;
                return acc;
            }, {});
            draft.loading = false;
            draft.loadingFailed = false;
            return;
        }
        case "loadingFailed": {
            draft.loading = false;
            draft.loadingFailed = true;
            return;
        }
    }
    throw Error("Unknown action: " + action.type);
}
