import Kitchensink from "../../../lib/kitchensink/Kitchensink";
import * as React from "react";
import ErrorBoundary from "../../../lib/errors/ErrorsBoundary";
import { useEmployeeState } from "./state";
import {
    AbsencesAndAbsenceCertificates
} from "./sections/absences-and-absence-certificates/AbsencesAndAbsenceCertificates";
import { WorkConditions } from "./sections/WorkConditions";
import { School } from "./sections/School";
import { EmployeeHeader } from "./EmployeeHeader";
import Loading, { LoadingSpinner } from "../../../lib/./empty-state/Loading";
import { LoadingError } from "../../../lib/errors/LoadingError";
import { EmploymentConditions } from "./sections/EmploymentConditions";
import { Vacation } from "./sections/Vacation";
import { TimeSheets } from "./sections/TimeSheets";
import { SkillsAndQualifications } from "./sections/SkillsAndQualifications";
import { conditionalClassNames } from "../../../lib/dom-utils/class-names";
import { WorkHub } from "./sections/WorkHub";

export function Factsheet() {
    return (
        <ErrorBoundary>
            <div className="EmployeeManager__Factsheet">
                <FactsheetInner />
            </div>
        </ErrorBoundary>
    );
}

export function FactsheetInner() {
    const { loadingFirst, loadingError } = useEmployeeState();
    if (loadingFirst) {
        return (
            <LoadingPanel>
                <Loading />
            </LoadingPanel>
        );
    }

    if (loadingError) {
        console.error(loadingError);
        return (
            <LoadingPanel>
                <LoadingError />
            </LoadingPanel>
        );
    }

    return (
        <>
            <LoadingOverlay />
            <EmployeeHeader />
            <Kitchensink className="EmployeeManager__Factsheet__Kitchensink">
                <WorkHub />
                <TimeSheets />
                <AbsencesAndAbsenceCertificates />
                <WorkConditions />
                <Vacation />
                <EmploymentConditions />
                <School />
                <SkillsAndQualifications />
            </Kitchensink>
        </>
    );
}

function LoadingPanel({ children }: { children?: React.ReactNode }) {
    return <div className="EmployeeManager__LoadingPanel">{children}</div>;
}

function LoadingOverlay() {
    const { loadingNext } = useEmployeeState();

    const classNames = conditionalClassNames({
        EmployeeManager__Factsheet__LoadingOverlay: true,
        "EmployeeManager__Factsheet__LoadingOverlay--loading": loadingNext
    });

    return (
        <div className={classNames.join(" ")}>
            <LoadingSpinner />
        </div>
    );
}
