import React from "react";
import Cell from "../cell";
import cellWidths from "../cell-widths";
import NumericValue from "../numeric-value";
import PropTypes from "prop-types";

export default function MonthSummaryRow({ summary }) {
    return (
        <tr style={{ borderBottom: "2px solid black" }}>
            <Cell center={true}>
                <div className="fw-bold">SUMME</div>
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                <NumericValue
                    label="Bezahlte Abwesenheiten"
                    humanValue={summary.human.paid_absent_hours}
                    robotValue={summary.paid_absent_hours}
                />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }} numeric={true}>
                <NumericValue
                    label="Unbezahlte Abwesenheiten"
                    humanValue={summary.human.unpaid_absent_hours}
                    robotValue={summary.unpaid_absent_hours}
                />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                <NumericValue
                    label="Abwesenheiten mit Stundengutschrift"
                    humanValue={summary.human.credited_absent_hours}
                    robotValue={summary.credited_absent_hours}
                />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }} numeric={true}>
                <NumericValue
                    label="Abwesenheiten ohne Stundengutschrift"
                    humanValue={summary.human.uncredited_absent_hours}
                    robotValue={summary.uncredited_absent_hours}
                />
            </Cell>
            <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                <NumericValue
                    label="Geleistete Arbeitsstunden"
                    humanValue={summary.human.performed_work_hours}
                    robotValue={summary.performed_work_hours}
                />
                <br />
                <NumericValue
                    label="Angerechnete Arbeitsstunden"
                    humanValue={summary.human.credited_work_hours}
                    robotValue={summary.credited_work_hours}
                />
            </Cell>
            <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                &nbsp;
                <br />
                <NumericValue
                    label="Korrekturwert"
                    humanValue={summary.human.adjustment_hours}
                    robotValue={summary.adjustment_hours}
                />
            </Cell>
            <Cell style={{ width: cellWidths.hours }} numeric={true}>
                <NumericValue
                    label="Geleistete Arbeitsstunden, korrigiert"
                    humanValue={summary.human.adjusted_performed_work_hours}
                    robotValue={summary.adjusted_performed_work_hours}
                />
                <br />
                <NumericValue
                    label="Angerechnete Arbeitsstunden, final"
                    humanValue={summary.human.final_credited_work_hours}
                    robotValue={summary.final_credited_work_hours}
                />
            </Cell>
            <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                &nbsp;
                <br />
                <NumericValue
                    label="Soll-Arbeitsstunden"
                    humanValue={summary.human.target_hours}
                    robotValue={summary.target_hours}
                />
            </Cell>
            <Cell style={{ width: cellWidths.hours }} numeric={true}>
                &nbsp;
                <br />
                <NumericValue label="Differenz" humanValue={summary.human.diff_hours} robotValue={summary.diff_hours} />
            </Cell>
            <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                &nbsp;
                <br />
                <NumericValue
                    label="Saldo Vormonat"
                    humanValue={summary.human.prev_month_balance}
                    robotValue={summary.prev_month_balance}
                />
            </Cell>
            <Cell style={{ width: cellWidths.hours }} numeric={true}>
                &nbsp;
                <br />
                <NumericValue
                    label="Saldo aktueller Monat"
                    humanValue={summary.human.current_month_balance}
                    robotValue={summary.current_month_balance}
                />
            </Cell>
            <td style={{ borderLeft: "2px solid #ccc" }} />
        </tr>
    );
}

MonthSummaryRow.propTypes = {
    summary: PropTypes.any,
};
