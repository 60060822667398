import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import { useFilterParams } from "./useFilterParams";
import { useCallback, useMemo } from "react";
import { AbsenceCategory } from "../types";

type NavigateReportOptions = {
    unitIds?: string[];
    startsOn?: DateTime;
    endsOn?: DateTime;
    categories?: AbsenceCategory[];
    includePresent?: boolean;
};
type NavigateReportType = (opts: NavigateReportOptions) => void;

export function useNavigateReport(): NavigateReportType {
    const filterParams = useFilterParams();
    const unitIdStrings = useMemo(() => filterParams.unitIds.map((id) => id.toString()), [filterParams.unitIds]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSearchParams] = useSearchParams();

    return useCallback(
        (props) => {
            const newParams = {
                unitIds: (props.unitIds || unitIdStrings).join(","),
                startsOn: (props.startsOn || filterParams.startsOn).toISODate(),
                endsOn: (props.endsOn || filterParams.endsOn).endOf("day").toISODate(),
                categories: (props.categories ?? filterParams.categories).join(","),
                includePresent: props.includePresent ?? filterParams.includePresent ? "true" : "false",
            };
            setSearchParams(newParams);
        },
        [
            unitIdStrings,
            filterParams.startsOn?.toISODate(),
            filterParams.endsOn?.toISODate(),
            filterParams.categories,
            filterParams.includePresent,
            setSearchParams,
        ]
    );
}
