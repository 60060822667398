import { useEffect, useState } from "react";
import { SkilledEmployee } from "../../../../../lib/types/employees";

export default function useEmployeeSelection(employees: SkilledEmployee[]) {
    const [selectedEmployee, setSelectedEmployee] = useState<SkilledEmployee | null>(null);

    useEffect(() => {
        if (employees.length > 0) {
            if (selectedEmployee === null) {
                setSelectedEmployee(employees[0]);
            } else {
                // If selected employee is included in employees, keep it.
                // Otherwise take the first available.
                const index = employees.findIndex((employee) => employee.id === selectedEmployee.id);
                if (index === -1) {
                    setSelectedEmployee(employees[0]);
                }
            }
        }
    }, [employees]);

    function selectNextEmployee() {
        const index = employees.findIndex((employee) => employee.id === selectedEmployee?.id);
        if (index < employees.length - 1) {
            setSelectedEmployee(employees[index + 1]);
        }
    }

    function selectPreviousEmployee() {
        const index = employees.findIndex((employee) => employee.id === selectedEmployee?.id);
        if (index > 0) {
            setSelectedEmployee(employees[index - 1]);
        }
    }

    return { selectedEmployee, selectNextEmployee, selectPreviousEmployee };
}
