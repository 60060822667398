import { Action } from "./actions";
import { createInitialState, WorkHubState } from "./state";

export const tokenExpirationGracePeriod = 0.9;

export function reducer(draft: WorkHubState, action: Action) {
    const args = [action.type];
    if (undefined !== action["payload"]) {
        args.push(action["payload"]);
    }
    console.log("🟦 ACTION: ", ...args);

    switch (action.type) {
        // ----- Debug ---------------------------------------------------------
        case "enableDevTools": {
            draft.ui.devToolsEnabled = true;
            return;
        }

        case "disableUiLockForDebugging": {
            draft.ui.lockAt = Date.now() + 365 * 24 * 60 * 60 * 1000;
            draft.ui.secondsLeftToLock = getSecondsLeft(draft.ui.lockAt);
            draft.access.expiresAt = draft.ui.lockAt;
            draft.access.secondsLeft = draft.ui.secondsLeftToLock;
            draft.access.expired = false;
            return;
        }

        // ----- Authentication ------------------------------------------------

        case "setAuthToken": {
            if (null === draft.auth.token) {
                draft.auth.token = action.payload;
            } else {
                console.warn("Refused to set token twice");
            }
            return;
        }

        case "finishTokenExchange": {
            draft.access.token = action.payload.accessToken;
            draft.access.expiresAt = Date.now() + (action.payload.expiresIn || 0) * 1000 * tokenExpirationGracePeriod;
            draft.access.secondsLeft = getSecondsLeft(draft.access.expiresAt);
            draft.ui.secondsLeftToLock = draft.access.secondsLeft;
            draft.employeeInfo = action.payload.employeeInfo;
            draft.ett.status = action.payload.employeeInfo.ettStatus;
            draft.ui.pageTitle = draft.employeeInfo.name;
            return;
        }

        case "failTokenExchange": {
            draft.access.exchangeError = action.payload.error;
            return;
        }

        case "updateAccessTimeout": {
            draft.access.secondsLeft = getSecondsLeft(draft.access.expiresAt);
            if (draft.ui.actionInProgress === null) {
                draft.ui.secondsLeftToLock = draft.access.secondsLeft;
            }
            draft.access.expired = draft.access.secondsLeft <= 0;
            return;
        }

        case "logout": {
            return createInitialState();
        }

        // ----- UI-------------------------------------------------------------

        case "updateUILockTimeout": {
            if (draft.ui.lockAt) {
                draft.ui.secondsLeftToLock = getSecondsLeft(draft.ui.lockAt);
            }
            return;
        }

        // ----- ETT status transitions ----------------------------------------

        case "startEttStatusTransition": {
            draft.ett.transitioning = true;
            draft.ett.error = null;
            draft.ui.actionInProgress = "transition_ett_status";
            return;
        }

        case "finishEttStatusTransition": {
            draft.ett.status = action.payload.newEttStatus;
            draft.ett.transitioning = false;
            draft.ui.lockAt = Date.now() + uiLockTimeouts.afterEttTransition;
            return;
        }

        case "failEttStatusTransition": {
            draft.ett.error = action.payload.error;
            draft.ett.transitioning = false;
            return;
        }

        // ----- Personal Schedules --------------------------------------------
        case "startLoadingPersonalSchedules": {
            draft.personalSchedule.loading = true;
            draft.personalSchedule.loadingError = null;
            draft.ui.actionInProgress = "request_personal_schedules";
            return;
        }

        case "finishLoadingPersonalSchedules": {
            draft.personalSchedule.personalScheduleSet = action.payload.personalScheduleSet;
            draft.personalSchedule.loading = false;
            draft.ui.lockAt = Date.now() + uiLockTimeouts.afterRequestSchedules;
            return;
        }

        case "failLoadingPersonalSchedules": {
            draft.personalSchedule.loadingError = action.payload.error;
            draft.personalSchedule.loading = false;
            return;
        }

        // ----- ETT Report ------- --------------------------------------------
        case "startLoadingEttReport": {
            draft.ettReport.loading = true;
            draft.ettReport.loadingError = null;
            draft.ui.actionInProgress = "request_ett_report";
            return;
        }

        case "finishLoadingEttReport": {
            draft.ettReport.ettReportSet = action.payload.ettReportSet;
            draft.ettReport.loading = false;
            draft.ui.lockAt = Date.now() + uiLockTimeouts.afterRequestEttReport;
            return;
        }

        case "failLoadingEttReport": {
            draft.ettReport.loadingError = action.payload.error;
            draft.ettReport.loading = false;
            return;
        }

        // ----- Fallback ------------------------------------------------------
        default:
            console.error("Unknown action: ", action["type"]);
    }
}

const uiLockTimeouts = {
    afterEttTransition: 2000,
    afterRequestSchedules: 60_000,
    afterRequestEttReport: 60_000
};

function getSecondsLeft(endsTimestamp) {
    return Math.max(0, Math.round((endsTimestamp - Date.now()) / 1000));
}
