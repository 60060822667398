export const ettStatuses = [
    "checked_in",
    "checked_out",
    "on_break"
];
export type EttStatus = typeof ettStatuses[number];
export const humanEttStatusMap: Record<EttStatus, string> = {
    checked_in: "eingecheckt",
    checked_out: "ausgecheckt",
    on_break: "in der Pause"
};
export type DefinitiveEmployeeState = EttStatus;
export type EmployeeStateTransition = "check_in" | "check_out" | "start_break" | "end_break";
