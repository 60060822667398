import { RecentSchedulesApiResponse } from "./api";
import { DateTime } from "luxon";

export type RecentSchedule = {
    id: string;
    weekName: string;
    unit: { name: string };
    balances: {
        humanPlanHours: string;
        humanPerformedWorkHours: string;
    };
};
export type RecentSchedulesState = {
    recentSchedules: RecentSchedule[] | null;
    schedulesCurrentWeek: RecentSchedule[] | null;
    schedulesNextWeek: RecentSchedule[] | null;
    schedulesLastWeek: RecentSchedule[] | null;
    loading: boolean;
    loadingFailed: boolean | null;
};

export const initialState: RecentSchedulesState = {
    recentSchedules: null,
    schedulesCurrentWeek: null,
    schedulesNextWeek: null,
    schedulesLastWeek: null,
    loading: true,
    loadingFailed: null,
};

export function reducer(draft: RecentSchedulesState, action) {
    switch (action.type) {
        case "load": {
            draft.loading = true;
            draft.loadingFailed = null;
            return;
        }
        case "loadedJson": {
            const currentIsoWeek = DateTime.local().toFormat("kkkk 'KW' W");
            const nextIsoWeek = DateTime.local().plus({ weeks: 1 }).toFormat("kkkk 'KW' W");
            const lastIsoWeek = DateTime.local().minus({ weeks: 1 }).toFormat("kkkk 'KW' W");

            const json: RecentSchedulesApiResponse = action.payload;
            draft.recentSchedules = json.data.map((schedule) => ({
                id: schedule.id,
                weekName: schedule.week_name,
                unit: { name: schedule.unit.name },
                balances: {
                    humanPlanHours: schedule.balances.human_plan_hours,
                    humanPerformedWorkHours: schedule.balances.human_performed_work_hours,
                },
            }));
            draft.schedulesCurrentWeek = draft.recentSchedules.filter(
                (schedule) => schedule.weekName === currentIsoWeek
            );
            draft.schedulesNextWeek = draft.recentSchedules.filter((schedule) => schedule.weekName === nextIsoWeek);
            draft.schedulesLastWeek = draft.recentSchedules.filter((schedule) => schedule.weekName === lastIsoWeek);
            draft.loading = false;
            draft.loadingFailed = false;
            return;
        }
        case "loadingFailed": {
            draft.loading = false;
            draft.loadingFailed = true;
            return;
        }
    }
    throw Error("Unknown action: " + action.type);
}
