import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../../../lib/dom-utils/class-names";

type TableDayProps = HTMLAttributes<HTMLDivElement> & {
    empty?: boolean;
    children: React.ReactNode;
};

export default function TableRowDay(props: TableDayProps) {
    const { children, empty = false, ...otherProps } = props;
    otherProps.className = joinClasses(
        "SchedulerTable__Row__Body__Day",
        props.className,
        empty && "SchedulerTable__Row__Body__Day--empty"
    );

    return <div {...otherProps}>{children}</div>;
}
