import * as React from "react";
import Icon from "../../icon/Icon";

export function ValidationErrorIndicator(): JSX.Element {
    return (
        <div className="h-5 w-5 text-red-500" aria-hidden="true">
            <Icon type="warning-circle" />
        </div>
    );
}

export default ValidationErrorIndicator;
