import * as React from "react";
import { PersonalSchedule } from "../../../../state/types/personal-schedule";
import TimeFrame from "../../../../shared/TimeFrame";
import Icon from "../../../../../../lib/icon/Icon";

type DeploymentProps = {
    deployment: PersonalSchedule["days"][number]["deployments"][number];
}

export default function Deployment(props: DeploymentProps) {
    const { deployment } = props;

    return (
        <div className="WorkHub__WeeklyDeploymentsList__Day__DeploymentsList__Deployment">
            <div className="WorkHub__WeeklyDeploymentsList__Day__DeploymentsList__Deployment__TimeFrame">
                <TimeFrame
                    startTime={deployment.startTime}
                    endTime={deployment.endTime}
                />
            </div>
            <div className="WorkHub__WeeklyDeploymentsList__Day__DeploymentsList__Deployment__Tags">
                <div>
                    <Icon type="unit" />
                    {" "}
                    {deployment.unitRef.name}
                </div>
                <div>
                    <Icon type="department" />
                    {" "}
                    {deployment.departmentRef.name}
                </div>
            </div>
        </div>
    );
}
