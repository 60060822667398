import * as React from "react";
import { useMemo } from "react";
import { useFilterParams } from "./useFilterParams";
import { buildReportUrl } from "./url";
import Icon from "../../../lib/icon/Icon";

export default function ExportButton() {
    const { unitIds, startsOn, endsOn, categories, includePresent } = useFilterParams();

    const url = useMemo(
        () => buildReportUrl(unitIds, startsOn, endsOn, categories, includePresent, "xlsx"),
        [unitIds, startsOn, endsOn, categories, includePresent]
    );

    return (
        <a href={url} download={"Abwesenheitsbericht.xlsx"} className="btn btn-success text-white">
            <Icon type="export-excel" />
            &nbsp; Export
        </a>
    );
}
