import { ThemeColor } from "../theme/colors";
import * as React from "react";

type ToastProps = {
    message: string;
    theme?: ThemeColor;
    onClose?: () => void;
};

export default function Toast(props: ToastProps) {
    const { message, onClose, theme = "primary" } = props;
    return (
        <div
            aria-atomic="true"
            aria-live="assertive"
            className={`toast bg-${theme} text-white fade show`}
            data-bs-autohide="false"
            role="alert"
        >
            <div className="d-flex">
                <div className="toast-body">{message}</div>
                {onClose && (
                    <button
                        aria-label="Close"
                        className="btn-close btn-close-white me-2 m-auto"
                        data-bs-dismiss="toast"
                        type="button"
                        onClick={onClose}
                    />
                )}
            </div>
        </div>
    );
}
