import { UnapprovedAbsencesApiResponse } from "./api";

type UnapprovedAbsence = {
    id: string;
    startsOn: string;
    endsOn: string;
    employee: {
        id: string;
        name: string;
    };
    reason: {
        name: string;
    };
};

type UnapprovedAbsencesState = {
    unapprovedAbsences: UnapprovedAbsence[] | null;
    loading: boolean;
    loadingFailed: boolean | null;
};

export const initialState: UnapprovedAbsencesState = {
    unapprovedAbsences: null,
    loading: true,
    loadingFailed: null,
};

export function reducer(draft: UnapprovedAbsencesState, action) {
    switch (action.type) {
        case "load": {
            draft.loading = true;
            draft.loadingFailed = null;
            return;
        }
        case "loadedJson": {
            const json: UnapprovedAbsencesApiResponse = action.payload;
            draft.unapprovedAbsences = json.data.map((absence) => ({
                id: absence.id,
                startsOn: absence.starts_on,
                endsOn: absence.ends_on,
                employee: {
                    id: absence.employee.id,
                    name: absence.employee.name,
                },
                reason: {
                    name: absence.reason.name,
                },
            }));
            draft.loading = false;
            draft.loadingFailed = false;
            return;
        }
        case "loadingFailed": {
            draft.loading = false;
            draft.loadingFailed = true;
            return;
        }
    }
    throw Error("Unknown action: " + action.type);
}
