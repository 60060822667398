import { EmployeeInfo } from "./types/employee-info";
import { EttReportSet } from "./types/ett-report";
import { EttStatus } from "./types/ett-status";
import { PersonalScheduleSet } from "./types/personal-schedule";

export type WorkHubState = {
    ui: {
        devToolsEnabled: boolean;
        actionInProgress: null | "transition_ett_status" | "request_personal_schedules" | "request_ett_report";
        actionError: null | string;
        lockAt: number | null;
        secondsLeftToLock: number | null;
        pageTitle: string | null;
    }
    access: {
        token: string | null;
        tokenUsed: boolean;
        expiresAt: number | null;
        secondsLeft: number | null;
        expired: boolean;
        exchangeError: string | null;
    }
    auth: {
        token: null | string;
    }
    employeeInfo: null | EmployeeInfo;
    ett: {
        status: null | EttStatus;
        transitioning: boolean;
        error: null | string;
    },
    personalSchedule: {
        personalScheduleSet: null | PersonalScheduleSet;
        loading: boolean;
        loadingError: string | null;
    },
    ettReport: {
        ettReportSet: null | EttReportSet;
        loading: boolean;
        loadingError: string | null;
    }
};

export function createInitialState() {
    // return {
    //     ui: {
    //         devToolsEnabled: true,
    //         actionInProgress: null,
    //         actionError: null,
    //         lockAt: null,
    //         secondsLeftToLock: null,
    //         pageTitle: null
    //     },
    //     access: {
    //         token: null,
    //         tokenUsed: false,
    //         expiresAt: null,
    //         secondsLeft: null,
    //         expired: false,
    //         exchangeError: null
    //     },
    //     auth: {
    //         token: "AuthTokenForDebugginInDevelopment-000001" // debug
    //     },
    //     employeeInfo: null,
    //     ett: {
    //         status: null,
    //         transitioning: false,
    //         error: null
    //     },
    //     personalSchedule: {
    //         personalScheduleSet: null,
    //         loading: false,
    //         loadingError: null
    //     },
    //     ettReport: {
    //         ettReportSet: null,
    //         loading: false,
    //         loadingError: null
    //     }
    // };

    return {
        ui: {
            devToolsEnabled: false,
            actionInProgress: null,
            actionError: null,
            lockAt: null,
            secondsLeftToLock: null,
            pageTitle: null
        },
        access: {
            token: null,
            tokenUsed: false,
            expiresAt: null,
            secondsLeft: null,
            expired: false,
            exchangeError: null
        },
        auth: {
            token: null
        },
        employeeInfo: null,
        ett: {
            status: null,
            transitioning: false,
            error: null
        },
        personalSchedule: {
            personalScheduleSet: null,
            loading: false,
            loadingError: null
        },
        ettReport: {
            ettReportSet: null,
            loading: false,
            loadingError: null
        }
    };
}

