import { DateTime, Interval } from "luxon";
import { dateTimeFormat, dateTimeFormatWithoutYear, timeFormat } from "./date-format";
import { DayOfWeek } from "./types";

export function formatHours(hours: string | number, signed = false): string {
    hours = Math.round(Number(hours) * 1000) / 1000;
    const negative = hours < 0;
    const absoluteHours = Math.abs(hours);
    const fraction = absoluteHours % 1;
    const minutes = Math.round(fraction * 60);
    const hoursInt = Math.floor(absoluteHours);
    return `${negative ? "-" : signed ? "+" : ""}${hoursInt}:${minutes < 10 ? "0" : ""}${minutes}`;
}

export function formatDays(days: number): string {
    return `${days.toLocaleString("de-DE", { minimumFractionDigits: 2 })} T`;
}

export function safeInterval(dt1: DateTime, dt2: DateTime) {
    if (dt1 > dt2) {
        return Interval.fromDateTimes(dt2, dt1);
    } else {
        return Interval.fromDateTimes(dt1, dt2);
    }
}

export function humanInterval(interval: Interval): string {
    const start = interval.start;
    const end = interval.end;
    const delimiter = " → ";

    if (end.year === start.year && end.month === start.month && end.day === start.day) {
        return `${start.toFormat(dateTimeFormat)}${delimiter}${end.toFormat(timeFormat)}`;
    } else if (start.year === end.year) {
        return `${start.toFormat(dateTimeFormat)}${delimiter}${end.toFormat(dateTimeFormatWithoutYear)}`;
    } else {
        return `${start.toFormat(dateTimeFormat)}${delimiter}${end.toFormat(dateTimeFormat)}`;
    }
}

const days = {
    1: "Montag",
    2: "Dienstag",
    3: "Mittwoch",
    4: "Donnerstag",
    5: "Freitag",
    6: "Samstag",
    7: "Sonntag"
};

export function humanDay(dow: DayOfWeek) {
    return days[dow];
}

export function shortHumanDay(dow: DayOfWeek) {
    return days[dow].substring(0, 2);
}

export const dayNumbers: DayOfWeek[] = [1, 2, 3, 4, 5, 6, 7];

export function castDateTime(date: DateTime | string) {
    return typeof date === "string" ? DateTime.fromISO(date) : date;
}

export function isWeekendDay(date: DateTime | string) {
    return castDateTime(date).weekday > 6; // For retail purposes, Saturday is not a weekend day. In fact it's the busiest day of the week.
}

export function humanDayForDate(dt: DateTime | string) {
    const dtObj = typeof dt === "string" ? DateTime.fromISO(dt) : dt;
    return humanDay(dtObj.weekday as DayOfWeek);
}
