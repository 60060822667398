import * as React from "react";
import { useEffect } from "react";
import useWorkHubState from "../../state/useWorkHubState";
import EttStatusPanel from "./ett-status-panel/EttStatusPanel";
import useWorkHubDispatch from "../../state/useWorkHubDispatch";
import EttPanel from "./././ett-panel/EttPanel";
import PersonalSchedulePanel from "./personal-schedule-panel/PersonalSchedulePanel";

type ActionsPageProps = Record<string, never>;
export default function ActionsPage(_prps: ActionsPageProps) {
    const { access: { expired } } = useWorkHubState();
    const dispatch = useWorkHubDispatch();

    // We're logging out on the actions page only. On the result page, we don't
    // care if the access token expires.
    useEffect(() => {
        if (expired) {
            dispatch({ type: "logout" });
        }
    }, [expired]);

    return (
        <>
            <EttStatusPanel />
            <PersonalSchedulePanel />
            <EttPanel />
        </>
    );
}
