import * as React from "react";
import { isSkillPlusLevel, Skill, SkillPlusLevel } from "../../../../../lib/types/skills";
import { conditionalClassNames } from "../../../../../lib/dom-utils/class-names";
import SkillIcon, { SkillIconType } from "../../../../../lib/icon/SkillIcon";

type SkillListProps = {
    skill: Skill | SkillPlusLevel;
};

export default function SkillBadge(props: SkillListProps) {
    const { skill } = props;
    const { name, icon, isPrimary } = skill;

    const classNames = conditionalClassNames({
        SkillBadge: true,
        "SkillBadge--primary": isPrimary,
        "SkillBadge--secondary": !isPrimary,
    });

    return (
        <div className={classNames.join(" ")}>
            <SkillIcon type={icon as SkillIconType} className="SkillBadge__Icon" />
            <div className="SkillBadge__Name">{name}</div>
            {isSkillPlusLevel(skill) && <div className="SkillBadge__Level">{skill.level}</div>}
        </div>
    );
}
