import * as React from "react";
import { useState } from "react";
import { NewAbsenceCertificateForm } from "./NewAbsenceCertificateForm";
import { Success } from "./Success";
import Modal3 from "../../../lib/modal/Modal3";

type NewAbsenceModalProps = {
    onClose: () => void;
    open: boolean;
};

export function NewAbsenceCertificateModal(props: NewAbsenceModalProps) {
    const { onClose, open } = props;
    const [absence, setAbsence] = useState(null);

    function handleClose() {
        setAbsence(null);
        onClose();
    }

    if (!open) {
        return null;
    }

    return (
        <Modal3 onClose={handleClose}>
            <Modal3.Header title="Neuer Abwesenheitsnachweis" />
            {absence ? (
                <Success onClose={handleClose} onNew={() => setAbsence(null)} data={absence} />
            ) : (
                <NewAbsenceCertificateForm onCancel={handleClose} onSuccess={(data) => setAbsence(data)} />
            )}
        </Modal3>
    );
}
