import * as React from "react";
import { conditionalClassNames, joinClasses } from "../../../lib/dom-utils/class-names";
import Icon from "../../../lib/icon/Icon";

type RecordProps = {
    highlight?: boolean;
    old?: boolean;
    children: React.ReactNode;
};

export function Record(props: RecordProps & React.HTMLAttributes<HTMLDivElement>) {
    const { highlight, old = false, children, ...divProps } = props;

    divProps.className = joinClasses(
        ...conditionalClassNames({
            EmployeeManager__Factsheet__Record: true,
            "EmployeeManager__Factsheet__Record--old": old,
            "EmployeeManager__Factsheet__Record--highlight": highlight
        }),
        divProps.className
    );
    return (
        <div {...divProps}>
            <div className="EmployeeManager__Factsheet__Record__Inner">{children}</div>
        </div>
    );
}

export function EditRecordLink(props: { editURL?: string } & React.HTMLAttributes<HTMLAnchorElement>) {
    const { editURL, ...aProps } = props;
    const classNames = joinClasses("EmployeeManager__Factsheet__EditRecordLink", aProps.className);

    return (
        <a {...aProps} className={classNames} href={editURL}>
            <Icon type="edit" />
        </a>
    );
}
