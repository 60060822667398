import * as React from "react";
import FormGroup from "../../../lib/form/form/FormGroup";
import { AbsenceResponseData } from "./NewAbsenceForm";
import Button from "../../../lib/button/Button";
import FormStaticValue from "../../../lib/form/form/FormStaticValue";
import Banner from "../../../lib/notifications/Banner";
import Modal3 from "../../../lib/modal/Modal3";

type SuccessProps = {
    data: AbsenceResponseData;
    onClose: () => unknown;
    onNew: () => unknown;
};

export function Success(props: SuccessProps) {
    const { data, onNew, onClose } = props;
    return (
        <>
            <Modal3.Body>
                <Banner theme="success" className="mb-3">
                    <Banner.Icon icon="done" />
                    <Banner.Message message="Abwesenheit wurde erstellt." />
                    <Banner.Action
                        title="Öffnen"
                        onClick={() => (document.location.href = `/employees/${data.employee.id}/absences/${data.id}`)}
                    />
                </Banner>
                <div className="mb-3">
                    <FormGroup label="Mitarbeiter">
                        <FormStaticValue>{data.employee.name}</FormStaticValue>
                    </FormGroup>
                    <div className="d-flex gap-2">
                        <FormGroup label="Abwesend ab" style={{ flex: "1 1 50%" }}>
                            <FormStaticValue>{data.starts_on}</FormStaticValue>
                        </FormGroup>
                        <FormGroup label="Voraussichtliche Rückkehr am" style={{ flex: "1 1 50%" }}>
                            <FormStaticValue>{data.ends_on}</FormStaticValue>
                        </FormGroup>
                    </div>
                    <FormGroup label="Grund">
                        <FormStaticValue>{data.absence_reason.name}</FormStaticValue>
                    </FormGroup>
                </div>
            </Modal3.Body>
            <Modal3.Footer>
                <div className="d-flex justify-content-between align-items-center">
                    <Button theme="primary" size="md" caption={"Weitere Abwesenheit erfassen"} onClick={onNew} />
                    <Button theme="secondary" size="md" caption={"Fertig"} onClick={onClose} />
                </div>
            </Modal3.Footer>
        </>
    );
}
