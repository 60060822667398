import * as React from "react";
import { useEffect } from "react";

import consumer from "../../../channels/consumer";
import { Absence, DepartmentBalanceMap, EmployeeBalanceMap, Schedule, ScheduleBalances } from "../types";

export type BalancesChannelData = {
    schedule_balances: ScheduleBalances;
    employee_balances: EmployeeBalanceMap;
    department_balances: DepartmentBalanceMap;
};

type SchedulerChannelSubscriptionProps = {
    scheduleId: Schedule["id"];
    onUpdateBalances: (balances: BalancesChannelData) => unknown;
    onUpdateAbsence: (absence: Absence) => unknown;
    onDeleteAbsence: (absenceId: Absence["id"]) => unknown;
    children: JSX.Element;
};

export default function SchedulerChannelSubscription(props: SchedulerChannelSubscriptionProps) {
    const { scheduleId, onUpdateBalances, onUpdateAbsence, onDeleteAbsence } = props;
    useEffect(() => {
        const subscription = consumer.subscriptions.create(
            {
                channel: "SchedulerChannel",
                schedule_id: scheduleId,
            },
            {
                received(data) {
                    if (data) {
                        if (data.absence) {
                            onUpdateAbsence(data.absence);
                        } else if (data.deleted_absence_id) {
                            onDeleteAbsence(data.deleted_absence_id);
                        } else {
                            onUpdateBalances(data);
                        }
                    }
                },
            }
        );
        return () => subscription.unsubscribe();
    }, [props.scheduleId]);

    return <>{props.children}</>;
}
