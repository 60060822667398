import * as React from "react";
import { useRecentSchedules } from "./useRecentSchedules";
import { DateTime } from "luxon";
import { ScheduleWidgetContent } from "./SchedulesWidgetContent";

export function LastWeekSchedules() {
    const { schedulesLastWeek, loading, loadingFailed } = useRecentSchedules();

    const pastIsoWeek = DateTime.local().minus({ weeks: 1 }).toFormat("kkkk 'KW' W");

    return (
        <ScheduleWidgetContent
            title="Vergangene Wochenpläne"
            weekName={pastIsoWeek}
            schedules={schedulesLastWeek}
            loading={loading}
            loadingFailed={loadingFailed}
        />
    );
}
