import { SkilledEmployee } from "../../../../../lib/types/employees";
import { Schedule } from "../../../types";
import { Skill, SkillPlusLevel } from "../../../../../lib/types/skills";
import { GetAvailableEmployeesResponse, GetAvailableEmployeesResponseEmployee } from "../../../types/api-responses";

export async function searchEmployees(
    scheduleId: Schedule["id"],
    query: string,
    signal: AbortSignal
): Promise<GetAvailableEmployeesResponse> {
    const response = await fetch(`/api/scheduler/schedules/${scheduleId}/employees/available?query=${query}`, {
        signal,
    });
    return response.json();
}

export function responseToSkilledEmployees(data: GetAvailableEmployeesResponse, skills: Skill[]): SkilledEmployee[] {
    return data.map((rawEmployee) => ({
        ...rawEmployee,
        skills: responseSkillLevelsToSkills(rawEmployee.skill_levels, skills),
    }));
}

function responseSkillLevelsToSkills(
    skillLevels: GetAvailableEmployeesResponseEmployee["skill_levels"],
    skills: Skill[]
): SkillPlusLevel[] {
    // Filter out skill levels that don't match the skills we have.
    const filteredSkillLevels = skillLevels.filter((skillLevel) => {
        return skills.find((skill) => skill.id === skillLevel.skill_id) !== undefined;
    });

    // Map the skill levels to skills.
    return filteredSkillLevels.map((skillLevel) => {
        const skill = skills.find((skill) => skill.id === skillLevel.skill_id);
        return {
            ...skill,
            level: skillLevel.level,
        };
    });
}
