import * as React from "react";
import { Outlet } from "react-router-dom";
import Chrome from "../../../lib/chrome/Chrome";

export default function Layout() {
    return (
        <div className="h-100 d-flex flex-column overflow-hidden">
            <Chrome.DetailPage.Header>
                <Chrome.DetailPage.HeaderMain>
                    <Chrome.DetailPage.PageSupertitle>Berichte & Prognosen</Chrome.DetailPage.PageSupertitle>
                    <Chrome.DetailPage.PageTitle>Urlaub</Chrome.DetailPage.PageTitle>
                </Chrome.DetailPage.HeaderMain>
            </Chrome.DetailPage.Header>
            <Outlet />
        </div>
    );
}
