import { useImmerReducer } from "use-immer";
import { initialState, reducer } from "./reducer";
import { useEffect } from "react";
import { fetchRecentSchedules } from "./api";

export function useRecentSchedules() {
    const [
        { recentSchedules, schedulesNextWeek, schedulesCurrentWeek, schedulesLastWeek, loading, loadingFailed },
        dispatch,
    ] = useImmerReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: "load" });
        let ignore = false;

        fetchRecentSchedules()
            .then((json) => {
                if (ignore) {
                    return;
                }
                dispatch({ type: "loadedJson", payload: json });
            })
            .catch((error) => {
                if (ignore) {
                    return;
                }
                console.error(error);
                dispatch({ type: "loadingFailed" });
            });
        return () => {
            ignore = false;
        };
    }, []);

    return { recentSchedules, schedulesCurrentWeek, schedulesNextWeek, schedulesLastWeek, loading, loadingFailed };
}
