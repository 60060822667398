import * as React from "react";
import { BrowserRouter, Route, Routes as RouterRoutes } from "react-router-dom";
import Table from "./Table";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";

const Auditor: React.FC = () => {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <BrowserRouter basename="/audit">
                    <RouterRoutes>
                        <Route index element={<Table />} />
                    </RouterRoutes>
                </BrowserRouter>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

export default Auditor;
