import * as React from "react";
import { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { createInitialState, State } from "./state/state";
import { Action } from "./state/actions";
import { reducer } from "./state/reducer";

const StateContext = createContext<{
    state: State,
    dispatch: React.Dispatch<Action>,
}>(undefined);

type FilterContextProviderProps = {
    children: React.ReactNode;
}

export default function StateContextProvider(props: FilterContextProviderProps) {
    const { children } = props;

    const [state, dispatch] = useImmerReducer(reducer, createInitialState());

    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {children}
        </StateContext.Provider>
    );
}

export function useReportState() {
    return useContext(StateContext).state;
}

export function useReportDispatch() {
    return useContext(StateContext).dispatch;
}
