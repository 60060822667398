import * as React from "react";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { DeploymentSlot } from "../types";
import { CellPayload, CursorPosition } from "./types";
import { buildCursorGrid, moveCursor } from "./grid";
import Cursor from "./Cursor";
import { CursorPositionContext } from "./CursorPositionContext";
import { DeploymentSequenceMap } from "../slots/types";
import { DataContext } from "../slots/DataContext";
import { EmployeeNotesContext } from "../slots/SlotsController";

type CursorAnchorType = unknown;
export type CursorAnchorSetter = Dispatch<SetStateAction<CursorAnchorType>>;

type CursorNavigationProps = {
    slots: DeploymentSlot[];
    deploymentSequences: DeploymentSequenceMap;
    children: (args: { setCursorAnchorElement: CursorAnchorSetter }) => React.ReactNode;
};

export default function CursorNavigation({ slots, deploymentSequences, children }: CursorNavigationProps) {
    const [cursorPosition, setCursorPosition] = useState<CursorPosition>(null);
    const [cursorGrid, setCursorGrid] = useState(null);
    const { deleteDeployment } = useContext(DataContext);
    const employeeNotes = useContext(EmployeeNotesContext);

    // Why a callback ref and not simply useRef()?
    // See: https://stackoverflow.com/a/59605067/616292
    // See: https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    const [cursorAnchorElement, setCursorAnchorElement] = useState<CursorAnchorType>(null);

    const currentCellPayload =
        cursorPosition &&
        cursorGrid &&
        cursorGrid[cursorPosition.row] &&
        cursorGrid[cursorPosition.row][cursorPosition.col] &&
        cursorGrid[cursorPosition.row][cursorPosition.col][cursorPosition.i];
    useEffect(() => {
        if (slots && deploymentSequences) {
            const newCursorGrid = buildCursorGrid(slots, deploymentSequences, employeeNotes);
            setCursorGrid(newCursorGrid);
        }
    }, [slots, deploymentSequences, employeeNotes]);

    useEffect(() => {
        const handleKeyDown = (keyEvent) => {
            if (!cursorGrid) {
                return;
            }

            const stopEvent = () => {
                keyEvent.stopPropagation();
                keyEvent.preventDefault();
            };
            const performMove = (rows, cols) => {
                stopEvent();
                setCursorPosition(moveCursor(cursorPosition, cursorGrid, rows, cols));
            };

            const modifierPressed = keyEvent.ctrlKey || keyEvent.metaKey || keyEvent.altKey;

            switch (keyEvent.key) {
                case "ArrowUp":
                    performMove(-1, 0);
                    break;
                case "ArrowDown":
                    performMove(+1, 0);
                    break;
                case "ArrowLeft":
                    if (!modifierPressed) {
                        // Allow navigating in input fields with Ctrl+ArrowLeft etc.
                        performMove(0, -1);
                    }
                    break;
                case "ArrowRight":
                    if (!modifierPressed) {
                        // Allow navigating in input fields with Ctrl+ArrowRight etc.
                        performMove(0, +1);
                    }
                    break;
                case "Enter":
                    // case " ": // What exactly was the idea behind this?
                    setCursorPosition({ ...cursorPosition, editorOpen: true, tab: 0 });
                    if (keyEvent.target.constructor.name !== "HTMLButtonElement") {
                        // Don't catch button events, eq. for new deployment button, which might listen for onClick().
                        stopEvent();
                    }
                    break;
                case "Escape":
                    // Observed a strange behavior with Safari 15: When closing the cursor with an focused input
                    // the page jumps all the way to the bottom. Looks like a glitch.
                    // The problem can be circumvented by removing the focus before closing the cursor.
                    if ("blur" in document.activeElement) {
                        (document.activeElement as HTMLElement).blur();
                    }

                    setCursorPosition({ ...cursorPosition, editorOpen: false });
                    deleteDeployment(null);
                    stopEvent();
                    break;
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [cursorGrid, cursorPosition]);

    function getCellPayload(row: number, col: number, i: number): CellPayload {
        return cursorGrid[row][col][i];
    }

    return (
        <CursorPositionContext.Provider
            value={{ cursorPosition, setCursorPosition, currentCellPayload, getCellPayload }}
        >
            <div className="SchedulerBody__ScrollContent">
                {children({ setCursorAnchorElement })}
                {cursorAnchorElement && (
                    <Cursor
                        cursorAnchorElement={cursorAnchorElement}
                        currentCellPayload={currentCellPayload}
                        cursorPosition={cursorPosition}
                        setCursorPosition={setCursorPosition}
                        getCellPayload={getCellPayload}
                    />
                )}
            </div>
        </CursorPositionContext.Provider>
    );
}
