import * as React from "react";
import { ChangeEvent, HTMLAttributes, InputHTMLAttributes, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { dateFormat } from "../../datetime/date-format";
import { isPresent } from "../../object/objects";
import buildInputProps from "../utils/build-input-props";
import ValidationErrors from "../validation/ValidationErrors";

type DateFieldProps = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
    value: DateTime | null;
    invalid?: boolean;
    onChange: (dt: DateTime) => void;
};

export default function DateField(props: DateFieldProps) {
    const { value, invalid: isInvalidFromExternalScope = false, onChange, ...formGroupProps } = props;

    const [inputValue, setInputValue] = useState<string>(value?.toFormat(dateFormat) ?? "");
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    useEffect(() => {
        setInputValue(value?.toFormat(dateFormat) ?? "");
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const nextInputValue = e.currentTarget.value.trim();

        if (isPresent(nextInputValue)) {
            const dt = DateTime.fromFormat(nextInputValue, dateFormat);
            setIsInvalid(!dt.isValid);

            if (dt.isValid) {
                onChange(dt);
            }
        }

        setInputValue(nextInputValue);
    };

    const inputProps = buildInputProps(
        {
            type: "text",
            value: inputValue,
        },
        { invalid: isInvalid || isInvalidFromExternalScope }
    ) as InputHTMLAttributes<HTMLInputElement>;

    const errors: string[] = [];

    if (isInvalid) {
        errors.push("Ungültiges Datumsformat (dd.mm.yyyy)");
    }
    if (isInvalidFromExternalScope) {
        errors.push("Ungültiges Datum");
    }

    return (
        <div {...formGroupProps}>
            <input onChange={handleChange} {...inputProps} />
            <ValidationErrors errors={errors} />
        </div>
    );
}
