import * as React from "react";
import { useContext } from "react";

import { DeploymentSlot as DeploymentSlotType } from "../../types";
import DeploymentSlotRowHeader from "./DeploymentSlotRowHeader";
import EmployeeWeekRow from "./employee-week-row/EmployeeWeekRow";
import { CursorPositionContext } from "../../cursor/CursorPositionContext";
import { CursorAnchorSetter } from "../../cursor/CursorNavigation";
import Table from "../../table/Table";

type DeploymentSlotProps = {
    slot: DeploymentSlotType;
    setCursorAnchorElement: CursorAnchorSetter;
    renderRow: number;
};

export default function DeploymentSlotRow({ slot, setCursorAnchorElement, renderRow }: DeploymentSlotProps) {
    const classNames = ["SchedulerDeploymentSlot"];

    const { cursorPosition } = useContext(CursorPositionContext);
    if (cursorPosition?.row === renderRow) {
        classNames.push("SchedulerDeploymentSlot--cursor");
    }

    return (
        <Table className={classNames.join(" ")} role="region" aria-label={slot.department.name}>
            <DeploymentSlotRowHeader
                department={slot.department}
                renderRow={renderRow}
                setCursorAnchorElement={setCursorAnchorElement}
            />
            {slot.employee_weeks.length > 0 ? (
                slot.employee_weeks.map((employee_week, idx) => (
                    <EmployeeWeekRow
                        slot={slot}
                        renderRow={renderRow + idx + 1}
                        employeeWeek={employee_week}
                        setCursorAnchorElement={setCursorAnchorElement}
                        key={slot.department.id + "--" + employee_week.employee.id}
                    />
                ))
            ) : (
                <div className="SchedulerDeploymentSlot__EmptyMessage">
                    <div>
                        <i className="bi bi-question-circle" /> Noch keine Einsätze geplant
                    </div>
                </div>
            )}
        </Table>
    );
}
