import { AbsenceReasonsApiResponse } from "./api";

type AbsenceReason = {
    id: string;
    name: string;
};
type AbsenceReasonsState = {
    absenceReasonOptions: Record<AbsenceReason["id"], AbsenceReason["name"]> | null;
    loading: boolean;
    loadingFailed: boolean | null;
};

export const initialState: AbsenceReasonsState = {
    absenceReasonOptions: null,
    loading: true,
    loadingFailed: null,
};

export function reducer(draft: AbsenceReasonsState, action) {
    switch (action.type) {
        case "load": {
            draft.loading = true;
            draft.loadingFailed = null;
            return;
        }
        case "loadedJson": {
            const json: AbsenceReasonsApiResponse = action.payload;
            draft.absenceReasonOptions = json.data.reduce((acc, absenceReason) => {
                acc[absenceReason.id] = absenceReason.name;
                return acc;
            }, {});
            draft.loading = false;
            draft.loadingFailed = false;
            return;
        }
        case "loadingFailed": {
            draft.loading = false;
            draft.loadingFailed = true;
            return;
        }
    }
    throw Error("Unknown action: " + action.type);
}
