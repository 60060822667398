import * as React from "react";
import { TimeSheetsWeek } from "./types";

type TimeSheetsWeeksPerMonthForEmployeeProps = {
  week: TimeSheetsWeek;
}

export default function TimeSheetsWeeksPerMonthForEmployee(props: TimeSheetsWeeksPerMonthForEmployeeProps) {
    const { week } = props

    return (
        <tr className="bg-body">
            <td><a href={week.cw_url}>{week.cw}</a></td>
            <td>{week.performed_work_hours}</td>
            <td>{week.final_credited_work_hours}</td>
            <td>{week.target_hours}</td>
            <td>{week.diff_hours}</td>
            <td>{week.week_balance}</td>
        </tr>
    )
}
