import * as React from "react";
import { OptionLabel, OptionValue } from "../form/select/ComboBox";
import SelectorButton from "./SelectorButton";

type CollapsedProps = {
    selection: Set<OptionValue>;
    setExpanded: (expanded: boolean) => void;
    labelMap: Map<OptionValue, OptionLabel> | null;
    allLabel?: string;
    className?: string;
};

export default function Collapsed(props: CollapsedProps) {
    const { selection, setExpanded, labelMap, allLabel = "Alle", className = "" } = props;

    if (!labelMap) {
        return null;
    }

    let label;

    if (selection.size === 0) {
        label = "Keine Auswahl";
    } else if (selection.size === labelMap.size) {
        label = allLabel;
    } else {
        label = [...selection].map((item) => labelMap.get(item)).join(", ");
    }
    return (
        <div
            className={`collapsed position-relative ${className}`}
            onClick={() => setExpanded(true)}
            data-brix-combobox=""
        >
            <div className="btn btn-light border bg-white d-flex align-items-center justify-content-between">
                <div className="overflow-hidden">{label}</div>
                <SelectorButton />
            </div>
        </div>
    );
}
