import { CreateOrUpdateResult, FormAdapter, GetResult } from "../adapter";
import TimeSheetsDay from "../../model/TimeSheetsDay";
import { patch, post, get } from "../api";

function createTimeSheetsDayPath(urlParams: { employeeId: string, unitId: string, date: string }) {
    return `/api/employees/${urlParams.employeeId}/time_sheets_days/${urlParams.unitId}/${urlParams.date}`;
}

function updateTimeSheetsDayPath(urlParams: { employeeId: string; unitId: string, date: string }) {
    return `/api/employees/${urlParams.employeeId}/time_sheets_days/${urlParams.unitId}/${urlParams.date}`;
}

function readTimeSheetsDayPath(urlParams: { employeeId: string; unitId: string, date: string }) {
    return `/api/employees/${urlParams.employeeId}/time_sheets_days/${urlParams.unitId}/${urlParams.date}`;
}

function read(urlParams): Promise<GetResult<TimeSheetsDay>> {
    return get(readTimeSheetsDayPath(urlParams));
}

function save(urlParams, data): Promise<CreateOrUpdateResult<TimeSheetsDay>> {
    // always update
    return update(urlParams, data);
}

function create(urlParams, data): Promise<CreateOrUpdateResult<TimeSheetsDay>> {
    return post(createTimeSheetsDayPath(urlParams), data);
}

function update(urlParams, data): Promise<CreateOrUpdateResult<TimeSheetsDay>> {
    return patch(updateTimeSheetsDayPath(urlParams), data);
}

export const timeSheetsDayAdapter: FormAdapter<TimeSheetsDay> = {
    read,
    save,
    create,
    update,
};

export default timeSheetsDayAdapter;
