import Icon from "../../../lib/icon/Icon";
import * as React from "react";

type ErrorProps = {
    message: string;
};

export function Error(props: ErrorProps) {
    const { message } = props;

    return (
        <div>
            <div className="fw-bold mb-2 text-danger">
                <Icon type="error" /> Tut uns leid, das hat nicht geklappt.
            </div>
            <div>{message}</div>
        </div>
    );
}
