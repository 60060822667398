import * as React from "react";
import { createRef, ReactNode, useState } from "react";
import Modal3 from "../../lib/modal/Modal3";
import Button from "../../lib/button/Button";
import Icon from "../../lib/icon/Icon";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import Chrome from "../../lib/chrome/Chrome";

export function DeleteButton({ employeeId }: { employeeId: string }) {
    const rel = createRef<HTMLFormElement>();

    const [deleting, setDeleting] = useState(false);

    const csrfTokenName = document.querySelector("meta[name='csrf-param']")?.getAttribute("content");
    const csrfTokenValue = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");

    function handleDelete() {
        rel.current.submit();
    }

    return (
        <>
            {deleting && (
                <Modal3 onClose={() => setDeleting(false)}>
                    <Modal3.Header title="Wirklich löschen?" />
                    <Modal3.Body>
                        <p>Soll dieser Mitarbeiter-Datensatz wirklich gelöscht werden?</p>
                        <p>
                            <strong>Diese Aktion kann nicht rückgängig gemacht werden.</strong>
                        </p>
                    </Modal3.Body>
                    <Modal3.Footer>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button theme="warning" onClick={handleDelete}>
                                Ja, löschen
                            </Button>
                            <Button theme="primary" onClick={() => setDeleting(false)}>
                                Abbrechen
                            </Button>
                        </div>
                    </Modal3.Footer>
                </Modal3>
            )}
            <form ref={rel} method="POST" action={`/employees/${employeeId}`}>
                <input type="hidden" name="_method" value="delete" />
                {csrfTokenName && csrfTokenValue && <input type="hidden" name={csrfTokenName} value={csrfTokenValue} />}

                <button onClick={() => setDeleting(true)} type="button" className="Button Button--dangerousCamo">
                    <Icon type="delete" /> Löschen
                </button>
            </form>
        </>
    );
}

export function EmployeePageHeader({
    employeeId,
    hasDeleteEmployee,
    pageTitle,
    pageSupertitle,
    backLinkParts,
}: {
    employeeId: string;
    hasDeleteEmployee: boolean;
    pageTitle: string;
    pageSupertitle?: string;
    backLinkParts: [string, string][];
}) {
    const backElements: ReactNode[] = backLinkParts.map(([name, href], index) => (
        <React.Fragment key={index}>
            <Chrome.BackLinkSubject href={href}>{name}</Chrome.BackLinkSubject>
            {index < backLinkParts.length - 1 ? <Icon type={"previous"} /> : ""}
        </React.Fragment>
    ));

    const trimmedPageSupertitle = (pageSupertitle ?? "").trim();

    return (
        <React.StrictMode>
            <ErrorBoundary>
                <Chrome.NavigationBar>
                    <Chrome.BackLink>{backElements}</Chrome.BackLink>
                </Chrome.NavigationBar>
                <Chrome.DetailPage.Header>
                    <Chrome.DetailPage.HeaderMain>
                        {trimmedPageSupertitle && (
                            <Chrome.DetailPage.PageSupertitle>{trimmedPageSupertitle}</Chrome.DetailPage.PageSupertitle>
                        )}
                        <Chrome.DetailPage.PageTitle>{pageTitle}</Chrome.DetailPage.PageTitle>
                    </Chrome.DetailPage.HeaderMain>
                    <Chrome.DetailPage.HeaderMeta>
                        {hasDeleteEmployee && <DeleteButton employeeId={employeeId} />}
                    </Chrome.DetailPage.HeaderMeta>
                </Chrome.DetailPage.Header>
            </ErrorBoundary>
        </React.StrictMode>
    );
}
