import * as React from "react";
import { useEmployeePermissions, useEmployeeState } from "../state";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import { DayOfWeek } from "../../../../lib/datetime/types";
import { joinClasses } from "../../../../lib/dom-utils/class-names";
import { formatHours, shortHumanDay } from "../../../../lib/datetime/datetime";
import { DateTime } from "luxon";
import WeekCalendar from "../../WeekCalendar";
import { Warning } from "../Warning";

export function School(props: React.HTMLAttributes<HTMLDivElement>) {
    const { canReadSchoolTimeSets, canManageSchoolTimeSets, canManageSchoolHolidays } = useEmployeePermissions();
    const { ...divProps } = props;
    const { employee, employments } = useEmployeeState();

    if (!canReadSchoolTimeSets && !canManageSchoolHolidays) {
        return null;
    }

    if (!employments?.hasBeenIsOrWillBecomeATrainee) {
        return null;
    }

    return (
        <Kitchensink.Item {...divProps}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Schule</Kitchensink.ItemBodyTitle>

                {canReadSchoolTimeSets && <SchoolTimeSet />}

                <Kitchensink.ItemBodyLinkList>
                    {canManageSchoolTimeSets && (
                        <a className="Button Button--primadonna" href={`/employees/${employee.id}/school_time_sets`}>
                            Schulzeiten verwalten
                        </a>
                    )}
                    {canManageSchoolHolidays && (
                        <a className="Button Button--primadonna" href={`/employees/${employee.id}/school_holidays`}>
                            Ferien verwalten
                        </a>
                    )}
                </Kitchensink.ItemBodyLinkList>
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}

function SchoolTimeSet() {
    const { schoolTimeSets } = useEmployeeState();
    return (
        <Kitchensink.ItemBodyPart className="EmployeeManager__Factsheet__SchoolTimes">
            <Kitchensink.ItemBodyPartHeadline>Schulzeiten</Kitchensink.ItemBodyPartHeadline>
            {!schoolTimeSets.currentSchoolTimeSet && <Warning>Keine aktuellen Schulzeiten</Warning>}
            <WeekCalendar>
                {Object.keys(schoolTimeSets.currentSchoolTimeSet?.days ?? {}).map((dow, index) => (
                    <SchoolTimeSetDay
                        key={index}
                        dow={Number(dow) as DayOfWeek}
                        attendsSchool={schoolTimeSets.currentSchoolTimeSet.days[dow].attendsSchool}
                        creditedHours={schoolTimeSets.currentSchoolTimeSet.days[dow].creditedHours}
                        absenceStartTime={schoolTimeSets.currentSchoolTimeSet.days[dow].absenceStartTime}
                        absenceEndTime={schoolTimeSets.currentSchoolTimeSet.days[dow].absenceEndTime}
                    />
                ))}
            </WeekCalendar>
        </Kitchensink.ItemBodyPart>
    );
}

function SchoolTimeSetDay(
    props: {
        dow: DayOfWeek;
        attendsSchool: boolean;
        creditedHours: number;
        absenceStartTime: string;
        absenceEndTime: string;
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const { dow, attendsSchool, creditedHours, absenceStartTime, absenceEndTime, ...divProps } = props;

    divProps.className = joinClasses("EmployeeManager__Factsheet__SchoolTimes__Day", divProps?.className);

    const dayName = shortHumanDay(dow);
    return (
        <WeekCalendar.Day active={attendsSchool} {...divProps}>
            <WeekCalendar.DayOfWeek>{dayName}</WeekCalendar.DayOfWeek>
            {attendsSchool ? (
                <>
                    <WeekCalendar.KeyValue title="Abgerechnete Stunden">
                        {formatHours(creditedHours)} h
                    </WeekCalendar.KeyValue>
                    <WeekCalendar.Content title="Schulzeiten">
                        {absenceStartTime && absenceEndTime && (
                            <>
                                {DateTime.fromISO(absenceStartTime).toFormat("HH:mm")}
                                {" → "}
                                {DateTime.fromISO(absenceEndTime).toFormat("HH:mm")}
                            </>
                        )}
                    </WeekCalendar.Content>
                </>
            ) : (
                <WeekCalendar.Content>
                    <WeekCalendar.EmptyMessage>Keine Schule</WeekCalendar.EmptyMessage>
                </WeekCalendar.Content>
            )}
        </WeekCalendar.Day>
    );
}
