import * as React from "react";
import { useEffect, useState } from "react";
import { InputHTMLAttributes } from "react";
import { humanToSeconds, secondsToHuman } from "../../../lib/datetime/time";

type DurationStringFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    valueSeconds?: number;
    valueHuman?: string;
    onChange: (value: string | number) => void;
    onSubmit?: (value: string | number) => void;
    human?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    ariaLabel?: string;
};

const DurationStringField = (props: DurationStringFieldProps) => {
    const {
        valueSeconds = 0,
        valueHuman = "",
        onChange,
        onSubmit,
        human = false,
        disabled = false,
        readOnly = false,
        ariaLabel = "Stunden",
        ...otherProps
    } = props;

    const [duration, setDuration] = useState(human ? valueHuman : secondsToHuman(valueSeconds, { seconds: false }));
    useEffect(
        () => setDuration(human ? valueHuman : secondsToHuman(valueSeconds, { seconds: false })),
        [valueHuman, valueSeconds]
    );

    const normalizeTimeMinus = (time) => {
        let minus = "";
        if (time.search("-") != -1) {
            minus = "-";
            time = time.replace(/-/g, "");
        }
        if (time.search("\\+") != -1) {
            minus = "";
            time = time.replace(/\+/g, "");
        }
        return [time, minus];
    };

    const normalizeTimeFirstLast = (time) => {
        let [first, last] = time.split(":");
        if (!last) {
            last = first;
            first = "0";
        }

        if (first.length == 0) {
            return ["0", last];
        } else if (first.length == 1) {
            return [first, last];
        } else if (first[0] == 0) {
            return [first.slice(1), last];
        } else {
            return [first.replace("00", "0"), last];
        }
    };

    const formatTimePart = (time) => {
        if (time == "") {
          return null
        }

        const [nTime, minus] = normalizeTimeMinus(time);
        const [first, last] = normalizeTimeFirstLast(nTime);

        if (last.length == 1) {
            return minus + (first.slice(0, -1) || "0") + ":" + first.slice(-1) + last;
        } else if (last.length == 2) {
            return minus + first + ":" + last;
        } else {
            return minus + first + last.slice(0, -2) + ":" + last.slice(-2);
        }
    };

    const handleChange = (e) => {
        const humanTime = formatTimePart(e.target.value);
        setDuration(humanTime || "");
        if (human) {
            onChange(humanTime);
        } else {
            onChange(humanToSeconds(humanTime));
        }
    };

    const handleKeyUp = (keyEvent) => {
        if (disabled) {
            return;
        }

        if (keyEvent.key === "Enter") {
            if (human) {
                onSubmit && onSubmit(duration);
            } else {
                onSubmit && onSubmit(humanToSeconds(duration));
            }
        }
    };

    return (
        <>
            <div
                className="d-inline-flex align-items-center gap-2"
                style={{ width: "auto" }}
                role="group"
                {...otherProps}
            >
                <div
                    className={`d-inline-flex border rounded py-1 px-2 ${readOnly || disabled ? "bg-light" : "bg-white"}`}
                    role="group"
                    style={{ width: "84px" }}
                >
                    <div style={{ flex: "0 0 72px", paddingRight: "5px" }}>
                        <input
                            value={duration}
                            className={`${readOnly || disabled ? "text-muted" : ""}`}
                            style={{ width: "100%", textAlign: "right", border: 0, backgroundColor: "transparent" }}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            disabled={disabled}
                            readOnly={readOnly}
                            aria-label={ariaLabel}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

DurationStringField.displayName = "DurationStringField";

export default DurationStringField;
