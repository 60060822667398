import * as React from "react";
import { useEmployeePermissions, useEmployeeState } from "../state";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { formatHours } from "../../../../lib/datetime/datetime";
import Metric from "../Metric";
import {
    defaultCartesianGridProps,
    defaultTooltipContentStyles,
    defaultTooltipLabelStyles,
    defaultXAxisStyles,
    defaultYAxisStyles,
} from "../../../../lib/chart/properties";
import { colors } from "../../../../lib/theme/colors";

export function TimeSheets(props: React.HTMLAttributes<HTMLDivElement>) {
    const { canReadTimeSheetDays, canManageTimeSheetDays } = useEmployeePermissions();

    const { ...divProps } = props;
    const { employee } = useEmployeeState();

    if (!canReadTimeSheetDays) {
        return null;
    }

    return (
        <Kitchensink.Item {...divProps}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Zeitkonto</Kitchensink.ItemBodyTitle>

                <Balances />
                <BalancesPlot />

                {canManageTimeSheetDays && (
                    <Kitchensink.ItemBodyLinkList>
                        <a className="Button Button--primadonna" href={`/employees/${employee.id}/time_sheets_days`}>
                            Zeitkonto verwalten
                        </a>
                    </Kitchensink.ItemBodyLinkList>
                )}
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}

function Balances() {
    const { timeSheets } = useEmployeeState();

    if (null === timeSheets.currentWeekBalances) {
        return (
            <Metric className="EmployeeManager__Factsheet__TimeSheets__Balances">
                <Metric.EmptyState>Kein aktueller Saldo verfügbar</Metric.EmptyState>
            </Metric>
        );
    }

    const balance = Number(timeSheets.currentWeekBalances.balance);
    const unbalanced = balance < -10 || balance > 10;

    return (
        <Metric type={unbalanced ? "negative" : "neutral"} className="EmployeeManager__Factsheet__TimeSheets__Balances">
            <Metric.Label>Saldo ({timeSheets.currentWeekBalances.unit.name}, aktuelle Woche)</Metric.Label>
            <Metric.Value>{formatHours(balance)} h</Metric.Value>
        </Metric>
    );
}

function BalancesPlot() {
    const { timeSheets } = useEmployeeState();

    let data;
    if (timeSheets?.weeklyBalances) {
        data = Object.keys(timeSheets.weeklyBalances).map((week) => {
            return {
                name: week,
                targetHours: timeSheets.weeklyBalances[week].targetHours,
                finalCreditedWorkHours: timeSheets.weeklyBalances[week].finalCreditedWorkHours,
                // performedWorkHours: timeSheets.weeklyBalances[week].performedWorkHours,
                creditedAbsentHours: timeSheets.weeklyBalances[week].creditedAbsentHours,
                adjustmentHours: timeSheets.weeklyBalances[week].adjustmentHours,
                otherCreditedHours:
                    timeSheets.weeklyBalances[week].finalCreditedWorkHours -
                    timeSheets.weeklyBalances[week].creditedAbsentHours -
                    timeSheets.weeklyBalances[week].adjustmentHours,
                balance: null,
            };
        });
    }

    if (!data) {
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
                data={data}
                margin={{
                    top: 20,
                    right: 80,
                    bottom: 20,
                    left: 20,
                }}
                barGap={1}
                barCategoryGap={1}
            >
                <CartesianGrid {...defaultCartesianGridProps} />
                <XAxis
                    dataKey="name"
                    label={{ position: "insideBottomRight", offset: 0 }}
                    scale="band"
                    style={defaultXAxisStyles}
                />
                <YAxis style={defaultYAxisStyles} />
                <Tooltip contentStyle={defaultTooltipContentStyles} labelStyle={defaultTooltipLabelStyles} />
                <Bar stackId="a" dataKey="otherCreditedHours" name="Einsatz (h)" fill={colors.brixIndigo400} />
                <Bar
                    stackId="a"
                    dataKey="creditedAbsentHours"
                    name="Abwesenheit mit Stundengutschrift (h)"
                    fill={colors.brixRose400}
                />
                <Bar stackId="a" dataKey="adjustmentHours" name="Korrektur (h)" fill={colors.brixSlate400} />
                <Line type="monotone" dataKey="targetHours" name="Soll (h)" stroke={colors.brixRose700} dot={false} />
            </ComposedChart>
        </ResponsiveContainer>
    );
}
