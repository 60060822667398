import * as React from "react";
import { joinClasses } from "../../dom-utils/class-names";

type FormGroupProps = React.HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    label?: string;
    required?: boolean;
};

export default function FormGroup(props: FormGroupProps) {
    const { children, label = null, required = null, ...otherProps } = props;

    otherProps.className = joinClasses("FormGroup", otherProps.className);

    return (
        <div {...otherProps}>
            <label className="FormGroup__Inner">
                {(label || true === required) && (
                    <div className="FormGroup__Label">
                        <div className="FormGroup__Label__FieldName">{label}</div>
                        {true === required && (
                            <abbr className="FormGroup__Label__Mandatory" title="Erforderlich">
                                *
                            </abbr>
                        )}
                    </div>
                )}
                {children}
            </label>
        </div>
    );
}
