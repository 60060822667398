import { Action } from "./actions";
import { State } from "./state";
import { dayNumbers } from "../../../lib/datetime/datetime";
import { groupDaysByWeeks } from "../../../lib/ett/report-grid/api";
import { ReportDay } from "../../../lib/ett/report-grid/types";

export function reducer(draft: State, action: Action) {
    switch (action.type) {
        case "setDateRange":
            draft.startsAt = action.payload.startsAt;
            draft.endsAt = action.payload.endsAt;
            break;

        case "setLoading":
            draft.loading = action.payload;
            break;

        case "setEmployee":
            draft.employee = action.payload;
            break;

        case "setDays":
            draft.daysByWeeks = groupDaysByWeeks(action.payload.days);
            draft.filteredDaysByWeeks = filterWeeks(draft.daysByWeeks, draft.weekDays);
            break;

        case "setWeekDays":
            if (action.payload === null || action.payload.length === 0) {
                draft.weekDays = dayNumbers;
            } else {
                draft.weekDays = action.payload;
            }
            draft.filteredDaysByWeeks = filterWeeks(draft.daysByWeeks, draft.weekDays);
            break;
    }
}

function filterWeeks(weeks: Record<string, ReportDay[]>, weekDays: number[]): Record<string, ReportDay[]> {
    return Object.keys(weeks).reduce<Record<string, ReportDay[]>>((acc, weekDate) => {
        acc[weekDate] = weeks[weekDate].filter(day => weekDays.includes(day.date.weekday));
        return acc;
    }, {});
}
