import * as React from "react";
import { CSSProperties } from "react";
import { DateTime, Interval } from "luxon";
import DayCell from "./DayCell";
import { DaysInWeek, DaysInWeekDayValue } from "./types";
import WeekCell from "./WeekCell";

function getWeeksInMonth(dateInMonth: DateTime): DaysInWeek[] {
    const weeks: DaysInWeek[] = [];

    let nextWeekDate = dateInMonth.startOf("month").startOf("week");
    do {
        weeks.push(getDaysInWeek(nextWeekDate, dateInMonth));
        nextWeekDate = nextWeekDate.plus({ week: 1 });
    } while (nextWeekDate.month === dateInMonth.month);

    return weeks;
}

function getDaysInWeek(dateInWeek: DateTime, dateInMonth: DateTime): DaysInWeek {
    const startDate = dateInWeek.startOf("week");

    const result: DaysInWeek = new Array<DateTime | null>(7).fill(null) as DaysInWeek;
    for (let i = 0; i < 7; i++) {
        const dt = startDate.plus({ day: i });
        if (dt.month === dateInMonth.month) {
            result[i] = dt;
        }
    }

    return result;
}

type MonthCalendarProps = {
    date: DateTime;
    selection: Interval;
    onSelectDay: (dt: DateTime) => unknown;
    onSelectInterval: (interval: Interval) => unknown;
};

export function MonthCalendar(props: MonthCalendarProps): JSX.Element {
    const { date, selection, onSelectDay, onSelectInterval } = props;

    const weeks = getWeeksInMonth(date);

    function handleDayClick(dayValue: DaysInWeekDayValue) {
        if (null !== dayValue) {
            onSelectDay(dayValue);
        }
    }

    function handleWeekClick(weekInterval: Interval) {
        if (null !== weekInterval) {
            onSelectInterval(weekInterval);
        }
    }

    function handleMonthClick() {
        if (null !== date) {
            onSelectInterval(Interval.fromDateTimes(date.startOf("month"), date.endOf("month")));
        }
    }

    const headerCellStyle: CSSProperties = {
        padding: "5px",
        textAlign: "center",
        border: "1px solid #ccc",
        backgroundColor: "#ddd",
        fontWeight: "normal",
        color: "#6c757d",
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", flex: "1 1 100%" }}>
            <div
                style={{
                    backgroundColor: "#ccc",
                    cursor: "default",
                    fontWeight: 700,
                    flex: "1 1 100%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    textAlign: "center",
                }}
                onClick={handleMonthClick}
            >
                {date.toFormat("MMMM yyyy")}
            </div>
            <table style={{ fontSize: "0.825rem" }}>
                <thead>
                    <tr className="border-t">
                        <th style={headerCellStyle}>W</th>
                        <th style={headerCellStyle}>M</th>
                        <th style={headerCellStyle}>D</th>
                        <th style={headerCellStyle}>M</th>
                        <th style={headerCellStyle}>D</th>
                        <th style={headerCellStyle}>F</th>
                        <th style={headerCellStyle}>S</th>
                        <th style={headerCellStyle}>S</th>
                    </tr>
                </thead>
                <tbody>
                    {weeks.map((week) => (
                        <tr style={{ borderTop: "1px solid #ccc" }} key={week.toString()}>
                            <WeekCell value={week[0] ?? week[week.length - 1]} onClick={handleWeekClick} />
                            {new Array(7).fill(null).map((_, idx) => (
                                <DayCell
                                    key={idx}
                                    value={week[idx]}
                                    selection={selection}
                                    onClick={() => handleDayClick(week[idx])}
                                />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MonthCalendar;
