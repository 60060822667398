import * as React from "react";
import { HTMLAttributes } from "react";
import Icon from "../../../lib/icon/Icon";
import { conditionalClassNames } from "../../../lib/dom-utils/class-names";

type AbsenceApprovalStatusProps = HTMLAttributes<HTMLDivElement> & {
    approved: boolean;
};

export default function AbsenceApprovalStatus(props: AbsenceApprovalStatusProps) {
    const { approved, ...otherProps } = props;

    delete otherProps.className;

    const classNames = conditionalClassNames({
        AbsenceApprovalStatus: true,
        "AbsenceApprovalStatus--approved": approved,
        "AbsenceApprovalStatus--not-approved": !approved,
    });

    let label;

    if (approved) {
        label = "Die Abwesenheit wurde genehmigt";
    } else {
        label = "Die Abwesenheit wurde noch nicht genehmigt";
    }

    return (
        <Icon
            type={`absence-${approved ? "approved" : "not-approved"}`}
            className={classNames.join(" ")}
            aria-label={label}
            title={label}
        />
    );
}
