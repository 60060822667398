import { Outlet } from "react-router-dom";
import * as React from "react";
import { RightSidebar } from "./RightSidebar";
import { LayoutContextProvider } from "./state";
import { useContext } from "react";
import { EmployeeManagerModeContext } from "../EmployeeManager";
import { EmployeeContextProvider, EmployeeIdContextFromParamsProvider } from "../factsheet/state";

export function Layout() {
    const { standaloneSidebar } = useContext(EmployeeManagerModeContext);

    return (
        <EmployeeIdContextFromParamsProvider>
            <EmployeeContextProvider>
                <LayoutContextProvider>
                    <div className="EmployeeManager">
                        <RightSidebar />
                        {!standaloneSidebar && (
                            <div className="EmployeeManager__Main">
                                <Outlet />
                            </div>
                        )}
                    </div>
                </LayoutContextProvider>
            </EmployeeContextProvider>
        </EmployeeIdContextFromParamsProvider>
    );
}
