import React from "react";
import Cell from "../cell";
import cellWidths from "../cell-widths";
import PropTypes from "prop-types";

export default function EmployeeCell({ employee, unitId, period }) {
    return (
        <Cell
            style={{ width: cellWidths.employee, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
            numeric={false}
            className="align-middle"
        >
            <a
                href={`/employees/${employee.id}/time_sheets_days/${unitId}/${period}`}
                title={employee.name}
                data-turbolinks="false"
            >
                {employee.name}
            </a>
        </Cell>
    );
}

EmployeeCell.propTypes = {
    employee: PropTypes.any,
    unitId: PropTypes.string,
    period: PropTypes.string,
};
