import * as React from "react";
import { FormHTMLAttributes, useEffect, useState } from "react";

import { CreateOrUpdateResult, Errors, FormAdapter, UrlParams } from "../../api/adapter";
import FormErrors from "../validation/FormErrors";
import { CardFooter, ScrollableContentCard, ScrollableContentCardBody } from "../../card/Card";
import Button from "../../button/Button";
import Noticiations from "../../notifications/Noticiations";

type ChildrenParams<DataType> = {
    loading: boolean;
    record: DataType;
    changes: Partial<DataType>;
    onChange: (changes: Partial<DataType>) => void;
};
type ChildrenT<DataType> = (params: ChildrenParams<DataType>) => React.ReactNode | React.ReactNode[];

type FormProps<DataType> = Omit<FormHTMLAttributes<HTMLFormElement>, "children"> & {
    adapter: FormAdapter<DataType>;
    urlParams: UrlParams;
    children: ChildrenT<DataType>;
};

function Form<DataType>(props: FormProps<DataType>) {
    return (
        <Noticiations>
            {(notify) => {
                const { children, adapter, urlParams, ...otherProps } = props;

                const [errors, setErrors] = useState<Errors>(null);
                const [record, setRecord] = useState<DataType>(null);
                const [changes, setChanges] = useState<Partial<DataType>>({});
                const [loading, setLoading] = useState(true);

                const onChange = (newChanges: Partial<DataType>) => setChanges({ ...changes, ...newChanges });

                const handleRecordResponse = (json: CreateOrUpdateResult<DataType>) => {
                    if (json.notifications) {
                        json.notifications.forEach((notification) => notify(notification.message, notification.theme));
                    }

                    if (Object.keys(json?.errors ?? {}).length) {
                        setErrors(json.errors);
                    } else {
                        setErrors(null);
                        setRecord(json.data);
                        setChanges({});
                    }
                    setLoading(false);
                };

                const handleSubmit = (event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    if (!loading) {
                        setLoading(true);
                        adapter.save(urlParams, { ...record, ...changes }).then((json) => handleRecordResponse(json));
                    }
                };

                useEffect(() => {
                    if (urlParams.id) {
                        setLoading(true);
                        adapter.read(urlParams).then((json) => handleRecordResponse(json));
                    } else {
                        if ("function" === typeof adapter.buildInitialRecord) {
                            setRecord(adapter.buildInitialRecord());
                        }
                        setLoading(false);
                    }
                }, [adapter, urlParams, children]);

                return (
                    <form
                        {...otherProps}
                        onSubmit={handleSubmit}
                        className="p-2 h-100 overflow-hidden d-flex flex-column"
                    >
                        <ScrollableContentCard>
                            <ScrollableContentCardBody>
                                <FormErrors errors={errors} />
                                {children({ loading, record, changes, onChange })}
                            </ScrollableContentCardBody>
                            <CardFooter>
                                <Button caption="Speichern" disabled={loading} theme="primary" />
                            </CardFooter>
                        </ScrollableContentCard>
                    </form>
                );
            }}
        </Noticiations>
    );
}

export default Form;
