import React, { useRef, useState } from "react";
import NumericValue from "../numeric-value";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import PropTypes from "prop-types";

export function AdjustmentInfo({ robot, human }) {
    const [showAdjustment, setShowAdjustment] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef();

    const handleClick = (event) => {
        setShowAdjustment(!showAdjustment);
        setTarget(event.target);
    };

    return (
        <div style={{ whiteSpace: "nowrap" }} ref={ref}>
            <div
                className="d-flex justify-content-between p-1 rounded border border-success text-success"
                style={{ marginRight: "-4px", cursor: "pointer", userSelect: "none" }}
                onClick={handleClick}
            >
                <i className="bi bi-info-circle-fill" />
                <NumericValue humanValue={human} robotValue={robot} />
            </div>
            <Overlay show={showAdjustment} target={target} placement="bottom" container={ref} containerPadding={20}>
                <Popover id="popover-contained">
                    <Popover.Header as="h3">Korrekturwert</Popover.Header>
                    <Popover.Body>
                        <p>An diesem Tag wurde ein Korrekturwert von {human} h verbucht.</p>
                        <p>Korrekturen können im Zeitkonto des Mitarbeiters vorgenommen werden.</p>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
}

AdjustmentInfo.propTypes = {
    robot: PropTypes.string,
    human: PropTypes.string,
};
