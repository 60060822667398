import { AbsenceStats } from "./AbsenceStats";
import { AbsenceReasonsPlot } from "./absence-reasons-plot/AbsenceReasonsPlot";
import * as React from "react";
import { useAbsenceStatsForTimeFrame } from "./useAbsenceStatsForTimeFrame";

export function AbsenceTabContent(props: { timeFrame: "last-3-years" | "last-year" }) {
    const [creditedRate, uncreditedRate, creditedHours, uncreditedHours, absentHoursByReasonCategory] =
        useAbsenceStatsForTimeFrame(props.timeFrame);
    return (
        <>
            <AbsenceStats
                creditedRate={creditedRate}
                uncreditedRate={uncreditedRate}
                creditedHours={creditedHours}
                uncreditedHours={uncreditedHours}
            />
            <AbsenceReasonsPlot absentHoursByReasonCategory={absentHoursByReasonCategory} />
        </>
    );
}
