import * as React from "react";
import EmptyState from "./EmptyState";
import { DataTableColumn, DataTableRow } from "./types";
import DataTableHeader from "./DataTableHeader";
import DataTableBody from "./DataTableBody";
import { useGridTemplateColumns } from "./useGridTemplateColumns";
import DataTableTotals from "./DataTableFooter";

type DataTableProps = {
    columns: DataTableColumn[];
    totals?: DataTableRow;
    data?: DataTableRow[];
};

export default function DataTable(props: DataTableProps) {
    const { columns, data, totals } = props;
    const gridTemplateColumns = useGridTemplateColumns(columns);

    if (data.length === 0) {
        return <EmptyState />;
    }

    return (
        <div className="DataTable d-flex flex-column h-100 overflow-hidden border-top border-bottom">
            <div style={{ flex: "1 1 100%" }} className="overflow-hidden d-flex flex-column">
                <table
                    style={{ flex: "0 0 auto", display: "grid", gridTemplateColumns, zIndex: 2 }}
                    className="shadow-sm"
                >
                    <DataTableHeader columns={columns} />
                </table>
                <div style={{ flex: "1 1 100%" }} className="bg-white overflow-auto d-flex flex-column">
                    <table
                        style={{ flex: "0 0 auto", display: "grid", gridTemplateColumns }}
                        className="overflow-y-auto"
                    >
                        <DataTableBody columns={columns} data={data} />
                    </table>
                </div>
            </div>
            {totals && (
                <table
                    style={{ flex: "0 0 auto", display: "grid", gridTemplateColumns, zIndex: 3 }}
                    className="shadow-lg border-top"
                >
                    {<DataTableTotals totals={totals} columns={columns} />}
                </table>
            )}
        </div>
    );
}
