import * as React from "react";
import { HTMLAttributes } from "react";
import { conditionalClassNames, joinClasses } from "../dom-utils/class-names";

type TabNavProps = HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    size?: "sm" | "md";
    theme?: "light" | "dark";
};

export default function TabNav({ theme = "dark", size = "md", children, ...otherProps }: TabNavProps) {
    otherProps.className = joinClasses(
        ...conditionalClassNames({
            TabNav: true,
            [`TabNav--${size}`]: true,
            [`TabNav--${theme}`]: true,
        }),
        otherProps.className
    );

    return (
        <nav aria-label="Tabs" {...otherProps}>
            {children}
        </nav>
    );
}
