import * as React from "react";
import Checkbox from "../../../../../lib/form/checkbox/Checkbox";
import useFinderKeyboardNavigation from "../../../../../lib/finder/useFinderKeyboardNavigation";

type FiltersProps = {
    onlyQualified: boolean;
    onOnlyQualifiedChange: (onlyQualified: boolean) => void;
    enableFilterByQualified: boolean;

    onNext: () => void;
    onPrevious: () => void;
    onClose: () => void;
    onCommit: () => void;
};

export function Filters(props: FiltersProps) {
    const {
        onlyQualified,
        onOnlyQualifiedChange: setOnlyQualified,
        enableFilterByQualified,
        onNext,
        onPrevious,
        onClose,
        onCommit,
    } = props;

    const ref = React.useRef();
    useFinderKeyboardNavigation({ ref, onNext, onPrevious, onClose, onCommit });

    return (
        <>
            {enableFilterByQualified && (
                <div className="SchedulerEmployeeWeekEditor__Filters">
                    <label className="SchedulerEmployeeWeekEditor__Filters__SkillFilter" ref={ref}>
                        <Checkbox checked={onlyQualified} onChange={(checked) => setOnlyQualified(checked)} />
                        <div className="SchedulerEmployeeWeekEditor__Filters__SkillFilter__Label">
                            Nur befähigte Mitarbeiter anzeigen
                        </div>
                    </label>
                </div>
            )}
        </>
    );
}
