import * as React from "react";
import { useContext, useMemo, useState } from "react";
import { DateTime, WeekdayNumbers } from "luxon";

import {
    BalancesContext,
    ExportPrivilegesContext,
    PublicHolidaysContext,
    ScheduleBalancesContext,
    ScheduleContext,
} from "./slots/SlotsController";
import Balances, { EmployeeBalance } from "../../lib/balances/Balances";
import Table from "./table/Table";
import TableRowHeader from "./table/TableRowHeader";
import TableRow from "./table/TableRow";
import TableRowBody from "./table/TableRowBody";
import { DayCell } from "./header/DayCell";
import { underscoreToCamelCase } from "../../lib/object/objects";
import Icon from "../../lib/icon/Icon";
import { DocumentWizard } from "./documents/DocumentWizard";
import { DocumentManager } from "./documents/DocumentManager";
import Button from "../../lib/button/Button";

export default function Header() {
    const { employeeBalances } = useContext(BalancesContext);
    const scheduleBalances = useContext(ScheduleBalancesContext);
    const exportPrivileges = useContext(ExportPrivilegesContext);
    const { total } = employeeBalances || {};
    const [documentWizardOpen, setDocumentWizardOpen] = useState(false);
    const [documentManagerOpen, setDocumentManagerOpen] = useState(false);

    const schedule = useContext(ScheduleContext);
    const scheduleStartsOn = useMemo(() => {
        if (schedule) {
            return DateTime.fromISO(schedule.starts_on);
        }
        return null;
    }, [schedule]);

    return (
        <div className="SchedulerHeader">
            <div className="SchedulerHeader__Inner">
                <div className="SchedulerHeader__Info">
                    <div className="SchedulerHeader__Info__Left">
                        <div className="SchedulerHeader__Info__Selection">
                            <div className="SchedulerHeader__Info__Selection__Unit">{schedule?.unit?.name}</div>
                            <div className="SchedulerHeader__Info__Selection__Week">{schedule?.week_name}</div>
                        </div>
                        <div className="MetricsList">
                            <div className="Metric Metric--brand">
                                <div className="Metric__Label">Plan</div>
                                <div className="Metric__Value">{scheduleBalances.human_plan_hours}</div>
                            </div>
                            <div className="Metric Metric--brand">
                                <div className="Metric__Label">Ist</div>
                                <div className="Metric__Value">{scheduleBalances.human_performed_work_hours}</div>
                            </div>
                        </div>
                    </div>
                    {schedule.approved && (
                        <div className="SchedulerHeader__Info__Approved">
                            <div className="badge bg-success text-white">Finalisiert</div>
                        </div>
                    )}
                    {schedule && (
                        <>
                            <div className="SchedulerHeader__Info__Options">
                                <a
                                    className="Button Button--brand"
                                    href={schedule.approved ? `/schedules/${schedule?.id}/approval` : schedule.edit_url}
                                >
                                    <i className="bi bi-sliders" /> Einstellungen
                                </a>
                                <Button
                                    className="Button Button--brand"
                                    aria-label="Dokumente"
                                    onClick={() => setDocumentManagerOpen(true)}
                                >
                                    <Icon type="documents" /> Dokumente
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                <PublicHolidaysContext.Consumer>
                    {(publicHolidays) => (
                        <Table className="SchedulerHeader__Week">
                            <TableRow>
                                <TableRowHeader className="SchedulerHeader__Balances">
                                    <div className="SchedulerHeader__Balances__Title">Total</div>
                                    <Balances
                                        balance={underscoreToCamelCase(total) as EmployeeBalance}
                                        hideLabels={false}
                                    />
                                </TableRowHeader>
                                <TableRowBody>
                                    {[1, 2, 3, 4, 5, 6, 7].map((dow) => (
                                        <DayCell
                                            key={dow}
                                            publicHolidays={publicHolidays}
                                            dow={dow as WeekdayNumbers}
                                            scheduleStartsOn={scheduleStartsOn}
                                        />
                                    ))}
                                </TableRowBody>
                            </TableRow>
                        </Table>
                    )}
                </PublicHolidaysContext.Consumer>
            </div>
            {schedule && documentWizardOpen && (
                <DocumentWizard
                    schedule={schedule}
                    exportPrivileges={exportPrivileges}
                    onClose={() => setDocumentWizardOpen(false)}
                />
            )}
            {schedule && documentManagerOpen && (
                <DocumentManager
                    scheduleId={schedule.id}
                    exportPrivileges={exportPrivileges}
                    onExport={() => {
                        setDocumentManagerOpen(false);
                        setDocumentWizardOpen(true);
                    }}
                    onClose={() => setDocumentManagerOpen(false)}
                />
            )}
        </div>
    );
}
