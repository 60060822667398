import { useEmployeePermissions, useEmployeeState } from "./state";
import { formatDate } from "../../../lib/datetime/date-format";
import Icon from "../../../lib/icon/Icon";
import * as React from "react";
import { GenderIcon } from "./GenderIcon";
import { DateTime } from "luxon";
import { Warning } from "./Warning";
import { Hint } from "./Hint";
import { pluralize } from "../../../lib/string/string";

export function EmployeeHeader() {
    const { canManageEmployees } = useEmployeePermissions();
    const { employee } = useEmployeeState();

    return (
        <div className="EmployeeManager__Factsheet__Header">
            <div className="EmployeeManager__Factsheet__Header__Clamp">
                <div className="EmployeeManager__Factsheet__Header__Body">
                    <Name />
                    <Address />
                    <Birthdate />

                    <div className="EmployeeManager__Factsheet__Header__Body__Hints">
                        <ApproachingBirthday />
                        <Inactive />
                        <AbsentToday />
                    </div>
                </div>
            </div>
            <div className="EmployeeManager__Factsheet__Header__ButtonGroup">
                {employee && canManageEmployees && (
                    <a className="Button Button--ghost" href={`/employees/${employee.id}/edit`}>
                        <span className="text-primary">
                            <Icon type={"personal-data"} />
                        </span>{" "}
                        Bearbeiten
                    </a>
                )}
                <ContactButtons />
            </div>
        </div>
    );
}

function Name() {
    const { employee } = useEmployeeState();

    return (
        <div className="EmployeeManager__Factsheet__Header__Name">
            {employee?.fullName ?? "Lade..."}
            {employee && (
                <div className="EmployeeManager__Factsheet__Header__Name__Gender">
                    <GenderIcon gender={employee.gender} />
                </div>
            )}
        </div>
    );
}

function Address() {
    const { employee } = useEmployeeState();

    if (!employee) {
        return null;
    }

    let addressParts = [];
    addressParts.push((employee.address ?? "").toString().trim());
    addressParts.push((employee.zipCode ?? "").toString().trim());
    addressParts = addressParts.filter((part) => part.length > 0);

    let fullAddress = [];
    fullAddress.push(addressParts.join(" "));
    fullAddress.push((employee.city ?? "").toString().trim());
    fullAddress = fullAddress.filter((part) => part.length > 0);

    const fullAddressString = fullAddress.join(", ");

    if (fullAddressString.length === 0) {
        return null;
    }

    return <address className="EmployeeManager__Factsheet__Header__Contact">{fullAddressString}</address>;
}

function Birthdate() {
    const { employee } = useEmployeeState();

    if (!employee) {
        return null;
    }

    const bornOn = DateTime.fromISO(employee.bornOn);
    const age = bornOn.until(DateTime.local()).toDuration(["years", "months", "days"]).toObject();

    return (
        <div className="EmployeeManager__Factsheet__Header__Birthdate">
            <div>
                <span className="EmployeeManager__Factsheet__Header__Birthdate__Years">{age.years} Jahre</span>
                <span className="EmployeeManager__Factsheet__Header__Birthdate__ExactDate">({formatDate(bornOn)})</span>
            </div>
        </div>
    );
}

function Inactive() {
    const { employee } = useEmployeeState();

    if (!employee || employee.active) {
        return null;
    }

    return <Warning dark>Ausgeschieden</Warning>;
}

function ApproachingBirthday() {
    const { employee } = useEmployeeState();

    if (!employee) {
        return null;
    }

    const bornOn = DateTime.fromISO(employee.bornOn);
    const age = bornOn.until(DateTime.local()).toDuration(["years", "months", "days"]).toObject();
    const nextBirthday = bornOn.plus({ years: age.years + 1 });
    const daysUntilNextBirthday = DateTime.local().until(nextBirthday).toDuration(["days"]).days;

    if (daysUntilNextBirthday > 30) {
        return null;
    }

    return (
        <Hint dark>
            Hat in {Math.round(daysUntilNextBirthday)} {pluralize(Math.round(daysUntilNextBirthday), "Tag", "Tagen")}{" "}
            Geburtstag
        </Hint>
    );
}

function AbsentToday() {
    const { absences } = useEmployeeState();

    const today = DateTime.local();
    const isAbsentToday = absences.relevantAbsences.some((absence) => {
        const startsOn = DateTime.fromISO(absence.startsOn);
        const endsOn = DateTime.fromISO(absence.endsOn);
        return startsOn <= today && today < endsOn;
    });

    if (!isAbsentToday) {
        return null;
    }

    return <Warning dark>Aktuell abwesend</Warning>;
}

function ContactButtons() {
    const { employee } = useEmployeeState();

    if (!employee) {
        return null;
    }

    const phoneForHumans = (employee.phone ?? "").toString().trim();
    const phoneForRobots = phoneForHumans.replace(/[^0-9]/g, "");

    const mobileForHumans = (employee.mobile ?? "").toString().trim();
    const mobileForRobots = mobileForHumans.replace(/[^0-9]/g, "");

    const normalizedEmail = (employee.email ?? "").toString().trim().toLowerCase();

    return (
        <>
            {phoneForRobots.length > 0 && (
                <a href={`tel:${phoneForRobots}`} className="Button Button--primadonna">
                    <Icon type={"call-phone"} /> {phoneForHumans}
                </a>
            )}
            {mobileForRobots.length > 0 && (
                <a href={`tel:${mobileForRobots}`} className="Button Button--primadonna">
                    <Icon type={"call-mobile"} /> {mobileForHumans}
                </a>
            )}
            {normalizedEmail.length > 0 && (
                <a href={`mailto:${normalizedEmail}`} className="Button Button--primadonna">
                    <Icon type={"send-mail"} /> {normalizedEmail}
                </a>
            )}
        </>
    );
}
