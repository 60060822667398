import * as React from "react";
import { useMemo, useState } from "react";
import useWorkHubState from "../../../state/useWorkHubState";
import { LoadingSpinner } from "../../../../../lib/empty-state/Loading";
import TabNav from "../../../shared/TabNav";
import { WeekIdentifier } from "../../../shared/types";
import Panel from "../../../layout/Panel";
import WeeklyEmptyMessage from "./WeeklyEmptyMessage";
import WeeklyPanelContent from "./WeeklyPanelContent";

type EttReportPanelProps = Record<string, never>;

export default function EttReportResultsPanel(_props: EttReportPanelProps) {
    const { ettReport: { ettReportSet, loading, loadingError: error } } = useWorkHubState();

    const [week, setWeek] = useState<WeekIdentifier>("current");

    const ettReport = useMemo(() => ettReportSet?.[week] ?? null, [ettReportSet, week]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div className="p-2 bg-danger text-white">Error: {JSON.stringify(error)}</div>;
    }

    return (
        <Panel>
            <Panel.Title text="Zeiterfassung" />
            <TabNav onChange={setWeek} week={week} />
            <Panel.Divider />
            <Panel.Section>
                {null === ettReport ? (
                    <WeeklyEmptyMessage />
                ) : (<>
                    <WeeklyPanelContent ettReport={ettReport} />
                </>)}
            </Panel.Section>
        </Panel>
    );
}
