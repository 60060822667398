import * as React from "react";

type FigureProps = {
    title: string;
    subtitle?: string;
    value: string;
}
export default function Figure(props: FigureProps) {
    const { title, subtitle, value } = props;
    return (
        <span className="WorkHub__Figure">
            <span className="WorkHub__Figure__Title">
                {title}
            </span>
            {subtitle && <span className="WorkHub__Figure__Subtitle">
                {subtitle}
            </span>}
            <span className="WorkHub__Figure__Value">
                {value}
            </span>
        </span>
    );
}
