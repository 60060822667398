import * as React from "react";
import { conditionalClassNames, joinClasses } from "../../lib/dom-utils/class-names";

export function WeekCalendar(props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("EmployeeManager__WeekCalendar", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function WeekCalendarDay(
    props: {
        active?: boolean;
        children: React.ReactNode;
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const { active = false, children, ...divProps } = props;
    divProps.className = joinClasses(
        ...conditionalClassNames({
            EmployeeManager__WeekCalendar__Day: true,
            "EmployeeManager__WeekCalendar__Day--active": active,
        }),
        divProps.className
    );
    return <div {...divProps}>{children}</div>;
}

export function WeekCalendarDayOfWeek(props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("EmployeeManager__WeekCalendar__Day__DayOfWeek", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function WeekCalendarKeyValue(props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("EmployeeManager__WeekCalendar__Day__KeyValue", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function WeekCalendarContent(props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("EmployeeManager__WeekCalendar__Day__Content", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function WeekCalendarEmptyMessage(props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("EmployeeManager__WeekCalendar__Day__EmptyMessage", divProps.className);
    return <div {...divProps}>{children}</div>;
}

WeekCalendar.Day = WeekCalendarDay;
WeekCalendar.DayOfWeek = WeekCalendarDayOfWeek;
WeekCalendar.KeyValue = WeekCalendarKeyValue;
WeekCalendar.Content = WeekCalendarContent;
WeekCalendar.EmptyMessage = WeekCalendarEmptyMessage;

export default WeekCalendar;
