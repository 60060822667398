import { jsonHeaders } from "./common";
import { DefinitiveEmployeeState, EmployeeStateTransition } from "../state/types/ett-status";

async function transitionEttStatus(transition: EmployeeStateTransition, accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return new Promise((resolve, _reject) => {
        const options = {
            method: "POST",
            signal,
            headers: {
                ...jsonHeaders,
                "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({ transition })
        };
        return fetch(`/workhub/api/events`, options)
            .then(response => response.json())
            .then(json => {
                if (json.errors) {
                    const error = Object.values(json.errors).flat().join("; ");
                    throw new Error(error);
                } else {
                    resolve(json.data.ett_status);
                }
            });
    });
}

export function checkIn(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transitionEttStatus("check_in", accessToken, signal);
}

export function checkOut(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transitionEttStatus("check_out", accessToken, signal);
}

export function startBreak(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transitionEttStatus("start_break", accessToken, signal);
}

export function endBreak(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transitionEttStatus("end_break", accessToken, signal);
}
