import { useCallback, useContext, useEffect } from "react";
import { DeviceContext } from "../DeviceContext";
import { AccessTokenResponse, fetchAccessToken } from "../api/access-tokens";
import { tokenExpirationGracePeriod } from "../state/reducer";
import { handleAbortError } from "../../../lib/api/abort";
import { EmployeeInfo } from "../state/types/employee-info";

export type UseAccessTokenExchangeProps = {
    onSuccess: (args: { accessToken: string, employeeInfo: EmployeeInfo, expiresIn: number }) => unknown;
    onError: (error: string) => unknown;
    onExpired: () => unknown;
}

export function useAccessTokenExchange(authToken: string, props: UseAccessTokenExchangeProps) {
    const { onSuccess, onError, onExpired } = props;

    const { deviceId } = useContext(DeviceContext);

    const fetchWithCallbacks = useCallback(async (signal: AbortSignal): Promise<AccessTokenResponse> => {
            return new Promise((resolve, _reject) => {
                fetchAccessToken(authToken, deviceId, signal)
                    .then(res => {
                        if (res.error) {
                            onError(res.error);
                        } else if (res) {
                            onSuccess({
                                accessToken: res.accessToken,
                                expiresIn: res.expiresIn,
                                employeeInfo: {
                                    name: res.employee.name,
                                    ettStatus: res.employee.ettStatus,
                                    today: res.employee.today
                                }
                            });
                            resolve(res);
                        } else {
                            onError("Unexpected response format from server, missing data or error.");
                        }
                    }).catch(handleAbortError);
            });
        }
        , [deviceId, authToken, onError, onSuccess]);

    useEffect(() => {
        if (!deviceId || !authToken || !onError || !onSuccess || !onExpired) {
            return;
        }

        const abortController = new AbortController();
        let expirationTimeout: number;

        const expire = (res: AccessTokenResponse) => {
            if (res.expiresIn) {
                const gracefulExpirationInterval = res.expiresIn * 1000 * tokenExpirationGracePeriod;
                console.log(`Expiring token in ${gracefulExpirationInterval}ms`);
                expirationTimeout = setTimeout(onExpired, gracefulExpirationInterval);
            }
        };

        fetchWithCallbacks(abortController.signal).then(expire);

        return () => {
            abortController.abort();
            clearTimeout(expirationTimeout);
        };
    }, [deviceId, authToken, onError, onSuccess, onExpired]);
}
