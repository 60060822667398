import * as React from "react";
import { EttReport } from "../../../../state/types/ett-report";
import DayOfWeek from "./DayOfWeek";
import { DayBar } from "./DayBar";
import PresencesTable from "./PresencesTable";
import { conditionalClassNames } from "../../../../../../lib/dom-utils/class-names";

type DayProps = {
    day: EttReport["days"][number]
}
export default function Day(props: DayProps) {
    const { day } = props;

    const classes = conditionalClassNames({
        "WorkHub__EttReport__Day": true,
        "WorkHub__EttReport__Day--empty": !day.presences.length
    }).join(" ");

    return (
        <div className={classes}>
            <div className="WorkHub__EttReport__Day__Header">
                <DayOfWeek date={day.date} />
                <DayBar day={day} />
                {/*<Balances workHours={day.workHours} breakHours={day.breakHours} />*/}
            </div>
            {day.presences.length > 0 && (
                <div className="WorkHub__EttReport__Day__Body">
                    <PresencesTable
                        workHours={day.workHours}
                        breakHours={day.breakHours}
                        presences={day.presences}
                    />
                </div>
            )}
        </div>
    );
}
