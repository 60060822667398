import * as React from "react";
import { useMemo } from "react";
import { joinClasses } from "../../dom-utils/class-names";

interface YearSelectProps {
    value: number;
    onChange: (year: number) => void;
}

export default function YearSelect(props: YearSelectProps) {
    const { value: currentYear, onChange } = props;

    const yearsRange = useMemo(() => {
        const years = [];
        for (let i = currentYear - 2; i <= currentYear + 2; i++) {
            years.push(i);
        }
        return years;
    }, [currentYear]);

    return (
        <div className="d-flex justify-content-center gap-1">
            {yearsRange.map((year, idx) => (
                <a
                    key={idx}
                    href="#"
                    className={joinClasses(
                        "btn btn-sm",
                        year === currentYear ? "active btn-outline-primary" : "btn-link"
                    )}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onChange(year);
                    }}
                >
                    {year}
                </a>
            ))}
        </div>
    );
}
