import { firstBusinessHour, lastBusinessHour } from "../../../../deployment-bar/deployment-times";

const firstBusinessHourSeconds = firstBusinessHour * 60 * 60;
const lastBusinessHourSeconds = lastBusinessHour * 60 * 60;

export function timeToPercent(time: string, treatZeroAsEnd = false) {
    const [hours, minutes] = time.split(":").map(Number);
    let secondsSinceMidnight = hours * 60 * 60 + minutes * 60;

    if (treatZeroAsEnd && secondsSinceMidnight == 0) {
        secondsSinceMidnight = 24 * 60 * 60;
    }

    return trimToBusinessHours(secondsSinceMidnight);
}

export function trimToBusinessHours(secondsSinceMidnight: number) {
    if (secondsSinceMidnight < firstBusinessHourSeconds) {
        return 0;
    } else if (secondsSinceMidnight > lastBusinessHourSeconds) {
        return 100;
    } else {
        return ((secondsSinceMidnight - firstBusinessHourSeconds) / (lastBusinessHourSeconds - firstBusinessHourSeconds)) * 100;
    }
}
