import useWorkHubState from "../../../state/useWorkHubState";
import useWorkHubDispatch from "../../../state/useWorkHubDispatch";
import { fetchPersonalSchedules } from "../../../api/personal-schedules";
import Button from "../../../shared/Button";
import * as React from "react";

export function PersonalSchedulesButton() {
    const { access: { token } } = useWorkHubState();
    const dispatch = useWorkHubDispatch();

    const openPersonalSchedules = () => {
        dispatch({ type: "startLoadingPersonalSchedules" });

        fetchPersonalSchedules(token)
            .then(personalScheduleSet => {
                dispatch({ type: "finishLoadingPersonalSchedules", payload: { personalScheduleSet } });
            })
            .catch(error => dispatch({ type: "failLoadingPersonalSchedules", payload: { error } }))
        ;
    };

    return (
        <Button colorTheme="slate" onClick={openPersonalSchedules} block={true}>
            Einsatzplan abrufen
        </Button>
    );
}
