import * as React from "react";
import { PersonalSchedule } from "../../../../state/types/personal-schedule";
import Deployment from "./Deployment";

type DeploymentsListProps = {
    deployments: PersonalSchedule["days"][number]["deployments"];
}

export default function DeploymentsList(props: DeploymentsListProps) {
    const { deployments } = props;

    return (
        <div className="WorkHub__WeeklyDeploymentsList__Day__DeploymentsList">
            {deployments.map(deployment => (
                <Deployment
                    key={deployment.id}
                    deployment={deployment}
                />
            ))}
        </div>
    );
}
