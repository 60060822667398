export function joinClasses(...classes: Array<unknown>): string {
    return classes.filter(Boolean).join(" ");
}

export function splitClasses(classNames: string | null | undefined) {
    return (classNames ?? "").split(/\s+/);
}

export function classesToSet(classNames: string | null | undefined): Set<string> {
    return new Set(splitClasses(classNames));
}

export function conditionalClassNames(classNames: { [key: string]: boolean }) {
    return Object.keys(classNames).filter((className) => classNames[className]);
}
