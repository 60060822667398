import * as React from "react";

export type FinderResultPaneProps = {
    loading: boolean;
    children: React.ReactNode;
};

export default function FinderResultPane(props: FinderResultPaneProps) {
    const { loading, children } = props;

    const className = "FinderResultPane";

    if (loading) {
        return <div className={className}>Lade...</div>;
    }

    return <div className={className}>{children}</div>;
}
