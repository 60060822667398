import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { conditionalClassNames } from "../../dom-utils/class-names";
import Icon from "../../icon/Icon";
import { useComboboxDispatch, useComboboxState } from "./state";
import { FloatingContext } from "./FloatingContext";

type InputProps = Omit<React.ComponentProps<"input">, "onChange" | "value" | "type" | "onKeyDown" | "onSubmit"> & {
    onSubmit: (value: string) => unknown;
    invalid?: boolean;
};

export default function Input(props: InputProps) {
    const { onSubmit, invalid = false, ...inputProps } = props;
    const state = useComboboxState();
    const dispatch = useComboboxDispatch();

    const inputRef = useRef<HTMLInputElement>(null);

    const {
        refs: { setReference: ref },
    } = useContext(FloatingContext);

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case "Tab":
                dispatch({ type: "blur" });
                break;
            case "ArrowDown":
                e.preventDefault();
                dispatch({ type: "moveBy", payload: 1 });
                break;
            case "ArrowUp":
                e.preventDefault();
                dispatch({ type: "moveBy", payload: -1 });
                break;
            case "PageDown":
                e.preventDefault();
                dispatch({ type: "moveBy", payload: 10 });
                break;
            case "PageUp":
                e.preventDefault();
                dispatch({ type: "moveBy", payload: -10 });
                break;
            case "Enter":
                if (state.open) {
                    e.preventDefault();
                    dispatch({ type: "select", payload: state.activeValue });
                    onSubmit(state.activeValue);
                }
                break;
            case "Escape":
                e.preventDefault();
                dispatch({ type: "close" });
                break;
            default:
                dispatch({ type: "open" });
                break;
        }
    }

    function handleToggleClick() {
        state.open ? dispatch({ type: "close" }) : dispatch({ type: "open" });
        inputRef.current?.focus();
    }

    const [hasBeenAutoFocused, setHasBeenAutoFocused] = useState(false);

    useEffect(() => {
        if (!hasBeenAutoFocused && inputProps.autoFocus && !inputProps.disabled) {
            if (inputRef.current) {
                inputRef.current.focus();
                setHasBeenAutoFocused(true);
            }
        }
    }, [hasBeenAutoFocused, inputProps.disabled, inputProps.autoFocus]);

    const className = conditionalClassNames({
        Combobox2__Input: true,
        "Combobox2__Input--invalid": invalid,
    }).join(" ");

    return (
        <div className={className} ref={ref}>
            <input
                aria-label="Suchbegriff"
                {...inputProps}
                autoComplete="off"
                ref={inputRef}
                className="form-control"
                type="text"
                value={state.query}
                onChange={(e) => dispatch({ type: "setQuery", payload: e.currentTarget.value })}
                onKeyDown={handleKeyDown}
            />
            <button type="button" onClick={handleToggleClick}>
                <Icon type={state.open ? "collapse" : "expand"} />
            </button>
        </div>
    );
}
