import * as React from "react";
import { useContext, useState } from "react";
import { EmployeeNoteCellPayload, NewEmployeeNoteEditorPayload } from "../types";
import CursorInspector from "../CursorInspector";
import { ApiAttributeErrors, ApiResult, EmployeeNote } from "../../slots/types";
import EmployeeNoteForm from "./employee-note-editor/EmployeeNoteForm";
import { DataContext } from "../../slots/DataContext";

function isEmployeeNoteCellPayload(
    payload: EmployeeNoteCellPayload | NewEmployeeNoteEditorPayload
): payload is EmployeeNoteCellPayload {
    return (payload as EmployeeNoteCellPayload).employeeNote !== undefined;
}

type EmployeeNoteEditorProps = {
    currentCellPayload: EmployeeNoteCellPayload | NewEmployeeNoteEditorPayload;
    busy: boolean;
    onBusyChange: (busy: boolean) => void;
    onDeploymentSuccessChanged: (success: boolean) => void;
};

export default function EmployeeNoteEditor(props: EmployeeNoteEditorProps) {
    const { currentCellPayload, onDeploymentSuccessChanged, busy, onBusyChange } = props;
    const [errors, setErrors] = useState<ApiAttributeErrors | null>(null);

    const { createEmployeeNote, updateEmployeeNote, destroyEmployeeNote } = useContext(DataContext);

    function makeApiRequest(fn: (...args: unknown[]) => Promise<ApiResult<EmployeeNote>>) {
        onBusyChange(true);
        const handleApiResult = (apiResult: ApiResult<EmployeeNote>) => {
            onBusyChange(false);
            setErrors(apiResult.errors);

            if (apiResult.errors && Object.keys(apiResult.errors).length > 0) {
                onDeploymentSuccessChanged(false);
            } else {
                onDeploymentSuccessChanged(true);
            }
        };

        fn().then(handleApiResult);
    }

    function handleCreate(attrs: EmployeeNote): void {
        makeApiRequest(() => createEmployeeNote(currentCellPayload.employeeId, currentCellPayload.dow, attrs.text));
    }

    function handleUpdate(attrs: EmployeeNote): void {
        makeApiRequest(() => updateEmployeeNote(attrs.id, attrs));
    }

    function handleDelete(employeeNoteId: EmployeeNote["id"]): void {
        makeApiRequest(() => destroyEmployeeNote(employeeNoteId));
    }

    return (
        <>
            {isEmployeeNoteCellPayload(currentCellPayload) ? (
                <EmployeeNoteForm
                    key={currentCellPayload.employeeNote.id}
                    value={currentCellPayload.employeeNote}
                    onSubmit={handleUpdate}
                    onDelete={() => handleDelete(currentCellPayload.employeeNote.id)}
                    busy={busy}
                />
            ) : (
                <div className="py-1">
                    <div className="mb-2">
                        <EmployeeNoteForm
                            placeholder="Neue Notiz"
                            value={{ id: null, text: "", color: "default" }}
                            onSubmit={handleCreate}
                            busy={busy}
                        />
                    </div>
                </div>
            )}
            <CursorInspector errors={errors} />
        </>
    );
}
