import * as React from "react";
import { useReportState } from "./StateContextProvider";
import { Loading } from "./report-grid/Loading";
import { Empty } from "./report-grid/Empty";
import { EttReportGrid } from "../../lib/ett/EttReportGrid";
import { Header } from "./Header";

export function ReportBrowser() {
    const { filteredDaysByWeeks } = useReportState();

    const { loading } = useReportState();
    const empty = !loading && Object.keys(filteredDaysByWeeks).length === 0;
    const populated = !loading && Object.keys(filteredDaysByWeeks).length > 0;

    return (
        <div className="EttReport">
            <Header />
            <div className="EttReport__Content">
                {loading && <Loading />}
                {empty && <Empty />}
                {populated && <EttReportGrid daysByWeeks={filteredDaysByWeeks} expandable={true} />}
            </div>
        </div>
    );
}
