export type UnapprovedAbsencesApiResponse = {
    data: {
        id: string;
        starts_on: string;
        ends_on: string;
        employee: {
            id: string;
            name: string;
        };
        reason: {
            name: string;
        };
    }[];
};

export async function fetchUnapprovedAbsences(): Promise<UnapprovedAbsencesApiResponse> {
    return fetch("/api/dashboard/unapproved_absences").then((response) => {
        if (!response.ok && response.status !== 422) {
            throw Error("Failed to load unapproved absences: " + response.statusText);
        }
        return response.json();
    });
}
