import * as React from "react";
import Header from "./Header";

type LayoutProps = {
    children?: React.ReactNode;
};

export function Layout(props: LayoutProps) {
    const { children } = props;

    return (
        <div className="WorkHubLayout">
            <Header />
            <main className="WorkHubLayout__Main">
                {children}
            </main>
        </div>
    );
}
