import * as React from "react";
import { createContext, ReactNode, useContext, useRef } from "react";
import { createPortal } from "react-dom";
import { useOutsideClickHandler } from "../dom-utils/useOutsideClickHandler";
import Icon from "../icon/Icon";
import { joinClasses } from "../dom-utils/class-names";

const Modal3Context = createContext(null);

type Modal3Props = React.HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
    onClose: () => unknown;
};

export function Modal3(props: Modal3Props) {
    const { onClose, children, ...divProps } = props;

    divProps.className = joinClasses("Modal3", divProps.className);

    const ref = useRef();
    useOutsideClickHandler(ref, onClose);

    const content = (
        <Modal3Context.Provider value={{ onClose: onClose }}>
            <div {...divProps}>
                <div className="Modal3__Window" ref={ref}>
                    {children}
                </div>
            </div>
        </Modal3Context.Provider>
    );

    return <>{createPortal(content, document.body)}</>;
}

type HeaderProps = Omit<React.HTMLAttributes<HTMLDivElement>, "title"> & {
    title: string;
};

export function Header(props: HeaderProps) {
    const { title, ...divProps } = props;
    divProps.className = joinClasses("Modal3__Header", divProps.className);

    const { onClose } = useContext(Modal3Context);

    return (
        <div {...divProps}>
            <div className="Modal3__Header__Close" onClick={onClose}>
                <Icon type="close" />
            </div>
            <div className="Modal3__Header__Title">{title}</div>
        </div>
    );
}

type BodyProps = React.HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
};

export function Body(props: BodyProps) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("Modal3__Body", divProps.className);
    return <div {...divProps}>{children}</div>;
}

type FooterProps = React.HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
};

export function Footer(props: FooterProps) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("Modal3__Footer", divProps.className);
    return <div {...divProps}>{children}</div>;
}

Modal3.Header = Header;
Modal3.Body = Body;
Modal3.Footer = Footer;
export default Modal3;
