import React, { useEffect, useRef, useState } from "react";
import { loadUnits } from "./api";
import { DateTime } from "luxon";
import { WeekTable } from "./week-table/week-table";
import { MonthTable } from "./month-table/month-table";
import SlotSearchBar from "./slot-search-bar";
import Chrome from "../../lib/chrome/Chrome";

const availableYears = [...Array(10).keys()].map((k) => new Date().getFullYear() + 1 - k);
const availableMonths = [...Array(12).keys()].map((k) => k + 1);

const unitIdSessionKey = "sheets--unitId";
const segmentSessionKey = "sheets--segment";
const modeSessionKey = "sheets--mode";

const previousSelectedUnitId = sessionStorage.getItem(unitIdSessionKey);
const previousSelectedSegment = JSON.parse(sessionStorage.getItem(segmentSessionKey));
const previousMode = sessionStorage.getItem(modeSessionKey);

const defaultSelectedDate = DateTime.local();

const Sheets = () => {
    const [availableWeeks, setAvailableWeeks] = useState([]);
    const [availableUnits, setAvailableUnits] = useState(null);

    const [selectedUnitId, setSelectedUnitId] = useState(previousSelectedUnitId || "");
    const [selectedSegment, setSelectedSegment] = useState(
        previousSelectedSegment || {
            year: Number(defaultSelectedDate.toFormat("kkkk")),
            week: Number(defaultSelectedDate.toFormat("W")),
            month: Number(defaultSelectedDate.toFormat("M")),
        }
    );

    const [mode, setMode] = useState(previousMode || "week");

    const [slotSearchValue, setSlotSearchValue] = useState("");

    const searchFieldRef = useRef(null);

    useEffect(() => {
        // Make sure to supply a date that is not prone to belong to the previous ISO year (like 2021-01-01).
        const weeksInYear = DateTime.local(selectedSegment.year, 6, 15).weeksInWeekYear;

        setAvailableWeeks(
            Array(weeksInYear)
                .fill()
                .map((element, index) => index + 1)
        );
        if (weeksInYear < selectedSegment.week) {
            setSelectedSegment({ ...selectedSegment, week: weeksInYear });
        }
    }, [selectedSegment.year]);

    // When week changes update month accordingly
    useEffect(() => {
        if ("week" === mode) {
            const dt = DateTime.fromObject({ weekYear: selectedSegment.year, weekNumber: selectedSegment.week });
            setSelectedSegment({ ...selectedSegment, month: Number(dt.toFormat("M")) });
        }
    }, [selectedSegment.week]);

    // When month changes set week to first week of month
    useEffect(() => {
        if ("month" === mode) {
            // Jump to 4th day of month to prevent going back to the last year according to ISO rules.
            const dt = DateTime.fromObject({ year: selectedSegment.year, month: selectedSegment.month, day: 4 });
            setSelectedSegment({
                ...selectedSegment,
                year: Number(dt.toFormat("kkkk")),
                week: Number(dt.toFormat("W")),
            });
        }
    }, [selectedSegment.month]);

    useEffect(() => {
        (async () => {
            const units = await loadUnits();
            setAvailableUnits(units);
            if (
                units &&
                (!selectedUnitId ||
                    !units
                        .map((unit) => {
                            return unit.id;
                        })
                        .includes(selectedUnitId))
            ) {
                changeSelectedUnitId(units[0].id);
            }
        })();
    }, []);

    useEffect(() => {
        sessionStorage.setItem(unitIdSessionKey, selectedUnitId);
        sessionStorage.setItem(segmentSessionKey, JSON.stringify(selectedSegment));
        sessionStorage.setItem(modeSessionKey, mode);
    }, [selectedUnitId, selectedSegment, mode]);

    const startOfSegment =
        "week" === mode
            ? DateTime.fromObject({ weekYear: selectedSegment.year, weekNumber: selectedSegment.week })
            : DateTime.fromObject({ year: selectedSegment.year, month: selectedSegment.month });
    const nextWeek = () => {
        stepWeek("plus", 1);
    };

    const prevWeek = () => {
        stepWeek("minus", 1);
    };

    const nextMonth = () => {
        stepMonth("plus", 1);
    };

    const prevMonth = () => {
        stepMonth("minus", 1);
    };

    const stepWeek = (op, stepSize = 1) => {
        const newWeekDate = startOfSegment[op]({ week: stepSize });
        setSelectedSegment({
            ...selectedSegment,
            year: Number(newWeekDate.toFormat("yyyy")),
            week: Number(newWeekDate.toFormat("W")),
        });
    };

    const stepMonth = (op, stepSize = 1) => {
        const newMonthDate = startOfSegment[op]({ month: stepSize });
        setSelectedSegment({
            ...selectedSegment,
            year: Number(newMonthDate.toFormat("yyyy")),
            month: Number(newMonthDate.toFormat("M")),
        });
    };

    const changeSelectedUnitId = (unitId) => {
        setSelectedUnitId(unitId);
        searchFieldRef.current.focus();
    };

    return (
        <div className="h-100 d-flex flex-column overflow-hidden">
            <div style={{ flex: "0 0 auto" }}>
                <Chrome.DetailPage.Header>
                    <Chrome.DetailPage.HeaderMain>
                        <Chrome.DetailPage.PageSupertitle>Berichte & Prognosen</Chrome.DetailPage.PageSupertitle>
                        <Chrome.DetailPage.PageTitle>Zeitkonto</Chrome.DetailPage.PageTitle>
                    </Chrome.DetailPage.HeaderMain>
                </Chrome.DetailPage.Header>

                <Chrome.DetailPage.ActionBar>
                    <div className="d-flex gap-2">
                        {availableUnits ? (
                            <select
                                style={{ width: "300px" }}
                                className="form-select"
                                value={selectedUnitId}
                                aria-label="Filiale"
                                onChange={(e) => changeSelectedUnitId(e.target.value)}
                            >
                                {availableUnits.map((unit) => (
                                    <option key={unit.id} value={unit.id}>
                                        {unit.name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <select style={{ width: "300px" }} className="form-select" value={"1"} disabled={true} />
                        )}
                        <select
                            style={{ width: "100px" }}
                            className="form-select"
                            value={selectedSegment.year}
                            aria-label="Jahr"
                            onChange={(e) => setSelectedSegment({ ...selectedSegment, year: Number(e.target.value) })}
                        >
                            {availableYears.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>

                        <div className="btn-group">
                            <button
                                className={`btn btn-${"month" === mode ? "outline-" : ""}primary`}
                                onClick={() => setMode("week")}
                            >
                                Woche
                            </button>
                            <button
                                className={`btn btn-${"week" === mode ? "outline-" : ""}primary`}
                                onClick={() => setMode("month")}
                            >
                                Monat
                            </button>
                        </div>

                        {"week" === mode && (
                            <select
                                style={{ width: "300px" }}
                                className="form-select"
                                value={selectedSegment.week}
                                aria-label="Woche"
                                onChange={(e) =>
                                    setSelectedSegment({ ...selectedSegment, week: Number(e.target.value) })
                                }
                            >
                                {availableWeeks.map((week) => (
                                    <option key={week} value={week}>
                                        KW {week} (
                                        {DateTime.fromObject({
                                            weekYear: selectedSegment.year,
                                            weekNumber: Number(week),
                                        }).toISODate()}{" "}
                                        -{" "}
                                        {DateTime.fromObject({
                                            weekYear: selectedSegment.year,
                                            weekNumber: Number(week),
                                        })
                                            .endOf("week")
                                            .toISODate()}
                                        )
                                    </option>
                                ))}
                            </select>
                        )}
                        {"month" === mode && (
                            <select
                                style={{ width: "300px" }}
                                className="form-select"
                                value={selectedSegment.month}
                                aria-label="Monat"
                                onChange={(e) =>
                                    setSelectedSegment({ ...selectedSegment, month: Number(e.target.value) })
                                }
                            >
                                {availableMonths.map((month) => (
                                    <option key={month} value={month}>
                                        {DateTime.fromObject({ month }, { locale: "de" }).toFormat("MMMM")}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                    <div className="d-flex gap-1">
                        <button
                            type="button"
                            aria-label="Zurück"
                            className="btn btn-primary"
                            onClick={() => ("week" === mode ? prevWeek() : prevMonth())}
                        >
                            <i className="bi-chevron-left" />
                        </button>
                        <button
                            type="button"
                            aria-label="Weiter"
                            className="btn btn-primary"
                            onClick={() => ("week" === mode ? nextWeek() : nextMonth())}
                        >
                            <i className="bi-chevron-right" />
                        </button>
                    </div>
                </Chrome.DetailPage.ActionBar>
            </div>

            <SlotSearchBar
                slotSearchValue={slotSearchValue}
                setSlotSearchValue={setSlotSearchValue}
                searchFieldRef={searchFieldRef}
            />

            {"week" === mode ? (
                <WeekTable
                    unitId={selectedUnitId}
                    year={selectedSegment.year}
                    week={selectedSegment.week}
                    slotSearchValue={slotSearchValue}
                />
            ) : (
                <MonthTable
                    unitId={selectedUnitId}
                    year={selectedSegment.year}
                    month={selectedSegment.month}
                    slotSearchValue={slotSearchValue}
                />
            )}
        </div>
    );
};

Sheets.defaultProps = {};

Sheets.propTypes = {};

export default Sheets;
