import * as React from "react";
import { useEmployeePermissions, useEmployeeState } from "../state";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import ContextualInfo from "../../../../lib/manual/ContextualInfo";
import Icon from "../../../../lib/icon/Icon";
import { EttReportGrid } from "../../../../lib/ett/EttReportGrid";

export function WorkHub(props: React.HTMLAttributes<HTMLDivElement>) {
    const { canManageAccessTokens } = useEmployeePermissions();

    const { ...divProps } = props;
    const { employee, ettDaysByWeek } = useEmployeeState();

    if (!canManageAccessTokens) {
        return null;
    }

    return (
        <Kitchensink.Item {...divProps}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>
                    Work Hub {" "}
                    <ContextualInfo manualKey="work_hub/work_hub" />
                </Kitchensink.ItemBodyTitle>

                {canManageAccessTokens && (
                    <>
                        <Kitchensink.ItemBodyPart>
                            <EttReportGrid daysByWeeks={ettDaysByWeek} expandable={false} />
                        </Kitchensink.ItemBodyPart>
                        <Kitchensink.ItemBodyLinkList>
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/ett_report`}>
                                <Icon type="ett-report" />{" "}
                                Zeiterfassungsbericht
                            </a>
                            <a className="Button Button--primadonna"
                               href={`/employees/${employee.id}/employee_auth_tokens`}>
                                <Icon type="auth-code" />{" "}
                                Authentifizierungsschlüssel verwalten
                            </a>
                        </Kitchensink.ItemBodyLinkList>
                    </>
                )}
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    )
        ;
}
