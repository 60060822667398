import { CreateOrUpdateResult, GetResult } from "./adapter";

const contentTypeJsonHeader = {
    "Content-Type": "application/json",
};
const acceptJsonHeader = {
    Accept: "application/json",
};

export function get<T>(path: string): Promise<GetResult<T>> {
    return handleResponsePromise(
        fetch(path, {
            method: "GET",
            headers: acceptJsonHeader,
        })
    );
}

export function post<T>(path: string, data: Partial<T>): Promise<CreateOrUpdateResult<T>> {
    return patchOrPost("POST", path, data);
}

export function patch<T>(path: string, data: Partial<T>): Promise<CreateOrUpdateResult<T>> {
    return patchOrPost("PATCH", path, data);
}

export function patchOrPost<T>(method, path, data: Partial<CreateOrUpdateResult<T>>): Promise<T> {
    return handleResponsePromise(
        fetch(path, {
            method: method,
            headers: {
                ...contentTypeJsonHeader,
                ...acceptJsonHeader,
            },
            body: JSON.stringify(data),
        })
    );
}

function handleResponsePromise<T>(responsePromise: Promise<Response>): Promise<T> {
    return responsePromise.then(handleResponse).catch(handleNetworkError);
}

function handleResponse(response) {
    // If you are using XHR requests other than GET or POST and redirecting after the request then some browsers will
    // follow the redirect using the original request method. This may lead to undesirable behavior such as a
    // double DELETE. To work around this you can return a 303 See Other status code which will be followed using a
    // GET request.
    if (response.ok || response.status === 422) {
        const redirectUrl = response.headers.get("X-Brix-WUILocation");
        if (redirectUrl) {
            window.location.href = redirectUrl;
            return {};
        }
        return response.json();
    } else {
        return Promise.resolve({ errors: { base: response.statusText } });
    }
}

function handleNetworkError(error) {
    console.error(error);
    return Promise.resolve({ errors: { base: error } });
}
