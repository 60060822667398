import * as React from "react";
import { HTMLAttributes } from "react";
import Icon from "../../../lib/icon/Icon";
import { conditionalClassNames } from "../../../lib/dom-utils/class-names";
import { DAbsenceCertificationStatus } from "../types";

type AbsenceCertificationStatusProps = HTMLAttributes<HTMLDivElement> & {
    status: DAbsenceCertificationStatus | null;
};

export default function AbsenceCertificationStatus(props: AbsenceCertificationStatusProps) {
    const { status, ...otherProps } = props;

    delete otherProps.className;

    const classNames = conditionalClassNames({
        AbsenceCertificationStatus: true,
        "AbsenceCertificationStatus--full": "full" === status,
        "AbsenceCertificationStatus--partial": "partial" === status,
        "AbsenceCertificationStatus--none": "none" === status,
    });

    let label;

    if ("full" === status) {
        label = "Es liegen Nachweise für den gesamten Abwesenheitszeitraum vor";
    } else if ("partial" === status) {
        label = "Es liegen Nachweise für einen Teil des Abwesenheitszeitraums vor";
    } else {
        label = "Es liegen keine Nachweise vor";
    }

    return (
        <Icon
            type={`absence-certification-${status}`}
            className={classNames.join(" ")}
            aria-label={label}
            title={label}
        />
    );
}
