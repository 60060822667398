import * as React from "react";
import { forwardRef, SelectHTMLAttributes } from "react";

type SelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, "size" | "value" | "onChange"> & {
    name?: string;
    value?: string;
    onChange?: (value: string) => void;
    options: { [key: string]: string };
    includeBlank?: boolean;
    blankName?: string;
    size?: "lg" | "md" | "sm";
};

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
    const { value = null, includeBlank = true, options, onChange, size = "md", blankName = "", ...otherProps } = props;
    const classNames = ["form-select"];

    if ("md" !== size) {
        classNames.push(`form-select-${size}`);
    }

    return (
        <>
            <select
                ref={ref}
                className={classNames.join(" ")}
                value={value || ""}
                onChange={(e) => onChange && onChange(e.target.value)}
                {...otherProps}
            >
                {includeBlank && <option value="">{blankName}</option>}
                {Object.keys(options).map((key) => (
                    <option key={key} value={key}>
                        {options[key]}
                    </option>
                ))}
            </select>
        </>
    );
});

Select.displayName = "Select";

export default Select;
