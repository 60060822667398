import * as React from "react";
import { useState } from "react";
import { Interval } from "luxon";
import Button, { ButtonProps } from "../../button/Button";
import { dateFormat } from "../../datetime/date-format";
import { IntervalPicker } from "./IntervalPicker";
import Modal from "../../modal/Modal";

type IntervalPickerFieldProps = Omit<ButtonProps, "onChange" | "value"> & {
    value: Interval;
    invalid?: boolean;
    onChange: (value: Interval) => void;
};

export default function IntervalPickerField(props: IntervalPickerFieldProps) {
    const { value, onChange, ...buttonProps } = props;
    const [open, setOpen] = useState<boolean>(false);

    buttonProps.theme = "light";
    buttonProps.className = "bg-white border w-100";

    return (
        <div className="w-100">
            <Button {...buttonProps} onClick={() => setOpen(!open)}>
                {value ? (
                    <>
                        {value.start.toFormat(dateFormat)} → {value.end.toFormat(dateFormat)}
                    </>
                ) : (
                    <div>Keine Auswahl</div>
                )}
            </Button>
            {open && (
                <Modal open={open} setOpen={(val) => setOpen(val)}>
                    <IntervalPicker
                        value={value}
                        onPick={(val) => {
                            setOpen(false);
                            onChange(val);
                        }}
                        onCancel={() => setOpen(false)}
                    />
                </Modal>
            )}
        </div>
    );
}
