import * as React from "react";
import { useEffect, useState } from "react";
import Collapsed from "../../combo-box/Collapsed";
import Expanded from "../../combo-box/Expanded";

export type OptionValue = string | number;
export type OptionLabel = string;

export type Option = {
    label: OptionLabel;
    value: OptionValue;
};

export type GroupedOptions = {
    [key: string]: Option[];
};

function buildFilterdOptions(query: string, options: GroupedOptions): GroupedOptions {
    if (!query.length) {
        return options;
    }

    const filteredOptions: GroupedOptions = {};
    Object.keys(options).forEach((groupName) => {
        filteredOptions[groupName] = options[groupName].filter((option) => {
            return (
                option.label.toLowerCase().includes(query.toLowerCase()) ||
                groupName.toLowerCase().includes(query.toLowerCase())
            );
        });
    });

    Object.keys(filteredOptions)
        .filter((key) => filteredOptions[key].length === 0)
        .forEach((key) => delete filteredOptions[key]);

    return filteredOptions;
}

export type ComboBoxProps = {
    value: OptionValue[];
    groupedOptions: GroupedOptions;
    onChange: (newValue: OptionValue[]) => void;
    expanded?: boolean;
    collapsible?: boolean;
    multiselect?: boolean;
    allLabel?: string;
};

export default function ComboBox(props: ComboBoxProps) {
    const {
        groupedOptions,
        value,
        onChange,
        expanded: initialExpanded = false,
        collapsible = true,
        multiselect = false,
        allLabel
    } = props;

    const [expanded, setExpanded] = useState<boolean>(initialExpanded);
    const [query, setQuery] = useState<string>("");
    const [selection, setSelection] = useState<Set<OptionValue>>(new Set<OptionValue>(value));

    useEffect(() => {
        setSelection(new Set(value));
    }, [value]);

    const [filteredOptions, setFilteredOptions] = useState<GroupedOptions>(buildFilterdOptions(query, groupedOptions));

    useEffect(() => {
        setFilteredOptions(buildFilterdOptions(query, groupedOptions));
    }, [query, groupedOptions]);

    const [labelMap, setLabelMap] = useState<Map<OptionValue, OptionLabel> | null>(new Map());

    useEffect(() => {
        const map = new Map();
        Object.keys(groupedOptions).forEach((groupName) => {
            groupedOptions[groupName].forEach((item) => {
                map.set(item.value, item.label);
            });
        });
        setLabelMap(map);
    }, [groupedOptions]);

    const onSelectOption = (value: Option["value"]) => {
        setSelection(new Set([value]));
        onChange([value]);
        setExpanded(false);
    };

    const onAddOptionsToSelection = (values: Option["value"][]) => {
        const newSelection = new Set(selection);

        values.forEach((value) => {
            if (newSelection.has(value)) {
                newSelection.delete(value);
            } else {
                newSelection.add(value);
            }
        });
        setSelection(newSelection);
        onChange([...newSelection]);
    };

    const selectGroupItems = (groupName: string) => {
        const newSelection = new Set(selection);
        filteredOptions[groupName].forEach((option) => {
            newSelection.add(option.value);
        });
        setSelection(newSelection);
        onChange([...newSelection]);
    };

    if (!expanded && collapsible) {
        return <Collapsed selection={selection} setExpanded={setExpanded} labelMap={labelMap} allLabel={allLabel} />;
    } else {
        return (
            <Expanded
                {...{
                    selection,
                    collapsible,
                    collapse: () => collapsible && setExpanded(false),
                    query,
                    setQuery,
                    options: filteredOptions,
                    selectGroupItems,
                    onSelectOption,
                    onAddOptionsToSelection,
                    multiselect
                }}
            />
        );
    }
}
