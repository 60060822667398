import * as React from "react";
import { FaceDefaultProps } from "./type";
import DeploymentEditor from "../editors/DeploymentEditor";
import { DeploymentCellPayload } from "../types";
import TabbedContent from "./TabbedContent";
import DeploymentSequenceEditor from "../editors/DeploymentSequenceEditor";
import FaceFrame from "./FaceFrame";
import CustomItemColorPicker from "../../../../lib/color-picker/CustomItemColorPicker";
import { useContext } from "react";
import { DataContext } from "../../slots/DataContext";
import { CustomItemColor } from "../../types";

type DeploymentFaceProps = FaceDefaultProps & {
    currentCellPayload: DeploymentCellPayload;
    onDeploymentSuccessChanged: (success: boolean) => unknown;
};

export default function DeploymentFace(props: DeploymentFaceProps): JSX.Element {
    const { busy, onBusyChange, currentCellPayload, onDeploymentSuccessChanged } = props;

    const commonProps = {
        currentCellPayload: currentCellPayload,
        busy: busy,
        onBusyChange: onBusyChange,
        onDeploymentSuccessChanged: onDeploymentSuccessChanged,
    };

    const tabs = {
        deployment: {
            label: "Einsatz",
            element: <DeploymentEditor {...commonProps} />,
        },
        break: {
            label: "Pause",
            element: <DeploymentSequenceEditor {...commonProps} />,
        },
    };

    const { updateDeployment } = useContext(DataContext);

    const handleColorChange = (color: CustomItemColor | null) => {
        updateDeployment(currentCellPayload.deploymentId, {
            color: color,
            start_time: currentCellPayload.startTime, // required by interface...
            end_time: currentCellPayload.endTime, // required by interface...
        }).then(() => undefined);
    };

    const colorPicker = <CustomItemColorPicker value={currentCellPayload.color} onChange={handleColorChange} />;

    return (
        <FaceFrame title={"Einsatz"} icon={"deployment"} widgets={colorPicker}>
            <TabbedContent tabs={tabs} initialTab="deployment" />
        </FaceFrame>
    );
}
