import { useEffect } from "react";

export function useKeyboardShortcut(combination: string, callback: () => void) {
    useEffect(() => {
        const keys = combination.toLowerCase().split("-");

        function handleKeyDown(e: KeyboardEvent) {
            const isCtrl = -1 !== keys.indexOf("ctrl") ? e.ctrlKey : true;
            const isMeta = -1 !== keys.indexOf("meta") ? e.metaKey : true;
            const isAlt = -1 !== keys.indexOf("alt") ? e.altKey : true;
            const isShift = -1 !== keys.indexOf("shift") ? e.shiftKey : true;
            const isKey = -1 !== keys.indexOf(e.key.toLowerCase());

            if (isCtrl && isMeta && isAlt && isShift && isKey) {
                callback();
            }
        }

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [combination, callback]);
}
