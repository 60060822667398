import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import {
    Absence,
    Day,
    DepartmentBalanceMap,
    DeploymentSlot,
    Employee,
    EmployeeBalanceMap,
    ExportPrivileges,
    Schedule,
    ScheduleBalances,
    ScheduleDailyNotesMap,
    Unit,
} from "../types";
import Loading from "../../../lib/./empty-state/Loading";
import SchedulerChannelSubscription, { BalancesChannelData } from "./SchedulerChannelSubscription";
import {
    DeploymentSequenceMap,
    EmployeeAllocations,
    EmployeeNotesDirectory,
    EmployeesDirectory,
    EmployeeWithAbsentDays,
    PublicHolidaysInWeek,
    ScheduleResponse,
    ShortEmployeeReference,
} from "./types";
import { DataContext, DataContextType } from "./DataContext";
import { buildIdMap, deleteAbsenceFromSlots, upsertAbsenceIntoSlots } from "./reducers";
import { buildDataContextValue } from "./data-context/build-value";

export const ScheduleContext = React.createContext<Schedule>(null);

type BalancesContextType = {
    employeeBalances: EmployeeBalanceMap;
    departmentBalances: DepartmentBalanceMap;
    invalidate: (employeeId: Employee["id"]) => void;
};
export const BalancesContext = React.createContext<BalancesContextType>(null);
export const PublicHolidaysContext = React.createContext<PublicHolidaysInWeek>(null);
export const DeploymentSequencesContext = React.createContext<DeploymentSequenceMap>(null);
export const EmployeeNotesContext = React.createContext<EmployeeNotesDirectory>(null);
export const ScheduleDailyNotesContext = React.createContext<ScheduleDailyNotesMap>(null);
export const AllocationsContext = React.createContext<EmployeeAllocations[]>(null);
export const ScheduleBalancesContext = React.createContext<ScheduleBalances>(null);
export const ExportPrivilegesContext = React.createContext<ExportPrivileges>(null);

type SlotsControllerProps = {
    scheduleId: Schedule["id"];
    children: (
        slots: DeploymentSlot[],
        deploymentSequences: DeploymentSequenceMap,
        allocations: EmployeeAllocations[],
        unit: Unit | null,
        undeployedEmployees: ShortEmployeeReference[],
        absentEmployees: EmployeeWithAbsentDays[]
    ) => React.ReactNode;
};

export default function SlotsController({ scheduleId, children }: SlotsControllerProps) {
    const [slots, setSlots] = useState<DeploymentSlot[] | null>(null);
    const [deploymentSequences, setDeploymentSequences] = useState<DeploymentSequenceMap | null>(null);
    const [employeeBalances, setEmployeeBalances] = useState<EmployeeBalanceMap | null>(null);
    const [departmentBalances, setDepartmentBalances] = useState<DepartmentBalanceMap | null>(null);
    const [schedule, setSchedule] = useState<Schedule | null>(null);
    const [scheduleBalances, setScheduleBalances] = useState<ScheduleBalances | null>(null);
    const [publicHolidays, setPublicHolidays] = useState<PublicHolidaysInWeek | null>(null);
    const [allocations, setAllocations] = useState<EmployeeAllocations[] | null>(null);
    const [employeeNotesDirectory, setEmployeeNotesDirectory] = useState<EmployeeNotesDirectory | null>(null);
    const [employees, setEmployees] = useState<EmployeesDirectory | null>(null);
    const [scheduleDailyNotes, setScheduleDailyNotes] = useState<ScheduleDailyNotesMap>(null);
    const [exportPrivileges, setExportPrivileges] = useState<ExportPrivileges>(null);

    function invalidateBalance(employeeId: Employee["id"]) {
        setEmployeeBalances((prevBalances) => {
            const nextBalances = { ...prevBalances };
            nextBalances[employeeId].invalidated = true;
            return nextBalances;
        });
        setDepartmentBalances((prevBalances) => {
            const nextBalances = { ...prevBalances };
            Object.keys(nextBalances).forEach((departmentId) => {
                nextBalances[departmentId].invalidated = true;
            });
            return nextBalances;
        });
    }

    useEffect(() => {
        fetch(`/api/scheduler/schedules/${scheduleId}`)
            .then((response) => response.json())
            .then((data: ScheduleResponse) => {
                setSchedule(data.schedule);
                setSlots(data.slots);
                setPublicHolidays(data.public_holidays);
                setDeploymentSequences(buildIdMap(data.deployment_sequences));
                setAllocations(data.allocations);
                setEmployeeNotesDirectory(data.employee_notes);
                setEmployees(data.employees);
                setScheduleBalances(data.schedule_balances);
                setScheduleDailyNotes(data.schedule_daily_notes);
                setExportPrivileges(data.export_privileges);
            });
    }, [scheduleId]);

    const presentEmployees = useMemo(() => {
        if (!employees) {
            return null;
        }

        const presentEmployees = new Map<Employee["id"], ShortEmployeeReference>();
        Object.keys(employees).forEach((employeeId) => {
            if (
                [1, 2, 3, 4, 5, 6, 7].every((dow) => {
                    const absences = employees[employeeId].absent_days[dow];
                    return !absences || absences.length === 0;
                })
            ) {
                presentEmployees.set(employeeId, employees[employeeId]);
            }
        });
        return presentEmployees;
    }, [employees]);

    const undeployedEmployees = useMemo(() => {
        if (!presentEmployees || !slots) {
            return null;
        }

        const res = new Map<Employee["id"], ShortEmployeeReference>(presentEmployees);

        for (const deploymentSlot of slots) {
            for (const employeeWeek of deploymentSlot.employee_weeks) {
                const hasDeployments = [1, 2, 3, 4, 5, 6, 7].some(
                    (dow) => (employeeWeek.days[dow] as Day).deployments.length > 0
                );
                if (hasDeployments) {
                    res.delete(employeeWeek.employee.id);
                }
            }
        }

        return [...res.values()];
    }, [presentEmployees, slots]);

    const absentEmployees = useMemo(() => {
        if (!employees) {
            return null;
        }

        const res = new Map<Employee["id"], EmployeeWithAbsentDays>();
        Object.keys(employees).forEach((employeeId) => {
            if (
                [1, 2, 3, 4, 5, 6, 7].some((dow) => {
                    const absences = employees[employeeId].absent_days[dow];
                    return absences && absences.length > 0;
                })
            ) {
                res.set(employeeId, employees[employeeId]);
            }
        });
        return [...res.values()];
    }, [employees]);

    const dataContextValue: DataContextType = useMemo(
        () =>
            buildDataContextValue(
                schedule,
                slots,
                setSlots,
                deploymentSequences,
                setDeploymentSequences,
                employeeNotesDirectory,
                setEmployeeNotesDirectory,
                scheduleDailyNotes,
                setScheduleDailyNotes
            ),
        [
            scheduleId,
            slots,
            setSlots,
            deploymentSequences,
            setDeploymentSequences,
            employeeNotesDirectory,
            setEmployeeNotesDirectory,
            scheduleDailyNotes,
            setScheduleDailyNotes,
        ]
    );

    if (!slots) {
        return (
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Loading loadingText={"Lade Wochenplan..."} />
            </div>
        );
    }

    const handleBalancesUpdated = (data: BalancesChannelData) => {
        setScheduleBalances(data.schedule_balances);
        setEmployeeBalances(data.employee_balances);
        setDepartmentBalances(data.department_balances);
    };

    const handleAbsenceUpdated = (absence: Absence) => {
        setSlots((slots) => upsertAbsenceIntoSlots(slots, absence, schedule));
    };

    const handleAbsenceDeleted = (absenceId: Absence["id"]) => {
        setSlots((slots) => deleteAbsenceFromSlots(slots, absenceId));
    };

    return (
        <SchedulerChannelSubscription
            scheduleId={scheduleId}
            onUpdateBalances={handleBalancesUpdated}
            onUpdateAbsence={handleAbsenceUpdated}
            onDeleteAbsence={handleAbsenceDeleted}
        >
            <BalancesContext.Provider
                value={{
                    departmentBalances: departmentBalances,
                    employeeBalances: employeeBalances,
                    invalidate: invalidateBalance,
                }}
            >
                <ScheduleContext.Provider value={schedule}>
                    <PublicHolidaysContext.Provider value={publicHolidays}>
                        <EmployeeNotesContext.Provider value={employeeNotesDirectory}>
                            <ScheduleDailyNotesContext.Provider value={scheduleDailyNotes}>
                                <AllocationsContext.Provider value={allocations}>
                                    <DeploymentSequencesContext.Provider value={deploymentSequences}>
                                        <ScheduleBalancesContext.Provider value={scheduleBalances}>
                                            <DataContext.Provider value={dataContextValue}>
                                                <ExportPrivilegesContext.Provider value={exportPrivileges}>
                                                    {children(
                                                        slots,
                                                        deploymentSequences,
                                                        allocations,
                                                        schedule.unit ? schedule.unit : null,
                                                        undeployedEmployees,
                                                        absentEmployees
                                                    )}
                                                </ExportPrivilegesContext.Provider>
                                            </DataContext.Provider>
                                        </ScheduleBalancesContext.Provider>
                                    </DeploymentSequencesContext.Provider>
                                </AllocationsContext.Provider>
                            </ScheduleDailyNotesContext.Provider>
                        </EmployeeNotesContext.Provider>
                    </PublicHolidaysContext.Provider>
                </ScheduleContext.Provider>
            </BalancesContext.Provider>
        </SchedulerChannelSubscription>
    );
}
