import * as React from "react";
import { InputHTMLAttributes, useRef } from "react";
import { objectToSeconds, padTimePart, secondsToObject } from "../../datetime/time";

type TimeFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    value: string | number;
    onChange: (value: string) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    disabled?: boolean;
    readOnly?: boolean;
};

const TimeField = (props: TimeFieldProps) => {
    const { value, onChange, onKeyDown = null, disabled = false, readOnly = false } = props;

    const time = secondsToObject(value);

    const hoursRef = useRef();
    const minutesRef = useRef();

    const handleChange = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const hours = hoursRef.current.value;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const minutes = Math.min(parseInt((minutesRef.current?.value || "0").slice(-2)), 59);

        const result = objectToSeconds({ hours, minutes, seconds: 0 });
        onChange(isNaN(result) ? value.toString() : result.toString());
    };

    return (
        <>
            <div
                className={`d-inline-flex border rounded py-1 px-2 ${readOnly ? "bg-light" : "bg-white"}`}
                role="group"
                style={{ width: "95px" }}
            >
                <div style={{ flex: "0 0 40px", paddingRight: "5px" }}>
                    <input
                        ref={hoursRef}
                        type="text"
                        className={`${readOnly ? "text-muted" : ""}`}
                        style={{ width: "100%", textAlign: "right", border: 0, backgroundColor: "transparent" }}
                        value={time.hours}
                        onChange={handleChange}
                        onKeyDown={onKeyDown}
                        aria-label="Stunden"
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
                <div className="text-muted">:</div>
                <div style={{ flex: "0 0 30px" }}>
                    <input
                        ref={minutesRef}
                        type="text"
                        className={`${readOnly ? "text-muted" : ""}`}
                        style={{ width: "100%", textAlign: "right", border: 0, backgroundColor: "transparent" }}
                        value={padTimePart(time.minutes)}
                        onChange={handleChange}
                        onKeyDown={onKeyDown}
                        aria-label="Minuten"
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

TimeField.displayName = "TimeField";

export default TimeField;
