import { useMemo } from "react";
import { isNumber } from "../number/number";

export function useGridTemplateColumns(columns): string {
    return useMemo(() => {
        // Use reduce() to create an array of grid template
        // column strings, where each string represents a
        // column with a minimum and maximum width in pixels.
        const gridTemplateColumnParts = columns.reduce((acc, column) => {
            const minWidth = isNumber(column.minWidth) ? `${column.minWidth}px` : column.minWidth;
            const maxWidth = isNumber(column.maxWidth) ? `${column.maxWidth}px` : column.maxWidth;

            acc.push(`minmax(${minWidth}, ${maxWidth})`);
            return acc;
        }, []);

        // Join the array of grid template column strings
        // into a single string that can be used as the value
        // for the grid-template-columns CSS property.
        return gridTemplateColumnParts.join(" ");
    }, [columns]);
}
