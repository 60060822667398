import * as React from "react";
import { Skill, SkillPlusLevel } from "../../../../../lib/types/skills";
import SkillBadge from "./SkillBadge";
import { useMemo } from "react";
import { sortSkills } from "./skills";

type SkillListProps = {
    skills: (SkillPlusLevel | Skill)[];
};

export default function SkillList(props: SkillListProps) {
    const { skills } = props;

    const sortedSkills = useMemo(() => sortSkills(skills), [skills]);

    return (
        <div className="SchedulerEmployeeWeekEditor__SkillList">
            {sortedSkills.map((skill) => (
                <SkillBadge key={skill.id} skill={skill} />
            ))}
        </div>
    );
}
