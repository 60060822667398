import * as React from "react";
import { useContext, useState } from "react";
import { NewEmployeeWeekCellPayload } from "../types";
import { ScheduleIdContext } from "../../Scheduler";
import { SkilledEmployee } from "../../../../lib/types/employees";
import { CursorPositionContext } from "../CursorPositionContext";
import { DataContext } from "../../slots/DataContext";
import useEmployeeSearch from "./new-employee-week-editor/useEmployeeSearch";
import useEmployeeSelection from "./new-employee-week-editor/useEmployeeSelection";
import FinderWindow from "../../../../lib/finder/FinderWindow";
import FinderWindowHeader from "../../../../lib/finder/FinderWindowHeader";
import FinderWindowBody from "../../../../lib/finder/FinderWindowBody";
import { SelectedDepartment } from "./new-employee-week-editor/SelectedDepartment";
import FinderPrompt from "../../../../lib/finder/FinderPrompt";
import { Filters } from "./new-employee-week-editor/Filters";
import EmployeeResultContent from "./new-employee-week-editor/EmployeeResultContent";
import FinderResultPane from "../../../../lib/finder/FinderResultPane";
import FinderResult from "../../../../lib/finder/FinderResult";

type NewEmployeeWeekEditorProps = {
    busy: boolean;
    onBusyChange: (busy: boolean) => void;
    currentCellPayload: NewEmployeeWeekCellPayload;
};

export default function NewEmployeeWeekEditor(props: NewEmployeeWeekEditorProps) {
    const { onBusyChange, currentCellPayload } = props;
    const {
        id: departmentId,
        primary_skills: departmentPrimarySkills,
        secondary_skills: departmentSecondarySkills,
    } = currentCellPayload.department;

    const { cursorPosition, setCursorPosition } = useContext(CursorPositionContext);
    const { newEmployeeWeek } = useContext(DataContext);
    const scheduleId = useContext(ScheduleIdContext);
    const [query, setQuery] = useState("");
    const [onlyQualified, setOnlyQualified] = useState(true);

    const employees = useEmployeeSearch(
        scheduleId,
        query,
        departmentPrimarySkills,
        departmentSecondarySkills,
        onlyQualified && departmentPrimarySkills.length > 0,
        onBusyChange
    );

    const { selectedEmployee, selectNextEmployee, selectPreviousEmployee } = useEmployeeSelection(employees);

    function closeEditor() {
        setCursorPosition({ ...cursorPosition, editorOpen: false });
    }

    function handleCommit() {
        selectedEmployee && insertEmployeeWeekIntoSlots(selectedEmployee);
    }

    async function insertEmployeeWeekIntoSlots(employee: SkilledEmployee) {
        closeEditor();
        onBusyChange(true);
        await newEmployeeWeek(departmentId, employee);
        onBusyChange(false);
    }

    const finderKeyboardNavProps = {
        onNext: selectNextEmployee,
        onPrevious: selectPreviousEmployee,
        onClose: closeEditor,
        onCommit: handleCommit,
    };

    return (
        <FinderWindow>
            <FinderWindowHeader onClose={closeEditor} title={"Mitarbeiter hinzufügen"} />
            <FinderWindowBody>
                <SelectedDepartment department={currentCellPayload.department} />

                <FinderPrompt query={query} onQueryChange={setQuery} {...finderKeyboardNavProps} />

                <Filters
                    onlyQualified={onlyQualified}
                    onOnlyQualifiedChange={setOnlyQualified}
                    enableFilterByQualified={departmentPrimarySkills.length > 0}
                    {...finderKeyboardNavProps}
                />

                <FinderResultPane loading={!employees}>
                    {employees.map((employee, idx) => (
                        <FinderResult
                            key={idx}
                            selected={selectedEmployee?.id === employee.id}
                            onClick={() => insertEmployeeWeekIntoSlots(employee)}
                        >
                            <EmployeeResultContent employee={employee} />
                        </FinderResult>
                    ))}
                </FinderResultPane>
            </FinderWindowBody>
        </FinderWindow>
    );
}
