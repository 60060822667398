import * as React from "react";
import LogoutButton from "../layout/LogoutButton";

export default function ExchangeErrorPanel() {
    return (<>
        <div
            className="px-3 py-3 text-center bg-danger text-white rounded-2 mb-4"
            style={{ left: 0, top: 0 }}>
            <h1 role="alert">
                Anmeldung fehlgeschlagen. <br />
                Bitte überprüfe Deine Code-Karte.
            </h1>
        </div>
        <div className="d-flex justify-content-center">
            <LogoutButton text="Zurück zur Anmeldung" />
        </div>
    </>);
}
