import * as React from "react";
import { Outlet } from "react-router-dom";
import Chrome from "../../../lib/chrome/Chrome";

export default function Layout() {
    return (
        <div className="AbsenceReport__Container">
            <Chrome.DetailPage.Header>
                <Chrome.DetailPage.HeaderMain>
                    <Chrome.DetailPage.PageSupertitle>Berichte & Prognosen</Chrome.DetailPage.PageSupertitle>
                    <Chrome.DetailPage.PageTitle>Abwesenheiten</Chrome.DetailPage.PageTitle>
                </Chrome.DetailPage.HeaderMain>
            </Chrome.DetailPage.Header>
            <Outlet />
        </div>
    );
}
