import * as React from "react";
import { useRef } from "react";
import { GroupedOptions, Option, OptionValue } from "../form/select/ComboBox";
import SelectorButton from "./SelectorButton";
import Options from "./Options";
import { useOutsideClickHandler } from "../dom-utils/useOutsideClickHandler";
import TextField from "../form/text/TextField";

type ExpandedProps = {
    selection: Set<OptionValue>;
    collapse: () => void;
    collapsible: boolean;
    query: string;
    setQuery: (query: string) => void;
    options: GroupedOptions;
    selectGroupItems: (groupName: string) => void;
    onSelectOption: (value: Option["value"]) => void;
    onAddOptionsToSelection: (value: Option["value"][]) => void;
    multiselect: boolean;
};

export default function Expanded(props: ExpandedProps) {
    const {
        selection,
        collapse,
        setQuery,
        query,
        options,
        selectGroupItems,
        onSelectOption,
        onAddOptionsToSelection,
        collapsible,
        multiselect
    } = props;

    const wrapperRef = useRef(null);
    useOutsideClickHandler(wrapperRef, collapse);

    return (
        <div className="expanded" ref={wrapperRef} data-brix-combobox="">
            <div className="position-relative d-flex flex-column">
                <div
                    className="btn btn-light bg-white border py-0 ps-1 d-flex align-items-center justify-content-between"
                    style={{ paddingRight: "30px" }}
                >
                    <TextField
                        autoFocus={true}
                        value={query}
                        onChange={(newQuery) => setQuery(newQuery)}
                        placeholder="Suchbegriff..."
                    />
                    {collapsible && <SelectorButton onClick={collapse} />}
                </div>
                <Options
                    options={options}
                    multiselect={multiselect}
                    selection={selection}
                    selectGroupItems={selectGroupItems}
                    onSelectOption={onSelectOption}
                    onAddOptionsToSelection={onAddOptionsToSelection}
                />
            </div>
        </div>
    );
}
