import * as React from "react";
import { useEffect, useRef } from "react";
import { conditionalClassNames } from "../../dom-utils/class-names";
import { useImmerReducer } from "use-immer";
import { useOutsideClickHandler } from "../../dom-utils/useOutsideClickHandler";
import Input from "./Input";
import Options from "./Options";
import { ComboboxContext, ComboboxDispatchContext, createInitialState, reducer } from "./state";
import { WithFloatingContext } from "./FloatingContext";

type ComboboxProps = React.HTMLAttributes<HTMLDivElement> & {
    value: string;
    onChange: (value: string) => unknown;
    options: Record<string, string>;
    autoFocus?: boolean;
    disabled?: boolean;
    placeholder?: string;
    emptyMessage?: string;
    invalid?: boolean;
};

function Combobox(props: ComboboxProps) {
    const {
        options,
        autoFocus,
        value,
        onChange,
        disabled,
        placeholder,
        emptyMessage = "Keine passenden Einträge gefunden",
        invalid = false,
        ...divProps
    } = props;
    const [state, dispatch] = useImmerReducer(reducer, createInitialState(value, options));

    useEffect(() => {
        dispatch({ type: "init", payload: { options, value } });
    }, [state.selectedValue, Object.keys(options).join("---")]);

    const containerRef = useRef<HTMLDivElement>(null);

    useOutsideClickHandler(containerRef, () => {
        dispatch({ type: "blur" });
    });

    const className = conditionalClassNames({
        Combobox2: true,
        "Combobox2--disabled": disabled,
        "Combobox2--invalid": invalid,
    }).join(" ");

    return (
        <ComboboxContext.Provider value={state}>
            <ComboboxDispatchContext.Provider value={dispatch}>
                <WithFloatingContext>
                    <div className={className} ref={containerRef} {...divProps}>
                        <Input
                            invalid={invalid}
                            autoFocus={autoFocus}
                            onSubmit={(value) => onChange(value)}
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                        {state.open && <Options onSelect={(value) => onChange(value)} emptyMessage={emptyMessage} />}
                        {/*{state.open &&*/}
                        {/*    createPortal(*/}
                        {/*        <Options onSelect={(value) => onChange(value)} emptyMessage={emptyMessage} />,*/}
                        {/*        document.body*/}
                        {/*    )}*/}
                    </div>
                </WithFloatingContext>
            </ComboboxDispatchContext.Provider>
        </ComboboxContext.Provider>
    );
}

export default Combobox;
