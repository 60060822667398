import * as React from "react";
import { HTMLAttributes, useEffect, useState } from "react";
import Badge from "../../lib/badge/Badge";
import Icon from "../../lib/icon/Icon";

type CollapsibleProps = HTMLAttributes<HTMLDivElement> & {
    colorScheme?: "warning";
    count?: number;
    title: string;
    collapseKey: string;
    children: React.ReactNode;
};

export default function Collapsible(props: CollapsibleProps) {
    const { colorScheme = "warning", count = null, collapseKey, title, children, ...otherProps } = props;
    const storageKey = `brix-scheduler-collapsibleState-${collapseKey}`;

    const [expanded, setExpanded] = useState<boolean>(localStorage.getItem(storageKey) === "true");

    useEffect(() => {
        localStorage.setItem(storageKey, expanded.toString());
    }, [expanded]);

    const classNames = ["SchedulerCollapsible", otherProps.className || ""];
    delete otherProps.className;

    classNames.push(`SchedulerCollapsible--${colorScheme}`);

    return (
        <div className={classNames.join(" ")} {...otherProps}>
            <div className="SchedulerCollapsible__Headline" onClick={() => setExpanded(!expanded)} role="button">
                <div className="SchedulerCollapsible__Headline__Text">
                    {null !== count && <Badge colorScheme="danger">{count}</Badge>}
                    <div>{title}</div>
                </div>
                <div className="SchedulerCollapsible__Headline__Toggle">
                    <Icon type={expanded ? "collapse" : "expand"} title={expanded ? "Zuklappen" : "Aufklappen"} />
                </div>
            </div>
            {expanded && <div className="SchedulerCollapsible__Content">{children}</div>}
        </div>
    );
}
