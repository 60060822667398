import * as React from "react";
import { forwardRef, InputHTMLAttributes } from "react";

type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
    name?: string;
    caption?: string;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const { caption = null, checked = null, onChange, ...otherProps } = props;
    return (
        <>
            <label className={`form-check ${otherProps.disabled ? "text-muted" : ""}`}>
                <input
                    type="checkbox"
                    className="form-check-input"
                    ref={ref}
                    checked={checked}
                    onChange={(e) => onChange && onChange(e.target.checked)}
                    {...otherProps}
                />
                {caption}
            </label>
        </>
    );
});
Checkbox.displayName = "Checkbox";
export default Checkbox;
