import * as React from "react";
import { createContext, useContext, useEffect, useReducer } from "react";
import { DayOfWeek } from "../../../lib/datetime/types";
import { useParams } from "react-router-dom";
import { convertKeysToCamelCase } from "../../../lib/object/objects";
import { SkillIconType } from "../../../lib/icon/SkillIcon";
import { ReportDay } from "../../../lib/ett/report-grid/types";
import { groupDaysByWeeks, transformDay } from "../../../lib/ett/report-grid/api";

export type State = {
    loadingFirst: boolean;
    loadingNext: boolean;
    loadingError: Error;
    permissions: {
        canManageEmployees: boolean;

        canReadTimeSheetDays: boolean;
        canManageTimeSheetDays: boolean;

        canReadAbsences: boolean;
        canManageAbsences: boolean;
        canManageAbsenceCertificates: boolean;

        canReadWorkTimeSets: boolean;
        canManageWorkTimeSets: boolean;

        canReadAvailabilityRules: boolean;
        canManageAvailabilityRules: boolean;

        canReadEmployments: boolean;
        canManageEmployments: boolean;

        canReadUnitAssignments: boolean;
        canManageUnitAssignments: boolean;

        canReadWorkplaces: boolean;
        canManageWorkplaces: boolean;

        canReadBreakRuleSets: boolean;
        canManageBreakRuleSets: boolean;

        canReadSchoolTimeSets: boolean;
        canManageSchoolTimeSets: boolean;
        canManageSchoolHolidays: boolean;

        canReadSkillLevels: boolean;
        canManageSkillLevels: boolean;

        canReadVacationEntitlements: boolean;
        canManageVacationEntitlements: boolean;

        canReadSpecialVacations: boolean;
        canManageSpecialVacations: boolean;

        canReadVacationAdjustments: boolean;
        canManageVacationAdjustments: boolean;

        canManageAccessTokens: boolean;
    };
    employee: {
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        fullName: string;
        gender: "m" | "f" | "o";
        email: string;
        phone: string;
        mobile: string;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        bornOn: string;
    };

    timeSheets: {
        weeklyBalances: {
            [key: string]: {
                targetHours: number;
                finalCreditedWorkHours: number;
                performedWorkHours: number;
                creditedAbsentHours: number;
                adjustmentHours: number;
            };
        };
        currentWeekBalances: {
            unit: {
                id: string;
                name: string;
            };
            balance: string | number;
        };
    };

    absences: {
        relevantAbsences: {
            id: string;
            absenceReason: {
                id: string;
                name: string;
            };
            startsOn: string;
            endsOn: string;
            fraction: "full_day" | "half_day" | "multiple_days" | "invalid";
        }[];
        statistics: {
            absenceRatePastYear: number;
            absenceRatePastThreeYears: number;
            creditedAbsenceRatePastYear: number;
            creditedAbsenceRatePastThreeYears: number;
            uncreditedAbsenceRatePastYear: number;
            uncreditedAbsenceRatePastThreeYears: number;
            creditedAbsentHoursPastYear: number;
            creditedAbsentHoursPastThreeYears: number;
            uncreditedAbsentHoursPastYear: number;
            uncreditedAbsentHoursPastThreeYears: number;
            absentHoursByReasonCategoryPastYear: {
                category: string;
                credited: boolean;
                absentHours: number;
                absenceRate: number;
            }[];
            absentHoursByReasonCategoryPastThreeYears: {
                category: string;
                credited: boolean;
                absentHours: number;
                absenceRate: number;
            }[];
        };
    };

    workTimeSets: {
        currentWorkTimeSet: {
            id: string;
            weeklyTargetHours: string;
            days: Record<
                DayOfWeek,
                {
                    working: boolean;
                    targetHours: number;
                }
            >;
        };
        nextWorkTimeSetStartsOn: string;
    };

    availabilityRules: {
        currentAvailabilityRules: {
            id: string;
            recurrence: string;
            times: {
                startTime: string;
                endTime: string;
            }[];
        }[];
    };

    employments: {
        hasBeenIsOrWillBecomeATrainee: boolean;
        currentEmployment: {
            id: string;
            employmentMode: {
                id: string;
                name: string;
                category: string;
            };
            startsOn: string;
            endsOn: string;
        };
    };

    unitAssignments: {
        currentUnitAssignment: {
            id: string;
            startsOn: string;
            endsOn: string;
            unit: {
                id: string;
                name: string;
            };
        };
    };

    workplaces: {
        currentWorkplaces: {
            id: string;
            startsOn: string;
            endsOn: string;
            unit: {
                id: string;
                name: string;
            };
        }[];
    };

    breakRuleSets: {
        currentBreakRuleSet: {
            id: string;
            breakRules: {
                id: string;
                workInterval: number;
                breakInterval: number;
            }[];
        };
    };

    schoolTimeSets: {
        isTrainee: boolean;
        currentSchoolTimeSet: {
            id: string;
            days: Record<
                DayOfWeek,
                {
                    attendsSchool: boolean;
                    creditedHours: number;
                    absenceStartTime: string;
                    absenceEndTime: string;
                }
            >;
            remarks: string;
        };
    };

    skillLevels: {
        id: string;
        level: number;
        skill: {
            id: string;
            name: string;
            icon: SkillIconType;
        };
    }[];

    vacations: {
        currentEntitlement: {
            id: string;
            startsOn: string;
            endsOn: string;
            daysPerYear: number;
        };
        specialVacations: {
            id: string;
            startsOn: string;
            endsOn: string;
            remarks: string;
        }[];
        adjustments: {
            id: string;
            valuedOn: string;
            days: number;
        }[];
        currentYearReport: {
            carry: number;
            new: number;
            special: number;
            total: number;
            planned: number;
            adjusted: number;
            used: number;
            open: number;
            balance: number;
        };
    };

    ettDays: ReportDay[];
    ettDaysByWeek: Record<string, ReportDay[]>;
};

const initialState: State = {
    loadingFirst: true,
    loadingNext: true,
    loadingError: null,
    permissions: {
        canManageEmployees: null,

        canReadTimeSheetDays: null,
        canManageTimeSheetDays: null,

        canReadAbsences: null,
        canManageAbsences: null,
        canManageAbsenceCertificates: null,

        canReadWorkTimeSets: null,
        canManageWorkTimeSets: null,

        canReadAvailabilityRules: null,
        canManageAvailabilityRules: null,

        canReadEmployments: null,
        canManageEmployments: null,

        canReadUnitAssignments: null,
        canManageUnitAssignments: null,

        canReadWorkplaces: null,
        canManageWorkplaces: null,

        canReadBreakRuleSets: null,
        canManageBreakRuleSets: null,

        canReadSchoolTimeSets: null,
        canManageSchoolTimeSets: null,
        canManageSchoolHolidays: null,

        canReadSkillLevels: null,
        canManageSkillLevels: null,

        canReadVacationEntitlements: null,
        canManageVacationEntitlements: null,

        canReadSpecialVacations: null,
        canManageSpecialVacations: null,

        canReadVacationAdjustments: null,
        canManageVacationAdjustments: null,

        canManageAccessTokens: null
    },
    employee: null,
    timeSheets: {
        weeklyBalances: null,
        currentWeekBalances: null
    },
    absences: {
        relevantAbsences: [],
        statistics: {
            absenceRatePastYear: null,
            absenceRatePastThreeYears: null,
            creditedAbsenceRatePastYear: null,
            creditedAbsenceRatePastThreeYears: null,
            uncreditedAbsenceRatePastYear: null,
            uncreditedAbsenceRatePastThreeYears: null,
            creditedAbsentHoursPastYear: null,
            creditedAbsentHoursPastThreeYears: null,
            uncreditedAbsentHoursPastYear: null,
            uncreditedAbsentHoursPastThreeYears: null,
            absentHoursByReasonCategoryPastYear: [],
            absentHoursByReasonCategoryPastThreeYears: []
        }
    },
    workTimeSets: {
        currentWorkTimeSet: null,
        nextWorkTimeSetStartsOn: null
    },
    availabilityRules: {
        currentAvailabilityRules: []
    },
    employments: {
        hasBeenIsOrWillBecomeATrainee: null,
        currentEmployment: null
    },
    unitAssignments: {
        currentUnitAssignment: null
    },
    workplaces: {
        currentWorkplaces: []
    },
    breakRuleSets: {
        currentBreakRuleSet: null
    },
    schoolTimeSets: {
        isTrainee: false,
        currentSchoolTimeSet: null
    },
    skillLevels: [],
    vacations: {
        currentEntitlement: null,
        specialVacations: [],
        adjustments: [],
        currentYearReport: null
    },
    ettDays: [],
    ettDaysByWeek: {}
};

type InitPayloadValue = Pick<
    State,
    | "permissions"
    | "employee"
    | "absences"
    | "workTimeSets"
    | "availabilityRules"
    | "employments"
    | "unitAssignments"
    | "workplaces"
    | "breakRuleSets"
    | "schoolTimeSets"
    | "skillLevels"
    | "vacations"
    | "ettDays"
    | "ettDaysByWeek"
>;

type InitEmployeeAction = {
    type: "initEmployee";
    payload: InitPayloadValue;
};

type LoadingErrorAction = {
    type: "loadingError";
    payload: Error;
};

type LoadingNextStart = {
    type: "loadingNextStart";
};

type Action = InitEmployeeAction | LoadingErrorAction | LoadingNextStart;

export function reducer(state: State, action: Action): State {
    console.debug("🔵 Factsheet Action", action);
    switch (action.type) {
        case "initEmployee":
            return {
                ...state,
                loadingFirst: false,
                loadingNext: false,
                loadingError: null,
                ...{
                    ...action.payload,
                    ettDaysByWeek: groupDaysByWeeks(action.payload.ettDays),
                    employee: {
                        ...action.payload.employee,
                        fullName: `${action.payload.employee.lastName}, ${action.payload.employee.firstName}`
                    }
                }
            };
        case "loadingError":
            return {
                ...state,
                loadingFirst: false,
                loadingError: action.payload
            };
        case "loadingNextStart":
            return {
                ...state,
                loadingNext: true
            };
        default:
            throw new Error();
    }
}

const EmployeeIdContext = createContext<string>(null);

export function EmployeeIdContextFromParamsProvider({ children }: { children: React.ReactNode }) {
    const { employeeId } = useParams();
    return <EmployeeIdContext.Provider value={employeeId}>{children}</EmployeeIdContext.Provider>;
}

export function useEmployeeId() {
    return useContext(EmployeeIdContext);
}

const EmployeeContext = createContext<{
    state: State;
    dispatch: React.Dispatch<Action>;
}>({ state: null, dispatch: null });

export function EmployeeContextProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const currentEmployeeId = useEmployeeId();

    useEffect(() => {
        let isCancelled = false;

        if (!currentEmployeeId) {
            return;
        }

        dispatch({ type: "loadingNextStart" });

        fetch(`/api/employee_manager/employees/${currentEmployeeId}`)
            .then((response) => response.json())
            .then((json) => {
                if (!isCancelled) {
                    const payload = convertKeysToCamelCase({
                        ...json.data,
                        ettDays: json.data["ett_days"].map(transformDay)
                    }) as InitPayloadValue;
                    dispatch({ type: "initEmployee", payload });
                }
            })
            .catch((error) => {
                if (!isCancelled) {
                    dispatch({ type: "loadingError", payload: error });
                }
            });

        return () => {
            isCancelled = true;
        };
    }, [currentEmployeeId]);

    return <EmployeeContext.Provider value={{ state, dispatch }}>{children}</EmployeeContext.Provider>;
}

export function useEmployeeState() {
    const { state } = useContext(EmployeeContext);
    return state;
}

export function useEmployeePermissions() {
    const { state } = useContext(EmployeeContext);
    return state.permissions;
}
