// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable";

const consumer = createConsumer();

export default consumer;

// Unsubscribe all existing AC subscriptions on "reload".
// Not really sure if there are some subtle implications that have a bad effect.
// The reason for doing this in first place is that hanging subscriptions prevent SchedulerChannel
// to be re-connected and this is not receiving initial data.
document.addEventListener("turbolinks:load", () => {
    if (consumer.subscriptions.subscriptions) {
        consumer.subscriptions.subscriptions.map((subscription) => subscription.unsubscribe());
    }
});
