import { State } from "../../../state";
import { DateTime } from "luxon";
import { Record } from "../../../Record";
import { formatDateRange } from "../../../../../../lib/datetime/date-format";
import { humanFraction } from "./helpers";
import * as React from "react";

export function AbsencesItem({ absence }: { absence: State["absences"]["relevantAbsences"][0] }) {
    const endsOn = DateTime.fromISO(absence.endsOn);
    const startsOn = DateTime.fromISO(absence.startsOn);
    const now = DateTime.now();
    const isOld = endsOn < now;
    const coversToday = startsOn <= now && endsOn > now;

    return (
        <Record old={isOld} highlight={coversToday}>
            <div className="EmployeeManager__Factsheet__Absences__List__Item">
                <div className="EmployeeManager__Factsheet__Absences__List__Item__Column EmployeeManager__Factsheet__Absences__List__Item__Column--duration">
                    {formatDateRange(absence.startsOn, absence.endsOn)}
                </div>
                <div className="EmployeeManager__Factsheet__Absences__List__Item__Column EmployeeManager__Factsheet__Absences__List__Item__Column--fraction">
                    {humanFraction(absence.fraction)}
                </div>
                <div className="EmployeeManager__Factsheet__Absences__List__Item__Column EmployeeManager__Factsheet__Absences__List__Item__Column--absenceReason">
                    {absence.absenceReason.name}
                </div>
            </div>
        </Record>
    );
}
