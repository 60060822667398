import FormGroup from "../../../lib/form/form/FormGroup";
import TextField from "../../../lib/form/text/TextField";
import Button from "../../../lib/button/Button";
import * as React from "react";
import { useCallback } from "react";
import { CustomItemColor } from "../types";
import CustomItemColorPicker from "../../../lib/color-picker/CustomItemColorPicker";

type FormValue = { text: string; color: CustomItemColor };

type ScheduleDailyNoteFormProps = {
    value: FormValue;
    validationErrors?: string[];
    onChange: (value: FormValue) => unknown;
    onSubmit: () => unknown;
    onDelete: () => unknown;
    disabled?: boolean;
};

export function ScheduleDailyNoteForm(props: ScheduleDailyNoteFormProps) {
    const { value, onChange, onSubmit, onDelete, disabled, validationErrors } = props;
    const handleSubmit = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            !disabled && onSubmit();
        },
        [onSubmit, disabled]
    );
    return (
        <form onSubmit={handleSubmit}>
            {validationErrors && validationErrors.length > 0 && (
                <div className="text text-danger">
                    {validationErrors.map((error) => (
                        <div key={error}>{error}</div>
                    ))}
                </div>
            )}

            <FormGroup label="Text">
                <TextField
                    autoFocus={true}
                    disabled={disabled}
                    value={value.text}
                    onChange={(text) => onChange({ ...value, text })}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            !disabled && onSubmit();
                        }
                    }}
                />
            </FormGroup>
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", gap: "15px" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                    <Button onClick={onSubmit} disabled={disabled} theme="primary">
                        {disabled ? "Speichern..." : "Speichern"}
                    </Button>
                    {onDelete && (
                        <Button onClick={onDelete} disabled={disabled} theme="secondary">
                            Löschen
                        </Button>
                    )}
                </div>
                <CustomItemColorPicker value={value.color} onChange={(color) => onChange({ ...value, color })} />
            </div>
        </form>
    );
}
