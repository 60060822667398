import * as React from "react";
import Button from "../../../shared/Button";
import useWorkHubState from "../../../state/useWorkHubState";
import useWorkHubDispatch from "../../../state/useWorkHubDispatch";
import { fetchEttReport } from "../../../api/ett-report";

export function EttReportButton() {
    const { access: { token } } = useWorkHubState();
    const dispatch = useWorkHubDispatch();

    const openEttReport = () => {
        dispatch({ type: "startLoadingEttReport" });

        fetchEttReport(token)
            .then(ettReportSet => {
                dispatch({ type: "finishLoadingEttReport", payload: { ettReportSet } });
            })
            .catch(error => dispatch({ type: "failLoadingEttReport", payload: { error } }))
        ;
    };

    return (
        <Button colorTheme="slate" onClick={openEttReport} block={true}>
            Zeiterfassung abrufen
        </Button>
    );
}
