import * as React from "react";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import { CurrentWeekSchedules } from "./widgets/recent-schedules/CurrentWeekSchedules";
import { UnapprovedAbsences } from "./widgets/unapproved-absences/UnapprovedAbsences";
import { TimeSheetFreezingWarning } from "./widgets/time-sheet-freezing-warning/TimeSheetFreezingWarning";
import Kitchensink from "../../lib/kitchensink/Kitchensink";
import Chrome from "../../lib/chrome/Chrome";
import { LastWeekSchedules } from "./widgets/recent-schedules/LastWeekSchedules";
import { NextWeekSchedules } from "./widgets/recent-schedules/NextWeekSchedules";

export function Dashboard() {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <div className="Dashboard">
                    <Chrome.DetailPage.Header>
                        <Chrome.DetailPage.PageTitle>Dashboard</Chrome.DetailPage.PageTitle>
                    </Chrome.DetailPage.Header>
                    <Kitchensink>
                        <LastWeekSchedules />
                        <CurrentWeekSchedules />
                        <NextWeekSchedules />
                        <UnapprovedAbsences />
                        <TimeSheetFreezingWarning />
                    </Kitchensink>
                </div>
            </ErrorBoundary>
        </React.StrictMode>
    );
}
