import * as React from "react";
import { useDayBarsData } from "../../../../../../lib/ett/report-grid/day/day-bar/useDayBarsData";
import { EttReportDay } from "../../../../state/types/ett-report";

type DayBarProps = {
    day: EttReportDay;
}

export function DayBar(props: DayBarProps) {
    const { day } = props;
    const { presences, breaks, ticks } = useDayBarsData(day);

    return (
        <div className="EttReportGrid__DayBar">
            {presences.map((presence, idx) => {
                return (
                    <div
                        key={idx}
                        className="EttReportGrid__DayBar__Presence"
                        title={`${presence.startTime} - ${presence.endTime}`}
                        style={{
                            left: `${presence.startPercent}%`,
                            width: `${presence.endPercent - presence.startPercent}%`
                        }}
                    />
                );
            })}
            {breaks.map((breakData, idx) => (
                <div
                    key={idx}
                    className="EttReportGrid__DayBar__Break"
                    title={`${breakData.startTime} - ${breakData.endTime}`}
                    style={{
                        left: `${breakData.startPercent}%`,
                        width: `${breakData.endPercent - breakData.startPercent}%`
                    }}
                />
            ))}
            {ticks.map((tick, idx) => {
                return (
                    <div
                        key={idx}
                        className="EttReportGrid__DayBar__Tick"
                        title={`${tick.startTime} - ${tick.endTime}`}
                        style={{
                            left: `${tick.startPercent}%`
                        }}
                    />
                );
            })}
        </div>
    );
}
