import * as React from "react";
import useWorkHubState from "../../../state/useWorkHubState";
import LabeledValue from "./LabeledValue";

export default function EttTotals() {
    const { employeeInfo } = useWorkHubState();

    return (
        <div className="WorkHub__EttTotals">
            <div className="WorkHub__EttTotals__Cell">
                <LabeledValue
                    size="lg"
                    colorTheme="indigo"
                    value={`${employeeInfo.today.totals.presenceTime} h`}
                    label="Anwesenheit"
                />
            </div>
            <div className="WorkHub__EttTotals__Cell">
                <LabeledValue
                    size="lg"
                    colorTheme="slate"
                    value={`${employeeInfo.today.totals.breakTime} h`}
                    label="Pause"
                />
            </div>
        </div>
    );
}
