import React, { useEffect, useState } from "react";
import WeekTableHeadRows from "./week-table-head-row";
import WeekPlaceholderRow from "./week-placeholder-row";
import WeekBodyRow from "./week-body-row";
import { loadSlotsForWeek } from "../api";
import createPlaceholderRows from "../create-placeholder-rows";
import { TimeSheetTable } from "../time-sheet-table";
import WeekSummaryRow from "./week-summary-row";
import { filterSlots } from "../slot-search-bar";
import PropTypes from "prop-types";

const PlaceholderRows = createPlaceholderRows(WeekPlaceholderRow, 12);

export function WeekTable({ unitId, week, year, slotSearchValue }) {
    const [slots, setSlots] = useState(null);
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filteredSlots, setFilteredSlots] = useState(null);

    useEffect(() => {
        if (unitId && year && week) {
            setLoading(true);
            (async () => {
                const { slots, summary } = await loadSlotsForWeek(year, week, unitId);
                setSlots(slots);
                setFilteredSlots(filterSlots(slots, slotSearchValue));
                setSummary(summary);
                setLoading(false);
            })();
        }
    }, [unitId, year, week]);

    useEffect(() => {
        setFilteredSlots(filterSlots(slots, slotSearchValue));
    }, [slotSearchValue]);

    const headRows = <WeekTableHeadRows week={week} year={year} />;
    const placeholderRows = <PlaceholderRows />;
    const bodyRows = filteredSlots && [
        <WeekSummaryRow key="summary" summary={summary} />,
        ...filteredSlots.map((slot) => (
            <WeekBodyRow key={slot.employee.id} slot={slot} unitId={unitId} year={year} week={week} />
        )),
    ];

    return <TimeSheetTable {...{ loading, headRows, placeholderRows, bodyRows }} />;
}

WeekTable.propTypes = {
    unitId: PropTypes.string,
    week: PropTypes.number,
    year: PropTypes.number,
    slotSearchValue: PropTypes.string,
};
