import { jsonHeaders } from "./common";
import { convertKeysToCamelCase } from "../../../lib/object/objects";
import { transformDay } from "../../../lib/ett/report-grid/api";
import { EttReportSet } from "../state/types/ett-report";

export async function fetchEttReport(accessToken: string, signal?: AbortSignal): Promise<EttReportSet> {
    const options = {
        method: "GET",
        signal,
        headers: {
            ...jsonHeaders,
            "Authorization": `Bearer ${accessToken}`
        }
    };
    return fetch(`/workhub/api/ett_reports`, options)
        .then(response => response.json())
        .then((json: { data: unknown }) => {
            const reportSet = {
                previous: { ...json.data["previous"], days: json.data["previous"]["days"].map(transformDay) },
                current: { ...json.data["current"], days: json.data["current"]["days"].map(transformDay) },
                next: { ...json.data["next"], days: json.data["next"]["days"].map(transformDay) }
            };
            return convertKeysToCamelCase(reportSet) as EttReportSet;
        });
}
