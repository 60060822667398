export type RecentSchedulesApiResponse = {
    data: {
        id: string;
        week_name: string;
        unit: { name: string };
        balances: {
            human_plan_hours: string;
            human_performed_work_hours: string;
        };
    }[];
};

export async function fetchRecentSchedules(): Promise<RecentSchedulesApiResponse> {
    return fetch("/api/dashboard/recent_schedules").then((response) => {
        if (!response.ok && response.status !== 422) {
            throw Error("Failed to load recent schedules: " + response.statusText);
        }
        return response.json();
    });
}
