const absenceReasonTranslations = {
    leave: "Urlaub",
    sick: "Krankheitsbedingte Abwesenheit",
    parental_leave: "Elternzeit/Mutterschutz",
    relocation: "Einsatz außer Haus",
    free_time: "Freizeit",
    training: "Weiterbildung",
};

export default absenceReasonTranslations;
