import * as React from "react";
import { forwardRef, HTMLAttributes } from "react";
import { BgColor } from "../theme/colors";

type CardProps = HTMLAttributes<HTMLDivElement> & {
    bg?: BgColor;
};

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
    const { className, ...rest } = props;
    const classNames = [className ?? "", "card", `bg-${props.bg ?? "light"}`];
    return (
        <div ref={ref} className={classNames.join(" ")} {...rest}>
            {props.children}
        </div>
    );
});
Card.displayName = "Card";

type CardBodyProps = HTMLAttributes<HTMLDivElement>;

export const CardBody = forwardRef<HTMLDivElement, CardBodyProps>((props, ref) => {
    const { className, ...rest } = props;
    const classNames = [className ?? "", "card-body"];
    return (
        <div ref={ref} className={classNames.join(" ")} {...rest}>
            {props.children}
        </div>
    );
});
CardBody.displayName = "CardBody";

type CardFooterProps = HTMLAttributes<HTMLDivElement>;

export const CardFooter = forwardRef<HTMLDivElement, CardFooterProps>((props, ref) => {
    return (
        <div ref={ref} className="card-footer" {...props}>
            {props.children}
        </div>
    );
});
CardFooter.displayName = "CardFooter";

export const ScrollableContentCard = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
    const classNames = [props.className ?? "", "d-flex flex-column flex-shrink-1 flex-grow-0 overflow-hidden"];
    return (
        <Card ref={ref} className={classNames.join(" ")} bg={"white"} {...props}>
            {props.children}
        </Card>
    );
});
ScrollableContentCard.displayName = "ScrollableContentCard";

export const ScrollableContentCardBody = forwardRef<HTMLDivElement, CardBodyProps>((props, ref) => {
    const { className, ...rest } = props;
    const classNames = [className ?? "", "flex-shrink-1 flex-grow-0 overflow-auto"];
    return (
        <CardBody ref={ref} className={classNames.join(" ")} {...rest}>
            {props.children}
        </CardBody>
    );
});
ScrollableContentCardBody.displayName = "ScrollableContentCardBody";
