import * as React from "react";
import { useMemo } from "react";
import { DReport } from "../types";
import { formatDays, formatHours } from "../../../lib/datetime/datetime";

type DSummary = {
    targetDays: {
        total: number;
        credited: number;
        uncredited: number;
    };
    hours: {
        total: number;
        credited: number;
        uncredited: number;
    };
};

type SummaryProps = {
    report: DReport;
};

export default function Summary(props: SummaryProps) {
    const { report } = props;

    const summary: DSummary = useMemo(() => {
        let totalTargetDays = 0;
        let creditedTargetDays = 0;
        let uncreditedTargetDays = 0;
        let totalHours = 0;
        let creditedHours = 0;
        let uncreditedHours = 0;

        report.forEach((employeeRow) => {
            Object.keys(employeeRow.days).forEach((dateStr) => {
                employeeRow.days[dateStr].absences.forEach((absence) => {
                    const credited_days = Number(absence.credited_days);
                    const hours = Number(absence.hours);

                    totalTargetDays += credited_days;
                    totalHours += hours;

                    if (absence.credit) {
                        creditedTargetDays += credited_days;
                        creditedHours += hours;
                    } else {
                        uncreditedTargetDays += credited_days;
                        uncreditedHours += hours;
                    }
                });
            });
        });

        return {
            targetDays: {
                total: totalTargetDays,
                credited: creditedTargetDays,
                uncredited: uncreditedTargetDays,
            },
            hours: {
                total: totalHours,
                credited: creditedHours,
                uncredited: uncreditedHours,
            },
        };
    }, [report]);

    return (
        <div className="list-group my-2 mx-2 shadow-sm" style={{ width: 250, fontSize: "0.825rem" }}>
            <div className="list-group-item d-flex justify-content-between">
                <div className="fw-bold">Gesamt</div>
                <div className="fw-bold">{formatDays(summary.targetDays.total)}</div>
                <div className="fw-bold">{formatHours(summary.hours.total)} h</div>
            </div>
            <div className="list-group-item d-flex justify-content-between">
                <div>mit SG</div>
                <div>{formatDays(summary.targetDays.credited)}</div>
                <div>{formatHours(summary.hours.credited)} h</div>
            </div>
            <div className="list-group-item d-flex justify-content-between">
                <div>ohne SG</div>
                <div>{formatDays(summary.targetDays.uncredited)}</div>
                <div>{formatHours(summary.hours.uncredited)} h</div>
            </div>
        </div>
    );
}
