import * as React from "react";
import { Hour } from "../../../lib/deployment-bar/deployment-times";
import { HighlightedHourContext } from "./HighlightedHourContext";
import { DayOfWeek } from "./types";

type HighlightedHourControllerProps = {
    children: React.ReactNode;
};

export function HighlightedHourController({ children }: HighlightedHourControllerProps) {
    const [hour, setHour] = React.useState<Hour>(null);
    const [dow, setDow] = React.useState<DayOfWeek>(null);

    return (
        <HighlightedHourContext.Provider
            value={{
                highlightedHour: hour,
                highlightedDow: dow,
                setHighlightedHour: (hour, day) => {
                    setHour(hour);
                    setDow(day);
                },
            }}
        >
            {children}
        </HighlightedHourContext.Provider>
    );
}
