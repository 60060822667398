import React from "react";
import PropTypes from "prop-types";
import Chrome from "../../lib/chrome/Chrome";

export function filterSlots(slots, slotSearchValue) {
    if (slots == null) {
        return slots;
    }
    if (slotSearchValue == "") {
        return slots;
    }
    return slots.filter((slot) => slot.employee.name.toLowerCase().includes(slotSearchValue.toLowerCase()));
}

export default function SlotSearchBar({ slotSearchValue, setSlotSearchValue, searchFieldRef }) {
    return (
        <Chrome.DetailPage.ActionBar>
            <form className="slotSearchBar" onSubmit={(e) => e.preventDefault()}>
                <div className="input-group">
                    <input
                        autoFocus
                        type="text"
                        className="form-control slotSearchBarInput"
                        value={slotSearchValue}
                        onChange={(e) => setSlotSearchValue(e.target.value)}
                        placeholder="Suchen"
                        ref={searchFieldRef}
                    />
                </div>
            </form>
        </Chrome.DetailPage.ActionBar>
    );
}

SlotSearchBar.propTypes = {
    slotSearchValue: PropTypes.string,
    setSlotSearchValue: PropTypes.func,
    searchFieldRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
    ]),
};
