import useWorkHubDispatch from "../../../state/useWorkHubDispatch";
import useWorkHubState from "../../../state/useWorkHubState";
import { EttStatus } from "../../../state/types/ett-status";

type EttTransitionFunction = (token: string) => Promise<EttStatus>;

export default function useEttStatusTransition(fn: EttTransitionFunction) {
    const { access: { token: accessToken } } = useWorkHubState();
    const dispatch = useWorkHubDispatch();

    if (!accessToken) {
        throw new Error("useEttStatusTransition requires an access token. Something is wired wrong here.");
    }

    return () => {
        dispatch({ type: "startEttStatusTransition" });
        fn(accessToken)
            .then((newEttStatus) =>
                dispatch({ type: "finishEttStatusTransition", payload: { newEttStatus } }))
            .catch((error) => dispatch({ type: "failEttStatusTransition", payload: { error } }));
    };
}
