import Icon from "../../../lib/icon/Icon";
import * as React from "react";

export function GenderIcon({ gender }: { gender: string }) {
    let iconType: "gender-male" | "gender-female" | "gender-other";

    if ("m" === gender) {
        iconType = "gender-male";
    } else if ("f" === gender) {
        iconType = "gender-female";
    } else {
        iconType = "gender-other";
    }

    return <Icon type={iconType} />;
}
