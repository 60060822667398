import * as React from "react";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import ValidationError from "../../validation/ValidationError";
import { CompositeDateInput } from "./CompositeDateInput";

type DatePickerFieldProps = React.HTMLAttributes<HTMLDivElement> & {
    value: string;
    onChange: (value: string) => unknown;
    onValidation?: (isValid: boolean) => unknown;
    forceValidation?: boolean;
    errors?: string[];
    additionalValidation?: (value: string) => string | null;
    disabled?: boolean;
};

export function DatePickerField(props: DatePickerFieldProps) {
    const { value, onChange, onValidation, forceValidation, errors, additionalValidation, disabled, ...divProps } =
        props;

    const [localError, setLocalError] = useState(null);
    const [hasBlurred, setHasBlurred] = useState(false);

    const shouldValidate = hasBlurred || forceValidation;

    const validate = (value) => {
        const trimmed = value.trim();

        if (trimmed) {
            const validFormats = [
                // Year is 4 digits or 2 digits. If it is 2 digits, then > 1960 should be assumed upstream.
                "yy-MM-dd", // Both, day and month with leading zero
                "yy-M-d", // Both, day and month without leading zero
                "yy-M-dd", // Only day with leading zero
                "yy-MM-d", // Only month with leading zero
            ];

            // Check if the date is valid
            if (!validFormats.some((format) => DateTime.fromFormat(trimmed, format).isValid)) {
                setLocalError(`Ungültiges Datum`);
                onValidation(false);
                return;
            }
        }

        if (additionalValidation) {
            const additionalError = additionalValidation(value);
            if (additionalError) {
                setLocalError(additionalError);
                onValidation(false);
                return;
            }
        }

        setLocalError(null);
        onValidation(true);
    };

    useEffect(() => {
        if (shouldValidate) {
            validate(value);
        }
    }, [value, shouldValidate]);

    return (
        <>
            <CompositeDateInput
                {...divProps}
                invalid={shouldValidate && (localError || errors?.length)}
                value={value}
                onChange={onChange}
                onBlur={() => setHasBlurred(true)}
                disabled={disabled}
            />
            {shouldValidate && localError && <ValidationError message={localError} />}
            {shouldValidate && errors && errors.map((error) => <ValidationError key={error} message={error} />)}
        </>
    );
}
