import * as React from "react";
import { useMemo } from "react";
import TimeFrame from "./TimeFrame";
import Badge from "./Badge";
import Duration from "./Duration";
import { EttStatus, humanEttStatusMap } from "../state/types/ett-status";
import Icon from "../../../lib/icon/Icon";
import { DeviceRef } from "../state/types/refs";
import { secondsToHuman } from "../../../lib/datetime/time";

export type LogEntry = {
    startTime: string,
    endTime: string | null,
    status: EttStatus,
    duration: number,
    deviceRef: DeviceRef;
};

type EttLogProps = {
    log: LogEntry[];
};

export default function EttLog(props: EttLogProps) {
    const { log } = props;

    const filteredLog = useFilteredLog(log);

    return (
        <div className="WorkHub__EttLog">
            {filteredLog.map((entry, idx) => (
                <div className="WorkHub__EttLog__Event" key={idx}>
                    <div className="WorkHub__EttLog__Event__Times">
                        <TimeFrame
                            startTime={entry.startTime}
                            endTime={null === entry.endTime ? "jetzt" : entry.endTime}
                        />
                    </div>
                    <div className="WorkHub__EttLog__Event__Duration">
                        <Duration>{secondsToHuman(entry.duration, { seconds: false })} h</Duration>
                    </div>
                    <div className="WorkHub__EttLog__Event__Status">
                        <Badge colorTheme={entry.status === "checked_in" ? "indigo" : "slate"}>
                            {humanEttStatusMap[entry.status]}
                        </Badge>
                    </div>
                    <div className="WorkHub__EttLog__Event__Location">
                        <Badge>
                            <Icon type="work-hub-device" />
                            {" "}
                            {entry.deviceRef.name}
                        </Badge>
                    </div>
                </div>
            ))}
        </div>
    );
}

function useFilteredLog(log: LogEntry[]) {
    return useMemo(() => (
        log.filter((entry) => {
            return entry.status !== "checked_out";
        })
    ), [log]);
}
