import * as React from "react";
import { useRef, useState } from "react";
import TimeSpan from "../../../../lib/form/datetime/TimeSpan";
import Button from "../../../../lib/button/Button";

type TimeSpanControlsProps = {
    disabled: boolean;
    onSubmit: (startTime: string, endTime: string) => void;
    startTime: string;
    endTime: string;
    children?: React.ReactNode;
};

export default function TimeSpanControls(props: TimeSpanControlsProps) {
    const { disabled, startTime, endTime, onSubmit, children } = props;

    const startHoursRef = useRef<HTMLInputElement>();
    const startMinutesRef = useRef<HTMLInputElement>();
    const endHoursRef = useRef<HTMLInputElement>();
    const endMinutesRef = useRef<HTMLInputElement>();

    const handleSubmit = () => {
        const startTime = `${startHoursRef.current.value}:${startMinutesRef.current.value}`;
        const endTime = `${endHoursRef.current.value}:${endMinutesRef.current.value}`;
        if (startTime != props.startTime || endTime != props.endTime) {
            onSubmit(startTime, endTime);
        }
    };

    const [dirty, setDirty] = useState(false);

    return (
        <div className="SchedulerTimeSpanControl">
            <div className="SchedulerTimeSpanControl__TimeSpans">
                <TimeSpan
                    disabled={disabled}
                    autoFocus={true}
                    onChange={() => setDirty(true)}
                    onSubmit={handleSubmit}
                    time={startTime}
                    hoursRef={startHoursRef}
                    minutesRef={startMinutesRef}
                    nextRef={endHoursRef}
                    ariaLabelPrefix="Startzeit"
                />
                <span className="SchedulerTimeSpanControl__TimeSpans__Separator"> → </span>
                <TimeSpan
                    disabled={disabled}
                    onChange={() => setDirty(true)}
                    onSubmit={handleSubmit}
                    time={endTime}
                    hoursRef={endHoursRef}
                    minutesRef={endMinutesRef}
                    prevRef={startMinutesRef}
                    ariaLabelPrefix="Endzeit"
                />
            </div>
            <div className="SchedulerTimeSpanControl__Buttons">
                <Button size={"sm"} disabled={disabled || !dirty} icon="apply" theme="primary" onClick={handleSubmit} />
                {children}
            </div>
        </div>
    );
}
