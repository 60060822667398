import * as React from "react";
import { createContext } from "react";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import { RouterProvider } from "react-router-dom";
import { defaultRouter, standaloneSidebarRouter } from "./router";

export const EmployeeManagerModeContext = createContext({ standaloneSidebar: false });

export default function EmployeeManager({ standaloneSidebar = false }: { standaloneSidebar?: boolean }) {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <EmployeeManagerModeContext.Provider value={{ standaloneSidebar }}>
                    <RouterProvider router={standaloneSidebar ? standaloneSidebarRouter : defaultRouter} />
                </EmployeeManagerModeContext.Provider>
            </ErrorBoundary>
        </React.StrictMode>
    );
}
