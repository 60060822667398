import * as React from "react";
import { CSSProperties } from "react";
import { DateTime, Interval } from "luxon";

type WeekCellProps = {
    value: DateTime;
    onClick: (interval: Interval) => void;
};

export default function WeekCell(props: WeekCellProps) {
    const { value, onClick } = props;

    const styles: CSSProperties = {
        padding: "2px",
        userSelect: "none",
        textAlign: "center",
        border: "1px solid #ccc",
        cursor: "default",
        backgroundColor: "#ddd",
        fontWeight: 700,
    };

    return (
        <td
            style={styles}
            onClick={() => {
                onClick(Interval.fromDateTimes(value.startOf("week"), value.endOf("week")));
            }}
        >
            {value.toFormat("W")}
        </td>
    );
}
