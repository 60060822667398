import { CreateOrUpdateResult, FormAdapter, GetResult } from "../adapter";
import Skill from "../../model/Skill";
import { patch, post, get } from "../api";

function createSkillPath() {
    return "/api/skills";
}

function updateSkillPath(urlParams: { id: string }) {
    return `/api/skills/${urlParams.id}`;
}

function readSkillPath(urlParams: { id: string }) {
    return `/api/skills/${urlParams.id}`;
}

function read(urlParams): Promise<GetResult<Skill>> {
    return get(readSkillPath(urlParams));
}

function save(urlParams, data): Promise<CreateOrUpdateResult<Skill>> {
    if (urlParams.id) {
        return update(urlParams, data);
    } else {
        return create(urlParams, data);
    }
}

function create(urlParams, data): Promise<CreateOrUpdateResult<Skill>> {
    return post(createSkillPath(), data);
}

function update(urlParams, data): Promise<CreateOrUpdateResult<Skill>> {
    return patch(updateSkillPath(urlParams), data);
}

export const skillAdapter: FormAdapter<Skill> = {
    read,
    save,
    create,
    update,
};

export default skillAdapter;
