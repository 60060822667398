import * as React from "react";
import { PureComponent } from "react";
import { truncateMiddle } from "../../../../../../../lib/string/string";
import { colors } from "../../../../../../../lib/theme/colors";
import absenceReasonTranslations from "./reason-category-translations";

type CustomizedAxisTickProps = {
    x?: number;
    y?: number;
    stroke?: string;
    payload?: {
        value: string;
    };
};

export class CustomizedAxisTick extends PureComponent<CustomizedAxisTickProps> {
    render() {
        const { x, y, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    fontSize={"12.25px"}
                    fontFamily={"var(--bs-body-font-family)"}
                    textAnchor="end"
                    fill={colors.brixSlate800}
                    fontWeight={500}
                >
                    {truncateMiddle(absenceReasonTranslations[payload.value] ?? payload.value, 20)}
                </text>
            </g>
        );
    }
}
