import * as React from "react";
import PersonalScheduleResultsPanel from "./personal-schedules/PersonalScheduleResultsPanel";
import useWorkHubState from "../../state/useWorkHubState";
import EttStatusTransitionResult from "./EttStatusTransitionResult";
import EttReportResultsPanel from "./ett-report/EttReportResultsPanel";

export default function ResultsPage() {
    const { ui: { actionInProgress } } = useWorkHubState();

    switch (actionInProgress) {
        case "transition_ett_status":
            return <EttStatusTransitionResult />;

        case "request_personal_schedules":
            return <PersonalScheduleResultsPanel />;

        case "request_ett_report":
            return <EttReportResultsPanel />;

        default:
            return <div>Unknown action: {actionInProgress}</div>;
    }
}
