import * as React from "react";
import Badge, { BadgeProps } from "./Badge";
import { joinClasses } from "../../../lib/dom-utils/class-names";

type DurationProps = BadgeProps;

export default function Duration(props: DurationProps) {
    const { children, ...rest } = props;

    rest.className = joinClasses(
        "WorkHub__Duration",
        rest.className
    );

    return (
        <Badge {...rest}>
            {children}
        </Badge>
    );
}
