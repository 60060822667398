import { ThemeColor } from "../theme/colors";
import * as React from "react";
import { joinClasses } from "../dom-utils/class-names";
import Icon, { IconType } from "../icon/Icon";
import { ReactNode } from "react";

type BannerProps = React.HTMLAttributes<HTMLDivElement> & {
    theme?: ThemeColor;
    children?: ReactNode;
};

export function Banner(props: BannerProps) {
    const { children, theme = "success", ...divProps } = props;

    divProps.className = joinClasses("Banner", `Banner--${theme}`, divProps.className);

    return <div {...divProps}>{children}</div>;
}

type BannerIconProps = React.HTMLAttributes<HTMLDivElement> & {
    icon: IconType;
};

export function BannerIcon(props: BannerIconProps) {
    const { icon, ...divProps } = props;

    return (
        <div className="Banner__Icon" {...divProps}>
            <Icon type={icon} />
        </div>
    );
}

type BannerMessageProps = React.HTMLAttributes<HTMLDivElement> & {
    message: string;
};

export function BannerMessage(props: BannerMessageProps) {
    const { message, ...divProps } = props;

    return (
        <div className="Banner__Message" {...divProps}>
            {message}
        </div>
    );
}

type BannerActionProps = Omit<React.HTMLAttributes<HTMLButtonElement>, "type"> & {
    title: string;
    onClick: () => unknown;
};

export function BannerAction(props: BannerActionProps) {
    const { title, onClick, ...divProps } = props;

    return (
        <button type="button" className="Banner__Action" onClick={onClick} {...divProps}>
            {title}
        </button>
    );
}

Banner.Icon = BannerIcon;
Banner.Message = BannerMessage;
Banner.Action = BannerAction;

export default Banner;
