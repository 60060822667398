import { useCallback, useState } from "react";

type ReturnType = [boolean, () => void, () => void];

export function useLoadingCounter(): ReturnType {
    const [loadingCounter, setLoadingCounter] = useState<number>(0);

    const startLoading = useCallback(() => {
        setLoadingCounter((loadingCounter) => loadingCounter + 1);
    }, [setLoadingCounter]);

    const stopLoading = useCallback(() => {
        setLoadingCounter((loadingCounter) => loadingCounter - 1);
    }, [setLoadingCounter]);

    const loading = loadingCounter > 0;

    return [loading, startLoading, stopLoading];
}
