import * as React from "react";

type LabeledValueProps = {
    value: string;
    label: string;
    colorTheme: "indigo" | "slate";
    size: "lg" | "sm";
}
export default function LabeledValue(props: LabeledValueProps) {
    const { value, label, colorTheme, size } = props;
    return (
        <div className={`WorkHub__LabeledValue WorkHub__LabeledValue--${colorTheme} WorkHub__LabeledValue--${size}`}>
            <div className="WorkHub__LabeledValue__Value">
                <span>{value}</span>
            </div>
            <div className="WorkHub__LabeledValue__Label">
                <span>{label}</span>
            </div>
        </div>
    );
}
