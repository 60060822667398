import * as React from "react";
import useWorkHubState from "../state/useWorkHubState";

export default function SecondsLeft() {
    const { ui: { secondsLeftToLock } } = useWorkHubState();

    return (
        <div className="WorkHubLayout__Header__SecondsLeft">
            <div>{secondsLeftToLock} s</div>
        </div>
    );
}
