import React from "react";
import PropTypes from "prop-types";

export default function Cell({ center = false, children, numeric = false, ...otherProps }) {
    const classNames = ["py-1"];
    if (center) {
        classNames.push("align-middle");
    }
    if (numeric) {
        classNames.push("font-monospace");
        classNames.push("text-end");
    }

    otherProps.className ||= "py-0";
    otherProps.className += " " + classNames.join(" ");

    otherProps.style ||= {};
    otherProps.style = { ...otherProps.style, ...{ fontSize: "0.875rem", verticalAlign: "top" } };

    if (numeric) {
        otherProps.style.fontSize = 12;
    }

    return <td {...otherProps}>{children}</td>;
}

Cell.propTypes = {
    center: PropTypes.bool,
    children: PropTypes.any,
    numeric: PropTypes.bool,
};
