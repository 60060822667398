export function isPresent(v: unknown) {
    if (undefined === v || null === v) {
        return false;
    } else if (Array.isArray(v) && v.length === 0) {
        return false;
    } else if (v["toLowerCase"] && typeof v["toLowerCase"] === "function" && v["length"] === 0) {
        return false;
    }

    return true;
}

export function deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function underscoreToCamelCase(obj: Record<string, unknown>): Record<string, unknown> {
    const newObj: Record<string, unknown> = {};
    for (const key in obj) {
        const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        newObj[newKey] = obj[key];
    }
    return newObj;
}

function toCamelCase(str: string) {
    return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
}

export function convertKeysToCamelCase(obj: unknown): unknown {
    if (Array.isArray(obj)) {
        return obj.map((value) => convertKeysToCamelCase(value));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => {
            acc[toCamelCase(key)] = convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
    return obj;
}
