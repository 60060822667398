import * as React from "react";
import { useEmployeePermissions, useEmployeeState } from "../state";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import Metric from "../Metric";
import { Record } from "../Record";
import { KeyFigure } from "../../../../lib/key-figure/KeyFigure";
import { formatDate } from "../../../../lib/datetime/date-format";
import { formatNumber } from "../../../../lib/number/number";

export function Vacation(props: React.HTMLAttributes<HTMLDivElement>) {
    const {
        canReadVacationEntitlements,
        canManageVacationEntitlements,
        canReadSpecialVacations,
        canManageSpecialVacations,
        canReadVacationAdjustments,
        canManageVacationAdjustments
    } = useEmployeePermissions();
    const { ...divProps } = props;
    const { employee, vacations } = useEmployeeState();

    if (!canReadVacationEntitlements && !canReadSpecialVacations && !canReadVacationAdjustments) {
        return null;
    }

    return (
        <Kitchensink.Item {...divProps}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Urlaub</Kitchensink.ItemBodyTitle>

                <Kitchensink.ItemBodyPart>
                    <Kitchensink.ItemBodyPartHeadline>Urlaubsanspuch (aktuelles Jahr)</Kitchensink.ItemBodyPartHeadline>

                    <Stats />
                    <CurrentEntitlement />

                    {canReadSpecialVacations &&
                        vacations.specialVacations.length !== 0 &&
                        vacations.specialVacations.map((specialVacation) => (
                            <Record key={specialVacation.id}>
                                Der Mitarbeiter erhält
                                vom <KeyFigure>{formatDate(specialVacation.startsOn)}</KeyFigure>{" "}
                                bis einschließlich <KeyFigure>{formatDate(specialVacation.endsOn)}</KeyFigure> einen
                                Sonderurlaub.
                            </Record>
                        ))}

                    {canReadVacationAdjustments &&
                        vacations.adjustments.length !== 0 &&
                        vacations.adjustments.map((adjustment) => (
                            <Record key={adjustment.id}>
                                Das Urlaubskonto des Mitarbeiters wurde zum{" "}
                                <KeyFigure>{formatDate(adjustment.valuedOn)}</KeyFigure> um{" "}
                                <KeyFigure>{formatNumber(adjustment.days)} Tage</KeyFigure> korrigiert
                            </Record>
                        ))}
                </Kitchensink.ItemBodyPart>

                {(canManageVacationEntitlements || canManageSpecialVacations || canManageVacationAdjustments) && (
                    <Kitchensink.ItemBodyLinkList>
                        {canManageVacationEntitlements && (
                            <a
                                className="Button Button--primadonna"
                                href={`/employees/${employee.id}/vacation_entitlements`}
                            >
                                Urlaubsansprüche verwalten
                            </a>
                        )}
                        {canManageSpecialVacations && (
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/special_vacations`}>
                                Sonderurlaub verwalten
                            </a>
                        )}

                        {canManageVacationAdjustments && (
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/vacation_adjustments`}>
                                Urlaubskorrekturen verwalten
                            </a>
                        )}
                    </Kitchensink.ItemBodyLinkList>
                )}
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}

function Stats() {
    const { canReadVacationEntitlements } = useEmployeePermissions();
    const { vacations } = useEmployeeState();

    return (
        canReadVacationEntitlements &&
        vacations.currentYearReport && (
            <div className="EmployeeManager__Factsheet__Vacations__MetricsList">
                <Metric type={0 == vacations.currentYearReport.carry ? "ghost" : "neutral"}>
                    <Metric.Label>Übertrag</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.carry)} Tage</span>
                    </Metric.Value>
                </Metric>
                <Metric type={0 == vacations.currentYearReport.new ? "ghost" : "neutral"}>
                    <Metric.Label>Jährl. Anspruch</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.new)} Tage</span>
                    </Metric.Value>
                </Metric>
                <Metric type={0 == vacations.currentYearReport.special ? "ghost" : "neutral"}>
                    <Metric.Label>Sonderurlaub</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.special)} Tage</span>
                    </Metric.Value>
                </Metric>
                <Metric type={0 == vacations.currentYearReport.total ? "ghost" : "neutral"}>
                    <Metric.Label>Anspruch</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.total)} Tage</span>
                    </Metric.Value>
                </Metric>
                <Metric type={0 == vacations.currentYearReport.planned ? "ghost" : "neutral"}>
                    <Metric.Label>Geplant</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.planned)} Tage</span>
                    </Metric.Value>
                </Metric>
                <Metric type={0 == vacations.currentYearReport.adjusted ? "ghost" : "neutral"}>
                    <Metric.Label>Korrektur</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.adjusted)} Tage</span>
                    </Metric.Value>
                </Metric>
                <Metric type={0 == vacations.currentYearReport.open ? "ghost" : "neutral"}>
                    <Metric.Label>Offen</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.open)} Tage</span>
                    </Metric.Value>
                </Metric>
                <Metric type={0 == vacations.currentYearReport.balance ? "ghost" : "neutral"}>
                    <Metric.Label>Saldo</Metric.Label>
                    <Metric.Value>
                        <span>{formatNumber(vacations.currentYearReport.balance)} Tage</span>
                    </Metric.Value>
                </Metric>
            </div>
        )
    );
}

function CurrentEntitlement() {
    const { canReadVacationEntitlements } = useEmployeePermissions();
    const { vacations } = useEmployeeState();

    return (
        canReadVacationEntitlements &&
        vacations.currentEntitlement && (
            <Record>
                Der Mitarbeiter hat seit
                dem <KeyFigure>{formatDate(vacations.currentEntitlement.startsOn)}</KeyFigure>{" "}
                einen jährlichen Urlaubsanspruch von{" "}
                <KeyFigure>{vacations.currentEntitlement.daysPerYear} Tagen</KeyFigure>
                {vacations.currentEntitlement.endsOn && (
                    <>
                        {" "}
                        (endet am <KeyFigure>{formatDate(vacations.currentEntitlement.endsOn)}</KeyFigure>)
                    </>
                )}
            </Record>
        )
    );
}
