import * as React from "react";
import Icon from "../../../lib/icon/Icon";
import { useOutsideClickHandler } from "../../../lib/dom-utils/useOutsideClickHandler";
import { useRef } from "react";

type DayContextActionsProps = {
    onAddScheduleDailyNote: () => void;
};

export function DayContextActions(props: DayContextActionsProps) {
    const { onAddScheduleDailyNote } = props;

    const [open, setOpen] = React.useState(false);

    const ref = useRef();
    useOutsideClickHandler(ref, () => setOpen(false));

    return (
        <div className="SchedulerHeader__ContextActions">
            <div
                className="SchedulerHeader__ContextActions__Toggle"
                role="button"
                aria-label="Wochentag-Kontextmenü anzeigen"
                onClick={() => setOpen(true)}
            >
                <Icon type="context-actions" />
            </div>
            {open && (
                <div className="SchedulerHeader__ContextActions__Menu" ref={ref}>
                    <div
                        className="SchedulerHeader__ContextActions__Menu__Item"
                        onClick={() => {
                            onAddScheduleDailyNote();
                            setOpen(false);
                        }}
                    >
                        <div className="SchedulerHeader__ContextActions__Menu__Item__Icon">
                            <Icon type="new" />
                        </div>
                        <div className="SchedulerHeader__ContextActions__Menu__Item__Text">Tagesnotiz hinzufügen</div>
                    </div>
                </div>
            )}
        </div>
    );
}
