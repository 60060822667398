import Modal2 from "../../../lib/modal/Modal2";
import { ScheduleDailyNoteForm } from "./ScheduleDailyNoteForm";
import * as React from "react";
import { ApiResult, DayOfWeek } from "../slots/types";
import { ScheduleDailyNote } from "../types";
import { useCallback, useContext, useMemo, useState } from "react";
import { ScheduleDailyNotesContext } from "../slots/SlotsController";
import { DataContext } from "../slots/DataContext";

type SchedulerDailyNoteEditorProps = {
    dow: DayOfWeek;
    scheduleDailyNoteId: ScheduleDailyNote["id"] | null;
    onClose: () => unknown;
};

export function SchedulerDailyNoteEditor(props: SchedulerDailyNoteEditorProps) {
    const { dow, scheduleDailyNoteId, onClose } = props;
    const [busy, setBusy] = useState(false);

    const scheduleDailyNotesMap = useContext(ScheduleDailyNotesContext);
    const { createScheduleDailyNote, updateScheduleDailyNote, destroyScheduleDailyNote } = useContext(DataContext);
    const initialAttributes: ScheduleDailyNote = useMemo(() => {
        if (null === scheduleDailyNoteId) {
            return {
                id: null,
                text: "",
                color: "default",
                day_of_week: dow,
            };
        } else {
            const note = scheduleDailyNotesMap[dow]?.find((note) => note.id === scheduleDailyNoteId) || null;
            if (!note) {
                console.log(scheduleDailyNotesMap, dow, scheduleDailyNoteId);
                throw new Error(`Could not find schedule daily note with id ${scheduleDailyNoteId} for DOW ${dow}`);
            }
            return note;
        }
    }, [scheduleDailyNoteId, dow]);
    const [attributes, setAttributes] = useState<ScheduleDailyNote | null>(initialAttributes);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const title = scheduleDailyNoteId ? "Tagesnotiz bearbeiten" : "Neue Tagesnotiz erstellen";

    const handleApiResponse = useCallback((apiResult: ApiResult<ScheduleDailyNote>) => {
        setBusy(false);
        if (apiResult.errors && apiResult.errors.length > 0) {
            setValidationErrors(apiResult.errors);
        } else {
            onClose();
        }
    }, []);

    const handleChange = useCallback((value: Partial<ScheduleDailyNote>) => {
        setAttributes((attributes) => ({ ...attributes, ...value }));
    }, []);

    const handleSubmit = useCallback(() => {
        setBusy(true);
        if (!scheduleDailyNoteId) {
            createScheduleDailyNote(attributes.day_of_week, attributes.text, attributes.color).then(handleApiResponse);
        } else {
            updateScheduleDailyNote(scheduleDailyNoteId, {
                day_of_week: attributes.day_of_week,
                text: attributes.text,
                color: attributes.color,
            }).then(handleApiResponse);
        }
    }, [scheduleDailyNoteId, attributes.day_of_week, attributes.text, attributes.color]);

    const handleDelete = useCallback(() => {
        if (scheduleDailyNoteId) {
            setBusy(true);
            destroyScheduleDailyNote(scheduleDailyNoteId).then(handleApiResponse);
        } else {
            onClose();
        }
    }, [scheduleDailyNoteId]);

    return (
        <Modal2 open={true} onClose={onClose} title={title}>
            <ScheduleDailyNoteForm
                disabled={busy}
                value={attributes}
                validationErrors={validationErrors}
                onChange={handleChange}
                onSubmit={handleSubmit}
                onDelete={scheduleDailyNoteId && handleDelete}
            />
        </Modal2>
    );
}
