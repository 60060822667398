import * as React from "react";
import Icon, { IconType } from "../../../lib/icon/Icon";
import { joinClasses } from "../../../lib/dom-utils/class-names";
import { HTMLAttributes } from "react";

type ButtonProps = Omit<HTMLAttributes<HTMLButtonElement>, "onClick"> & {
    colorTheme: "indigo" | "slate" | "rose" | "amber" | "lime" |
        "indigo-light" | "slate-light" | "rose-light" | "amber-light" | "lime-light"
    onClick: () => void
    icon?: IconType | null,
    arrowIconType?: IconType | null;
    block?: boolean
    children: React.ReactNode
}

export default function Button(props: ButtonProps) {
    const {
        block = false,
        onClick,
        children,
        icon = null,
        arrowIconType = "chevron-right",
        colorTheme,
        ...rest
    } = props;

    const buttonClasses = joinClasses(
        "WorkHub__Button",
        `WorkHub__Button--${colorTheme}`,
        block && "WorkHub__Button--block"
    );

    rest.className = joinClasses(
        buttonClasses,
        rest.className
    );

    return (
        <button
            type="button"
            {...rest}
            onClick={onClick}
        >
            <span className="WorkHub__Button__Caption">
                {null !== icon && <Icon type={icon} />}
                {children}
            </span>
            {null !== arrowIconType && <Icon type={arrowIconType} />}
        </button>
    );
}
