import { colors } from "../../../../../../lib/theme/colors";
import * as React from "react";
import { LegendRect } from "./LegendRect";

export function MetricValueContentSplitByCreditType(props: { creditedValue: string; uncreditedValue: string }) {
    const { creditedValue, uncreditedValue } = props;

    return (
        <>
            <div className="d-flex justify-content-between align-items-baseline gap-3">
                <small className="text-nowrap">
                    <LegendRect color={colors.brixIndigo400} />
                    mit <abbr title="Stundengutschrift">SG</abbr>
                </small>
                <div className="text-nowrap">{creditedValue}</div>
            </div>
            <div className="d-flex justify-content-between align-items-baseline gap-3">
                <small className="text-nowrap">
                    <LegendRect color={colors.brixIndigo200} />
                    ohne <abbr title="Stundengutschrift">SG</abbr>
                </small>
                <div className="text-nowrap">{uncreditedValue}</div>
            </div>
        </>
    );
}
