import * as React from "react";
import useWorkHubState from "../../../state/useWorkHubState";
import Panel from "../../../layout/Panel";
import CheckedOutActions from "./CheckedOutActions";
import CheckedInActions from "./CheckedInActions";
import OnBreakActions from "./OnBreakActions";
import { humanEttStatusMap } from "../../../state/types/ett-status";
import EttTotals from "../ett-panel/EttTotals";

export default function EttStatusPanel() {
    const { ett: { status: ettStatus } } = useWorkHubState();
    const humanState = humanEttStatusMap[ettStatus];

    return <Panel className="WorkHub__EttStatusPanel">
        <Panel.Title text="Stempeluhr" />

        <Panel.Section>
            <div className="WorkHub__EttStatusPanel__StatusText">
                Du bist {humanState}.
            </div>
        </Panel.Section>

        <Panel.Section>
            <div className="w-100 d-flex gap-3">
                {ettStatus === "checked_out" && (
                    <CheckedOutActions />
                )}
                {ettStatus === "checked_in" && (
                    <CheckedInActions />
                )}
                {ettStatus === "on_break" && (
                    <OnBreakActions />
                )}
            </div>
        </Panel.Section>

        <Panel.Divider />

        <Panel.Section>
            <Panel.SectionTitle text="Deine heutige Anwesenheit" />
            <Panel.SubPanel>
                <EttTotals />
            </Panel.SubPanel>
        </Panel.Section>
    </Panel>;
}
