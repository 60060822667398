import * as React from "react";
import { HTMLAttributes, ReactNode } from "react";
import { joinClasses } from "../../../lib/dom-utils/class-names";

type PanelProps = HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
}

function Panel(props: PanelProps) {
    const { children, ...rest } = props;

    rest.className = joinClasses(
        "WorkHubLayout__Panel",
        rest.className
    );

    return (
        <section {...rest}>
            {children}
        </section>
    );
}

type PanelTitleProps = {
    text: string;
}

function PanelTitle(props: PanelTitleProps) {
    const { text } = props;
    return (
        <h1 className="WorkHubLayout__Panel__Title">
            {text}
        </h1>
    );
}

type PanelCaptionProps = {
    text: string;
}

function PanelCaption(props: PanelCaptionProps) {
    const { text } = props;
    return (
        <h1 className="WorkHubLayout__PanelCaption">
            <span>{text}</span>
        </h1>
    );
}

type PanelSectionTitleProps = {
    text: string;
}

function PanelSectionTitle(props: PanelSectionTitleProps) {
    const { text } = props;
    return (
        <h1 className="WorkHubLayout__Panel__SectionTitle">
            <span>{text}</span>
        </h1>
    );
}

type PanelSectionProps = {
    children: ReactNode;
}

function PanelSection(props: PanelSectionProps) {
    const { children } = props;
    return (
        <section className="WorkHubLayout__Panel__Section">
            {children}
        </section>
    );
}

function PanelDivider() {
    return (
        <div className="WorkHubLayout__Panel__Divider" />
    );
}

function PanelVerticalDivider() {
    return (
        <div className="WorkHubLayout__Panel__VerticalDivider" />
    );
}

type SubPanelProps = HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
}

function SubPanel(props: SubPanelProps) {
    const { children, ...rest } = props;

    rest.className = joinClasses(
        "WorkHubLayout__SubPanel",
        rest.className
    );

    return (
        <section {...rest}>
            {children}
        </section>
    );
}

type PanelHGroupProps = HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
}

function PanelHGroup(props: PanelHGroupProps) {
    const { children, ...rest } = props;

    rest.className = joinClasses(
        "WorkHubLayout__Panel__HGroup",
        rest.className
    );
    return (
        <section  {...rest}>
            {children}
        </section>
    );
}

Panel.Title = PanelTitle;
Panel.Caption = PanelCaption;
Panel.SectionTitle = PanelSectionTitle;
Panel.Section = PanelSection;
Panel.Divider = PanelDivider;
Panel.VerticalDivider = PanelVerticalDivider;
Panel.SubPanel = SubPanel;
Panel.HGroup = PanelHGroup;

export default Panel;
