import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import { isPresent } from "../../../lib/object/objects";
import { useMemo } from "react";
import { AbsenceCategory } from "../types";
import { absenceReasonOptions } from "./absence-reasons";

function safeDateFromIso(isoStr, defaultValue) {
    if (isPresent(isoStr)) {
        return DateTime.fromISO(isoStr);
    } else {
        return defaultValue;
    }
}

type ReturnType = {
    unitIds: string[];
    startsOn: DateTime;
    endsOn: DateTime;
    categories: AbsenceCategory[];
    includePresent: boolean;
};

export function useFilterParams(): ReturnType {
    const [searchParams] = useSearchParams();

    const rawUnitIds = searchParams.get("unitIds");
    const rawStartsOn = searchParams.get("startsOn");
    const rawEndsOn = searchParams.get("endsOn");
    const rawCategories = searchParams.get("categories");
    const rawIncludePresent = searchParams.get("includePresent");

    const unitIds = useMemo(() => {
        if (isPresent(rawUnitIds)) {
            return rawUnitIds.split(",");
        } else {
            return [];
        }
    }, [rawUnitIds]);

    const categories: AbsenceCategory[] = useMemo(() => {
        if (isPresent(rawCategories)) {
            return rawCategories.split(",").filter((c) => undefined !== absenceReasonOptions[c]) as AbsenceCategory[];
        } else {
            return [] as AbsenceCategory[];
        }
    }, [rawCategories]);

    const defaultEndsOn = DateTime.local();
    const defaultStartsOn = defaultEndsOn.minus({ day: 7 });

    const startsOn = useMemo(() => safeDateFromIso(rawStartsOn, defaultStartsOn), [rawStartsOn]);
    const endsOn = useMemo(() => safeDateFromIso(rawEndsOn, defaultEndsOn), [rawEndsOn]);
    const includePresent = useMemo(() => "true" === rawIncludePresent, [rawIncludePresent]);

    return { unitIds, startsOn, endsOn, categories, includePresent };
}
