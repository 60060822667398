import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { CursorAnchorElement } from "../types";
import { CursorPositionContext } from "../CursorPositionContext";

type DomCoordinates = {
    x: number;
    y: number;
};

type EditorOverlayProps = {
    cursorAnchorElement: CursorAnchorElement;
    busy: boolean;
    success: boolean;
    children;
};

export default function EditorOverlay(props: EditorOverlayProps) {
    const { cursorAnchorElement, busy, success, children } = props;

    const cursorRef = useRef<HTMLDivElement>(null);

    const anchorRect = (cursorAnchorElement as HTMLElement).getBoundingClientRect();
    const scrollContentRect = document.querySelector(".SchedulerBody__ScrollContent").getBoundingClientRect();

    const [cursorStyle, setCursorStyle] = useState(null);

    useEffect(() => {
        const rect: DomCoordinates = {
            x: anchorRect.x,
            y: anchorRect.y + anchorRect.height,
        };

        // When the cursor overlaps the right edge of the scroll content, we want to
        // move the editor to the left so that it doesn't overflow the scroll content.
        const cursorRect = cursorRef.current?.getBoundingClientRect();
        if (cursorRect && rect.x + cursorRect.width > scrollContentRect.width) {
            rect.x = scrollContentRect.width - cursorRect.width;
        }

        const nextCursorStyle = { top: rect.y + 2, left: rect.x - 8 };

        // Prevent infinite loop
        if (!cursorStyle || nextCursorStyle.top !== cursorStyle.top || nextCursorStyle.left !== cursorStyle.left) {
            setCursorStyle({ top: rect.y + 2, left: rect.x - 8 });
        }
    }, [cursorRef.current, anchorRect, scrollContentRect]);

    const { cursorPosition, setCursorPosition, currentCellPayload } = useContext(CursorPositionContext);
    const close = () => setCursorPosition({ ...cursorPosition, editorOpen: false });

    useEffect(() => {
        const timeout = setTimeout(() => document.body.addEventListener("click", close), 100);
        return () => {
            clearTimeout(timeout);
            document.body.removeEventListener("click", close);
        };
    }, []);

    const classNames = ["SchedulerCursor"];
    if (busy) {
        classNames.push("SchedulerCursor--busy");
    }
    if (success) {
        classNames.push("SchedulerCursor--success");
    } else if (null !== success) {
        classNames.push("SchedulerCursor--failure");
    }

    classNames.push(`SchedulerCursor--${currentCellPayload.type}`);

    return (
        <div className="SchedulerCursorBackdrop">
            {cursorStyle && (
                <div
                    ref={cursorRef}
                    className={classNames.join(" ")}
                    style={cursorStyle}
                    role="dialog"
                    aria-label="Cursor"
                    onClick={(e) => e.stopPropagation()}
                >
                    {busy && (
                        <div className="SchedulerCursor__Spinner">
                            <div className="spinner-border" role="status" />
                        </div>
                    )}
                    {children}
                </div>
            )}
        </div>
    );
}
