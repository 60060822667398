import * as React from "react";
import { State, useEmployeePermissions, useEmployeeState } from "../state";
import { dayNumbers, formatHours, shortHumanDay } from "../../../../lib/datetime/datetime";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import { formatDate } from "../../../../lib/datetime/date-format";
import { Warning } from "../Warning";
import WeekCalendar from "../../WeekCalendar";
import { Record } from "../Record";
import { KeyFigure } from "../../../../lib/key-figure/KeyFigure";

export function WorkConditions(props: React.HTMLAttributes<HTMLDivElement>) {
    const {
        canReadWorkTimeSets,
        canManageWorkTimeSets,
        canReadAvailabilityRules,
        canManageAvailabilityRules,
        canReadBreakRuleSets,
        canManageBreakRuleSets
    } = useEmployeePermissions();

    const { ...divProps } = props;
    const { employee } = useEmployeeState();

    if (!canReadWorkTimeSets && !canReadAvailabilityRules && !canReadBreakRuleSets) {
        return null;
    }

    return (
        <Kitchensink.Item {...divProps}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Arbeitszeiten, Pausen & Verfügbarkeit</Kitchensink.ItemBodyTitle>

                <WorkTimeSets />
                <AvailabilityRules />
                <BreakRuleSets />

                <Kitchensink.ItemBodyLinkList>
                    {canManageWorkTimeSets && (
                        <a className="Button Button--primadonna" href={`/employees/${employee.id}/work_time_sets`}>
                            Arbeitszeitregelungen verwalten
                        </a>
                    )}
                    {canManageAvailabilityRules && (
                        <a className="Button Button--primadonna" href={`/employees/${employee.id}/availability_rules`}>
                            Verfügbarkeitsregeln verwalten
                        </a>
                    )}
                    {canManageBreakRuleSets && (
                        <a className="Button Button--primadonna" href={`/employees/${employee.id}/break_rule_sets`}>
                            Pausenregelungen verwalten
                        </a>
                    )}
                </Kitchensink.ItemBodyLinkList>
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}

function WorkTimeSets() {
    const { canReadWorkTimeSets } = useEmployeePermissions();
    const { workTimeSets } = useEmployeeState();

    if (!canReadWorkTimeSets) {
        return null;
    }

    return (
        <>
            {workTimeSets.nextWorkTimeSetStartsOn && (
                <Kitchensink.ItemBodyPart>
                    <Warning>
                        Eine neue Arbeitszeitregelung tritt ab dem {formatDate(workTimeSets.nextWorkTimeSetStartsOn)} in
                        Kraft.
                    </Warning>
                </Kitchensink.ItemBodyPart>
            )}

            <Kitchensink.ItemBodyPart>
                <Kitchensink.ItemBodyPartHeadline>Aktuelle Arbeitszeitregelung</Kitchensink.ItemBodyPartHeadline>
                {!workTimeSets.currentWorkTimeSet && <Warning>Keine aktiven Arbeitszeitregelung vorhanden</Warning>}
                {workTimeSets.currentWorkTimeSet && (
                    <>
                        <Record>
                            <div className="mb-3">
                                Wöchentliche Soll-Arbeitszeit:{" "}
                                <KeyFigure>
                                    {formatHours(workTimeSets.currentWorkTimeSet.weeklyTargetHours)} h
                                </KeyFigure>
                            </div>
                            <WeekCalendar>
                                {dayNumbers.map((dow) => (
                                    <WeekCalendar.Day
                                        key={dow}
                                        active={workTimeSets.currentWorkTimeSet.days[dow].working}
                                    >
                                        <WeekCalendar.DayOfWeek>{shortHumanDay(dow)}</WeekCalendar.DayOfWeek>
                                        <WeekCalendar.KeyValue>
                                            {workTimeSets.currentWorkTimeSet.days[dow].working ? (
                                                <>
                                                    {formatHours(workTimeSets.currentWorkTimeSet.days[dow].targetHours)}{" "}
                                                    h
                                                </>
                                            ) : (
                                                <WeekCalendar.EmptyMessage>Freizeit</WeekCalendar.EmptyMessage>
                                            )}
                                        </WeekCalendar.KeyValue>
                                    </WeekCalendar.Day>
                                ))}
                            </WeekCalendar>
                        </Record>
                    </>
                )}
            </Kitchensink.ItemBodyPart>
        </>
    );
}

function AvailabilityRules() {
    const { canReadAvailabilityRules } = useEmployeePermissions();
    const { availabilityRules } = useEmployeeState();

    if (!canReadAvailabilityRules) {
        return null;
    }

    return (
        <Kitchensink.ItemBodyPart>
            <Kitchensink.ItemBodyPartHeadline>Aktuelle Verfügbarkeit</Kitchensink.ItemBodyPartHeadline>
            {availabilityRules.currentAvailabilityRules?.length ? (
                <>
                    {availabilityRules.currentAvailabilityRules.map((availabilityRule) => (
                        <Record key={availabilityRule.id}>
                            {availabilityRule.recurrence},{" "}
                            {availabilityRule.times
                                .map(({ startTime, endTime }) => `${startTime} → ${endTime}`)
                                .join(", ")}
                        </Record>
                    ))}
                </>
            ) : (
                <Warning>Keine aktiven Verfügbarkeitsregeln vorhanden</Warning>
            )}
        </Kitchensink.ItemBodyPart>
    );
}

function BreakRuleSets() {
    const { canReadBreakRuleSets } = useEmployeePermissions();
    const { breakRuleSets } = useEmployeeState();

    if (!canReadBreakRuleSets) {
        return null;
    }

    return (
        <Kitchensink.ItemBodyPart>
            <Kitchensink.ItemBodyPartHeadline>Aktuelle Pausenregeln</Kitchensink.ItemBodyPartHeadline>
            {breakRuleSets.currentBreakRuleSet?.breakRules?.length ? (
                breakRuleSets.currentBreakRuleSet.breakRules.map((breakRule) => (
                    <BreakRule key={breakRule.id} breakRule={breakRule} />
                ))
            ) : (
                <Warning>Keine aktiven Pausenregeln vorhanden</Warning>
            )}
        </Kitchensink.ItemBodyPart>
    );
}

function BreakRule(
    props: {
        breakRule: State["breakRuleSets"]["currentBreakRuleSet"]["breakRules"][0];
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const { breakRule, ...divProps } = props;
    return (
        <Record {...divProps}>
            Bei einer Arbeitszeit von mehr als <KeyFigure>{formatHours(breakRule.workInterval / 3600)} h</KeyFigure> ist
            eine Pause von <KeyFigure>{formatHours(breakRule.breakInterval / 3600)} h</KeyFigure> vorgeschrieben
        </Record>
    );
}
