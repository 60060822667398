import Icon from "../icon/Icon";
import * as React from "react";

export type FinderWindowHeaderProps = {
    onClose: () => void;
    title: string;
};

export default function FinderWindowHeader(props: FinderWindowHeaderProps) {
    const { onClose, title } = props;

    return (
        <div className="FinderWindow__Header">
            <div className="FinderWindow__Header__Title">{title}</div>
            <div className="FinderWindow__Header__Close" onClick={onClose}>
                <Icon type={"close"} />
            </div>
        </div>
    );
}
