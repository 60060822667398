import * as React from "react";

import Form from "../../../lib/form/form/Form";
import Checkbox from "../../../lib/form/checkbox/Checkbox";
import TimeSheetsDay from "../../../lib/model/TimeSheetsDay";
import TimeSheetsDayRow from "./TimeSheetsDayRow";
import TimeSheetsDayCorrection from "./TimeSheetsDayCorrection";
import timeSheetsDayAdapter from "../../../lib/api/adapters/time-sheets-day";

type TimeSheetsDayFormProps = {
    employeeId: string;
    unitId: string;
    date: string;
};

export default function TimeSheetsDayForm(props: TimeSheetsDayFormProps) {
    const { employeeId, unitId, date } = props;
    const urlParams = { id: 'always', employeeId, unitId, date };

    return (
        <Form adapter={timeSheetsDayAdapter} urlParams={urlParams} className="overflow-hidden d-flex flex-column TimeSheetsDay" style={{width: "450px"}}>
            {({ loading, changes, record, onChange }) => {
                const attrs = { ...record, ...changes } as TimeSheetsDay;
                const immutable = attrs.status == "immutable";
                const disabled = loading || immutable;
                const rows = [
                    { label: "Sollstunden", hours: attrs.target_hours },
                    { label: "Abwesend mit Stundengutschrift", hours: attrs.credited_absent_hours },
                    { label: "Abwesend ohne Stundengutschrift", hours: attrs.uncredited_absent_hours },
                    { label: "Abwesend, bezahlt", hours: attrs.paid_absent_hours },
                    { label: "Abwesend, unbezahlt", hours: attrs.unpaid_absent_hours },
                    { label: "Anwesend", hours: attrs.deployed_hours },
                    { label: "Pausen", hours: attrs.break_hours },
                ];

                return (
                    <table>
                        <tbody>
                            {rows.map((row) => <TimeSheetsDayRow label={row.label} hours={row.hours} key={row.label} />)}
                            <tr><td colSpan={2}><hr /></td></tr>
                            <TimeSheetsDayRow
                                label="Stundengutschrift"
                                hours={attrs.credited_work_hours}
                                className="TimeSheetsDay__Bold"
                            />
                            <tr><td colSpan={2}><hr /></td></tr>
                            {false && immutable && ( // not used yet - feature: overwrite immutable days and adjustments
                                <>
                                <tr>
                                    <td><strong>Zeitkontoeintrag festgeschrieben</strong></td>
                                    <td className="TimeSheetsDay__Time TimeSheetsDay__ImmutableCheckbox">
                                        <Checkbox
                                            caption=""
                                            checked={immutable}
                                            onChange={(checked) => onChange({ status: (checked ? "immutable" : "planned") })}
                                            disabled={!disabled}
                                            aria-label="Festgeschrieben"
                                        />
                                    </td>
                                </tr>
                                <tr><td colSpan={2}><hr /></td></tr>
                                </>
                                )
                            }
                            <TimeSheetsDayCorrection
                                onChange={onChange}
                                name="Korrekturwert"
                                field="additional"
                                adjustment={attrs?.adjustment}
                                disabled={disabled}
                            />
                            <tr><td colSpan={2}><hr /></td></tr>
                            <TimeSheetsDayRow
                                label="Finale Stundengutschrift"
                                hours={attrs.final_credited_work_hours}
                                className="TimeSheetsDay__Bold"
                            />
                            <tr><td colSpan={2}><hr /></td></tr>
                            <TimeSheetsDayCorrection
                                onChange={onChange}
                                name="Korrigierte finale Stundengutschrift"
                                field="final"
                                adjustment={attrs?.adjustment}
                                disabled={disabled}
                            />
                        </tbody>
                    </table>
                )
            }}
        </Form>
    )
}
