import * as React from "react";
import useWorkHubDispatch from "../state/useWorkHubDispatch";
import { useAuthTokenScanner } from "./useAuthTokenScanner";
import Panel from "../layout/Panel";
import DebugLoginButton from "./DebugLoginButton";
import Video from "./Video";
import { useCallback } from "react";

export default function LoginPanel() {
    const dispatch = useWorkHubDispatch();

    const onAuthToken = useCallback((token) => {
        dispatch({ type: "setAuthToken", payload: token });
    }, []);

    const { videoRef, scannerActive } = useAuthTokenScanner({ onAuthToken });

    return (
        <Panel>
            <Panel.Title text="Login" />
            <div className="d-flex flex-column gap-3">
                <div className="text-center">
                    Bitte halte deine Brix-Code-Karte vor die Kamera.
                </div>
                <DebugLoginButton />
                <Video ref={videoRef} scannerActive={scannerActive} />
            </div>
        </Panel>
    );
}
