import * as React from "react";

import { Deployment } from "../../../../types";
import { useContext } from "react";
import BreakTime from "./BreakTime";
import { DeploymentCellPayload } from "../../../../cursor/types";
import { CursorPositionContext } from "../../../../cursor/CursorPositionContext";
import { CursorAnchorSetter } from "../../../../cursor/CursorNavigation";

type DeploymentListItemProps = {
    deployment: Deployment;
    setCursorAnchorElement: CursorAnchorSetter;
    renderRow: number;
    renderCol: number;
    renderSubRow: number;
};
export default function DeploymentListItem(props: DeploymentListItemProps) {
    const { deployment, setCursorAnchorElement, renderRow, renderCol, renderSubRow } = props;
    const classNames = ["SchedulerDeployment SchedulerDeployment--danger"];

    const { cursorPosition, setCursorPosition, currentCellPayload } = useContext(CursorPositionContext);
    const currentDeploymentSequenceId = (currentCellPayload as DeploymentCellPayload)?.deploymentSequenceId ?? null;
    if (
        null !== deployment.deployment_sequence_id &&
        deployment.deployment_sequence_id === currentDeploymentSequenceId
    ) {
        classNames.push("SchedulerDeployment--SameSequence");
    }

    classNames.push("SchedulerDeployment--" + deployment.color);

    const isActive =
        cursorPosition &&
        renderRow === cursorPosition.row &&
        renderCol === cursorPosition.col &&
        renderSubRow === cursorPosition.i;
    if (isActive) {
        classNames.push("SchedulerDeployment--active");
    }

    if (deployment.suspended) {
        classNames.push("SchedulerDeployment--suspended");
    }

    const handleClick = () => {
        setCursorPosition({
            row: renderRow,
            col: renderCol,
            i: renderSubRow,
            editorOpen: true,
            tab: 0,
        });
    };

    const handleDeploymentSequenceClick = (e) => {
        e.stopPropagation();
        setCursorPosition({
            row: renderRow,
            col: renderCol,
            i: renderSubRow,
            editorOpen: true,
            tab: 1,
        });
    };

    return (
        <div className={classNames.join(" ")} onClick={handleClick} ref={isActive ? setCursorAnchorElement : null}>
            <div className="SchedulerDeployment__Time">
                {deployment.start_time}
                <span className="text-muted" style={{ color: "#aaa" }}>
                    {" "}
                    –{" "}
                </span>
                {deployment.end_time} <BreakTime deployment={deployment} onClick={handleDeploymentSequenceClick} />
            </div>
            {deployment.warnings.length > 0 && (
                <div
                    className="SchedulerDeployment__Badge"
                    role="note"
                    aria-label="Es existieren Warnungen für diesen Einsatz"
                    title="Es existieren Warnungen für diesen Einsatz"
                >
                    <i className="bi bi-exclamation-triangle-fill" />
                </div>
            )}
        </div>
    );
}
