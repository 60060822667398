import * as React from "react";
import { useEmployeePermissions, useEmployeeState } from "../state";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import { Record } from "../Record";
import { formatDate } from "../../../../lib/datetime/date-format";
import { Warning } from "../Warning";
import { KeyFigure } from "../../../../lib/key-figure/KeyFigure";

export function EmploymentConditions(props: React.HTMLAttributes<HTMLDivElement>) {
    const {
        canReadEmployments,
        canManageEmployments,
        canManageUnitAssignments,
        canReadWorkplaces,
        canManageWorkplaces
    } = useEmployeePermissions();
    const { ...divProps } = props;
    const { employee } = useEmployeeState();

    if (!canReadEmployments && !canReadWorkplaces) {
        return null;
    }

    return (
        <Kitchensink.Item {...divProps}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Anstellung</Kitchensink.ItemBodyTitle>

                <Employment />
                <Workplaces />

                {(canManageEmployments || canManageUnitAssignments || canManageWorkplaces) && (
                    <Kitchensink.ItemBodyLinkList>
                        {canManageEmployments && (
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/employments`}>
                                Beschäftigungsverhältnisse verwalten
                            </a>
                        )}

                        {canManageUnitAssignments && (
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/unit_assignments`}>
                                Anstellungsort verwalten
                            </a>
                        )}

                        {canManageWorkplaces && (
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/workplaces`}>
                                Arbeitsorte verwalten
                            </a>
                        )}
                    </Kitchensink.ItemBodyLinkList>
                )}
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}

function Employment() {
    const { canReadEmployments } = useEmployeePermissions();
    const { employments, unitAssignments } = useEmployeeState();

    if (!canReadEmployments) {
        return null;
    }

    return (
        <>
            <Kitchensink.ItemBodyPart>
                {!employments.currentEmployment && <Warning>Kein Beschäftigungsverhältnis</Warning>}
                {employments.currentEmployment && (
                    <Record>
                        <KeyFigure>{employments.currentEmployment?.employmentMode?.name}</KeyFigure> seit dem{" "}
                        <KeyFigure>{formatDate(employments.currentEmployment.startsOn)}</KeyFigure>
                        {employments.currentEmployment.endsOn && (
                            <>
                                {", "}wird
                                am <KeyFigure>{formatDate(employments.currentEmployment.endsOn)}</KeyFigure>{" "}
                                enden.
                            </>
                        )}
                    </Record>
                )}

                {!unitAssignments.currentUnitAssignment && <Warning>Kein Anstellungsort</Warning>}
                {unitAssignments.currentUnitAssignment && (
                    <Record>
                        Angestellt in{" "}
                        <KeyFigure>{unitAssignments.currentUnitAssignment.unit?.name ?? "(Unbekannt)"}</KeyFigure> seit
                        dem <KeyFigure>{formatDate(unitAssignments.currentUnitAssignment.startsOn)}</KeyFigure>
                        {unitAssignments.currentUnitAssignment.endsOn && (
                            <>
                                {", "}wird am{" "}
                                <KeyFigure>{formatDate(unitAssignments.currentUnitAssignment.endsOn)}</KeyFigure> enden.
                            </>
                        )}
                    </Record>
                )}
            </Kitchensink.ItemBodyPart>
        </>
    );
}

function Workplaces() {
    const { canReadWorkplaces } = useEmployeePermissions();
    const { workplaces } = useEmployeeState();

    if (!canReadWorkplaces) {
        return null;
    }

    return (
        <>
            <Kitchensink.ItemBodyPart>
                <Kitchensink.ItemBodyPartHeadline>
                    Arbeitet aktuell in folgenden Filialen
                </Kitchensink.ItemBodyPartHeadline>
                {!workplaces.currentWorkplaces?.length && <Warning>Aktuell ist kein Arbeitsplatz zugewiesen</Warning>}
                {workplaces.currentWorkplaces.map((workplace) => (
                    <Record key={workplace.id}>
                        <KeyFigure>{workplace.unit.name}</KeyFigure> seit dem{" "}
                        <KeyFigure>{formatDate(workplace.startsOn)}</KeyFigure>
                        {workplace.endsOn && (
                            <>
                                {", "}wird am <KeyFigure>{formatDate(workplace.endsOn)}</KeyFigure> enden.
                            </>
                        )}
                    </Record>
                ))}
            </Kitchensink.ItemBodyPart>
        </>
    );
}
