import * as React from "react";
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from "react";
import ValidationErrorIndicator from "../validation/ValidationErrorIndicator";
import buildInputProps from "../utils/build-input-props";

type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
    invalid?: boolean;
    onChange: (value: string) => void;
    type?: "text" | "number";
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
    const { invalid = false, onChange, type = "text", ...userProps } = props;
    const inputProps = buildInputProps(userProps, { invalid });
    return (
        <>
            <input
                onChange={(e) => onChange(e.currentTarget.value)}
                type={type}
                ref={ref}
                {...(inputProps as DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>)}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                {invalid && <ValidationErrorIndicator />}
            </div>
        </>
    );
});

TextField.displayName = "TextField";

export default TextField;
