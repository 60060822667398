import * as React from "react";

export default function EmptyState() {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "rgb(170, 170, 170)",
                flex: "1 1 100%",
            }}
        >
            Keine Einträge für diese Filter-Kriterien gefunden.
        </div>
    );
}
