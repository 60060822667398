import * as React from "react";
import { useRecentSchedules } from "./useRecentSchedules";
import { DateTime } from "luxon";
import { ScheduleWidgetContent } from "./SchedulesWidgetContent";

export function CurrentWeekSchedules() {
    const { schedulesCurrentWeek, loading, loadingFailed } = useRecentSchedules();

    const currentIsoWeek = DateTime.local().toFormat("kkkk 'KW' W");

    return (
        <ScheduleWidgetContent
            title="Aktuelle Wochenpläne"
            weekName={currentIsoWeek}
            schedules={schedulesCurrentWeek}
            loading={loading}
            loadingFailed={loadingFailed}
        />
    );
}
