import { createInitialState, WorkHubState } from "./state";
import { createContext, Dispatch } from "react";
import { Action } from "./actions";

export type StateContextValue = {
    state: WorkHubState,
    dispatch: Dispatch<Action>,
}

export const StateContext = createContext<StateContextValue>({
    state: createInitialState(), dispatch: () => null
});
