export type SkillIcon = string;

export type Skill = {
    id: string;
    name: string;
    icon: SkillIcon;
    isPrimary?: boolean;
};

export type SkillLevel = 1 | 2 | 3 | 4 | 5;

export type SkillPlusLevel = Skill & {
    level: SkillLevel;
};

export function isSkillPlusLevel(skill: Skill | SkillPlusLevel): skill is SkillPlusLevel {
    return (skill as SkillPlusLevel).level !== undefined;
}
