import * as React from "react";
import { useEffect, useRef } from "react";
import QRCodeLib from "qrcode";

export default function QRCode({ text }: { text: string }) {
    const ref = useRef(null);

    if (!text) {
        return null;
    }

    useEffect(() => {
        const canvas = ref.current;
        if (canvas) {
            QRCodeLib.toCanvas(canvas, text, { scale: 4 }, function (error: unknown) {
                if (error) {
                    console.error(error);
                }
            });
        }
    }, [text]);

    return <canvas ref={ref}></canvas>;
}
