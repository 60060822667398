import * as React from "react";
import ValidationError from "./ValidationError";

type ValidationErrorsProps = {
    errors?: string[];
};

export default function ValidationErrors(props: ValidationErrorsProps) {
    const { errors, ...otherProps } = props;
    return (
        <div {...otherProps}>
            {errors.map((msg) => (
                <ValidationError key={msg} className="mb-1" message={msg} />
            ))}
        </div>
    );
}
