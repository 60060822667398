import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../../dom-utils/class-names";

type ValidationErrorProps = HTMLAttributes<HTMLDivElement> & { message: string };

export default function ValidationError(props: ValidationErrorProps) {
    const { message, ...elementProps } = props;

    elementProps.className = joinClasses("ValidationError", elementProps.className);

    return <div {...elementProps}>{message}</div>;
}
