import * as React from "react";
import { useContext } from "react";
import { CursorPositionContext } from "../../../../cursor/CursorPositionContext";
import { CursorAnchorSetter } from "../../../../cursor/CursorNavigation";
import { EmployeeNote } from "../../../types";

type EmplyeeNoteProps = {
    employeeNote: EmployeeNote;
    renderRow: number;
    renderCol: number;
    renderSubRow: number;
    setCursorAnchorElement: CursorAnchorSetter;
};

export default function EmplyeeNote(props: EmplyeeNoteProps): JSX.Element {
    const { renderRow, renderCol, renderSubRow, setCursorAnchorElement, employeeNote } = props;

    const { cursorPosition, setCursorPosition } = useContext(CursorPositionContext);
    const isActive =
        cursorPosition &&
        renderRow === cursorPosition.row &&
        renderCol === cursorPosition.col &&
        renderSubRow === cursorPosition.i;

    const handleClick = () => {
        setCursorPosition({
            row: renderRow,
            col: renderCol,
            i: renderSubRow,
            editorOpen: true,
            tab: 0,
        });
    };

    const classNames = ["SchedulerEmployeeNote"];
    classNames.push(`SchedulerEmployeeNote--${employeeNote.color}`);
    if (isActive) {
        classNames.push("SchedulerEmployeeNote--active");
    }

    return (
        <div
            className={classNames.join(" ")}
            key={employeeNote.id}
            ref={isActive ? setCursorAnchorElement : undefined}
            onClick={handleClick}
        >
            <div className="SchedulerEmployeeNote__Inner">{employeeNote.text}</div>
        </div>
    );
}
