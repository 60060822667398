import * as React from "react";

type ErrorBoundaryState = { hasError: boolean; error: { message: string } };

type ErrorBoundaryProps = {
    children: React.ReactNode;
};

export default class ErrorBoundary extends React.Component {
    public state: ErrorBoundaryState;
    public props: ErrorBoundaryProps;

    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    // componentDidCatch(error, errorInfo) {
    //     // You can also log the error to an error reporting service
    //     // logErrorToMyService(error, errorInfo);
    // }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <div className="SchedulerError">(Fehler)</div>
                    <div className="SchedulerErrorOverlay">
                        <div className="SchedulerErrorOverlay__Inner">
                            <div>
                                <strong>Ein Fehler ist aufgetreten.</strong>
                                <br />
                            </div>
                            <p>Bitte laden Sie die Seite neu.</p>
                            <div>
                                <strong>Details:</strong>
                            </div>
                            <p>
                                <code>{this.state.error.message}</code>
                            </p>
                        </div>
                    </div>
                </>
            );
        }
        return this.props.children;
    }
}
