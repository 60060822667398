import * as React from "react";
import useWorkHubDispatch from "../state/useWorkHubDispatch";
import useWorkHubState from "../state/useWorkHubState";

export default function DebugLoginButton() {
    const dispatch = useWorkHubDispatch();
    const { ui: { devToolsEnabled } } = useWorkHubState();

    if (!devToolsEnabled) {
        return null;
    }
    return (
        <button type="button" onClick={() => {
            dispatch({
                type: "setAuthToken",
                // Won't work automatically, obviously. Insert into your local DB manually
                payload: "AuthTokenForDebugginInDevelopment-000001"
            });
        }}>
            Debug Login
        </button>
    );
}

