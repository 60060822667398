import { AllocationTimes } from "../../../../../../lib/deployment-bar/DeploymentDayBar";

export function buildAllocationTimes(allocationWorkplacesOnDay, scheduleUnitId) {
    const allocationTimes: AllocationTimes[] = [];

    allocationWorkplacesOnDay.forEach((workplacesOnDay) => {
        workplacesOnDay.forEach((workplace) => {
            if (workplace.unit_id !== scheduleUnitId) {
                workplace.deployments.forEach((deployment) => {
                    allocationTimes.push([deployment.start_time, deployment.end_time, workplace.unit_name]);
                });
            }
        });
    });

    return allocationTimes;
}
