import { DateTime } from "luxon";
import { ReportEmployee } from "../types";
import { DayOfWeek } from "../../../lib/datetime/types";
import { dayNumbers } from "../../../lib/datetime/datetime";
import { ReportDay } from "../../../lib/ett/report-grid/types";

export type State = {
    startsAt: DateTime | null;
    endsAt: DateTime | null;
    loading: boolean;
    employee: ReportEmployee | null;
    daysByWeeks: Record<string, ReportDay[]>
    filteredDaysByWeeks: Record<string, ReportDay[]>
    weekDays: DayOfWeek[];
}

export function createInitialState(): State {
    return {
        startsAt: null,
        endsAt: null,
        loading: true,
        employee: null,
        daysByWeeks: {},
        filteredDaysByWeeks: {},
        weekDays: dayNumbers
    };
}
