import { Schedule } from "../types";
import { Document } from "./types";
import { convertKeysToCamelCase } from "../../../lib/object/objects";

export function loadDocuments(scheduleId: Schedule["id"]): Promise<Document[]> {
    return fetch(`/api/scheduler/schedules/${scheduleId}/documents`, {
        headers: {
            Accept: "application/json",
        },
    })
        .then((response) => response.json())
        .then((json) => convertKeysToCamelCase(json.data) as Document[]);
}

export function deleteDocument(scheduleId: Schedule["id"], documentId: Document["id"]): Promise<boolean> {
    return fetch(`/api/scheduler/schedules/${scheduleId}/documents/${documentId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
    }).then((response) => response.ok);
}
