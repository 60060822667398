import * as React from "react";
import AbsenceBar from "../../../../AbsenceBar";
import { useContext } from "react";
import { CursorPositionContext } from "../../../../cursor/CursorPositionContext";
import { Absence, Day } from "../../../../types";
import { CursorAnchorSetter } from "../../../../cursor/CursorNavigation";
import { ScheduleContext } from "../../../SlotsController";
import { DateTime } from "luxon";

type AbsenceProps = {
    absence: Absence;
    renderRow: number;
    renderCol: number;
    renderSubRow: number;
    setCursorAnchorElement: CursorAnchorSetter;
    day: Day;
};

export default function Absence(props: AbsenceProps): JSX.Element {
    const { renderRow, renderCol, renderSubRow, setCursorAnchorElement, absence, day } = props;

    const { cursorPosition, setCursorPosition } = useContext(CursorPositionContext);
    const isActive =
        cursorPosition &&
        renderRow === cursorPosition.row &&
        renderCol === cursorPosition.col &&
        renderSubRow === cursorPosition.i;

    // I found the following code fragment here, which was causing jumping cursor positions.
    // It appears though as if the code once served a purpose but I cannot figure out what it was.
    // Leaving it here for reference.
    //
    // Background:
    // The same absence can appear multiple times on the grid.
    // Eq. when an employee is deployed in multiple departments.
    // Thus, an absence can be active but still not the focus of the cursor.
    // With the code below, the cursor would always jump to the last occurrence of the absence,
    // even it it wasn't the one the user selected.
    //
    // const currentAbsence = (currentCellPayload as AbsenceCellPayload)?.absences?.[0];
    // if ("absence" === currentCellPayload?.type && currentAbsence && currentAbsence.id === absence.id) {
    //     isActive = true;
    // }

    const schedule = useContext(ScheduleContext);
    const today = getDateOfScheduleDay(DateTime.fromISO(schedule.starts_on), day.dow);

    const absenceFirstDay = DateTime.fromISO(absence.starts_on);
    const absenceLastDay = DateTime.fromISO(absence.ends_on).minus({ days: 1 });

    const absenceStartedBeforeToday = absenceFirstDay < today;
    const absenceEndsAfterToday = absenceLastDay > today;

    const handleClick = () => {
        setCursorPosition({
            row: renderRow,
            col: renderCol,
            i: renderSubRow,
            editorOpen: true,
            tab: 0,
        });
    };

    return (
        <div ref={isActive ? setCursorAnchorElement : undefined}>
            <AbsenceBar
                message={absence.reason}
                approved={absence.approved}
                interactive={true}
                active={isActive}
                onClick={handleClick}
                leftOpen={absenceStartedBeforeToday}
                rightOpen={absenceEndsAfterToday}
                extension={absenceStartedBeforeToday}
            />
        </div>
    );
}

function getDateOfScheduleDay(scheduleStartsOn: DateTime, dayOfWeek: number): DateTime {
    // Day of week starts at 1, not 0.
    const dayOfWeekZeroBased = dayOfWeek - 1;
    return scheduleStartsOn.plus({ days: dayOfWeekZeroBased });
}
