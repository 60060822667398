import * as React from "react";
import { ButtonHTMLAttributes, forwardRef } from "react";

import { ThemeColor } from "../theme/colors";
import Icon, { IconType } from "../icon/Icon";
import { joinClasses } from "../dom-utils/class-names";

type ButtonSizes = "sm" | "md" | "lg";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    caption?: string;
    theme?: ThemeColor;
    size?: ButtonSizes;
    icon?: IconType;
    text?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { caption = null, theme = "secondary", size = null, children, icon, ...otherProps } = props;

    otherProps.className = joinClasses("btn", `btn-${theme}`, otherProps.className, null !== size && `btn-${size}`);

    return (
        <button ref={ref} {...otherProps}>
            <div className="d-flex align-items-center justify-content-between gap-2">
                {icon && <Icon type={icon} />}
                {caption}
                {children}
            </div>
        </button>
    );
});
Button.displayName = "Button";
export default Button;
