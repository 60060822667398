import * as React from "react";
import { SkilledEmployee } from "../../../../../lib/types/employees";
import SkillList from "./SkillList";

type EmployeeResultContentProps = {
    employee: SkilledEmployee;
};

export default function EmployeeResultContent(props: EmployeeResultContentProps) {
    const { employee } = props;

    return (
        <div className="SchedulerEmployeeWeekEditor__EmployeeResultContent">
            <div className="SchedulerEmployeeWeekEditor__EmployeeResultContent__Name">{employee.name}</div>
            <SkillList skills={employee.skills} />
        </div>
    );
}
