import * as React from "react";
import { FaceDefaultProps } from "./type";
import EmployeeNoteEditor from "../editors/EmployeeNoteEditor";
import { EmployeeNoteCellPayload } from "../types";
import FaceFrame from "./FaceFrame";
import CustomItemColorPicker from "../../../../lib/color-picker/CustomItemColorPicker";
import { CustomItemColor } from "../../types";
import { useContext } from "react";
import { DataContext } from "../../slots/DataContext";

type EmployeeNoteFaceProps = FaceDefaultProps & {
    currentCellPayload: EmployeeNoteCellPayload;
    onDeploymentSuccessChanged: (success: boolean) => unknown;
};

export default function EmployeeNoteFace(props: EmployeeNoteFaceProps): JSX.Element {
    const { busy, onBusyChange, currentCellPayload, onDeploymentSuccessChanged } = props;

    const commonProps = {
        currentCellPayload: currentCellPayload,
        busy: busy,
        onBusyChange: onBusyChange,
        onDeploymentSuccessChanged: onDeploymentSuccessChanged,
    };

    const { updateEmployeeNote } = useContext(DataContext);

    const handleColorChange = (color: CustomItemColor | null) => {
        updateEmployeeNote(currentCellPayload.employeeNote.id, { color: color });
    };

    const colorPicker = (
        <CustomItemColorPicker value={currentCellPayload.employeeNote.color} onChange={handleColorChange} />
    );

    return (
        <FaceFrame title={"Mitarbeiternotiz"} icon={"employee-note"} widgets={colorPicker}>
            <EmployeeNoteEditor {...commonProps} />
        </FaceFrame>
    );
}
