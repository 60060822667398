import { useLayoutDispatch, useLayoutState } from "../state";
import { useRef } from "react";
import { useKeyboardShortcut } from "../../../../lib/keyboard-shortcut/useKeyboardShortcut";
import TextField from "../../../../lib/form/text/TextField";
import * as React from "react";

export default function SearchBar() {
    const dispatch = useLayoutDispatch();
    const { query } = useLayoutState();

    function handleChange(query: string) {
        dispatch({
            type: "filterByQuery",
            payload: { query }
        });
    }

    const ref = useRef<HTMLInputElement>(null);

    useKeyboardShortcut("CTRL-f", () => ref.current?.focus());
    useKeyboardShortcut("Escape", () => ref.current?.blur());

    return (
        <div className="EmployeeManager__SearchBar">
            <TextField
                aria-label="Suchbegriff"
                ref={ref}
                type="text"
                value={query}
                onChange={handleChange}
                placeholder="Suchen mit STRG-F…"
            />
        </div>
    );
}
