import * as React from "react";
import { SchoolWeekAttributes } from "./SchoolWeekFieldSet";
import { DayOfWeek } from "../../scheduler/slots/types";
import Checkbox from "../../../lib/form/checkbox/Checkbox";
import DurationField from "../../../lib/form/datetime/DurationField";
import { humanDay } from "../../../lib/datetime/datetime";
import SchoolTimeSet from "../../../lib/model/SchoolTimeSet";
import TimeOfDayField from "../../../lib/form/datetime/TimeOfDayField";
import { useMemo } from "react";

type DayAttributes = {
    attendSchool?: SchoolTimeSet["attends_school_on_1"];
    creditedHours?: SchoolTimeSet["credited_hours_1"];
    absenceStartTime?: SchoolTimeSet["absence_start_time_1"];
    absenceEndTime?: SchoolTimeSet["absence_end_time_1"];
};

function attributesForDay(origAttributes: SchoolWeekAttributes, dow, dayAttrs: DayAttributes) {
    const attrs: Partial<SchoolWeekAttributes> = {};

    if (undefined !== dayAttrs.attendSchool) {
        attrs[`attends_school_on_${dow}`] = dayAttrs.attendSchool;
    }
    if (undefined !== dayAttrs.creditedHours) {
        attrs[`credited_hours_${dow}`] = dayAttrs.creditedHours;
    }
    if (undefined !== dayAttrs.absenceStartTime) {
        attrs[`absence_start_time_${dow}`] = dayAttrs.absenceStartTime;
    }
    if (undefined !== dayAttrs.absenceEndTime) {
        attrs[`absence_end_time_${dow}`] = dayAttrs.absenceEndTime;
    }

    return { ...origAttributes, ...attrs };
}

type SchoolWeekFieldSetDayProps = {
    value?: SchoolWeekAttributes;
    onChange?: (value: SchoolWeekAttributes) => void;
    disabled?: boolean;
    dow: DayOfWeek;
};

const SchoolWeekFieldSetDay = (props: SchoolWeekFieldSetDayProps) => {
    const { value = null, onChange, disabled = false, dow } = props;

    const dayValues = useMemo(
        () => ({
            attendsSchool: value[`attends_school_on_${dow}`],
            creditedHours: value[`credited_hours_${dow}`],
            absenceStartTime: value[`absence_start_time_${dow}`],
            absenceEndTime: value[`absence_end_time_${dow}`],
        }),
        [value, dow]
    );

    function onToggleDay(checked) {
        let newAttributes;
        if (checked) {
            newAttributes = attributesForDay(value, dow, {
                attendSchool: true,
                creditedHours: dayValues.creditedHours,
            });
        } else {
            newAttributes = attributesForDay(value, dow, { attendSchool: false, creditedHours: 0.0 });
        }

        onChange(newAttributes);
    }

    function mergeDayChanges(changes: DayAttributes) {
        const newAttributes = attributesForDay(value, dow, changes);
        onChange(newAttributes);
    }

    const checkboxAriaLabel = useMemo(() => `Besucht Schule am Wochentag ${dow}`, [dow]);

    return (
        <div
            className="list-group-item d-flex justify-content-start align-items-center gap-2"
            role="group"
            aria-label={`Schulzeiten für Wochentag ${dow}`}
        >
            <div style={{ flex: "0 0 150px" }} className={dayValues.attendsSchool ? "" : "text-muted"}>
                <Checkbox
                    caption={humanDay(dow)}
                    disabled={disabled}
                    onChange={onToggleDay}
                    checked={dayValues.attendsSchool || false}
                    aria-label={checkboxAriaLabel}
                />
            </div>
            <div style={{ flex: "0 0 200px" }} role="group" aria-label="Abgerechnete Stunden">
                {dayValues.attendsSchool ? (
                    <DurationField
                        value={3600 * (dayValues.creditedHours ?? 0.0)}
                        disabled={disabled}
                        onChange={(newCreditedSeconds) => mergeDayChanges({ creditedHours: newCreditedSeconds / 3600 })}
                    />
                ) : (
                    <span className="text-muted">Keine</span>
                )}
            </div>
            <div style={{ flex: "1 1 100%" }} role="group" aria-label="Abwesenheitszeit">
                {dayValues.attendsSchool ? (
                    <>
                        <span role="group" aria-label="Startzeit">
                            <TimeOfDayField
                                disabled={disabled}
                                value={dayValues.absenceStartTime}
                                onChange={(newStartTime) => mergeDayChanges({ absenceStartTime: newStartTime })}
                            />
                        </span>{" "}
                        bis{" "}
                        <span role="group" aria-label="Endzeit">
                            <TimeOfDayField
                                disabled={disabled}
                                value={dayValues.absenceEndTime}
                                onChange={(newEndTime) => mergeDayChanges({ absenceEndTime: newEndTime })}
                            />
                        </span>
                    </>
                ) : (
                    <span className="text-muted">Keine</span>
                )}
            </div>
        </div>
    );
};

SchoolWeekFieldSetDay.displayName = "SchoolWeekFieldSetDay";

export default SchoolWeekFieldSetDay;
