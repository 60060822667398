import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../dom-utils/class-names";
import Icon, { IconType } from "../icon/Icon";

type IndicatorProps = HTMLAttributes<HTMLDivElement> & {
    colorScheme?: "primary" | "secondary";
    type: IconType;
    text?: string | number;
};

export default function Indicator(props: IndicatorProps) {
    const { className, colorScheme = "primary", type, text = null, ...otherProps } = props;
    const cnames = ["badge"];
    cnames.push(`border-${colorScheme} border text-${colorScheme}`);
    const cname = joinClasses(className, ...cnames);

    return (
        <div className={cname} {...otherProps}>
            <Icon type={type} />
            {text !== null && <> {text}</>}
        </div>
    );
}
