const locale = "de-DE";

export type FormatNumberOptions = {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
};

export function formatNumber(value: number, options: FormatNumberOptions = {}): string {
    const format = new Intl.NumberFormat(locale, {
        minimumFractionDigits: options.minimumFractionDigits ?? 2,
        maximumFractionDigits: options.maximumFractionDigits ?? 2,
    });
    return format.format(value);
}

export function isNumber(x: unknown): x is number {
    return typeof x === "number";
}
