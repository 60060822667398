import * as React from "react";
import { DApiErrors } from "../types";

type ApiErrorsProps = {
    errors: DApiErrors;
};

export default function ApiErrors(props: ApiErrorsProps) {
    const { errors } = props;

    if (!errors || !errors.length) {
        return null;
    }

    return (
        <div className="AbsenceReport__ApiErrors">
            {errors.map((msg) => (
                <div key={msg} className="AbsenceReport__ApiErrors">
                    {msg}
                </div>
            ))}
        </div>
    );
}
