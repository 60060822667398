import * as React from "react";
import { HTMLAttributes } from "react";

type TimeSheetsDayRowProps = HTMLAttributes<HTMLTableRowElement> & {
    label: string;
    hours: string;
};

export default function TimeSheetsDayRow(props: TimeSheetsDayRowProps) {
  const { label, hours, ...otherProps } = props

    return (
        <tr {...otherProps}>
            <td className="TimeSheetsDay__Label">{label}</td>
            <td className="TimeSheetsDay__Time">{hours} h</td>
        </tr>
    )
};
