import * as React from "react";
import { conditionalClassNames } from "../../dom-utils/class-names";

type GridHeaderProps = {
    expandable: boolean;
}

export function GridHeader(props: GridHeaderProps) {
    const { expandable } = props;
    return (
        <header className={conditionalClassNames({
            "EttReportGrid__Row EttReportGrid__HeadRow": true,
            "EttReportGrid__Row--expandable": expandable
        }).join(" ")}>
            {expandable && <div className="EttReportGrid__Col EttReportGrid__HeadCol" />}
            <div className="EttReportGrid__Col EttReportGrid__HeadCol">Datum</div>
            <div className="EttReportGrid__Col EttReportGrid__HeadCol">Details</div>
            <div className="EttReportGrid__Col EttReportGrid__HeadCol EttReportGrid__Col--right">Arbeitszeit</div>
            <div className="EttReportGrid__Col EttReportGrid__HeadCol EttReportGrid__Col--right">Pausenzeit</div>
            <div className="EttReportGrid__Col EttReportGrid__HeadCol" />
        </header>
    );
}
