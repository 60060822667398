import * as React from "react";
import { BrandSvg } from "./BrandSvg";
import useWorkHubState from "../state/useWorkHubState";
import LogoutButton from "./LogoutButton";

export default function Header() {
    const { ui: { pageTitle }, access: { token } } = useWorkHubState();
    return (
        <header className="WorkHubLayout__Header">
            <div className="WorkHubLayout__Header__Inner">
                <div className="WorkHubLayout__Header__Brand">
                    <BrandSvg />
                </div>
                <div className="WorkHubLayout__Header__Title">
                    {pageTitle}
                </div>
                <div className="WorkHubLayout__Header__Actions">
                    {token !== null && <LogoutButton />}
                </div>
            </div>
        </header>
    );
}
