import * as React from "react";
import Button from "../../../shared/Button";
import useEttStatusTransition from "./useEttStatusTransition";
import { endBreak } from "../../../api/ett-statuses";

type OnBreakActionProps = Record<string, never>;

export default function OnBreakActions(_props: OnBreakActionProps) {
    const transitionEndBreak = useEttStatusTransition(endBreak);

    return (<>
        <Button colorTheme="slate-light" onClick={transitionEndBreak} block={true}>
            Pause beenden
        </Button>
    </>);
}
