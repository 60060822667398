import * as React from "react";
import { HTMLAttributes, useMemo } from "react";
import { DateTime, Interval } from "luxon";
import DateField from "./DateField";
import { safeInterval } from "../../datetime/datetime";
import Button from "../../button/Button";

type IntervalFieldProps = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
    value: Interval;
    invalid?: boolean;
    onChange: (value: Interval) => void;
};

export default function IntervalField(props: IntervalFieldProps) {
    const { value, onChange } = props;

    const startDate = useMemo(() => value?.start ?? DateTime.local().startOf("day"), [value]);
    const endDate = useMemo(
        () => value?.end ?? startDate.endOf("day") ?? DateTime.local().endOf("day"),
        [value, startDate]
    );

    function handleNextMonth() {
        const nextStart = value.start.plus({ months: 1 }).startOf("month");
        const nextMonthInterval = Interval.fromDateTimes(nextStart, nextStart.endOf("month"));
        onChange(nextMonthInterval);
    }

    function handlePrevMonth() {
        const nextStart = value.start.minus({ months: 1 }).startOf("month");
        const nextMonthInterval = Interval.fromDateTimes(nextStart, nextStart.endOf("month"));
        onChange(nextMonthInterval);
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                gap: "5px",
            }}
        >
            <Button theme="light" size="sm" icon="previous" onClick={handlePrevMonth} />
            <DateField
                aria-label="Zeitraum-Startdatum"
                style={{ flex: "1" }}
                value={startDate}
                onChange={(dt) => onChange(safeInterval(dt, endDate))}
            />
            <div
                style={{
                    flex: "none",
                    padding: "5px",
                }}
            >
                →
            </div>
            <DateField
                aria-label="Zeitraum-Enddatum"
                style={{ flex: "1" }}
                value={endDate}
                onChange={(dt) => onChange(safeInterval(startDate, dt))}
            />
            <Button theme="light" size="sm" icon="next" onClick={handleNextMonth} />
        </div>
    );
}
