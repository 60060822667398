import * as React from "react";
import { FaceDefaultProps } from "./type";
import DeploymentEditor from "../editors/DeploymentEditor";
import AbsenceEditor from "../editors/AbsenceEditor";
import { NewDeploymentCellPayload } from "../types";
import TabbedContent from "./TabbedContent";
import FaceFrame from "./FaceFrame";
import EmployeeNoteEditor from "../editors/EmployeeNoteEditor";

type NewDeploymentFaceProps = FaceDefaultProps & {
    currentCellPayload: NewDeploymentCellPayload;
    onDeploymentSuccessChanged: (success: boolean) => unknown;
};

export default function NewDeploymentFace(props: NewDeploymentFaceProps): JSX.Element {
    const { busy, onBusyChange, currentCellPayload, onDeploymentSuccessChanged } = props;

    const commonProps = {
        currentCellPayload: currentCellPayload,
        busy: busy,
        onBusyChange: onBusyChange,
        onDeploymentSuccessChanged: onDeploymentSuccessChanged,
    };

    const tabs = {
        deployment: {
            label: "Einsatz",
            element: <DeploymentEditor {...commonProps} />,
        },
        absence: {
            label: "Abwesenheit",
            element: <AbsenceEditor {...commonProps} />,
        },
        employeeNote: {
            label: "Notiz",
            element: <EmployeeNoteEditor {...commonProps} />,
        },
    };

    return (
        <FaceFrame title={"Hinzufügen…"} icon={"new"}>
            <TabbedContent tabs={tabs} initialTab="deployment" />
        </FaceFrame>
    );
}
