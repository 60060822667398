import * as React from "react";
import WithReportFromApi from "./WithReportFromApi";
import FilterBar from "./FilterBar";
import EmployeeRow from "./EmployeeRow";
import EmptyState from "./EmptyState";
import HeaderRow from "./HeaderRow";
import Summary from "./Summary";
import WithUnitsFromApi from "./WithUnitsFromApi";

export default function Report() {
    return (
        <>
            <WithUnitsFromApi>
                {({ data: units }) => (
                    <>
                        <FilterBar units={units} />
                        <WithReportFromApi>
                            {({ data: report }) => (
                                <>
                                    {0 === report.length ? (
                                        <EmptyState />
                                    ) : (
                                        <>
                                            <Summary report={report} />
                                            <div className="AbsenceReport__Results">
                                                <HeaderRow />
                                                {report.map((employeeRow) => (
                                                    <EmployeeRow
                                                        key={employeeRow.employee_id}
                                                        employeeRow={employeeRow}
                                                    />
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </WithReportFromApi>
                    </>
                )}
            </WithUnitsFromApi>
        </>
    );
}
