import * as React from "react";

import FormGroup from "../../../lib/form/form/FormGroup";
import DateSelect from "../../../lib/form/datetime/DateSelect";
import DurationField from "../../../lib/form/datetime/DurationField";
import WeekTimeDistributionFieldSet from "./WeekTimeDistributionFieldSet";
import Form from "../../../lib/form/form/Form";
import WorkTimeSet from "../../../lib/model/WorkTimeSet";
import workTimeSetAdapter from "../../../lib/api/adapters/work-time-set";
import Checkbox from "../../../lib/form/checkbox/Checkbox";
import TextField from "../../../lib/form/text/TextField";

type WorkTimeSetFormProps = {
    workTimeSetId: WorkTimeSet["id"] | null;
    employeeId: string;
    hasAnyTraineeships: boolean;
};

export default function WorkTimeSetForm(props: WorkTimeSetFormProps) {
    const { employeeId, workTimeSetId, hasAnyTraineeships } = props;
    const urlParams = { employeeId, id: workTimeSetId };

    return (
        <Form adapter={workTimeSetAdapter} urlParams={urlParams} className="overflow-hidden d-flex flex-column">
            {({ loading, changes, record, onChange }) => {
                const attrs = { ...record, ...changes };
                const disabled = loading;

                return (
                    <>
                        <FormGroup label="Gültig ab" required={true}>
                            <DateSelect
                                disabled={disabled}
                                value={attrs.starts_on || ""}
                                from="2021-01-01"
                                to="2025-01-01"
                                onChange={(value) => onChange({ starts_on: value })}
                                aria-label="Gültig ab"
                            />
                        </FormGroup>
                        <div className="d-flex">
                            <FormGroup label="Soll-Wochentage" required={true}>
                                <TextField
                                    type="number"
                                    value={attrs.days_per_week ?? ""}
                                    onChange={(days_per_week) => onChange({ days_per_week: parseInt(days_per_week) })}
                                    disabled={disabled}
                                    aria-label="Soll-Wochentage"
                                />
                            </FormGroup>
                        </div>
                        <WeekTimeDistributionFieldSet
                            disabled={disabled}
                            value={{
                                show_schooldays: attrs.show_schooldays ?? (!disabled && hasAnyTraineeships),
                                weekly_seconds_target: attrs.weekly_seconds_target,
                                schoolday_weekly_seconds_target: attrs.schoolday_weekly_seconds_target,
                                auto_distribution: attrs.auto_distribution,
                                reset_time_sheet_balance: attrs.reset_time_sheet_balance,
                                target_seconds_1: attrs.target_seconds_1,

                                work_on_1: attrs.work_on_1,
                                schoolday_target_seconds_1: attrs.schoolday_target_seconds_1,
                                schoolday_work_on_1: attrs.schoolday_work_on_1,

                                target_seconds_2: attrs.target_seconds_2,
                                work_on_2: attrs.work_on_2,
                                schoolday_target_seconds_2: attrs.schoolday_target_seconds_2,
                                schoolday_work_on_2: attrs.schoolday_work_on_2,

                                target_seconds_3: attrs.target_seconds_3,
                                work_on_3: attrs.work_on_3,
                                schoolday_target_seconds_3: attrs.schoolday_target_seconds_3,
                                schoolday_work_on_3: attrs.schoolday_work_on_3,

                                target_seconds_4: attrs.target_seconds_4,
                                work_on_4: attrs.work_on_4,
                                schoolday_target_seconds_4: attrs.schoolday_target_seconds_4,
                                schoolday_work_on_4: attrs.schoolday_work_on_4,

                                target_seconds_5: attrs.target_seconds_5,
                                work_on_5: attrs.work_on_5,
                                schoolday_target_seconds_5: attrs.schoolday_target_seconds_5,
                                schoolday_work_on_5: attrs.schoolday_work_on_5,

                                target_seconds_6: attrs.target_seconds_6,
                                work_on_6: attrs.work_on_6,
                                schoolday_target_seconds_6: attrs.schoolday_target_seconds_6,
                                schoolday_work_on_6: attrs.schoolday_work_on_6,

                                target_seconds_7: attrs.target_seconds_7,
                                work_on_7: attrs.work_on_7,
                                schoolday_target_seconds_7: attrs.schoolday_target_seconds_7,
                                schoolday_work_on_7: attrs.schoolday_work_on_7,
                            }}
                            onChange={(value) => {
                                onChange({ ...value });
                            }}
                        />
                        <FormGroup label="Maximale Wochenstunden" required={true}>
                            <DurationField
                                disabled={disabled}
                                value={attrs.weekly_seconds_maximum}
                                onChange={(value) => onChange({ weekly_seconds_maximum: value })}
                                aria-label="Maximale Wochenstunden"
                            />
                        </FormGroup>
                        <div className="mb-3">
                            <Checkbox
                                caption="Stundenkonto bei Monatsbeginn auf Null setzen"
                                checked={attrs.reset_time_sheet_balance ?? false}
                                onChange={(checked) => onChange({ reset_time_sheet_balance: checked })}
                                disabled={disabled}
                                aria-label="Stundenkonto bei Monatsbeginn auf Null setzen"
                            />
                        </div>
                        <strong>zugehörige Beschäftigungsverhältnisse:</strong>
                        <ul>
                            {undefined === attrs?.employments || attrs.employments.length === 0 ? (
                                <li>Keine</li>
                            ) : (
                                attrs?.employments?.map((employment) => {
                                    return (
                                        <li key={employment.name}>
                                            <a href={employment.url}>{employment.name}</a>
                                        </li>
                                    );
                                })
                            )}
                        </ul>
                    </>
                );
            }}
        </Form>
    );
}
