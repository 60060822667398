import * as React from "react";
import { size, useFloating, UseFloatingReturn, VirtualElement } from "@floating-ui/react";
import { createContext } from "react";

export const FloatingContext = createContext<UseFloatingReturn<Element | VirtualElement>>(null);

type WithFloatingContextProps = {
    children: React.ReactNode;
};

export function WithFloatingContext(props: WithFloatingContextProps) {
    const { children } = props;
    const value = useFloating({
        strategy: "fixed",
        placement: "bottom-start",
        middleware: [
            size({
                apply({ elements }) {
                    elements.floating.style.width = elements.reference.clientWidth + "px";
                },
            }),
        ],
    });
    return <FloatingContext.Provider value={value}>{children}</FloatingContext.Provider>;
}
