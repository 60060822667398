import * as React from "react";
import Panel from "../../../layout/Panel";
import WeeklyBalances from "./WeeklyBalances";
import WeeklyDeploymentListSums from "./WeeklyDeploymentListSums";
import WeeklyDeploymentList from "./WeeklyDeploymentList";
import { PersonalSchedule } from "../../../state/types/personal-schedule";

type WeeklyPanelContentProps = {
    personalSchedule: PersonalSchedule
}

export default function WeeklyPanelContent(props: WeeklyPanelContentProps) {
    const { personalSchedule } = props;

    return (
        <>
            <Panel.SectionTitle text={`KW ${personalSchedule.week} ${personalSchedule.year}`} />
            <Panel.HGroup className="WorkHub__PersonalSchedule__HeaderHGroup">
                <WeeklyBalances personalSchedule={personalSchedule} />
                <Panel.VerticalDivider />
                <WeeklyDeploymentListSums personalSchedule={personalSchedule} />
            </Panel.HGroup>
            <Panel.Divider />
            <WeeklyDeploymentList personalSchedule={personalSchedule} />
        </>
    );
}
