import * as React from "react";
import { FaceDefaultProps } from "./type";
import { NewEmployeeWeekCellPayload } from "../types";
import NewEmployeeWeekEditor from "../editors/NewEmployeeWeekEditor";

type NewDeploymentFaceProps = FaceDefaultProps & {
    currentCellPayload: NewEmployeeWeekCellPayload;
    onDeploymentSuccessChanged: (success: boolean) => unknown;
};

export default function NewDeploymentFace(props: NewDeploymentFaceProps): JSX.Element {
    return (
        <div className="SchedulerNewEmployeeWeekFace">
            <div className="SchedulerNewEmployeeWeekFace__Inner">
                <NewEmployeeWeekEditor
                    busy={props.busy}
                    onBusyChange={props.onBusyChange}
                    currentCellPayload={props.currentCellPayload}
                />
            </div>
        </div>
    );
}
