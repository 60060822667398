import * as React from "react";
import { useRef, useState, useEffect } from "react";
import Button from "../button/Button";

export function CopyStringField({ text, caption }: { text: string; caption: string }) {
    const [copySuccess, setCopySuccess] = useState(false);
    const copyUrlRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        let timeout: number;
        if (copySuccess) {
            timeout = setTimeout(() => {
                setCopySuccess(false);
            }, 2000);
        }
        return () => clearTimeout(timeout);
    }, [copySuccess]);

    const handleCopyUrl = async () => {
        if (copyUrlRef.current) {
            await navigator.clipboard.writeText(copyUrlRef.current.value);
            setCopySuccess(true);
        }
    };

    return (
        <span>
            <input ref={copyUrlRef} type="text" value={text} readOnly style={{ display: "none" }} />
            <Button caption={caption} theme={copySuccess ? "success" : "secondary"} onClick={handleCopyUrl} />
        </span>
    );
}
