import { State } from "../../../../state";
import * as React from "react";
import { useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {
    defaultCartesianGridProps,
    defaultTooltipContentStyles,
    defaultTooltipLabelStyles,
    defaultXAxisStyles,
    defaultYAxisStyles,
} from "../../../../../../../lib/chart/properties";
import { CustomizedAxisTick } from "./CustomizedAxisTick";
import { formatHours } from "../../../../../../../lib/datetime/datetime";
import { CustomLabel } from "./CustomLabel";
import { colors } from "../../../../../../../lib/theme/colors";
import absenceReasonTranslations from "./reason-category-translations";

export function AbsenceReasonsPlot({
    absentHoursByReasonCategory,
}: {
    absentHoursByReasonCategory: State["absences"]["statistics"]["absentHoursByReasonCategoryPastYear"];
}) {
    const data = useMemo(() => {
        return absentHoursByReasonCategory.map((stat) => ({
            category: stat.category,
            hours: Math.round(stat.absentHours * 100) / 100,
            credited: stat.credited,
            absenceRate: stat.absenceRate,
        }));
    }, [absentHoursByReasonCategory]);

    if (data.length === 0) {
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height={200}>
            <BarChart
                data={data}
                margin={{
                    top: 0,
                    right: 120,
                    bottom: 0,
                    left: 0,
                }}
                layout="vertical"
            >
                <CartesianGrid {...defaultCartesianGridProps} vertical={true} />
                <XAxis style={defaultYAxisStyles} type="number" />
                <YAxis
                    dataKey="category"
                    type="category"
                    label={{ position: "center" }}
                    style={defaultXAxisStyles}
                    interval={0}
                    width={150}
                    tick={<CustomizedAxisTick />}
                />
                <Tooltip
                    cursor={false}
                    contentStyle={defaultTooltipContentStyles}
                    labelStyle={defaultTooltipLabelStyles}
                    formatter={(value, name, _props) => {
                        return [`${formatHours(value as number)} h`, name];
                    }}
                    labelFormatter={(label) => {
                        return absenceReasonTranslations[label] ?? label;
                    }}
                />
                <Bar
                    dataKey="hours"
                    name="Stunden"
                    radius={[0, 4, 4, 0]}
                    maxBarSize={100}
                    barSize={15}
                    label={<CustomLabel data={data} />}
                >
                    {data.map((entry, index) => {
                        return (
                            <Cell
                                key={`cell-${index}`}
                                fill={entry.credited ? colors.brixIndigo400 : colors.brixIndigo200}
                            />
                        );
                    })}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
