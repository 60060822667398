import React from "react";
import Cell from "../cell";
import cellWidths from "../cell-widths";
import TextPlaceholder from "../text-placeholder";

export default function MonthPlaceholderRow() {
    return (
        <tr>
            <Cell center={true} style={{ width: cellWidths.employee, height: "48px" }}>
                <TextPlaceholder text="Mustermann, Manfred" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
        </tr>
    );
}
