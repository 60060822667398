import * as React from "react";
import { useContext } from "react";
import { DateTime, WeekdayNumbers } from "luxon";
import { PublicHolidaysInWeek } from "../slots/types";
import { ScheduleDailyNote } from "./ScheduleDailyNote";
import { PublicHolidayNote } from "./PublicHolidayNote";
import { ScheduleDailyNotesContext } from "../slots/SlotsController";

type DayHeaderContentProps = {
    dow: WeekdayNumbers;
    scheduleStartsOn: DateTime | null;
    publicHolidays: PublicHolidaysInWeek;
};

export function DayHeaderContent(props: DayHeaderContentProps) {
    const { dow, scheduleStartsOn, publicHolidays } = props;
    const dt = scheduleStartsOn ? scheduleStartsOn.plus({ days: dow - 1 }) : null;
    const scheduleDailyNotes = useContext(ScheduleDailyNotesContext);

    return (
        <>
            <div className="SchedulerHeader__DowName">{dt && dt.toLocaleString({ weekday: "long" })}</div>
            <div className="SchedulerHeader__DowDom">{dt && dt.toFormat("dd.MM")}</div>
            <div className="SchedulerHeader__DailyNotes">
                {publicHolidays &&
                    publicHolidays[dow].map((publicHolidayName) => (
                        <PublicHolidayNote name={publicHolidayName} key={publicHolidayName} />
                    ))}

                {scheduleDailyNotes[dow].map((note) => (
                    <ScheduleDailyNote note={note} key={note.id} />
                ))}
            </div>
        </>
    );
}
