import * as React from "react";
import { MutableRefObject, useEffect, useRef, useState } from "react";

type TimeSpanProps = {
    disabled?: boolean;
    autoFocus?: boolean;
    onChange?: (time: string) => void;
    onSubmit: (time: string) => void;
    time: string;
    hoursRef?: { current: HTMLInputElement };
    minutesRef?: { current: HTMLInputElement };
    prevRef?: { current: HTMLInputElement };
    nextRef?: { current: HTMLInputElement };
    className?: string;
    ariaLabelPrefix?: string;
};

export type CursorInput = {
    hours?: string;
    minutes?: string;
};

function extractPartsOfTimes(time) {
    if (time) {
        const [hours, minutes] = time.split(":");

        return {
            hours: hours || "",
            minutes: minutes || "",
        };
    } else {
        return {
            hours: "",
            minutes: "",
        };
    }
}

let timeoutId;

function debounce(fn) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, 250);
}

export default function TimeSpan(props: TimeSpanProps) {
    const {
        disabled = false,
        autoFocus = false,
        time,
        onChange,
        onSubmit,
        hoursRef,
        minutesRef,
        prevRef,
        nextRef,
        className,
        ariaLabelPrefix,
    } = props;

    const innerHoursRef = hoursRef || useRef<HTMLInputElement>();
    const innerMinutesRef = minutesRef || useRef<HTMLInputElement>();
    const controls = [prevRef, innerHoursRef, innerMinutesRef, nextRef];

    const [input, setInput] = useState<CursorInput>(extractPartsOfTimes(time));
    useEffect(() => setInput(extractPartsOfTimes(time)), [time]);

    const formatTimePart = (rest) => {
        if (rest.length == 0) {
            return "";
        } else if (rest.length == 1) {
            return rest;
        } else if (rest.length == 2) {
            return rest;
        } else {
            return rest.slice(-2);
        }
    };

    const buildTime = () => {
        const time = `${innerHoursRef.current.value}:${formatTimePart(innerMinutesRef.current.value)}`;
        return time;
    };

    const handleKeyUp = (keyEvent) => {
        if (disabled) {
            return;
        }
        if (keyEvent.key === "Enter") {
            debounce(() => {
                const time = buildTime();

                // I don't comprehend why but Cursor manages to catch the ENTER key event that triggers
                // it being rendered in first place. This in turn causes the initial input to be committed
                // right away.
                //
                // This appears to be related to autoFocus=true on an input element (when removed the event
                // is not triggered).
                //
                // Nontheless, it apparently doesn't make sense since Cursor shouldn't exist the moment the
                // event is fired.
                //
                // Only submit when there were actual changes: This prevents the twice ENTER bug to trigger.
                if (time != props.time) {
                    onSubmit(time);
                }
            });
        } else if (keyEvent.key === ":") {
            let idx = 0;
            for (; idx < controls.length; idx++) {
                if (controls[idx] && controls[idx].current === keyEvent.target) {
                    break;
                }
            }
            idx++;
            if (idx < controls.length) {
                controls[idx] && controls[idx].current.focus();
            }
        }
    };

    const createHandleChange = (
        property: keyof CursorInput,
        nextRef?: MutableRefObject<unknown>,
        prevRef?: MutableRefObject<unknown>
    ) => {
        return (event) => {
            if (disabled) {
                return;
            }

            const value = event.currentTarget.value.replace(/\D/g, "").substring(0, 2);
            setInput({ ...input, [property]: value });

            const time = buildTime();
            if (onChange && time != props.time) {
                onChange(time);
            }

            const withMinus = time[0] == "-";
            if (
                nextRef &&
                event.currentTarget.value.length >= (withMinus ? 3 : 2) &&
                ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].indexOf(event.nativeEvent.data) >= 0
            ) {
                (nextRef.current as HTMLElement).focus();
            } else if (prevRef && event.currentTarget.value.length === 0 && null === event.nativeEvent.data) {
                (prevRef.current as HTMLElement).focus();
            }
        };
    };

    useEffect(() => {
        const innerHoursRefTarget = innerHoursRef.current;
        const innerMinutesRefTarget = innerMinutesRef.current;

        setTimeout(() => {
            innerHoursRefTarget.addEventListener("keyup", handleKeyUp);
            innerMinutesRefTarget.addEventListener("keyup", handleKeyUp);
        }, 150);

        return () => {
            innerHoursRefTarget.removeEventListener("keyup", handleKeyUp);
            innerMinutesRefTarget.removeEventListener("keyup", handleKeyUp);
        };
    }, [innerHoursRef.current, innerMinutesRef.current]);

    return (
        <span className={className}>
            <input
                disabled={disabled}
                ref={innerHoursRef}
                className="SchedulerCursor__TimeControl SchedulerCursor__HoursControl"
                type="text"
                min="0"
                max="23"
                placeholder="hh"
                autoFocus={autoFocus}
                onChange={createHandleChange("hours", innerMinutesRef, prevRef)}
                value={input.hours}
                aria-label={ariaLabelPrefix + " Stunden"}
            />
            <span>:</span>
            <input
                disabled={disabled}
                ref={innerMinutesRef}
                className="SchedulerCursor__TimeControl SchedulerCursor__MinutesControl"
                type="text"
                min="0"
                max="59"
                placeholder="mm"
                onChange={createHandleChange("minutes", nextRef, innerHoursRef)}
                value={input.minutes}
                aria-label={ariaLabelPrefix + " Minuten"}
            />
        </span>
    );
}
