import Chrome from "../../lib/chrome/Chrome";
import * as React from "react";
import { useReportState } from "./StateContextProvider";
import IntervalPickerField from "../../lib/form/interval-picker/IntervalPickerField";
import { Interval } from "luxon";
import { useReportEffects } from "./EffectsContextProvider";
import ComboBox from "../../lib/form/select/ComboBox";
import { dayNumbers, humanDay } from "../../lib/datetime/datetime";
import { DayOfWeek } from "../../lib/datetime/types";

export function Header() {
    const { employee } = useReportState();

    const { startsAt, endsAt, weekDays } = useReportState();
    const { setDateRange, setWeekDays } = useReportEffects();

    const handleTimeRangeChange = (newInterval: Interval) => {
        setDateRange(newInterval.start, newInterval.end);
    };

    const weekDayOptions = {
        "Alle": dayNumbers.map(day => ({
            label: humanDay(day),
            value: day
        }))
    };

    return (
        <Chrome.DetailPage.ActionBar split>
            <div className="d-flex gap-2 align-items-start">
                {startsAt && endsAt && <div style={{ flex: "0 0 200px" }}>
                    <IntervalPickerField
                        aria-label="Zeitraumsauswahl"
                        value={Interval.fromDateTimes(startsAt, endsAt)}
                        onChange={handleTimeRangeChange}
                    />
                </div>}
                <div style={{ flex: "0 0 400px" }}>
                    <ComboBox
                        allLabel="Wochentage"
                        multiselect={true}
                        value={weekDays}
                        groupedOptions={weekDayOptions}
                        onChange={(weekDays: DayOfWeek[]) => setWeekDays(weekDays)}
                    />
                </div>
            </div>
            <div>
                <a href={employee?.logUrl} className="btn btn-primary btn-sm">
                    Zeiterfassungs-Log
                </a>
            </div>
        </Chrome.DetailPage.ActionBar>
    );
}
