import * as React from "react";
import { DataTableColumn, DataTableRow } from "./types";
import DataTableHeader from "./DataTableHeader";

type DataTableHeaderProps = {
    columns: DataTableColumn[];
    totals: DataTableRow;
};

export default function DataTableTotals(props: DataTableHeaderProps) {
    const { columns, totals } = props;
    return (
        <>
            <DataTableHeader columns={columns} />
            <tfoot style={{ display: "contents" }}>
                <tr style={{ display: "contents" }}>
                    {columns.map((column) => (
                        <td
                            key={column.name}
                            style={{ textAlign: column.align ?? "left" }}
                            className="bg-primary text-white px-3 py-1 border-top fw-bold"
                        >
                            {column.totalValue
                                ? column.totalValue(totals[column.name], totals)
                                : column.value(totals[column.name], totals)}
                        </td>
                    ))}
                </tr>
            </tfoot>
        </>
    );
}
