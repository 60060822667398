import * as React from "react";
import { conditionalClassNames, joinClasses } from "../dom-utils/class-names";
import Icon from "../icon/Icon";
import Masonry from "react-masonry-css";

export default function Kitchensink(props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    const classNames = joinClasses("Kitchensink", divProps.className);

    const breakpointColumnsObj = {
        default: 2,
        1100: 3,
        500: 1,
    };

    return (
        <Masonry
            {...divProps}
            className={classNames}
            columnClassName="Kitchensink__Column"
            breakpointCols={breakpointColumnsObj}
        >
            {children}
        </Masonry>
    );
}

export function KitchensinkItem(
    props: {
        wide?: boolean;
        children: React.ReactNode;
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const { children, wide = false, ...divProps } = props;
    divProps.className = joinClasses(
        ...conditionalClassNames({
            Kitchensink__Item: true,
            "Kitchensink__Item--wide": wide,
        }),
        divProps.className
    );
    return <div {...divProps}>{children}</div>;
}

export function KitchensinkItemBodyTitle({ children }: { children: React.ReactNode }) {
    return <div className="Kitchensink__Item__Body__Title">{children}</div>;
}

export function KitchensinkItemBodyTitleAddendum({ children }: { children: React.ReactNode }) {
    return <div className="Kitchensink__Item__Body__Title__Addendum">{children}</div>;
}

export function KitchensinkItemAction({ href }: { href: string }) {
    return (
        <a href={href || "#"} className="Button Button--ghost">
            <span className="text-primary">
                <Icon type="edit" />
            </span>{" "}
            Verwalten
        </a>
    );
}

export function KitchensinkItemBody({ children }: { children: React.ReactNode }) {
    return <div className="Kitchensink__Item__Body">{children}</div>;
}

export function KitchensinkItemBodyPart(props: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("Kitchensink__Item__Body__Part", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function KitchensinkItemBodyPartHeadline({ children }: { children: React.ReactNode }) {
    return <div className="Kitchensink__Item__Body__Part__Headline">{children}</div>;
}

export function KitchensinkItemBodyLinkList({ children }: { children: React.ReactNode }) {
    return <div className="Kitchensink__Item__Body__LinkList">{children}</div>;
}

export function KitchensinkItemActions({ children }: { children: React.ReactNode }) {
    return <div className="Kitchensink__Item__Actions">{children}</div>;
}

Kitchensink.Item = KitchensinkItem;
Kitchensink.ItemBody = KitchensinkItemBody;
Kitchensink.ItemBodyTitle = KitchensinkItemBodyTitle;
Kitchensink.ItemBodyTitleAddendum = KitchensinkItemBodyTitleAddendum;
Kitchensink.ItemBodyPart = KitchensinkItemBodyPart;
Kitchensink.ItemBodyPartHeadline = KitchensinkItemBodyPartHeadline;
Kitchensink.ItemBodyLinkList = KitchensinkItemBodyLinkList;
Kitchensink.ItemActions = KitchensinkItemActions;
Kitchensink.ItemAction = KitchensinkItemAction;
