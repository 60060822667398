import * as React from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";
import Layout from "./chrome/Layout";
import Report from "./report/Report";

export default function AppRoutes() {
    return (
        <RouterRoutes>
            <Route element={<Layout />}>
                <Route index element={<Report />} />
                <Route path="*" element={<Report />} />
            </Route>
        </RouterRoutes>
    );
}
