export type EmployeesApiResponse = {
    data: {
        id: string;
        name: string;
    }[];
};

export async function fetchEmployees(): Promise<EmployeesApiResponse> {
    return fetch("/api/shortcuts/shared/employees").then((response) => {
        if (!response.ok && response.status !== 422) {
            throw Error("Failed to load employees: " + response.statusText);
        }
        return response.json();
    });
}
