import consumer from "../../channels/consumer";

export async function loadUnits() {
    const response = await fetch("/units", { headers: { Accept: "application/json" } });
    return await response.json();
}

export async function loadSlotsForWeek(year, week, unitId) {
    const url = new URL(`${window.location.origin}/api/time_sheets/${unitId}/${year}/w${week}/slots`);
    const response = await fetch(url, { headers: { Accept: "application/json" } });
    return await response.json();
}

export async function loadSlotsForMonth(year, month, unitId) {
    const url = new URL(`${window.location.origin}/api/time_sheets/${unitId}/${year}/${month}/slots`);
    const response = await fetch(url, { headers: { Accept: "application/json" } });
    return await response.json();
}

export async function buildTimeSheet({ employeeId, unitId, year, week }) {
    const url = new URL(`${window.location.origin}/api/time_sheets/${unitId}/${year}/w${week}/slots/${employeeId}`);
    const response = await fetch(url, {
        method: "post",
        headers: { Accept: "application/json" },
    });
    return await response.json();
}

export function subscribeToTimeSheetBuildStatus({ employeeId, unitId, year, week }, mixin) {
    return consumer.subscriptions.create(
        {
            channel: "TimeSheets::BuildStatusChannel",
            employee_id: employeeId,
            unit_id: unitId,
            year,
            week,
        },
        mixin
    );
}

export async function updateMonthAdjustment(employeeId, unitId, year, month, adjustment) {
    const url = new URL(
        `${window.location.origin}/api/time_sheets/${unitId}/${year}/${month}/adjustment/${employeeId}`
    );
    const response = await fetch(url, {
        method: "post",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({ adjustment: { human_value: adjustment } }),
    });

    if (response.ok || 422 === response.status) {
        return await response.json();
    } else {
        return { errors: { base: [response.statusText] } };
    }
}
