import * as React from "react";
import { useRef, useState } from "react";
import Loading from "../empty-state/Loading";
import { LoadingError } from "../errors/LoadingError";
import Icon from "../icon/Icon";
import Markdown from "react-markdown";
import { conditionalClassNames } from "../dom-utils/class-names";
import { autoUpdate, FloatingPortal, useFloating } from "@floating-ui/react";
import { useOutsideClickHandler } from "../dom-utils/useOutsideClickHandler";

type ContextualInfoTooltipProps = {
    manualKey: string;
};

export default function ContextualInfo(props: ContextualInfoTooltipProps) {
    const { manualKey } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [document, setDocument] = useState<string | null>(null);

    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        whileElementsMounted: autoUpdate,
    });

    const innerRef = useRef(null);

    useOutsideClickHandler(innerRef, () => {
        setExpanded(false);
    });

    React.useEffect(() => {
        if (!document && manualKey && expanded) {
            setLoading(true);
            fetch(`/api/manual/contextual_infos/${manualKey}`)
                .then((response) => response.json())
                .then((json) => {
                    setDocument(json.data.document);
                    setLoading(false);
                    setError(null);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [manualKey, expanded]);

    const toggleClassNames = conditionalClassNames({
        ContextualInfoToggle: true,
        "ContextualInfoToggle--loading": loading,
        "ContextualInfoToggle--error": null !== error,
        "ContextualInfoToggle--expanded": expanded,
    }).join(" ");

    const panelClassNames = conditionalClassNames({
        ContextualInfoPanel: true,
        "ContextualInfoPanel--loading": loading,
        "ContextualInfoPanel--error": null !== error,
        "ContextualInfoPanel--expanded": expanded,
    }).join(" ");

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div className={toggleClassNames} ref={refs.setReference} onClick={handleToggle}>
                <Icon type={error ? "error" : "contextual-info"} />
            </div>

            <FloatingPortal>
                <div className={panelClassNames} ref={refs.setFloating} style={floatingStyles}>
                    <div className="ContextualInfoPanel__Inner" ref={innerRef}>
                        {error && <LoadingError message={error.message} />}
                        {loading && (
                            <div className="ContextualInfoPanel__Loading">
                                <Loading theme="teal" loadingText="Suche Erläuterung…" />
                            </div>
                        )}
                        <div className="ContextualInfoPanel__Body">
                            {null !== document && <Markdown>{document}</Markdown>}
                        </div>
                    </div>
                </div>
            </FloatingPortal>
        </>
    );
}
