import * as React from "react";

import { DeploymentSlot, Unit } from "./types";
import DeploymentSlotRow from "./slots/deployment-slot/DeploymentSlotRow";
import CursorNavigation from "./cursor/CursorNavigation";
import { DeploymentSequenceMap, EmployeeAllocations, EmployeeWithAbsentDays } from "./slots/types";
import Allocations from "./Allocations";
import Absences from "./Absences";

type BodyProps = {
    slots: DeploymentSlot[];
    deploymentSequences: DeploymentSequenceMap;
    allocations: EmployeeAllocations[];
    unit: Unit | null;
    absentEmployees: EmployeeWithAbsentDays[];
};

export default function Body(props: BodyProps) {
    const { slots, deploymentSequences, allocations, unit, absentEmployees } = props;
    return (
        <div className="SchedulerBody">
            <div className="SchedulerBody__Inner">
                {allocations && <Allocations allocations={allocations} unit={unit} />}
                {absentEmployees && <Absences absentEmployees={absentEmployees} />}
                <CursorNavigation slots={slots} deploymentSequences={deploymentSequences}>
                    {({ setCursorAnchorElement }) => {
                        let renderRow = 0;

                        if (slots.length === 0) {
                            return (
                                <div className="SchedulerBody__EmptyMessage">
                                    <div>
                                        <i className="bi bi-question-circle" /> Noch keine Planungsbereiche vorhanden
                                    </div>
                                </div>
                            );
                        }

                        return (
                            <>
                                {slots.map((slot) => {
                                    const res = (
                                        <DeploymentSlotRow
                                            renderRow={renderRow++}
                                            setCursorAnchorElement={setCursorAnchorElement}
                                            slot={slot}
                                            key={slot.department.name}
                                        />
                                    );
                                    renderRow += slot.employee_weeks.length;
                                    return res;
                                })}
                            </>
                        );
                    }}
                </CursorNavigation>
            </div>
        </div>
    );
}
