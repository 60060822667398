import * as React from "react";
import Icon, { IconType } from "../../../../lib/icon/Icon";

type FaceFrameProps = {
    title: string;
    icon?: IconType;
    widgets?: JSX.Element;
    children: JSX.Element;
};

export default function FaceFrame({ title, icon = null, widgets, children }: FaceFrameProps): JSX.Element {
    return (
        <div className="SchedulerCursor__Face">
            <div className="SchedulerCursor__Face__TopBar">
                <div className="SchedulerCursor__Face__TopBar__Title">
                    {icon && <Icon type={icon} />}
                    <span>{title}</span>
                </div>
                {widgets && <div className="SchedulerCursor__Face__TopBar__Widgets">{widgets}</div>}
            </div>
            <div className="SchedulerCursor__Face__Content">{children}</div>
        </div>
    );
}
