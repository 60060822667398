import * as React from "react";
import AbsenceBar from "../../../../AbsenceBar";
import { IntraDayRange } from "../../../../types";

type SchoolAttendanceProps = {
    range: IntraDayRange;
};

export default function SchoolAttendance(props: SchoolAttendanceProps): JSX.Element {
    const { range } = props;

    return <AbsenceBar theme="school" message={`Schule ${range.start_time} - ${range.end_time}`} />;
}
