import * as React from "react";
import { CustomItemColor } from "../../apps/scheduler/types";
import { useRef, useState } from "react";
import { useOutsideClickHandler } from "../dom-utils/useOutsideClickHandler";
import ColorDot from "./ColorDot";

type DeploymentColorPickerProps = {
    onChange: (color: CustomItemColor | null) => unknown;
    value: CustomItemColor;
};

export default function CustomItemColorPicker(props: DeploymentColorPickerProps) {
    const { value, onChange } = props;

    const [expanded, setExpanded] = useState(false);

    const colorListRef = useRef(null);

    useOutsideClickHandler(colorListRef, () => {
        setExpanded(false);
    });

    const handlePickColor = (color) => {
        setExpanded(false);
        onChange(color);
    };

    return (
        <div className="DeploymentColorPicker" ref={colorListRef}>
            <ColorDot
                type={value}
                onClick={(e) => {
                    e.stopPropagation();
                    setExpanded(!expanded);
                }}
            />
            {expanded && (
                <div className="DeploymentColorPicker__ColorList">
                    <ColorDot type="default" onClick={() => handlePickColor("default")} />
                    <ColorDot type="red" onClick={() => handlePickColor("red")} />
                    <ColorDot type="yellow" onClick={() => handlePickColor("yellow")} />
                    <ColorDot type="green" onClick={() => handlePickColor("green")} />
                    <ColorDot type="cyan" onClick={() => handlePickColor("cyan")} />
                    <ColorDot type="blue" onClick={() => handlePickColor("blue")} />
                    <ColorDot type="purple" onClick={() => handlePickColor("purple")} />
                </div>
            )}
        </div>
    );
}
