import * as React from "react";
import { useEffect, useState } from "react";

import FormGroup from "../../../lib/form/form/FormGroup";
import Form from "../../../lib/form/form/Form";
import Select from "../../../lib/form/select/Select";
import Skill from "../../../lib/model/Skill";
import skillAdapter from "../../../lib/api/adapters/skill";
import TextField from "../../../lib/form/text/TextField";
import Icon from "../../../lib/model/Icon";
import SkillIcon, { SkillIconType } from "../../../lib/icon/SkillIcon";

type SkillFormProps = {
    skillId: Skill["id"] | null;
};

export default function SkillForm(props: SkillFormProps) {
    const { skillId } = props;
    const urlParams = { id: skillId };

    const [icons, setIcons] = useState<{ [key: string]: string }>(null);

    useEffect(() => {
        fetch("/api/skills/icons")
            .then((response) => response.json())
            .then((icons: Icon[]) => {
                setIcons(
                    icons.reduce(
                        (memo, icon) => ({
                            ...memo,
                            [icon.name]: icon.description,
                        }),
                        {}
                    )
                );
            });
    }, []);

    return (
        <Form adapter={skillAdapter} urlParams={urlParams} className="overflow-hidden d-flex flex-column">
            {({ loading, changes, record, onChange }) => {
                const attrs = { ...record, ...changes };
                const disabled = loading;

                return (
                    <>
                        <div className="d-flex gap-2">
                            <FormGroup label="Name" required={true}>
                                <TextField
                                    type="text"
                                    value={attrs.name ?? ""}
                                    onChange={(name) => onChange({ name })}
                                    disabled={disabled}
                                    aria-label="Name"
                                />
                            </FormGroup>
                            <FormGroup label="Icons" required={true}>
                                {icons ? (
                                    <Select
                                        aria-label="Icon"
                                        autoFocus={true}
                                        options={icons}
                                        includeBlank={true}
                                        blankName={"- Bitte wählen -"}
                                        value={attrs.icon ?? ""}
                                        onChange={(icon) => onChange({ icon: icon as SkillIconType })}
                                        disabled={false}
                                    />
                                ) : (
                                    <div>loading icons...</div>
                                )}
                            </FormGroup>
                            {attrs?.icon && (
                                <span style={{ paddingLeft: "1rem", fontSize: "3rem" }}>
                                    <SkillIcon type={attrs.icon} />
                                </span>
                            )}
                        </div>
                    </>
                );
            }}
        </Form>
    );
}
