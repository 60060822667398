import SkillIcon, { SkillIconType } from "../icon/SkillIcon";
import * as React from "react";

type SkillListProps = {
    name: string;
    level: number;
    icon: SkillIconType;
};
export default function SkillLevelBadge(props: SkillListProps) {
    const { name, level, icon } = props;

    return (
        <div className={"SkillLevelBadge"}>
            <SkillIcon className="SkillLevelBadge__Icon" type={icon as SkillIconType} />
            <div className="SkillLevelBadge__Name">{name}</div>
            <div className="SkillLevelBadge__Level">{level}</div>
        </div>
    );
}
