import * as React from "react";

import FormGroup from "../../../lib/form/form/FormGroup";
import DateSelect from "../../../lib/form/datetime/DateSelect";
import Form from "../../../lib/form/form/Form";
import SchoolTimeSet from "../../../lib/model/SchoolTimeSet";
import schoolTimeSetAdapter from "../../../lib/api/adapters/school-time-set";
import SchoolWeekFieldSet from "./SchoolWeekFieldSet";

type SchoolTimeSetFormProps = {
    schoolTimeSetId: SchoolTimeSet["id"] | null;
    employeeId: string;
};

export default function SchoolTimeSetForm(props: SchoolTimeSetFormProps) {
    const { employeeId, schoolTimeSetId } = props;
    const urlParams = { employeeId, id: schoolTimeSetId };

    return (
        <Form adapter={schoolTimeSetAdapter} urlParams={urlParams} className="overflow-hidden d-flex flex-column">
            {({ loading, changes, record, onChange }) => {
                const attrs = { ...record, ...changes };
                const disabled = loading;

                return (
                    <>
                        <FormGroup label="Gültig ab" required={true}>
                            <DateSelect
                                disabled={disabled}
                                value={attrs.starts_on || ""}
                                from="1999-01-01"
                                to="2030-01-01"
                                onChange={(value) => onChange({ starts_on: value })}
                                aria-label="Gültig ab"
                            />
                        </FormGroup>
                        <FormGroup label="Endet ab" required={true}>
                            <DateSelect
                                disabled={disabled}
                                value={attrs.ends_on || ""}
                                from="1999-01-01"
                                to="2030-01-01"
                                onChange={(value) => onChange({ ends_on: value })}
                                aria-label="Endet ab"
                            />
                        </FormGroup>
                        <SchoolWeekFieldSet value={attrs} disabled={disabled} onChange={onChange} />
                    </>
                );
            }}
        </Form>
    );
}
