import Metric from "../../../Metric";
import Manual from "../../../../../../lib/manual/Manual";
import { MetricValueContentSplitByCreditType } from "./MetricValueContentSplitByCreditType";
import { formatNumber } from "../../../../../../lib/number/number";
import { formatHours } from "../../../../../../lib/datetime/datetime";
import * as React from "react";

export function AbsenceStats(props: {
    creditedRate: number;
    uncreditedRate: number;
    creditedHours: number;
    uncreditedHours: number;
}) {
    const { creditedRate, uncreditedRate, creditedHours, uncreditedHours } = props;

    return (
        <div className="EmployeeManager__Factsheet__Absences__MetricsList">
            <Metric align="center">
                <Metric.Label>
                    <div className="d-flex gap-1 align-items-baseline">
                        <span>Abwesenheitsrate</span>
                        <Manual.ContextualInfo manualKey="factsheet/absence_rate" />
                    </div>
                </Metric.Label>
                <Metric.Value>
                    <MetricValueContentSplitByCreditType
                        creditedValue={`${formatNumber(creditedRate * 100)} %`}
                        uncreditedValue={`${formatNumber(uncreditedRate * 100)} %`}
                    />
                </Metric.Value>
            </Metric>
            <Metric align="center">
                <Metric.Label>
                    <div className="d-flex gap-1 align-items-baseline">
                        <span>Abwesenheitsdauer</span>
                        <Manual.ContextualInfo manualKey="factsheet/absence_duration" />
                    </div>
                </Metric.Label>
                <Metric.Value>
                    <MetricValueContentSplitByCreditType
                        creditedValue={`${formatHours(creditedHours)} h`}
                        uncreditedValue={`${formatHours(uncreditedHours)} h`}
                    />
                </Metric.Value>
            </Metric>
        </div>
    );
}
