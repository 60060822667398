import * as React from "react";
import { useRecentSchedules } from "./useRecentSchedules";
import { DateTime } from "luxon";
import { ScheduleWidgetContent } from "./SchedulesWidgetContent";

export function NextWeekSchedules() {
    const { schedulesNextWeek, loading, loadingFailed } = useRecentSchedules();

    const nextIsoWeek = DateTime.local().plus({ weeks: 1 }).toFormat("kkkk 'KW' W");

    return (
        <ScheduleWidgetContent
            title="Nächste Wochenpläne"
            weekName={nextIsoWeek}
            schedules={schedulesNextWeek}
            loading={loading}
            loadingFailed={loadingFailed}
        />
    );
}
