import * as React from "react";

type EmptyListMessageProps = {
    text?: string;
};

export default function EmptyListMessage(props: EmptyListMessageProps) {
    const { text = "Keine Einträge gefunden" } = props;
    return <div className="EmptyListMessage">{text}</div>;
}
