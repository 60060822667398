import { timeStringToObject } from "../datetime/time";

export type TimeObject = { hour: number; minute: number };
export type DeploymentTime = [string, string];
export type DeploymentTimeAsObjects = [TimeObject, TimeObject];

export type Hour =
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23;

type HourState = "partial" | "full" | null;

type HourStateMap = {
    [key in Hour]: HourState;
};

export function getStatesByHour(sequences: DeploymentTimeAsObjects[]): HourStateMap {
    const map = new Array(24).fill(null).reduce((acc, _, index) => {
        acc[index] = null;
        return acc;
    }, {});

    for (let hour = 0; hour < 24; hour++) {
        sequences.forEach((sequence) => {
            const [start, end] = sequence;

            const sequenceEndsBeforeHour = end.hour < hour;
            const sequenceStartsAfterHour = start.hour > hour;
            const sequenceStartsWithinHour = start.hour === hour && start.minute !== 0;
            const sequenceEndsWithinHour = end.hour === hour && end.minute !== 0;
            const sequenceEndsOnBeginningOfHour = end.hour === hour && end.minute === 0;

            if (sequenceEndsOnBeginningOfHour) {
                return;
            }

            if (sequenceEndsBeforeHour) {
                return;
            }

            if (sequenceStartsAfterHour) {
                return;
            }

            if (sequenceStartsWithinHour || sequenceEndsWithinHour) {
                map[hour] = "partial";
            } else {
                map[hour] = "full";
            }
        });
    }
    return map;
}

export function deploymentTimesToObjects(deploymentTimes: DeploymentTime[]): DeploymentTimeAsObjects[] {
    return deploymentTimes.map((deploymentTime) => {
        const start = timeStringToObject(deploymentTime[0]);
        const end = timeStringToObject(deploymentTime[1]);
        return [
            { hour: start.hours, minute: start.minutes },
            { hour: end.hours, minute: end.minutes }
        ];
    });
}

export function compactDeploymentTimeObjects(deploymentTimes: DeploymentTimeAsObjects[]): DeploymentTimeAsObjects[] {
    let lastStart = null;
    let lastEnd = null;
    const result: DeploymentTimeAsObjects[] = [];

    // Sort deploymentTimes by property [0].hour, then by [0].minute
    const sortedDeploymentTimes = sortDeploymentTimes(deploymentTimes);

    for (let i = 0; i < sortedDeploymentTimes.length; i++) {
        const [start, end] = sortedDeploymentTimes[i];

        if (!lastEnd) {
            lastStart = start;
            lastEnd = end;
        } else {
            if (lastEnd.hour === start.hour && lastEnd.minute === start.minute) {
                lastEnd = end;
            } else {
                result.push([lastStart, lastEnd]);
                lastStart = start;
                lastEnd = end;
            }
        }
    }

    if (lastStart && lastEnd) {
        result.push([lastStart, lastEnd]);
    }

    return result;
}

export function sortDeploymentTimes(deploymentTimes: DeploymentTimeAsObjects[]): DeploymentTimeAsObjects[] {
    return deploymentTimes.concat().sort((a, b) => {
        if (a[0].hour < b[0].hour) {
            return -1;
        } else if (a[0].hour > b[0].hour) {
            return 1;
        } else {
            if (a[0].minute < b[0].minute) {
                return -1;
            } else if (a[0].minute > b[0].minute) {
                return 1;
            } else {
                return 0;
            }
        }
    });
}

export const firstBusinessHour = 4;
export const lastBusinessHour = 22;
