import * as React from "react";
import { InputHTMLAttributes } from "react";
import TimeField from "./TimeField";

type DurationFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    value: string | number;
    onChange: (value: string) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    disabled?: boolean;
    readOnly?: boolean;
};

const DurationField = (props: DurationFieldProps) => {
    const { value, onChange, onKeyDown = null, disabled = false, readOnly = false, ...otherProps } = props;

    return (
        <>
            <div
                className="d-inline-flex align-items-center gap-2"
                style={{ width: "auto" }}
                role="group"
                {...otherProps}
            >
                <TimeField {...{ value, onChange, onKeyDown, disabled, readOnly }} />
                <div className="text-muted">Stunden</div>
            </div>
        </>
    );
};

DurationField.displayName = "DurationField";

export default DurationField;
