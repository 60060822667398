import * as React from "react";
import { HTMLAttributes } from "react";
import Icon from "../icon/Icon";

type SelectorButtonProps = HTMLAttributes<HTMLDivElement>;

export default function SelectorButton(props: SelectorButtonProps) {
    const { ...iconProps } = props;

    return (
        <Icon
            {...iconProps}
            type="select"
            className="text-secondary"
            style={{ position: "absolute", top: "calc(50% - 11px)", right: "5px", width: "22px", height: "22px" }}
        />
    );
}
