import { useEffect } from "react";
import useWorkHubState from "../state/useWorkHubState";
import useWorkHubDispatch from "../state/useWorkHubDispatch";

export default function useAutomaticUILock() {
    const {
        ui: { lockAt: uiLockAt }
    } = useWorkHubState();

    const dispatch = useWorkHubDispatch();

    useEffect(() => {
        if (uiLockAt) {
            const interval = setInterval(() => {
                if (uiLockAt > Date.now()) {
                    dispatch({ type: "updateUILockTimeout" });
                } else {
                    dispatch({ type: "logout" });
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [uiLockAt]);
}
