import * as React from "react";
import { Hour } from "../../../lib/deployment-bar/deployment-times";
import { DayOfWeek } from "./types";

export type HighlightedHourContextType = {
    setHighlightedHour: (hour: Hour, day: DayOfWeek) => unknown;
    highlightedHour: Hour;
    highlightedDow: DayOfWeek;
};

export const HighlightedHourContext = React.createContext<HighlightedHourContextType>({
    setHighlightedHour: () => null,
    highlightedHour: null,
    highlightedDow: null,
});
