import React from "react";
import PropTypes from "prop-types";

export default function TextPlaceholder({ text }) {
    return (
        <div className="text-secondary bg-secondary rounded" style={{ opacity: 0.2 }}>
            {text}
        </div>
    );
}

TextPlaceholder.propTypes = {
    text: PropTypes.string,
};
