import * as React from "react";
import Panel from "../../../layout/Panel";
import { PersonalSchedulesButton } from "./PersonalSchedulesButton";
import TodaysDeployments from "./TodaysDeployment";

export default function PersonalSchedulePanel() {
    return <>
        <Panel.Caption text="Einsatzplan" />
        <Panel className="WorkHub__PersonalSchedulesPanel">
            <Panel.Section>
                <Panel.SectionTitle text="Deine heutigen Einsätze" />
                <TodaysDeployments />
            </Panel.Section>
            <Panel.Section>
                <PersonalSchedulesButton />
            </Panel.Section>
        </Panel>
    </>;
}
