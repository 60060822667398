import * as React from "react";
import { PersonalSchedule } from "../../../../state/types/personal-schedule";
import { DateTime } from "luxon";
import { dayOfWeekShortNameMap } from "../../../../../../lib/datetime/date-format";

type DayProps = {
    date: PersonalSchedule["days"][number]["date"];
}

export default function Day(props: DayProps) {
    const { date } = props;

    const dt = DateTime.fromISO(date);
    const dayName = dayOfWeekShortNameMap[dt.weekday];
    const shortDate = dt.toFormat("dd.MM");

    return (
        <div className="WorkHub__WeeklyDeploymentsList__Day__DayOfWeek">
            <div className="WorkHub__WeeklyDeploymentsList__Day__DayOfWeek__DayName">
                {dayName}
            </div>
            <div className="WorkHub__WeeklyDeploymentsList__Day__DayOfWeek__Date">
                {shortDate}
            </div>
        </div>
    );
}
