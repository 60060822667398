import { Day, DeploymentSlot } from "../types";
import { CellPayload, CursorGrid, CursorPosition } from "./types";
import { DayOfWeek, DeploymentSequenceMap, EmployeeNote, EmployeeNotesDirectory } from "../slots/types";

export function buildCursorGrid(
    slots: DeploymentSlot[],
    deploymentSequences: DeploymentSequenceMap,
    employeeNotes: EmployeeNotesDirectory
): CursorGrid {
    const pos = { row: 0, col: 0 };
    const rows: CellPayload[][][] = [];

    for (const slot of slots) {
        rows[pos.row] = new Array<CellPayload[]>(7).fill([
            { type: "newEmployeeWeek", departmentId: slot.department.id, department: slot.department },
        ]); // TODO: breaking change ?
        pos.row++;

        for (const employeeWeek of slot.employee_weeks) {
            rows[pos.row] ||= [];
            pos.col = 0;

            for (const dow of Object.keys(employeeWeek.days)) {
                rows[pos.row][pos.col] ||= [];

                const day: Day = employeeWeek.days[dow];

                const employeeNotesForEmployeeAndDay: EmployeeNote[] =
                    (employeeNotes &&
                        employeeNotes[employeeWeek.employee.id] &&
                        employeeNotes[employeeWeek.employee.id][dow]) ||
                    [];

                employeeNotesForEmployeeAndDay.forEach((note) => {
                    rows[pos.row][pos.col].push({
                        type: "employeeNote",
                        employeeNote: note,
                        employeeId: employeeWeek.employee.id,
                        dow: Number(dow) as DayOfWeek,
                    });
                });

                day.absences.forEach(() => {
                    rows[pos.row][pos.col].push({
                        type: "absence",
                        absences: day.absences,
                        employeeId: employeeWeek.employee.id,
                        dow: Number(dow) as DayOfWeek,
                    });
                });

                for (const deployment of day.deployments) {
                    const deploymentSequence = deploymentSequences[deployment.deployment_sequence_id];
                    rows[pos.row][pos.col].push({
                        type: "deployment",
                        color: deployment.color,
                        persisted: null !== deployment.id,
                        deploymentId: deployment.id,
                        startTime: deployment.start_time,
                        endTime: deployment.end_time,
                        dow: Number(dow) as DayOfWeek,
                        employeeId: employeeWeek.employee.id,
                        departmentId: slot.department.id,
                        department: null, // not needed in other rows except header
                        availabilities: day.availabilities,
                        absences: day.absences,
                        warnings: deployment.warnings,

                        deploymentSequenceId: deployment.deployment_sequence_id,

                        // @Deprecated, not updated properly, use sequence context instead
                        breakSeconds: deploymentSequence?.break_seconds,
                        autoBreakSeconds: deploymentSequence?.auto_break_seconds,
                        manualBreakSeconds: deploymentSequence?.manual_break_seconds,

                        unitAssignment: day.unit_assignment,
                        workplaces: day.workplaces,
                        globalDeployments: day.global_deployments,
                        school: day.school,
                    });
                }

                rows[pos.row][pos.col].push({
                    type: "newDeployment",
                    color: null,
                    persisted: false,
                    deploymentId: null,
                    startTime: "",
                    endTime: "",
                    dow: Number(dow) as DayOfWeek,
                    employeeId: employeeWeek.employee.id,
                    departmentId: slot.department.id,
                    department: null, // not needed in other rows except header
                    availabilities: day.availabilities,
                    absences: day.absences,
                    warnings: [],
                    unitAssignment: day.unit_assignment,
                    workplaces: day.workplaces,
                    globalDeployments: day.global_deployments,
                    school: day.school,
                });
                pos.col++;
            }
            pos.row++;
        }
    }
    return rows;
}

export function addRow(grid: CursorGrid, currentRow: number, diff: number) {
    currentRow += diff;

    if (currentRow < 0) {
        currentRow = 0;
    } else if (currentRow >= grid.length) {
        currentRow = grid.length - 1;
    }

    return currentRow;
}

export function moveCursor(prevPos: CursorPosition, grid: CursorGrid, yDiff: number, xDiff: number) {
    const newPos = { ...prevPos };

    newPos.row ||= 0;
    newPos.col ||= 0;
    newPos.i ||= 0;

    // First, try to move i
    newPos.i += yDiff;

    // If it exceeds the cells length, move to the start of the next row
    if (newPos.i >= grid[newPos.row][newPos.col].length) {
        newPos.row = addRow(grid, newPos.row, yDiff);
        newPos.i = 0;
    } else if (newPos.i < 0) {
        if (newPos.row <= 0) {
            newPos.i = 0;
        } else {
            newPos.row = addRow(grid, newPos.row, yDiff);
            newPos.i = grid[newPos.row][newPos.col].length - 1;
        }
    }

    newPos.col += xDiff;

    if (newPos.col >= grid[newPos.row].length) {
        newPos.col = grid[newPos.row].length - 1;
    } else if (newPos.col < 0) {
        newPos.col = 0;
    }

    // Move i to the last element in the new cell if there is no equivalent.
    if (newPos.i >= grid[newPos.row][newPos.col].length) {
        newPos.i = grid[newPos.row][newPos.col].length - 1;
    }
    return newPos;
}

export function isActiveCursorPosition(cursorPosition, renderRow, renderCol, buttonSubRow) {
    return (
        cursorPosition &&
        renderRow === cursorPosition.row &&
        renderCol === cursorPosition.col &&
        buttonSubRow === cursorPosition.i
    );
}
