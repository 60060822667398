import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../dom-utils/class-names";

export type SkillIconType =
    | "basket"
    | "bag"
    | "cart"
    | "cash"
    | "code-scanner"
    | "wallet"
    | "credit-card"
    | "bread"
    | "meat"
    | "dairy"
    | "fruit-1"
    | "fruit-2"
    | "vegetables-1"
    | "vegetables-2"
    | "freezer"
    | "flowers"
    | "electronics"
    | "circle"
    | "collection"
    | "diamond"
    | "hexagon"
    | "star"
    | "cold-beverages"
    | "hot-beverages"
    | "drugs"
    | "sanitary"
    | "toys"
    | "store-1"
    | "store-2"
    | "store-3"
    | "bullseye"
    | "calculator"
    | "people"
    | "person"
    | "box-1"
    | "box-2"
    | "box-3"
    | "shelf"
    | "alert"
    | "book"
    | "chat";

type ElementProps = HTMLAttributes<HTMLSpanElement> & {
    type: SkillIconType;
};

export default function SkillIcon({ type, ...elementProps }: ElementProps): JSX.Element {
    let iconClass = "bi-question";
    switch (type) {
        case "basket":
            iconClass = "bi-basket";
            break;
        case "bag":
            iconClass = "bi-bag";
            break;
        case "cart":
            iconClass = "bi-cart";
            break;
        case "cash":
            iconClass = "bi-cash-coin";
            break;
        case "code-scanner":
            iconClass = "bi-qr-code-scan";
            break;
        case "wallet":
            iconClass = "bi-wallet";
            break;
        case "credit-card":
            iconClass = "bi-credit-card";
            break;
        case "bread":
            iconClass = "bi-xbox";
            break;
        case "meat":
            iconClass = "bi-piggy-bank";
            break;
        case "dairy":
            iconClass = "bi-paint-bucket";
            break;
        case "fruit-1":
            iconClass = "bi-patch-question";
            break;
        case "fruit-2":
            iconClass = "bi-patch-question";
            break;
        case "vegetables-1":
            iconClass = "bi-patch-question";
            break;
        case "vegetables-2":
            iconClass = "bi-patch-question";
            break;
        case "freezer":
            iconClass = "bi-snow";
            break;
        case "flowers":
            iconClass = "bi-flower1";
            break;
        case "electronics":
            iconClass = "bi-battery-half";
            break;
        case "circle":
            iconClass = "bi-circle";
            break;
        case "collection":
            iconClass = "bi-collection";
            break;
        case "diamond":
            iconClass = "bi-diamond";
            break;
        case "hexagon":
            iconClass = "bi-hexagon";
            break;
        case "star":
            iconClass = "bi-star";
            break;
        case "cold-beverages":
            iconClass = "bi-cup-straw";
            break;
        case "hot-beverages":
            iconClass = "bi-cup-hot";
            break;
        case "drugs":
            iconClass = "bi-capsule";
            break;
        case "sanitary":
            iconClass = "bi-bandaid";
            break;
        case "toys":
            iconClass = "bi-balloon";
            break;
        case "store-1":
            iconClass = "bi-shop";
            break;
        case "store-2":
            iconClass = "bi-house";
            break;
        case "store-3":
            iconClass = "bi-building";
            break;
        case "bullseye":
            iconClass = "bi-bullseye";
            break;
        case "calculator":
            iconClass = "bi-calculator";
            break;
        case "people":
            iconClass = "bi-people";
            break;
        case "person":
            iconClass = "bi-person";
            break;
        case "box-1":
            iconClass = "bi-box-seam";
            break;
        case "box-2":
            iconClass = "bi-boxes";
            break;
        case "box-3":
            iconClass = "bi-box2";
            break;
        case "shelf":
            iconClass = "bi-bookshelf";
            break;
        case "alert":
            iconClass = "bi-bell";
            break;
        case "book":
            iconClass = "bi-book";
            break;
        case "chat":
            iconClass = "bi-chat";
            break;
        default:
            iconClass = "bi-question";
            break;
    }

    elementProps.className = joinClasses("bi", iconClass, elementProps.className);

    return <span {...elementProps} />;
}
