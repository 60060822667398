export type AbsenceReasonsApiResponse = {
    data: {
        id: string;
        name: string;
    }[];
};

export async function fetchAbsenceReasons(): Promise<AbsenceReasonsApiResponse> {
    return fetch("/api/shortcuts/shared/absence_reasons").then((response) => {
        if (!response.ok && response.status !== 422) {
            throw Error("Failed to load employees: " + response.statusText);
        }
        return response.json();
    });
}
