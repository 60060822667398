import Loading from "../../../lib/empty-state/Loading";
import * as React from "react";

export function WidgetLoading(props: { height?: number }) {
    const { height = 150 } = props;

    return (
        <div
            style={{
                display: "flex",
                height: height,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Loading />
        </div>
    );
}
