import * as React from "react";
import { IconType } from "../../../lib/icon/Icon";
import SecondsLeft from "./SecondsLeft";
import useWorkHubDispatch from "../state/useWorkHubDispatch";
import Button from "../shared/Button";
import { joinClasses } from "../../../lib/dom-utils/class-names";
import useWorkHubState from "../state/useWorkHubState";

type LogoutButtonProps = {
    text?: string;
    icon?: null | IconType;
    colorTheme?: "dark" | "link";
}

export default function LogoutButton(props: LogoutButtonProps) {
    const { text = "Abmelden", icon = "logout", colorTheme = "dark" } = props;
    const dispatch = useWorkHubDispatch();
    const { ui: { secondsLeftToLock } } = useWorkHubState();

    const classes = joinClasses(
        "WorkHubLayout__Header__LogoutButton",
        `WorkHubLayout__Header__LogoutButton--${colorTheme}`
    );

    return (
        <Button
            className={classes}
            colorTheme="indigo"
            onClick={() => dispatch({ type: "logout" })}
            arrowIconType={icon}
        >
            {secondsLeftToLock && <>
                <SecondsLeft />{" "}
            </>}

            {text}
        </Button>
    );
}
