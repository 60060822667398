import * as React from "react";
import { ReactNode, useCallback } from "react";
import { useFilterParams } from "./useFilterParams";
import { AbsenceCategory, DReport } from "../types";
import { DateTime } from "luxon";
import WithDataFromApi, { WithDataFromApiChildrenArgs } from "../../../lib/api/WithDataFromApi";
import { buildReportUrl } from "./url";

async function fetchData(
    unitIds: string[],
    startsOn: DateTime,
    endsOn: DateTime,
    categories: AbsenceCategory[],
    includePresent: boolean,
    signal
): Promise<DReport> {
    const url = buildReportUrl(unitIds, startsOn, endsOn, categories, includePresent, "json");
    const response = await fetch(url, { signal });
    return await response.json();
}

type WithReportFromApiProps = {
    children: (args: WithDataFromApiChildrenArgs<DReport>) => ReactNode;
};

export default function WithReportFromApi(props: WithReportFromApiProps) {
    const { children } = props;
    const { unitIds, startsOn, endsOn, categories, includePresent } = useFilterParams();

    const fetchDataCallback = useCallback(
        (signal) => fetchData(unitIds, startsOn, endsOn, categories, includePresent, signal),
        [unitIds, startsOn?.toISODate(), endsOn?.toISODate(), categories, includePresent]
    );

    return (
        <WithDataFromApi<DReport> hot={null !== startsOn && null !== endsOn} fetchData={fetchDataCallback}>
            {children}
        </WithDataFromApi>
    );
}
