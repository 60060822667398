import { DateTime } from "luxon";
import { Dispatch } from "react";
import { Action } from "./actions";
import { SetURLSearchParams } from "react-router-dom";
import { setFilterToStorage } from "../filters";
import { DayOfWeek } from "../../../lib/datetime/types";
import { ReportEmployee } from "../types";
import { ReportDay } from "../../../lib/ett/report-grid/types";

export type Effects = {
    setDays: (days: ReportDay[]) => unknown;
    setEmployee: (employee: ReportEmployee) => unknown;
    setDateRange: (startsAt: DateTime, endsAt: DateTime, updateHistory?: boolean, replace?: boolean) => unknown;
    setLoading: (loading: boolean) => unknown;
    startLoading: () => unknown;
    stopLoading: () => unknown;
    setWeekDays: (weekDays: DayOfWeek[]) => unknown;
}

export function createEffects(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams): Effects {
    return {
        setDays: setDaysFactory(dispatch),
        setEmployee: setEmployeeFactory(dispatch),
        setDateRange: setDateRangeFactory(dispatch, setSearchParams),
        setLoading: setLoadingFactory(dispatch),
        startLoading: setStaticLoadingFactory(true, dispatch),
        stopLoading: setStaticLoadingFactory(false, dispatch),
        setWeekDays: setWeekDaysFactory(dispatch)
    };
}

function setDaysFactory(dispatch: Dispatch<Action>) {
    return (days: ReportDay[]) => {
        dispatch({
            type: "setDays",
            payload: { days }
        });
    };
}

function setEmployeeFactory(dispatch: Dispatch<Action>) {
    return (employee: ReportEmployee) => {
        dispatch({
            type: "setEmployee",
            payload: employee
        });
    };
}

function setDateRangeFactory(dispatch: Dispatch<Action>, setSearchParams: SetURLSearchParams) {
    return (startsAt: DateTime, endsAt: DateTime, updateHistory: boolean = true, replace: boolean = false) => {
        if (updateHistory) {
            setSearchParams({
                starts_at: startsAt.toISO(),
                ends_at: endsAt.toISO()
            }, { replace });
        }

        setFilterToStorage("starts-at", startsAt.toISO());
        setFilterToStorage("ends-at", endsAt.toISO());

        dispatch({
            type: "setDateRange",
            payload: {
                startsAt,
                endsAt
            }
        });
    };
}

function setLoadingFactory(dispatch: Dispatch<Action>) {
    return (loading: boolean) => {
        dispatch({
            type: "setLoading",
            payload: loading
        });
    };
}

function setStaticLoadingFactory(value: boolean, dispatch: Dispatch<Action>) {
    return () => {
        dispatch({
            type: "setLoading",
            payload: value
        });
    };
}

function setWeekDaysFactory(dispatch: Dispatch<Action>) {
    return (weekDays: DayOfWeek[]) => {
        dispatch({
            type: "setWeekDays",
            payload: weekDays
        });
    };
}
