import * as React from "react";
import AppRoutes from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";

export default function App() {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <BrowserRouter basename="/absence_report">
                    <AppRoutes />
                </BrowserRouter>
            </ErrorBoundary>
        </React.StrictMode>
    );
}
