import React, { useEffect, useState } from "react";
import { loadSlotsForMonth } from "../api";
import MonthTableHeadRows from "./month-table-head-rows";
import createPlaceholderRows from "../create-placeholder-rows";
import MonthPlaceholderRow from "./month-placeholder-row";
import { TimeSheetTable } from "../time-sheet-table";
import MonthBodyRow from "./month-body-row";
import MonthSummaryRow from "./month-summary-row";
import { filterSlots } from "../slot-search-bar";
import PropTypes from "prop-types";

const PlaceholderRows = createPlaceholderRows(MonthPlaceholderRow, 12);

export function MonthTable({ unitId, year, month, slotSearchValue }) {
    const [slots, setSlots] = useState(null);
    const [summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filteredSlots, setFilteredSlots] = useState(null);

    const loadSlots = async () => {
        setLoading(true);
        const { slots, summary } = await loadSlotsForMonth(year, month, unitId);
        setSlots(slots);
        setFilteredSlots(filterSlots(slots, slotSearchValue));
        setSummary(summary);
        setLoading(false);
    };

    useEffect(() => {
        if (unitId && year && month) {
            (async () => {
                await loadSlots();
            })();
        }
    }, [unitId, year, month]);

    useEffect(() => {
        setFilteredSlots(filterSlots(slots, slotSearchValue));
    }, [slotSearchValue]);

    const headRows = <MonthTableHeadRows />;
    const placeholderRows = <PlaceholderRows />;
    const bodyRows = filteredSlots && [
        <MonthSummaryRow key="summary" summary={summary} />,
        ...filteredSlots.map((slot) => (
            <MonthBodyRow
                key={slot.employee.id}
                slot={slot}
                unitId={unitId}
                period={`${year}-${("0" + month).slice(-2)}`}
            />
        )),
    ];

    return <TimeSheetTable {...{ loading, headRows, placeholderRows, bodyRows }} />;
}

MonthTable.propTypes = {
    unitId: PropTypes.string,
    year: PropTypes.number,
    month: PropTypes.number,
    slotSearchValue: PropTypes.string,
};
