import { jsonHeaders } from "./common";
import { convertKeysToCamelCase } from "../../../lib/object/objects";
import { DayTotals, TodaysEttLogEntry } from "../state/types/employee-info";
import { EttStatus } from "../state/types/ett-status";
import { PersonalScheduleDeployment } from "../state/types/personal-schedule";

export type RawAccessTokenResponse = {
    data?: {
        access_token: string;
        expires_in: number;
        employee: {
            name: string;
            ett_status: string;
            today: {
                totals: {
                    presence_time: string;
                    break_time: string;
                },
                deployments: {
                    id: string;
                    start_time: string;
                    end_time: string;
                    department_name: string;
                }[],
                ett_log: {
                    start_time: string,
                    end_time: string | null,
                    status: EttStatus,
                    duration: number,
                    humanDuration: string,
                }[],
            }
        }
    };
    error?: string;
};
export type AccessTokenResponse = {
    accessToken?: string;
    expiresIn?: number;
    employee?: {
        name: string;
        ettStatus: string;
        today: {
            totals: DayTotals,
            deployments: PersonalScheduleDeployment[],
            ettLog: TodaysEttLogEntry[],
        }
    };
    error?: string;
};

export async function fetchAccessToken(authToken: string, deviceId: string, signal?: AbortSignal): Promise<AccessTokenResponse> {
    const options = {
        method: "POST",
        signal,
        headers: jsonHeaders,
        body: JSON.stringify({ auth_token: authToken, device_id: deviceId })
    };
    return fetch(`/workhub/api/access_tokens`, options)
        .then(response => response.json())
        .then((json: RawAccessTokenResponse) => {
                if (json.error) {
                    return { error: json.error };
                } else if (json.data) {
                    return {
                        accessToken: json.data.access_token,
                        expiresIn: json.data.expires_in,
                        employee: {
                            name: json.data.employee.name,
                            ettStatus: json.data.employee.ett_status,
                            today: {
                                totals: convertKeysToCamelCase(json.data.employee.today.totals) as DayTotals,
                                deployments: json.data.employee.today.deployments.map(convertKeysToCamelCase) as PersonalScheduleDeployment[],
                                ettLog: json.data.employee.today.ett_log.map(convertKeysToCamelCase) as TodaysEttLogEntry[]
                            }
                        }
                    };
                } else {
                    return { error: "Unexpected response format from server, missing data or error." };
                }
            }
        );
}
