import * as React from "react";
import TextField from "../form/text/TextField";
import useFinderKeyboardNavigation from "./useFinderKeyboardNavigation";

export type FinderPromptProps = {
    query: string;
    onNext: () => void;
    onPrevious: () => void;
    onClose: () => void;
    onCommit: () => void;
    onQueryChange: (newQuery: string) => void;
};

export default function FinderPrompt(props: FinderPromptProps) {
    const { query, onNext, onCommit, onPrevious, onClose, onQueryChange } = props;

    const queryRef = React.useRef();
    useFinderKeyboardNavigation({ ref: queryRef, onNext, onPrevious, onClose, onCommit });

    return (
        <div ref={queryRef}>
            <TextField autoFocus={true} onChange={onQueryChange} value={query} placeholder="Mitarbeiter suchen…" />
        </div>
    );
}
