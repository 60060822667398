import * as React from "react";
import { useState } from "react";
import { ScheduleDailyNote } from "../types";
import { SchedulerDailyNoteEditor } from "../schedule-daily-notes/SchedulerDailyNoteEditor";

type ScheduleDailyNoteProps = {
    note: ScheduleDailyNote;
};

export function ScheduleDailyNote(props: ScheduleDailyNoteProps) {
    const { note } = props;

    const [isEditing, setIsEditing] = useState(false);

    const classNames = [
        "SchedulerHeader__DailyNotes__Note",
        "SchedulerHeader__DailyNotes__Note--scheduler-daily-note",
        `SchedulerHeader__DailyNotes__Note--${note.color}`,
    ];

    return (
        <>
            <div className={classNames.join(" ")} onClick={() => setIsEditing(true)} role="button">
                {note.text}
            </div>
            {isEditing && (
                <SchedulerDailyNoteEditor
                    dow={note.day_of_week}
                    scheduleDailyNoteId={note.id}
                    onClose={() => setIsEditing(false)}
                />
            )}
        </>
    );
}
