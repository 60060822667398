import * as React from "react";
import { default as LoadingFromLib } from "../../../lib/empty-state/Loading";

export function Loading() {
    return (
        <div className="d-flex h-100 justify-content-center align-items-center">
            <LoadingFromLib />
        </div>
    );
}
