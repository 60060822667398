import * as React from "react";
import { RecentSchedule } from "./reducer";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import Icon from "../../../../lib/icon/Icon";
import { WidgetLoading } from "../WidgetLoading";
import { DataTable2 } from "../../../../lib/data-table/DataTable2";

export function ScheduleWidgetContent({
    title,
    weekName,
    schedules,
    loading,
    loadingFailed,
}: {
    title: string;
    weekName: string;
    schedules: RecentSchedule[];
    loading: boolean;
    loadingFailed: boolean;
}) {
    return (
        <Kitchensink.Item aria-label={title}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>
                    <span>{title}</span>
                    <Kitchensink.ItemBodyTitleAddendum>{weekName}</Kitchensink.ItemBodyTitleAddendum>
                </Kitchensink.ItemBodyTitle>
                <Kitchensink.ItemBodyPart>
                    {loadingFailed && (
                        <div className="Badge Badge--warning">
                            <Icon type="error" /> Fehler beim Laden
                        </div>
                    )}
                    {!loadingFailed && loading && <WidgetLoading height={290} />}
                    {!loadingFailed && !loading && !schedules?.length && (
                        <div className="DashboardRecentSchedules__Empty">
                            <div className="DashboardRecentSchedules__Empty__Text">
                                Keine aktuellen Wochenpläne vorhanden.
                            </div>
                            <div className="DashboardRecentSchedules__Empty__Action">
                                <a href="/schedules/new" className="btn btn-primary btn-sm">
                                    Neuen Wochenplan erstellen
                                </a>
                            </div>
                        </div>
                    )}
                    {!loadingFailed && !loading && schedules?.length > 0 && (
                        <DataTable2>
                            <DataTable2.Head>
                                <DataTable2.HeadRow>
                                    <DataTable2.HeadCell>Filiale</DataTable2.HeadCell>
                                    <DataTable2.HeadCell align="right">Planstunden Soll</DataTable2.HeadCell>
                                    <DataTable2.HeadCell align="right">Planstunden Ist</DataTable2.HeadCell>
                                    <DataTable2.HeadCell align="right"></DataTable2.HeadCell>
                                </DataTable2.HeadRow>
                            </DataTable2.Head>
                            <DataTable2.Body>
                                {schedules.map((schedule) => (
                                    <DataTable2.Row key={schedule.id}>
                                        <DataTable2.Cell primary>
                                            <span className="text-nowrap">{schedule.unit.name}</span>
                                        </DataTable2.Cell>
                                        <DataTable2.Cell align="right">
                                            <span className="text-nowrap">{schedule.balances.humanPlanHours} h</span>
                                        </DataTable2.Cell>
                                        <DataTable2.Cell align="right">
                                            <span className="text-nowrap">
                                                {schedule.balances.humanPerformedWorkHours} h
                                            </span>
                                        </DataTable2.Cell>
                                        <DataTable2.Cell align="right">
                                            <a
                                                href={`/schedules/${schedule.id}/deployments`}
                                                className="DashboardRecentSchedules__Schedule__Week"
                                                data-turbolinks="false"
                                            >
                                                Mehr <Icon type="follow-link" />
                                            </a>
                                        </DataTable2.Cell>
                                    </DataTable2.Row>
                                ))}
                            </DataTable2.Body>
                        </DataTable2>
                    )}
                </Kitchensink.ItemBodyPart>
                <Kitchensink.ItemBodyLinkList>
                    <a href="/schedules" className="Button Button--ghost">
                        Alle Wochenpläne verwalten
                    </a>
                </Kitchensink.ItemBodyLinkList>
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}
