import * as React from "react";
import {
    Absence,
    AbsenceReason,
    CreateAbsenceReponseData,
    CustomItemColor,
    Department,
    Deployment,
    DeploymentSequence,
    Employee,
    ScheduleDailyNote,
} from "../types";
import { ApiResult, DayOfWeek, EmployeeNote, SimpleDeployment, UpdateApiResult } from "./types";

export type DataContextType = {
    newDeployment: (departmentId: Department["id"], employeeId: Employee["id"], dow: DayOfWeek) => void;
    createDeployment: (
        departmentId: Department["id"],
        employeeId: Employee["id"],
        dow: DayOfWeek,
        values: SimpleDeployment
    ) => Promise<UpdateApiResult>;
    updateDeployment: (id: Deployment["id"], values: SimpleDeployment) => Promise<UpdateApiResult>;
    deleteDeployment: (id: Deployment["id"]) => Promise<UpdateApiResult>;
    newEmployeeWeek: (departmentId: Department["id"], employee: Employee) => Promise<void>;
    updateDeploymentSequence: (
        deplomentSequenceId: DeploymentSequence["id"],
        values: Partial<DeploymentSequence>
    ) => Promise<ApiResult<DeploymentSequence>>;
    createAbsence: (
        employeeId: Employee["id"],
        absenceReasonId: AbsenceReason["id"],
        dow: DayOfWeek
    ) => Promise<ApiResult<CreateAbsenceReponseData>>;
    destroyAbsence: (absenceId: Absence["id"]) => Promise<ApiResult<Absence>>;
    createEmployeeNote: (employeeId: Employee["id"], dow: DayOfWeek, text: string) => Promise<ApiResult<EmployeeNote>>;
    updateEmployeeNote: (
        employeeNoteId: EmployeeNote["id"],
        values: Partial<EmployeeNote>
    ) => Promise<ApiResult<EmployeeNote>>;
    destroyEmployeeNote: (employeeNoteId: EmployeeNote["id"]) => Promise<ApiResult<EmployeeNote>>;
    createScheduleDailyNote: (
        dayOfWeek: DayOfWeek,
        text: string,
        color: CustomItemColor
    ) => Promise<ApiResult<ScheduleDailyNote>>;
    updateScheduleDailyNote: (
        scheduleDailyNoteId: ScheduleDailyNote["id"],
        values: Partial<ScheduleDailyNote>
    ) => Promise<ApiResult<ScheduleDailyNote>>;
    destroyScheduleDailyNote: (scheduleDailyNoteId: ScheduleDailyNote["id"]) => Promise<ApiResult<ScheduleDailyNote>>;
};

export const DataContext = React.createContext<DataContextType>({
    newDeployment: null,
    createDeployment: null,
    updateDeployment: null,
    deleteDeployment: null,
    newEmployeeWeek: null,
    updateDeploymentSequence: null,
    createAbsence: null,
    destroyAbsence: null,
    createEmployeeNote: null,
    updateEmployeeNote: null,
    destroyEmployeeNote: null,
    createScheduleDailyNote: null,
    updateScheduleDailyNote: null,
    destroyScheduleDailyNote: null,
});
