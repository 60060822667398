import * as React from "react";
import { ReactNode, useCallback, useState } from "react";
import { useLoadingCounter } from ".././empty-state/useLoadingCounter";
import { useApi } from "./useApi";
import LoadingGuard from ".././empty-state/LoadingGuard";
import ApiErrors from "../../apps/absence-report/report/ApiErrors";

export type WithDataFromApiChildrenArgs<T> = {
    loading: boolean;
    data: T | null;
    errors: ApiErrors;
};

export type ApiErrors = string[];

type WithDataFromApiProps<T> = {
    hot: boolean;
    fetchData: (signal: AbortSignal) => Promise<T>;
    children: (args: WithDataFromApiChildrenArgs<T>) => ReactNode;
    onData?: (T) => unknown;
};

export default function WithDataFromApi<T>(props: WithDataFromApiProps<T>) {
    const { children, fetchData, hot, onData } = props;

    const [loading, startLoading, stopLoading] = useLoadingCounter();
    const [data, setData] = useState<T | null>(null);
    const [errors, setErrors] = useState<ApiErrors>([]);

    const fetchDataCallback = useCallback((signal) => fetchData(signal), [fetchData]);
    const onErrorsCallback = useCallback((error) => setErrors([error]), []);
    const onSuccessCallback = useCallback(
        (data) => {
            setData(data);
            onData && onData(data);
        },
        [onData]
    );

    useApi<T>({
        hot,
        fetchData: fetchDataCallback,
        startLoading,
        stopLoading,
        onError: onErrorsCallback,
        onSuccess: onSuccessCallback,
    });

    return (
        <>
            <ApiErrors errors={errors} />
            <LoadingGuard loaded={() => !!(!loading && null !== data)}>
                {() => <>{children({ loading, data, errors })}</>}
            </LoadingGuard>
        </>
    );
}
