import { Skill } from "../../../../../lib/types/skills";
import { useMemo } from "react";

export default function useCombinedDepartmentSkills(
    departmentPrimarySkills: Skill[],
    departmentSecondarySkills: Skill[]
): Skill[] {
    return useMemo(
        () => [
            ...departmentPrimarySkills.map((skill) => ({ ...skill, isPrimary: true })),
            ...departmentSecondarySkills.map((skill) => ({ ...skill, isPrimary: false })),
        ],
        [departmentPrimarySkills, departmentSecondarySkills]
    );
}
