import * as React from "react";
import { useContext } from "react";
import { EmployeeIdContext } from "./EmployeeIdContext";
import { useReportFromApi } from "./api";

export function DataFromApi(props: { children: React.ReactNode }) {
    const employeeId = useContext(EmployeeIdContext).employeeId;
    useReportFromApi(employeeId);
    return props.children;
}
