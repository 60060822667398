import { useSearchParams } from "react-router-dom";
import { isPresent } from "../../../lib/object/objects";
import { useMemo } from "react";

type ReturnType = {
    unitIds: string[];
    year: number;
};

export function useFilterParams(): ReturnType {
    const [searchParams] = useSearchParams();

    const rawUnitIds = searchParams.get("unitIds");
    const rawYear = searchParams.get("year");

    const unitIds = useMemo(() => {
        if (isPresent(rawUnitIds)) {
            return rawUnitIds.split(",");
        } else {
            return [];
        }
    }, [rawUnitIds]);

    const year = useMemo(() => (rawYear ? parseInt(rawYear) : new Date().getFullYear()), [rawYear]);

    return { unitIds, year };
}
