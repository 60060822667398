import * as React from "react";

export type FinderWindowBodyProps = {
    children: React.ReactNode;
};

export default function FinderWindowBody(props: FinderWindowBodyProps) {
    const { children } = props;

    return <div className="FinderWindow__Body">{children}</div>;
}
