import { Controller } from "stimulus";

export default class AbsenceFormController extends Controller {
    static values = { initialMode: String };
    static targets = ["checkBox", "startTimeCol", "endRow", "advancedButton", "simpleButton"];

    connect() {
        if (this.advancedButtonTarget) {
            this.applyInitialValue();
        }
    }

    applyInitialValue() {
        if ("advanced" === this.initialModeValue) {
            this.enableAdvancedView();
        } else {
            this.disableAdvancedView();
        }
    }

    enableAdvancedView() {
        this.endRowTarget.classList.remove("d-none");
        this.endRowTarget.querySelectorAll("input, textarea, select").forEach((input) => (input.disabled = false));

        this.startTimeColTarget.classList.remove("d-none");
        this.startTimeColTarget
            .querySelectorAll("input, textarea, select")
            .forEach((input) => (input.disabled = false));

        this.advancedButtonTarget.classList.add("active");
        this.simpleButtonTarget.classList.remove("active");
    }

    disableAdvancedView() {
        this.endRowTarget.classList.add("d-none");
        this.endRowTarget.querySelectorAll("input, textarea, select").forEach((input) => (input.disabled = true));

        this.startTimeColTarget.classList.add("d-none");
        this.startTimeColTarget.querySelectorAll("input, textarea, select").forEach((input) => (input.disabled = true));

        this.advancedButtonTarget.classList.remove("active");
        this.simpleButtonTarget.classList.add("active");
    }
}
