import * as React from "react";
import Button from "../../../shared/Button";
import useEttStatusTransition from "./useEttStatusTransition";
import { checkOut, startBreak } from "../../../api/ett-statuses";

type CheckedInActionProps = Record<string, never>;

export default function CheckedInActions(_props: CheckedInActionProps) {
    const transitionCheckOut = useEttStatusTransition(checkOut);
    const transitionStartBreak = useEttStatusTransition(startBreak);

    return (
        <>
            <div style={{ flex: "1" }}>
                <Button colorTheme="rose" onClick={transitionCheckOut} block={true}>
                    Auschecken
                </Button>
            </div>
            <div style={{ flex: "1" }}>
                <Button colorTheme="slate" onClick={transitionStartBreak} block={true}>
                    Pause beginnen
                </Button>
            </div>
        </>
    );
}
