import { jsonHeaders } from "./common";
import { convertKeysToCamelCase } from "../../../lib/object/objects";
import { PersonalScheduleSet } from "../state/types/personal-schedule";

export async function fetchPersonalSchedules(accessToken: string, signal?: AbortSignal): Promise<PersonalScheduleSet> {
    const options = {
        method: "GET",
        signal,
        headers: {
            ...jsonHeaders,
            "Authorization": `Bearer ${accessToken}`
        }
    };
    return fetch(`/workhub/api/personal_schedules`, options)
        .then(response => response.json())
        .then((json: { data: unknown }) => convertKeysToCamelCase(json.data) as PersonalScheduleSet);
}
