import * as React from "react";
import { joinClasses } from "../dom-utils/class-names";

export function KeyFigure(
    props: {
        children: React.ReactNode;
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const { children, ...divProps } = props;
    divProps.className = joinClasses("KeyFigure", divProps.className);
    return <div {...divProps}>{children}</div>;
}
