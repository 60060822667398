import * as React from "react";
import { useEffect } from "react";
import Modal2 from "../../../lib/modal/Modal2";
import Button from "../../../lib/button/Button";
import { DocumentConfig } from "./types";
import { useImmerReducer } from "use-immer";
import consumer from "../../../channels/consumer";
import Loading from "../../../lib/empty-state/Loading";
import Checkbox from "../../../lib/form/checkbox/Checkbox";
import Icon from "../../../lib/icon/Icon";
import Banner from "../../../lib/notifications/Banner";
import QRCode from "../../../lib/qrcode/QRCode";
import { CopyStringField } from "../../../lib/copy-string-field/CopyStringField";
import FormGroup from "../../../lib/form/form/FormGroup";
import TextField from "../../../lib/form/text/TextField";
import { ExportPrivileges, Schedule } from "../types";
import { Error } from "./Error";

type State = {
    config: DocumentConfig;
    configSubmitted: boolean;
    requestSent: boolean;
    downloadUrl: string | null;
    buildError: string | null;
};

type Action =
    | { type: "configureFeature"; featureName: keyof DocumentConfig["features"]; value: unknown }
    | { type: "setTitle"; title: string }
    | { type: "submitConfig" }
    | { type: "buildError"; message: string }
    | { type: "setRequestSent"; requestSent: boolean }
    | { type: "setDownloadUrl"; downloadUrl: string }
    | { type: "backToConfig" };

function reducer(draft: State, action: Action) {
    switch (action.type) {
        case "configureFeature":
            if (action.featureName === "absences") {
                draft.config.features.absences = action.value as "absences" | "absencesAndAbsenceReasons" | "none";
            } else {
                draft.config.features[action.featureName] = action.value as boolean;
            }
            break;
        case "setTitle":
            draft.config.title = action.title;
            break;
        case "submitConfig":
            draft.configSubmitted = true;
            break;
        case "buildError":
            draft.buildError = action.message;
            break;
        case "setRequestSent":
            draft.requestSent = action.requestSent;
            draft.buildError = null;
            break;
        case "setDownloadUrl":
            draft.downloadUrl = action.downloadUrl;
            draft.buildError = null;
            break;
        case "backToConfig":
            draft.configSubmitted = false;
            draft.buildError = null;
            break;
    }
}

function createInitialState(schedule: Schedule) {
    return {
        config: {
            title: `${schedule.unit.name} ${schedule.week_name}`,
            features: {
                absences: "absencesAndAbsenceReasons",
                employeeBalances: true,
                departmentBalances: true,
                breaks: true,
                deploymentBars: true,
                notes: true,
                times: true,
                employeeNames: true,
                school: true,
            },
        },
        configSubmitted: false,
        requestSent: false,
        downloadUrl: null,
        buildError: null,
    };
}

type DocumentWizardProps = {
    schedule: Schedule;
    onClose: () => unknown;
    exportPrivileges: ExportPrivileges;
};

export function DocumentWizard(props: DocumentWizardProps) {
    const { schedule, onClose, exportPrivileges } = props;
    const [state, dispatch] = useImmerReducer(reducer, createInitialState(schedule));
    const title = "Exportieren";

    const handleClick = () => {
        dispatch({ type: "submitConfig" });
    };

    useEffect(() => {
        if (state.configSubmitted) {
            const subscription = consumer.subscriptions.create(
                {
                    channel: "ScheduleDocumentChannel",
                    schedule_id: schedule.id,
                    title: state.config.title,
                    features: state.config.features,
                },
                {
                    received(data) {
                        if (data) {
                            if ("finished" === data.type) {
                                dispatch({ type: "setDownloadUrl", downloadUrl: data.document_url });
                            } else if ("error" === data.type) {
                                dispatch({ type: "buildError", message: data.message });
                                console.error("Error while generating document", data.message);
                            } else {
                                console.warn("Unknown message type", data.type);
                            }
                        }
                    },
                    connected() {
                        this.perform("generate_document", { config: state.config });
                    },
                }
            );
            return () => subscription.unsubscribe();
        }
    }, [schedule.id, state.configSubmitted]);

    function configureFeature(
        propName: keyof DocumentConfig["features"],
        value: boolean | "absences" | "absencesAndAbsenceReasons" | "none"
    ) {
        dispatch({ type: "configureFeature", featureName: propName, value });
    }

    return (
        <Modal2 open={true} onClose={onClose} title={title}>
            {!state.configSubmitted && (
                <>
                    <div className="mb-3">
                        <FormGroup label="Titel">
                            <TextField
                                onChange={(value) => dispatch({ type: "setTitle", title: value })}
                                value={state.config.title}
                            />
                        </FormGroup>
                        <FormGroup label="Darstellung">
                            <div style={{ columns: 2, columnGap: "30px" }}>
                                <Checkbox
                                    caption="Mitarbeiternamen"
                                    checked={
                                        exportPrivileges.can_export_employee_names &&
                                        state.config.features.employeeNames
                                    }
                                    onChange={(checked) => configureFeature("employeeNames", checked)}
                                    disabled={!exportPrivileges.can_export_employee_names}
                                />
                                <Checkbox
                                    caption="Arbeitszeiten"
                                    checked={exportPrivileges.can_export_times && state.config.features.times}
                                    onChange={(checked) => configureFeature("times", checked)}
                                    disabled={!exportPrivileges.can_export_times}
                                />
                                <Checkbox
                                    caption="Pausenzeiten"
                                    checked={exportPrivileges.can_export_breaks && state.config.features.breaks}
                                    onChange={(checked) => configureFeature("breaks", checked)}
                                    disabled={!exportPrivileges.can_export_breaks}
                                />
                                <Checkbox
                                    caption="Plan- & Ist-Stunden"
                                    checked={
                                        exportPrivileges.can_export_department_balances &&
                                        state.config.features.departmentBalances
                                    }
                                    onChange={(checked) => configureFeature("departmentBalances", checked)}
                                    disabled={!exportPrivileges.can_export_department_balances}
                                />
                                <Checkbox
                                    caption="Zeitkonten Mitarbeiter"
                                    checked={
                                        exportPrivileges.can_export_employee_balances &&
                                        state.config.features.employeeBalances
                                    }
                                    onChange={(checked) => configureFeature("employeeBalances", checked)}
                                    disabled={!exportPrivileges.can_export_employee_balances}
                                />
                                <Checkbox
                                    caption="Notizen"
                                    checked={exportPrivileges.can_export_notes && state.config.features.notes}
                                    onChange={(checked) => configureFeature("notes", checked)}
                                    disabled={!exportPrivileges.can_export_notes}
                                />
                                <Checkbox
                                    caption="Abwesenheiten"
                                    checked={
                                        exportPrivileges.can_export_absences &&
                                        state.config.features.absences !== "none"
                                    }
                                    onChange={(checked) =>
                                        configureFeature("absences", checked ? "absencesAndAbsenceReasons" : "none")
                                    }
                                    disabled={!exportPrivileges.can_export_absences}
                                />
                                <Checkbox
                                    caption="Abwesenheitsgründe"
                                    checked={
                                        exportPrivileges.can_export_absences &&
                                        state.config.features.absences === "absencesAndAbsenceReasons"
                                    }
                                    onChange={(checked) =>
                                        configureFeature("absences", checked ? "absencesAndAbsenceReasons" : "absences")
                                    }
                                    disabled={
                                        !exportPrivileges.can_export_absences ||
                                        state.config.features.absences === "none"
                                    }
                                />
                                <Checkbox
                                    caption="Berufsschule"
                                    checked={exportPrivileges.can_export_school && state.config.features.school}
                                    onChange={(checked) => configureFeature("school", checked)}
                                    disabled={!exportPrivileges.can_export_school}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <Button
                        theme="primary"
                        size="md"
                        icon="build-document"
                        caption="Dokument generieren"
                        onClick={handleClick}
                    />
                </>
            )}

            {state.buildError !== null && (
                <div className="d-flex gap-2 flex-column">
                    <Error message="Beim Generieren des Dokuments ist ein Fehler aufgetreten." />
                    <div className="d-flex gap-2 justify-content-between">
                        <Button theme="secondary" onClick={() => dispatch({ type: "backToConfig" })}>
                            Zurück
                        </Button>
                        <Button theme="secondary" onClick={onClose}>
                            Schließen
                        </Button>
                    </div>
                </div>
            )}

            {state.configSubmitted && !state.buildError && !state.downloadUrl && (
                <div style={{ textAlign: "center" }}>
                    <Loading loadingText="Dokument wird generiert…" />
                </div>
            )}

            {state.downloadUrl && <DownloadDocument showBanner={true} downloadUrl={state.downloadUrl} />}
        </Modal2>
    );
}

function DownloadDocument({ downloadUrl, showBanner = false }: { downloadUrl: string; showBanner: boolean }) {
    return (
        <div className="d-flex justify-content-between align-items-start gap-3">
            <div
                className="h-100 d-flex justify-content-between flex-column align-items-end"
                style={{ flex: "1 1 100%" }}
            >
                {showBanner && (
                    <Banner theme="success" className="mb-3">
                        <Icon type="success" /> Ein neues Dokument wurde erstellt. <br />
                        Sie können die Datei jetzt herunterladen oder den Direktlink kopieren.
                    </Banner>
                )}
                <a
                    className="Button Button--primadonna Button--large w-100"
                    style={{ textAlign: "right" }}
                    href={downloadUrl}
                    target="_blank"
                    download
                    rel="noreferrer"
                >
                    <Icon type="save" /> als PDF herunterladen
                </a>
                <div className="text-muted my-2">
                    <CopyStringField text={downloadUrl} caption="Direktlink in Zwischenablage kopieren" />
                </div>
            </div>

            <div className="d-flex flex-column gap-2" style={{ flex: "0 0 148px" }}>
                <QRCode text={downloadUrl} />
            </div>
        </div>
    );
}
