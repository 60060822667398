import { useEmployeePermissions, useEmployeeState } from "../../state";
import Kitchensink from "../../../../../lib/kitchensink/Kitchensink";
import * as React from "react";
import { Absences } from "./absences/Absences";
import { AbsenceSummary } from "./absence-summary/AbsenceSummary";

export function AbsencesAndAbsenceCertificates() {
    const { canReadAbsences, canManageAbsences, canManageAbsenceCertificates } = useEmployeePermissions();
    const { employee } = useEmployeeState();

    if (!canReadAbsences) {
        return null;
    }

    return (
        <Kitchensink.Item>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Abwesenheiten & Nachweise</Kitchensink.ItemBodyTitle>

                <AbsenceSummary />
                <Absences />

                {(canManageAbsences || canManageAbsenceCertificates) && (
                    <Kitchensink.ItemBodyLinkList>
                        {canManageAbsences && (
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/absences`}>
                                Abwesenheiten verwalten
                            </a>
                        )}
                        {canManageAbsenceCertificates && (
                            <a className="Button Button--primadonna" href={`/employees/${employee.id}/absence_certificates`}>
                                Abwesenheitsnachweise verwalten
                            </a>
                        )}
                    </Kitchensink.ItemBodyLinkList>
                )}
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}
