import * as React from "react";
import { AllocationWorkplace, EmployeeAllocations } from "./slots/types";
import { Unit } from "./types";
import Collapsible from "./Collapsible";
import Table from "./table/Table";
import TableRow from "./table/TableRow";
import TableRowHeader from "./table/TableRowHeader";
import TableRowBody from "./table/TableRowBody";
import TableRowDay from "./table/TableRowDay";
import TableRowHeaderText from "./table/TableRowHeaderText";

type AllocationsProps = {
    allocations: EmployeeAllocations[];
    unit: Unit | null;
};

export default function Allocations(props: AllocationsProps) {
    const { allocations, unit } = props;

    if (!allocations || allocations.length === 0 || !unit) {
        return null;
    }

    return (
        <Collapsible
            className="SchedulerAllocations"
            colorScheme="warning"
            title="Einsatz außer Haus"
            count={allocations.length}
            collapseKey="showAllocations"
        >
            <Table>
                {allocations.map((employeeAllocation) => (
                    <TableRow key={employeeAllocation.employee.id}>
                        <TableRowHeader>
                            <TableRowHeaderText>{employeeAllocation.employee.name}</TableRowHeaderText>
                        </TableRowHeader>
                        <TableRowBody>
                            {[1, 2, 3, 4, 5, 6, 7].map((dow) => {
                                const workplaces = employeeAllocation.days[dow].filter(
                                    (workplace) => workplace.unit_id != unit.id
                                );
                                return (
                                    <TableRowDay empty={workplaces.length == 0} key={dow}>
                                        {workplaces.map((workplace: AllocationWorkplace) => (
                                            <div key={workplace.unit_id} className="SchedulerAllocations__Workplace">
                                                <div className="SchedulerAllocations__Workplace__UnitName">
                                                    {workplace.unit_name}
                                                </div>
                                                {workplace.deployments.length === 0 ? (
                                                    <div className="SchedulerAllocations__Workplace__Times--empty">
                                                        Kein Einsatz geplant
                                                    </div>
                                                ) : (
                                                    <div className="SchedulerAllocations__Workplace__Times">
                                                        {workplace.deployments.map((deployment) => (
                                                            <div
                                                                key={deployment.id}
                                                                className="SchedulerAllocations__Workplace__Times__Time"
                                                            >
                                                                {deployment.start_time} - {deployment.end_time}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </TableRowDay>
                                );
                            })}
                        </TableRowBody>
                    </TableRow>
                ))}
            </Table>
        </Collapsible>
    );
}
