import Button from "./Button";
import * as React from "react";
import { WeekIdentifier } from "./types";
import { conditionalClassNames } from "../../../lib/dom-utils/class-names";

type TabNavProps = {
    week: WeekIdentifier;
    onChange: (week: WeekIdentifier) => unknown;
};

export default function TabNav(props: TabNavProps) {
    const { week, onChange } = props;

    return (
        <div className="d-flex gap-1 mb-3 justify-content-center">
            <TabNavButton active={week === "previous"} onClick={() => onChange("previous")}>
                Vorherige Woche
            </TabNavButton>
            <TabNavButton active={week === "current"} onClick={() => onChange("current")}>
                Aktuelle Woche
            </TabNavButton>
            <TabNavButton active={week === "next"} onClick={() => onChange("next")}>
                Nächste Woche
            </TabNavButton>
            <TabNavButton active={week === "afterNext"} onClick={() => onChange("afterNext")}>
                Übernächste Woche
            </TabNavButton>
        </div>
    );
}

type TabNavButtonProps = {
    active: boolean;
    onClick: () => unknown;
    children: React.ReactNode;
};

function TabNavButton(props: TabNavButtonProps) {
    const { active, onClick, children } = props;
    const classNames = conditionalClassNames({
        "WorkHub__TabNav__Button": true,
        "WorkHub__TabNav__Button--active": active
    }).join(" ");
    return (
        <Button
            className={classNames}
            colorTheme={active ? "indigo-light" : "slate"}
            arrowIconType={null}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}
