import * as React from "react";
import { useEffect, useRef } from "react";
import { conditionalClassNames } from "../dom-utils/class-names";

export type FinderResultProps = {
    children: React.ReactNode;
    selected: boolean;
    onClick: () => void;
};

export default function FinderResult(props: FinderResultProps) {
    const { children, selected, onClick } = props;

    const ref = useRef();

    useEffect(() => {
        if (selected) {
            const element = ref?.current as HTMLElement;
            element.scrollIntoView({ behavior: "instant", block: "nearest", inline: "start" });
        }
    }, [selected]);

    const classNames = conditionalClassNames({
        FinderResult: true,
        "FinderResult--selected": selected,
    });

    return (
        <li className={classNames.join(" ")} onClick={onClick} ref={ref}>
            {children}
        </li>
    );
}
