import * as React from "react";
import { useState } from "react";
import Kitchensink from "../../../../../../lib/kitchensink/Kitchensink";
import TabNav from "../../../../../../lib/tab-nav/TabNav";
import Tab from "../../../../../../lib/tab-nav/Tab";
import { AbsenceTabContent } from "./AbsenceTabContent";

export function AbsenceSummary() {
    const [activeTab, setActiveTab] = useState<"last-3-years" | "last-year">("last-3-years");

    return (
        <Kitchensink.ItemBodyPart className="EmployeeManager__Factsheet__Absences__ReaonsPlot">
            <Kitchensink.ItemBodyPartHeadline>Statistik</Kitchensink.ItemBodyPartHeadline>

            <TabNav size="sm" theme="light">
                <Tab onClick={() => setActiveTab("last-3-years")} active={activeTab === "last-3-years"}>
                    Letzte 3 Jahre
                </Tab>
                <Tab onClick={() => setActiveTab("last-year")} active={activeTab === "last-year"}>
                    Letzte 12 Monate
                </Tab>
            </TabNav>

            <AbsenceTabContent timeFrame={activeTab} />
        </Kitchensink.ItemBodyPart>
    );
}
