import * as React from "react";
import { ReactNode, useState } from "react";
import { DUnit } from "../types";
import WithDataFromApi, { WithDataFromApiChildrenArgs } from "../../../lib/api/WithDataFromApi";
import { useNavigateReport } from "./useNavigateReport";

async function fetchData(signal): Promise<DUnit[]> {
    const response = await fetch("/units.json", { signal });
    return await response.json();
}

type WithUnitsFromApiProps = {
    children: (args: WithDataFromApiChildrenArgs<DUnit[]>) => ReactNode;
};

export default function WithUnitsFromApi(props: WithUnitsFromApiProps) {
    const { children } = props;

    const navigateReport = useNavigateReport();

    const handleData = (data: DUnit[]) => {
        setPerformed(true);
        navigateReport({ unitIds: data.map((unit) => unit.id) });
    };

    const [performed, setPerformed] = useState<boolean>(false);

    return (
        <WithDataFromApi<DUnit[]> hot={!performed} fetchData={fetchData} onData={handleData}>
            {children}
        </WithDataFromApi>
    );
}
