import * as React from "react";
import { FaceDefaultProps } from "./type";
import AbsenceEditor from "../editors/AbsenceEditor";
import { AbsenceCellPayload } from "../types";
import FaceFrame from "./FaceFrame";

type AbsenceFaceProps = FaceDefaultProps & {
    currentCellPayload: AbsenceCellPayload;
    onDeploymentSuccessChanged: (success: boolean) => unknown;
};

export default function AbsenceFace(props: AbsenceFaceProps): JSX.Element {
    const { busy, onBusyChange, currentCellPayload, onDeploymentSuccessChanged } = props;

    const commonProps = {
        currentCellPayload: currentCellPayload,
        busy: busy,
        onBusyChange: onBusyChange,
        onDeploymentSuccessChanged: onDeploymentSuccessChanged,
    };

    return (
        <FaceFrame title={"Abwesenheit"} icon={"absence"}>
            <AbsenceEditor {...commonProps} />
        </FaceFrame>
    );
}
