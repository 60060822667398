import * as React from "react";
import useWorkHubState from "../../../state/useWorkHubState";

import Deployment from "../../results-page/personal-schedules/weekly-deployment-list/Deployment";

export default function TodaysDeployments() {
    const { employeeInfo } = useWorkHubState();
    return (
        <div className="WorkHub__TodaysDeployments">
            {employeeInfo.today.deployments.map((deploymentData) => (
                <Deployment key={deploymentData.id} deployment={deploymentData} />
            ))}
        </div>
    );
}
