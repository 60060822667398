import { PresenceRow } from "./PresenceRow";
import * as React from "react";
import { ReportDay } from "../types";

type PresenceTableProps = {
    day: ReportDay;
}

export function PresenceTable(props: PresenceTableProps) {
    const { day } = props;

    return (
        <div className="EttReportGrid__PresenceTableWrapper">
            <table className="EttReportGrid__PresenceTable">
                <thead>
                <tr>
                    <th className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--presence-times">Anwesenheit</th>
                    <th className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--break-times">Pausenzeiten</th>
                    <th className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--break-hours">Pause</th>
                    <th className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--work-hours">Arbeit</th>
                    <th className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--spacer"></th>
                </tr>
                </thead>
                <tbody>
                {day.presences.length !== 0 && day.presences.map((presence, idx1) => (
                    <PresenceRow key={idx1} presence={presence} />
                ))}
                </tbody>
            </table>
        </div>
    );
}
