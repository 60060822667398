import * as React from "react";
import { useEffect } from "react";
import Modal2 from "../../../lib/modal/Modal2";
import { useImmerReducer } from "use-immer";
import Loading from "../../../lib/empty-state/Loading";
import { Document } from "./types";
import { ExportPrivileges } from "../types";
import { Error } from "./Error";
import Button from "../../../lib/button/Button";
import { deleteDocument, loadDocuments } from "./api";
import { formatDateTime } from "../../../lib/datetime/date-format";
import { CopyStringField } from "../../../lib/copy-string-field/CopyStringField";

type State = {
    loading: boolean;
    documents?: Document[];
    error?: string;
    deletingDocuments: Document["id"][];
    deleteRequestSent: Document["id"][];
};

type Action =
    | { type: "setDocuments"; documents: Document[] }
    | { type: "loadingError"; error: string }
    | { type: "deleteDocument"; documentId: Document["id"] }
    | { type: "documentDeleted"; documentId: Document["id"] }
    | { type: "deleteDocumentError"; documentId: Document["id"]; error: string }
    | { type: "deleteRequestSent"; documentId: Document["id"] };

function reducer(draft: State, action: Action) {
    switch (action.type) {
        case "setDocuments":
            draft.documents = action.documents;
            draft.loading = false;
            draft.error = null;
            return;
        case "loadingError":
            draft.error = action.error;
            draft.loading = false;
            return;
        case "deleteDocument":
            draft.deletingDocuments.push(action.documentId);
            return;
        case "deleteRequestSent":
            draft.deleteRequestSent.push(action.documentId);
            return;
        case "documentDeleted":
            draft.deletingDocuments = draft.deletingDocuments.filter((id) => id !== action.documentId);
            draft.deleteRequestSent = draft.deleteRequestSent.filter((id) => id !== action.documentId);
            draft.documents = draft.documents.filter((document) => document.id !== action.documentId);
            return;
        case "deleteDocumentError":
            draft.deletingDocuments = draft.deletingDocuments.filter((id) => id !== action.documentId);
            draft.deleteRequestSent = draft.deleteRequestSent.filter((id) => id !== action.documentId);
            draft.error = action.error;
            return;
    }
}

const initialState: State = {
    loading: true,
    documents: null,
    error: null,
    deletingDocuments: [],
    deleteRequestSent: [],
};

type DocumentManagerProps = {
    scheduleId: string;
    onClose: () => unknown;
    onExport: () => unknown;
    exportPrivileges: ExportPrivileges;
};

export function DocumentManager(props: DocumentManagerProps) {
    const { scheduleId, onClose, onExport } = props;
    const [state, dispatch] = useImmerReducer(reducer, initialState);
    const title = "Dokumente";

    useEffect(() => {
        loadDocuments(scheduleId)
            .then((documents) => dispatch({ type: "setDocuments", documents }))
            .catch((error) => {
                console.error(error);
                dispatch({ type: "loadingError", error });
            });
    }, [scheduleId]);

    useEffect(() => {
        if (state.deletingDocuments.length > 0) {
            const documentIdsToDelete = state.deletingDocuments.filter(
                (id) => -1 === state.deleteRequestSent.indexOf(id)
            );

            for (const documentId of documentIdsToDelete) {
                dispatch({ type: "deleteRequestSent", documentId });
                deleteDocument(scheduleId, documentId)
                    .then((success) => {
                        if (success) {
                            dispatch({ type: "documentDeleted", documentId });
                        } else {
                            dispatch({
                                type: "deleteDocumentError",
                                documentId,
                                error: "Das Dokument konnte nicht gelöscht werden.",
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        dispatch({ type: "deleteDocumentError", documentId, error });
                    });
            }
        }
    }, [state.deletingDocuments, state.deleteRequestSent]);

    return (
        <Modal2 open={true} onClose={onClose} title={title}>
            <div className="mb-3">Die folgenden Dokumente wurden für diesen Wochenplan exportiert.</div>
            {state.loading && <Loading />}
            {!state.loading && (
                <>
                    {state.documents !== null && (
                        <div className="card mb-3 overflow-auto" style={{ maxHeight: 300 }}>
                            <div className="list-group list-group-flush">
                                {0 === state.documents.length && (
                                    <div className="list-group-item text-muted">
                                        Es wurden noch keine Dokumente exportiert.
                                    </div>
                                )}
                                {state.documents.map((document) => {
                                    const deleting = -1 !== state.deletingDocuments.indexOf(document.id);
                                    return (
                                        <div
                                            key={document.id}
                                            rel="noreferrer"
                                            className="list-group-item list-group-item-action d-flex justify-content-between align-items-start"
                                        >
                                            <div>
                                                <div>
                                                    <a
                                                        href={document.url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-decoration-none text-body"
                                                    >
                                                        {document.title}
                                                    </a>
                                                </div>
                                                <div className="text-muted">
                                                    von {document.user.name} am {formatDateTime(document.createdAt)}
                                                </div>
                                            </div>
                                            <div className="d-flex gap-1">
                                                {!deleting && (
                                                    <>
                                                        <CopyStringField text={document.url} caption="Link kopieren" />
                                                        <Button
                                                            caption="Download"
                                                            icon="save"
                                                            theme="primary"
                                                            onClick={() => {
                                                                window.open(document.url, "_blank");
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                <Button
                                                    icon="delete"
                                                    theme="light"
                                                    disabled={deleting}
                                                    aria-label="Löschen"
                                                    title="Löschen"
                                                    onClick={() =>
                                                        dispatch({ type: "deleteDocument", documentId: document.id })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div>
                        <Button caption="Neues Dokument exportieren" icon="export" theme="primary" onClick={onExport} />
                    </div>
                </>
            )}
            {!state.loading && state.error && (
                <div className="d-flex gap-2 flex-column">
                    <Error message="Beim Laden der Dokumente ist ein Fehler aufgetreten." />
                    <div className="d-flex gap-2 justify-content-between">
                        <Button theme="secondary" onClick={onClose}>
                            Schließen
                        </Button>
                    </div>
                </div>
            )}
        </Modal2>
    );
}
