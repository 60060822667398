import React from "react";

export default function createPlaceholderRows(RowComponent, rowCount) {
    return () => {
        const rows = [];
        for (let i = 0; i < rowCount; i++) {
            rows.push(<RowComponent key={i} />);
        }
        return rows;
    };
}
