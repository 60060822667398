import * as React from "react";
import { conditionalClassNames } from "../dom-utils/class-names";

export type EmployeeBalance = {
    invalidated?: boolean;
    employeeId: string;
    thisWeekBalance: string;
    humanThisWeekBalance: string;
    targetHours: string;
    humanTargetHours: string;
    diffHours: string;
    humanDiffHours: string;
    split?: boolean;
    splitThisWeekBalance: string;
    humanSplitThisWeekBalance: string;
    splitTargetHours: string;
    humanSplitTargetHours: string;
    splitDiffHours: string;
    humanSplitDiffHours: string;
};

type BalancesProps = {
    balance: EmployeeBalance;
    hideLabels?: boolean;
};

function classForNumber(num) {
    if (num < 0) {
        return "negative";
    } else if (num > 0) {
        return "positive";
    } else {
        return "zero";
    }
}

export default function Balances(props: BalancesProps) {
    const { balance, hideLabels = true } = props;
    const {
        thisWeekBalance,
        targetHours,
        diffHours,
        splitThisWeekBalance,
        splitDiffHours,
        splitTargetHours,
        humanDiffHours,
        humanTargetHours,
        humanThisWeekBalance,
        humanSplitDiffHours,
        humanSplitTargetHours,
        humanSplitThisWeekBalance,
        invalidated = false,
        split = false,
    } = balance || {};

    const invalidEl = <div className="text-muted">Lade...</div>;

    const classNames = conditionalClassNames({
        Balances: true,
        "Balances--hidden-labels": hideLabels,
    });

    return (
        <div className={classNames.join(" ")}>
            <table>
                <thead>
                    <tr>
                        {null !== targetHours && <th className={"target"}>Soll</th>}
                        {null !== diffHours && <th className={"diff"}>Diff</th>}
                        {null !== thisWeekBalance && <th className={"current"}>Aktuell</th>}
                    </tr>
                </thead>
                <tbody>
                    {split && (
                        <tr>
                            {null !== splitTargetHours && (
                                <td className={`target ${classForNumber(splitTargetHours)}`}>
                                    {invalidated ? invalidEl : humanSplitTargetHours ?? "..."}
                                </td>
                            )}
                            {null !== splitDiffHours && (
                                <td className={`diff ${classForNumber(splitDiffHours)}`}>
                                    {invalidated ? invalidEl : humanSplitDiffHours ?? "..."}
                                </td>
                            )}
                            {null !== splitThisWeekBalance && (
                                <td className={`current ${classForNumber(splitThisWeekBalance)}`}>
                                    {invalidated ? invalidEl : humanSplitThisWeekBalance ?? "..."}
                                </td>
                            )}
                        </tr>
                    )}
                    <tr>
                        {null !== targetHours && (
                            <td className={`target ${classForNumber(targetHours)}`}>
                                {invalidated ? invalidEl : humanTargetHours ?? "..."}
                            </td>
                        )}
                        {null !== diffHours && (
                            <td className={`diff ${classForNumber(diffHours)}`}>
                                {invalidated ? invalidEl : humanDiffHours ?? "..."}
                            </td>
                        )}
                        {null !== thisWeekBalance && (
                            <td className={`current ${classForNumber(thisWeekBalance)}`}>
                                {invalidated ? invalidEl : humanThisWeekBalance ?? "..."}
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
