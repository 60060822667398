import { CreateOrUpdateResult, FormAdapter, GetResult } from "../adapter";
import SchoolTimeSet from "../../model/SchoolTimeSet";
import { patch, post, get } from "../api";
import { DateTime } from "luxon";

function createSchoolTimeSetPath(urlParams: { employeeId: string }) {
    return `/api/employees/${urlParams.employeeId}/school_time_sets`;
}

function updateSchoolTimeSetPath(urlParams: { employeeId: string; id: string }) {
    return `/api/employees/${urlParams.employeeId}/school_time_sets/${urlParams.id}`;
}

function readSchoolTimeSetPath(urlParams: { employeeId: string; id: string }) {
    return `/api/employees/${urlParams.employeeId}/school_time_sets/${urlParams.id}`;
}

function read(urlParams): Promise<GetResult<SchoolTimeSet>> {
    return get(readSchoolTimeSetPath(urlParams));
}

function save(urlParams, data): Promise<CreateOrUpdateResult<SchoolTimeSet>> {
    if (urlParams.id) {
        return update(urlParams, data);
    } else {
        return create(urlParams, data);
    }
}

function create(urlParams, data): Promise<CreateOrUpdateResult<SchoolTimeSet>> {
    return post(createSchoolTimeSetPath(urlParams), data);
}

function update(urlParams, data): Promise<CreateOrUpdateResult<SchoolTimeSet>> {
    return patch(updateSchoolTimeSetPath(urlParams), data);
}

type Initializer = () => SchoolTimeSet;

const buildInitialRecord: Initializer = () => {
    return {
        id: "",

        starts_on: DateTime.local().toISODate(),
        ends_on: null,

        credited_hours_1: 0.0,
        credited_hours_2: 0.0,
        credited_hours_3: 0.0,
        credited_hours_4: 0.0,
        credited_hours_5: 0.0,
        credited_hours_6: 0.0,
        credited_hours_7: 0.0,

        attends_school_on_1: false,
        attends_school_on_2: false,
        attends_school_on_3: false,
        attends_school_on_4: false,
        attends_school_on_5: false,
        attends_school_on_6: false,
        attends_school_on_7: false,

        absence_start_time_1: null,
        absence_start_time_2: null,
        absence_start_time_3: null,
        absence_start_time_4: null,
        absence_start_time_5: null,
        absence_start_time_6: null,
        absence_start_time_7: null,

        absence_end_time_1: null,
        absence_end_time_2: null,
        absence_end_time_3: null,
        absence_end_time_4: null,
        absence_end_time_5: null,
        absence_end_time_6: null,
        absence_end_time_7: null,

        remarks: "",
    };
};

export const schoolTimeSetAdapter: FormAdapter<SchoolTimeSet> = {
    read,
    save,
    create,
    update,
    buildInitialRecord,
};

export default schoolTimeSetAdapter;
