import { State } from "../../../state";

export function humanFraction(simpleFraction: State["absences"]["relevantAbsences"][0]["fraction"]) {
    switch (simpleFraction) {
        case "full_day":
            return "ganztags";
        case "half_day":
            return "halbtags";
        case "multiple_days":
            return "mehrtägig";
        case "invalid":
        default:
            return "ungültig";
    }
}
