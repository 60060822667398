import * as React from "react";
import { HTMLAttributes } from "react";
import Icon from "../../lib/icon/Icon";
import { conditionalClassNames } from "../../lib/dom-utils/class-names";

type AbsenceBarProps = HTMLAttributes<HTMLDivElement> & {
    url?: string;
    message: string;
    onClick?: () => void;
    interactive?: boolean;
    active?: boolean;
    approved?: boolean;
    theme?: "school" | "absence";
    leftOpen?: boolean;
    rightOpen?: boolean;
    extension?: boolean;
};

export default function AbsenceBar(props: AbsenceBarProps) {
    const {
        interactive,
        message,
        onClick,
        active = false,
        approved = true,
        theme = "absence",
        leftOpen = false,
        rightOpen = false,
        extension = false,
        ...otherProps
    } = props;

    delete otherProps.className;

    const classNames = conditionalClassNames({
        SchedulerAbsenceBar: true,
        "SchedulerAbsenceBar--interactive": interactive,
        "SchedulerAbsenceBar--active": active,
        "SchedulerAbsenceBar--approved": approved,
        "SchedulerAbsenceBar--unapproved": !approved,
        "SchedulerAbsenceBar--left-open": leftOpen,
        "SchedulerAbsenceBar--right-open": rightOpen,
        "SchedulerAbsenceBar--extension": extension,
    });
    classNames.push(`SchedulerAbsenceBar--${theme}`);

    const inner = [
        <i key="icon1" className="SchedulerAbsenceBar__Icon1 bi bi-person-x-fill" />,
        <div key="msg" className="SchedulerAbsenceBar__Message">
            {message}
        </div>,
    ];

    if (false === approved) {
        inner.push(
            <Icon
                key={"icon2"}
                type="unapproved"
                className="SchedulerAbsenceBar__Icon2"
                aria-label="Die Abwesenheit wurde noch nicht genehmigt"
                title="Die Abwesenheit wurde noch nicht genehmigt"
            />
        );
    }

    return (
        <div title={message} className={classNames.join(" ")} onClick={() => onClick && onClick()} {...otherProps}>
            {!extension && inner}
        </div>
    );
}
