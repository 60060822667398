import * as React from "react";
import { ReactNode, useRef } from "react";
import { createPortal } from "react-dom";
import { useOutsideClickHandler } from "../dom-utils/useOutsideClickHandler";
import Icon from "../icon/Icon";

type Modal2Props = {
    open: boolean;
    children: ReactNode;
    onClose: () => unknown;
    title: string;
};

export function Modal2(props: Modal2Props) {
    const { open, onClose, title, children } = props;

    if (!open) {
        return null;
    }

    const ref = useRef();
    useOutsideClickHandler(ref, onClose);

    const content = (
        <div className="Modal2">
            <div className="Modal2__Window" ref={ref}>
                <div className="Modal2__Window__Header">
                    <div className="Modal2__Window__Header__Title">{title}</div>
                    <div className="Modal2__Window__Header__Close" onClick={onClose}>
                        <Icon type="close" />
                    </div>
                </div>
                <div className="Modal2__Window__Body">{children}</div>
            </div>
        </div>
    );

    return <>{createPortal(content, document.body)}</>;
}

type Modal2ScrollContentProps = {
    children: ReactNode;
};

export function Modal2ScrollContent(props: Modal2ScrollContentProps) {
    const { children } = props;

    return <div className="Modal2__ScrollContent">{children}</div>;
}

Modal2.ScrollContent = Modal2ScrollContent;
export default Modal2;
