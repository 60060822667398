import * as React from "react";
import useWorkHubState from "../../../state/useWorkHubState";

export default function WeeklyEmptyMessage() {
    const _state = useWorkHubState();
    return (
        <div className="WorkHub__PersonalSchedule__EmptyMessage">
            Für diese Woche wurde kein Einsatzplan veröffentlicht.
        </div>
    );
}
