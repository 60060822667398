import * as React from "react";
import { ReactNode } from "react";
import { createPortal } from "react-dom";

type ModalProps = {
    open: boolean;
    children: ReactNode;
    setOpen: (open: boolean) => unknown;
};

export default function Modal(props: ModalProps) {
    const { open, setOpen, children } = props;

    if (!open) {
        return null;
    }

    const content = (
        <div className="Modal" onClick={() => setOpen(false)}>
            <div className="Modal__Backdrop" />
            <div className="Modal__Backdrop">
                <div className="Modal__Panel__Inner">
                    <div onClick={(e) => e.stopPropagation()} className="Modal__Panel__Inner__VeryInner shadow-lg">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );

    return <>{createPortal(content, document.body)}</>;
}
