import * as React from "react";
import { useMemo } from "react";
import { useEmployeePermissions, useEmployeeState } from "../state";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import { Hint } from "../Hint";
import SkillLevelBadge from "../../../../lib/skill/SkillLevelBadge";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from "recharts";

export function SkillsAndQualifications(props: React.HTMLAttributes<HTMLDivElement>) {
    const { canReadSkillLevels, canManageSkillLevels } = useEmployeePermissions();
    const { ...divProps } = props;
    const { employee } = useEmployeeState();

    if (!canReadSkillLevels) {
        return null;
    }

    return (
        <Kitchensink.Item {...divProps}>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Fähigkeiten & Qualifikation</Kitchensink.ItemBodyTitle>

                <SkillRadarPlot />
                <Skills />

                <Kitchensink.ItemBodyLinkList>
                    {canManageSkillLevels && (
                        <a className="Button Button--primadonna" href={`/employees/${employee.id}/skill_levels`}>
                            Fähigkeiten verwalten
                        </a>
                    )}
                </Kitchensink.ItemBodyLinkList>
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}

function Skills() {
    const { skillLevels } = useEmployeeState();
    return (
        <Kitchensink.ItemBodyPart className="EmployeeManager__Factsheet__Skills">
            {!skillLevels?.length && <Hint muted>Keine Fähigkeiten erfasst</Hint>}
            {skillLevels?.length > 0 && (
                <div className="EmployeeManager__Factsheet__Skills__SkillList">
                    {skillLevels.map((skillLevel) => (
                        <SkillLevelBadge
                            key={skillLevel.id}
                            name={skillLevel.skill.name}
                            level={skillLevel.level}
                            icon={skillLevel.skill.icon}
                        />
                    ))}
                </div>
            )}
        </Kitchensink.ItemBodyPart>
    );
}

function SkillRadarPlot() {
    const { employee, skillLevels } = useEmployeeState();
    const data = useMemo(() => {
        if (!skillLevels) {
            return [];
        }
        return skillLevels.map((skillLevel) => ({
            name: skillLevel.skill.name,
            level: skillLevel.level,
        }));
    }, [employee.id]);

    if (skillLevels.length < 3) {
        // A radar plot with less than 3 data points is not useful.
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <RadarChart
                cx="50%"
                cy="50%"
                outerRadius="80%"
                data={data}
                margin={{ top: 10, right: 50, bottom: 10, left: 50 }}
            >
                <PolarGrid />
                <PolarAngleAxis
                    dataKey="name"
                    style={{
                        fontSize: "12.25px",
                        fontFamily: "var(--bs-body-font-family)",
                        fontWeight: 500,
                    }}
                />
                <PolarRadiusAxis
                    style={{
                        fontSize: "12.25px",
                        fontFamily: "var(--bs-body-font-family)",
                        fontWeight: 500,
                    }}
                />
                <Radar name="Mike" dataKey="level" stroke="#4F46E5" fill="#818CF8" fillOpacity={0.6} />
            </RadarChart>
        </ResponsiveContainer>
    );
}
