import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Meta, BardEvent } from "./types";
import IntervalPickerField from "../../lib/form/interval-picker/IntervalPickerField";
import { DateTime, Interval } from "luxon";
import TextField from "../../lib/form/text/TextField";
import Button from "../../lib/button/Button";

const API_URL = "/api/bard/events";

function getNavigationValues(url) {
    if (!url) {
        return;
    }
    const urlObj = new URL(url, "http://example.com"); // The second parameter is a dummy base URL for compatibility
    return urlObj.searchParams.get("cursor");
}

const initialFilterInterval = Interval.fromDateTimes(DateTime.local().minus({ years: 5 }), DateTime.local());

const Table: React.FC = () => {
    const [events, setEvents] = useState<BardEvent[]>([]);
    const [meta, setMeta] = useState<Meta | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [cursor, setCursor] = useState<string | null>(null);
    const [filterInterval, setFilterInterval] = useState<Interval>(initialFilterInterval);
    const [eventTypeQuery, setEventTypeQuery] = useState<string>("");
    const [tagsQuery, setTagsQuery] = useState<string>("");

    useEffect(() => {
        navigateToSelection();
    }, [cursor]);

    const navigateToSelection = () => {
        const starts_at = filterInterval.start.toISO();
        const ends_at = filterInterval.end.toISO();
        const params = new URLSearchParams({ cursor, starts_at, ends_at, event_type: eventTypeQuery, tags: tagsQuery });
        navigate({ search: params.toString() });
    };

    useEffect(() => {
        fetchEvents();
    }, [location]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            switch (event.key) {
                case "ArrowLeft":
                    handleCursorNavigation(meta?.pagination.prev);
                    break;
                case "ArrowRight":
                    handleCursorNavigation(meta?.pagination.next);
                    break;
                default:
                    break;
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [meta]);

    const fetchEvents = async () => {
        const navigatorParams = new URLSearchParams(location.search);
        const cursor = navigatorParams.get("cursor");
        const starts_at = filterInterval.start.toISO();
        const ends_at = filterInterval.end.toISO();

        const apiParams = new URLSearchParams({
            cursor,
            "filters[starts_at]": starts_at,
            "filters[ends_at]": ends_at,
            "filters[event_type]": eventTypeQuery,
            "filters[tags]": tagsQuery,
        });
        const url = `${API_URL}?${apiParams.toString()}`;
        const response = await fetch(url);
        const responseData = await response.json();

        setEvents(responseData.data);
        setMeta(responseData.meta);
    };

    const handleCursorNavigation = (cursorUrl: string | null) => {
        setCursor(getNavigationValues(cursorUrl));
    };

    const renderTableHeader = () => (
        <tr>
            <th>Date</th>
            <th>Event ID</th>
            <th>Event Type</th>
            <th>Payload</th>
            <th>Tags</th>
        </tr>
    );

    const renderTableRow = (event: BardEvent) => {
        return (
            <tr key={event.id}>
                <td className="text-nowrap">
                    {DateTime.fromISO(event.created_at).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                </td>
                <td>{event.id}</td>
                <td>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setEventTypeQuery(event.event_type);
                            navigateToSelection();
                        }}
                    >
                        {event.event_type}
                    </a>
                </td>
                <td>
                    <code>{JSON.stringify(event.payload, null, 2)}</code>
                </td>
                <td>
                    <div className="d-flex gap-1 flex-wrap">
                        {event.tags.map((tag) => {
                            return (
                                <span
                                    className="badge bg-secondary cursor-pointer"
                                    key={tag}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setTagsQuery(tagsQuery + ` ${tag}`);
                                        navigateToSelection();
                                    }}
                                >
                                    {tag}
                                </span>
                            );
                        })}
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="d-flex flex-column h-100 overflow-hidden">
            <h1 className="h3 p-3 mb-3 border-bottom">Audit Log</h1>
            <div className="p-1 d-flex gap-2">
                <IntervalPickerField value={filterInterval} onChange={(value) => setFilterInterval(value)} />
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigateToSelection();
                }}
            >
                <div className="p-1 d-flex gap-2">
                    <TextField
                        onChange={(value) => setEventTypeQuery(value)}
                        value={eventTypeQuery}
                        placeholder="Event Type"
                    />
                    <TextField onChange={(value) => setTagsQuery(value)} value={tagsQuery} placeholder="Tags" />
                    <Button>Filtern</Button>
                </div>
            </form>
            <div className="overflow-auto" style={{ flex: "1 1 100%" }}>
                <table className="table table-striped">
                    <thead>{renderTableHeader()}</thead>
                    <tbody>{events.map(renderTableRow)}</tbody>
                </table>
            </div>
            <div className="d-flex gap-1 p-3 border-top" style={{ flex: "0 0 auto" }}>
                <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleCursorNavigation(meta?.pagination.first)}
                    disabled={!meta?.pagination.first}
                >
                    First
                </button>
                <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleCursorNavigation(meta?.pagination.prev)}
                    disabled={!meta?.pagination.prev}
                >
                    Previous
                </button>
                <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleCursorNavigation(meta?.pagination.next)}
                    disabled={!meta?.pagination.next}
                >
                    Next
                </button>
                <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => handleCursorNavigation(meta?.pagination.last)}
                    disabled={!meta?.pagination.last}
                >
                    Last
                </button>
            </div>
        </div>
    );
};

export default Table;
