import * as React from "react";
import { HTMLAttributes } from "react";

import DurationField from "../../../lib/form/datetime/DurationField";
import Checkbox from "../../../lib/form/checkbox/Checkbox";
import FormGroup from "../../../lib/form/form/FormGroup";

export type WeekTimeDistribution = {
    show_schooldays: boolean;

    weekly_seconds_target: number;
    schoolday_weekly_seconds_target: number;
    auto_distribution: boolean;
    reset_time_sheet_balance: boolean;

    target_seconds_1: number;
    work_on_1: boolean;
    schoolday_target_seconds_1: number;
    schoolday_work_on_1: boolean;

    target_seconds_2: number;
    work_on_2: boolean;
    schoolday_target_seconds_2: number;
    schoolday_work_on_2: boolean;

    target_seconds_3: number;
    work_on_3: boolean;
    schoolday_target_seconds_3: number;
    schoolday_work_on_3: boolean;

    target_seconds_4: number;
    work_on_4: boolean;
    schoolday_target_seconds_4: number;
    schoolday_work_on_4: boolean;

    target_seconds_5: number;
    work_on_5: boolean;
    schoolday_target_seconds_5: number;
    schoolday_work_on_5: boolean;

    target_seconds_6: number;
    work_on_6: boolean;
    schoolday_target_seconds_6: number;
    schoolday_work_on_6: boolean;

    target_seconds_7: number;
    work_on_7: boolean;
    schoolday_target_seconds_7: number;
    schoolday_work_on_7: boolean;
};

type WeekTimeDistributionFieldSetProps = HTMLAttributes<HTMLSelectElement> & {
    value?: WeekTimeDistribution;
    onChange?: (value: WeekTimeDistribution) => void;
    disabled?: boolean;
};

type DayControlProps = {
    value: WeekTimeDistribution;
    dayName: string;
    dow: number;
    onChange: (value: WeekTimeDistribution) => void;
    disabled?: boolean;
};

function updateTarget(
    weekTimeDistribution: WeekTimeDistribution,
    target: WeekTimeDistribution["weekly_seconds_target"] | WeekTimeDistribution["schoolday_weekly_seconds_target"],
    schoolday: boolean
): WeekTimeDistribution {
    return autoDistribute(
        {
            ...weekTimeDistribution,
            [`${schoolday ? "schoolday_" : ""}weekly_seconds_target`]: target,
        },
        schoolday
    );
}

function updateDaySelection(
    weekTimeDistribution: WeekTimeDistribution,
    dow,
    checked,
    schoolday: boolean
): WeekTimeDistribution {
    return autoDistribute(
        {
            ...weekTimeDistribution,
            [`${schoolday ? "schoolday_" : ""}work_on_${dow}`]: checked,
        },
        schoolday
    );
}

function updateAutoDistribution(
    weekTimeDistribution: WeekTimeDistribution,
    autoDistributeChecked: WeekTimeDistribution["auto_distribution"]
): WeekTimeDistribution {
    return autoDistribute(
        autoDistribute(
            {
                ...weekTimeDistribution,
                auto_distribution: autoDistributeChecked,
            },
            true
        ),
        false
    );
}

function autoDistribute(weekTimeDistribution: WeekTimeDistribution, schoolday: boolean): WeekTimeDistribution {
    const prefix = schoolday ? "schoolday_" : "";
    if (weekTimeDistribution.auto_distribution) {
        const numberOfWorkingDays = Object.keys(weekTimeDistribution).filter(
            (key) => key.startsWith(`${prefix}work_on_`) && weekTimeDistribution[key]
        ).length;

        let secondsPerWorkingDay = Math.round(
            0 === numberOfWorkingDays ? 0 : weekTimeDistribution[`${prefix}weekly_seconds_target`] / numberOfWorkingDays
        );
        // Remember: While we agreed on saving seconds the rounding still applies to minutes. This is a bit awkward
        // (and doesn't make sense completely) as we're basically ignoring the seconds part.
        // But from an UI point of view I don't see how we can still refrain from rounding to the nearest minute.
        //
        // Consider for example 39:00 for 7 days, which is 20057.142857142857143 seconds per day
        // (eq. 5,571428571428571 hours). Rounded to minutes this is 5:34. If we multiply this by 7 we get a remainder
        // of 2 minutes.
        secondsPerWorkingDay = secondsPerWorkingDay - (secondsPerWorkingDay % 60);

        const weeklySecondsTarget = parseInt((weekTimeDistribution[`${prefix}weekly_seconds_target`] || 0).toString());
        const restForLastDay = weeklySecondsTarget - Math.max(numberOfWorkingDays - 1, 0) * secondsPerWorkingDay;

        const targetSeconds = {};
        let activeDaysCounter = 0;
        [1, 2, 3, 4, 5, 6, 7].forEach((dow) => {
            let currentDaySeconds;

            if (weekTimeDistribution[`${prefix}work_on_${dow}`]) {
                activeDaysCounter++;
                if (activeDaysCounter === numberOfWorkingDays) {
                    currentDaySeconds = restForLastDay;
                } else {
                    currentDaySeconds = secondsPerWorkingDay;
                }
            } else {
                currentDaySeconds = 0;
            }
            targetSeconds[`${prefix}target_seconds_${dow}`] = currentDaySeconds;
        });

        return {
            ...weekTimeDistribution,
            ...targetSeconds,
        };
    } else {
        return weekTimeDistribution;
    }
}

function DayControl({ value, dayName, dow, onChange, disabled = false, ...otherProps }: DayControlProps) {
    const workOnProp = `work_on_${dow}`;
    const schooldayWorkOnProp = `schoolday_work_on_${dow}`;
    const hoursProp = `target_seconds_${dow}`;
    const schooldayHoursProp = `schoolday_target_seconds_${dow}`;
    const isValueReadOnly = value.auto_distribution || !value[workOnProp];

    return (
        <div
            className="list-group-item d-flex justify-content-start align-items-center gap-2"
            role="group"
            {...otherProps}
        >
            <div className="flex-1 d-flex justify-content-start align-items-center gap-2" style={{ flex: "0 0 350px" }}>
                <div style={{ flex: "0 0 130px" }}>
                    <Checkbox
                        caption={dayName}
                        checked={value[workOnProp] ?? false}
                        onChange={(checked) => onChange(updateDaySelection(value, dow, checked, false))}
                        disabled={disabled}
                        aria-label={"Verfügbar"}
                    />
                </div>
                <div style={{ flex: "1 1 100%" }}>
                    <DurationField
                        value={value[hoursProp]}
                        onChange={(v) => onChange({ ...value, [hoursProp]: v })}
                        disabled={disabled || isValueReadOnly}
                        aria-label={"Dauer"}
                    />
                </div>
            </div>
            {value.show_schooldays && <div className="flex-1 d-flex justify-content-start align-items-center gap-2" style={{ flex: "0 0 350px" }}>
                <div style={{ flex: "0 0 130px" }}>
                    <Checkbox
                        caption={dayName}
                        checked={value[schooldayWorkOnProp] ?? false}
                        onChange={(checked) => onChange(updateDaySelection(value, dow, checked, true))}
                        disabled={disabled}
                        aria-label={"Verfügbar an Schultagen"}
                    />
                </div>
                <div style={{ flex: "1 1 100%" }}>
                    <DurationField
                        value={value[schooldayHoursProp]}
                        onChange={(v) => onChange({ ...value, [schooldayHoursProp]: v })}
                        disabled={disabled || isValueReadOnly}
                        aria-label={"Dauer an Schultagen"}
                    />
                </div>
            </div>}
        </div>
    );
}

const WeekTimeDistributionFieldSet = (props: WeekTimeDistributionFieldSetProps) => {
    const { value = null, onChange, disabled = false } = props;

    return (
        <>
            <div className="d-flex justify-content-start align-items-center gap-5">
                <FormGroup label="Soll-Wochenstunden" required={true}>
                    <DurationField
                        value={value.weekly_seconds_target ?? ""}
                        onChange={(target) => onChange(updateTarget(value, target, false))}
                        disabled={disabled}
                        aria-label="Soll-Wochenstunden"
                    />
                </FormGroup>
                {value.show_schooldays && <FormGroup label="Soll-Wochenstunden (Schulzeit)" required={true}>
                    <DurationField
                        value={value.schoolday_weekly_seconds_target ?? ""}
                        onChange={(target) => onChange(updateTarget(value, target, true))}
                        disabled={disabled}
                        aria-label="Soll-Wochenstunden (Schulzeit)"
                    />
                </FormGroup>}
            </div>
            <div className="mb-3">
                <div className="d-flex gap-2 align-items-center">
                    <Checkbox
                        caption="Stunden automatisch verteilen"
                        checked={value.auto_distribution ?? false}
                        onChange={(checked) => onChange(updateAutoDistribution(value, checked))}
                        disabled={disabled}
                        aria-label="Stunden automatisch verteilen"
                    />
                </div>
            </div>
            <div className="list-group mb-3">
                <div className="list-group-item d-flex justify-content-start align-items-center gap-2 bg-light">
                    <div
                        className="flex-1 d-flex justify-content-start align-items-center gap-2"
                        style={{ flex: "0 0 350px" }}
                    >
                        <strong>Regulär</strong>
                    </div>
                    {value.show_schooldays && <div
                        className="flex-1 d-flex justify-content-start align-items-center gap-2"
                        style={{ flex: "0 0 350px" }}
                    >
                        <strong>An Schultagen</strong>
                    </div>}
                </div>
                <DayControl
                    value={value}
                    dayName="Montag"
                    dow={1}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label="Wochentag 1"
                />
                <DayControl
                    value={value}
                    dayName="Dienstag"
                    dow={2}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label="Wochentag 2"
                />
                <DayControl
                    value={value}
                    dayName="Mittwoch"
                    dow={3}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label="Wochentag 3"
                />
                <DayControl
                    value={value}
                    dayName="Donnerstag"
                    dow={4}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label="Wochentag 4"
                />
                <DayControl
                    value={value}
                    dayName="Freitag"
                    dow={5}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label="Wochentag 5"
                />
                <DayControl
                    value={value}
                    dayName="Samstag"
                    dow={6}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label="Wochentag 6"
                />
                <DayControl
                    value={value}
                    dayName="Sonntag"
                    dow={7}
                    onChange={onChange}
                    disabled={disabled}
                    aria-label="Wochentag 7"
                />
            </div>
        </>
    );
};

WeekTimeDistributionFieldSet.displayName = "WeekTimeDistributionFieldSet";

export default WeekTimeDistributionFieldSet;
