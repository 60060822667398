export function pluralize(count: number, singular: string, plural: string) {
    return count === 1 ? singular : plural;
}

// Truncate string in the middle.
// Eq. thesaurus -> the...rus
export function truncateMiddle(str: string, maxLength: number) {
    if (str.length <= maxLength) {
        return str;
    }

    const separator = "…";

    const sepLen = separator.length;
    const charsToShow = maxLength - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return str.substr(0, frontChars) + separator + str.substr(str.length - backChars);
}

export type SnakeToCamelCase<S extends string> =
    S extends `${infer T}_${infer U}` ?
        `${T}${Capitalize<SnakeToCamelCase<U>>}` :
        S
    ;

export type CamelToSnakeCase<S extends string> =
    S extends `${infer T}${infer U}` ?
        `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}` :
        S
