import * as React from "react";
import { Option } from "../form/select/ComboBox";
import { joinClasses } from "../dom-utils/class-names";
import Icon from "../icon/Icon";

type OptionProps = {
    option: Option;
    selected: boolean;
    onSelectOption: () => void;
    onAddOptionToSelection: () => void;
};

export default function Option(props: OptionProps) {
    const { option, selected, onSelectOption, onAddOptionToSelection } = props;

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.ctrlKey || event.metaKey || event.shiftKey || event.altKey) {
            onAddOptionToSelection();
        } else {
            onSelectOption();
        }
    };

    return (
        <div
            data-brix-combobox-item={option.value}
            onClick={handleClick}
            className={joinClasses("list-group-item user-select-none", selected && "fw-bold")}
        >
            <span className={joinClasses("block truncate", selected && "font-semibold")}>{option.label}</span>
            {selected && <Icon type="selected" style={{ width: "5rem", height: "5rem" }} className="text-success" />}
        </div>
    );
}
