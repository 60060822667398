import * as React from "react";
import { EmployeeWithAbsentDays } from "./slots/types";
import { Absence } from "./types";
import AbsenceBar from "./AbsenceBar";
import Collapsible from "./Collapsible";
import Table from "./table/Table";
import TableRow from "./table/TableRow";
import TableRowHeader from "./table/TableRowHeader";
import TableRowBody from "./table/TableRowBody";
import TableRowDay from "./table/TableRowDay";
import TableRowHeaderText from "./table/TableRowHeaderText";

type AbsencesProps = {
    absentEmployees: EmployeeWithAbsentDays[];
};

export default function Absences(props: AbsencesProps) {
    const { absentEmployees } = props;

    if (!absentEmployees || absentEmployees.length === 0) {
        return null;
    }

    return (
        <Collapsible
            className={"SchedulerAbsences"}
            colorScheme="warning"
            title="Abwesenheitsübersicht"
            count={absentEmployees.length}
            collapseKey="showAbsences"
        >
            <Table>
                {absentEmployees.map((absentEmployee) => (
                    <TableRow key={absentEmployee.id}>
                        <TableRowHeader>
                            <TableRowHeaderText>{absentEmployee.name}</TableRowHeaderText>
                        </TableRowHeader>
                        <TableRowBody>
                            {[1, 2, 3, 4, 5, 6, 7].map((dow) => {
                                const absences = (absentEmployee.absent_days[dow] as Absence[]) || [];
                                return (
                                    <TableRowDay empty={absences.length == 0} key={dow}>
                                        {absences.map((absence) => (
                                            <AbsenceBar
                                                key={absence.id}
                                                message={absence.reason}
                                                interactive={false}
                                                theme="absence"
                                            />
                                        ))}
                                    </TableRowDay>
                                );
                            })}
                        </TableRowBody>
                    </TableRow>
                ))}
            </Table>
        </Collapsible>
    );
}
