import { useEffect } from "react";
import { ApiReport, ReportEmployee } from "./types";
import { useReportState } from "./StateContextProvider";
import { DateTime } from "luxon";
import { useReportEffects } from "./EffectsContextProvider";
import { underscoreToCamelCase } from "../../lib/object/objects";
import { transformDay } from "../../lib/ett/report-grid/api";

export function useReportFromApi(employeeId: ReportEmployee["id"]) {
    const { setEmployee, setDays, startLoading, stopLoading } = useReportEffects();

    const { startsAt, endsAt } = useReportState();

    useEffect(() => {
        if (null === startsAt || null === endsAt) {
            return;
        }

        startLoading();
        loadReport(employeeId, startsAt, endsAt)
            .then(report => {
                setDays(report.days.map(transformDay));
                setEmployee(underscoreToCamelCase(report.employee) as ReportEmployee);
                stopLoading();
                return report;
            });
    }, [employeeId, startsAt, endsAt]);
}

export async function loadReport(employeeId: string, startsAt: DateTime, endsAt: DateTime): Promise<ApiReport> {
    const url = new URL(`/employees/${employeeId}/ett_report/api/report`, window.location.origin);
    url.searchParams.append("starts_at", startsAt.toISODate());
    url.searchParams.append("ends_at", endsAt.plus({ days: 1 }).toISODate());

    const headers = {
        "Accept": "application/json"
    };

    const response = await fetch(url, { headers });
    return await response.json();
}

