import * as React from "react";
import { PersonalSchedule } from "../../../../state/types/personal-schedule";
import Duration from "../../../../shared/Duration";

type BalancesProps = {
    balances: PersonalSchedule["days"][number]["balances"];
}

export default function Balances(props: BalancesProps) {
    const { balances: { humanBreakDuration, humanWorkDuration } } = props;

    return (
        <div className="WorkHub__WeeklyDeploymentsList__Day__Balances">
            <Duration colorTheme={"indigo"}>
                {humanWorkDuration} h
            </Duration>
            <Duration colorTheme={"slate"}>
                {humanBreakDuration} h
            </Duration>
        </div>
    );
}
