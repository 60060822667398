import { Schedule } from "../../../types";
import { Skill } from "../../../../../lib/types/skills";
import { useEffect, useMemo, useState } from "react";
import { responseToSkilledEmployees, searchEmployees } from "./search";
import useCombinedDepartmentSkills from "./useCombinedDepartmentSkills";
import { GetAvailableEmployeesResponse } from "../../../types/api-responses";
import { sortEmployeesBySkillLevels } from "./skills";

export default function useEmployeeSearch(
    scheduleId: Schedule["id"],
    query: string,
    departmentPrimarySkills: Skill[],
    departmentSecondarySkills: Skill[],
    onlyQualified: boolean,
    onBusyChange: (busy: boolean) => void
) {
    const [response, setResponse] = useState<GetAvailableEmployeesResponse>([]);
    const combinedDepartmentSkills = useCombinedDepartmentSkills(departmentPrimarySkills, departmentSecondarySkills);

    useEffect(() => {
        const abortCtrl = new AbortController();
        (async () => {
            onBusyChange(true);
            const response = await searchEmployees(scheduleId, query, abortCtrl.signal);
            setResponse(response);
            onBusyChange(false);
        })();
    }, [scheduleId]);

    const skilledEmployees = useMemo(
        () => responseToSkilledEmployees(response, combinedDepartmentSkills),
        [response, combinedDepartmentSkills]
    );

    const filteredEmployees = useMemo(() => {
        if (query.length > 0) {
            const queryInLowerCase = query.toLowerCase();
            return skilledEmployees.filter((employee) => employee.name.toLowerCase().includes(queryInLowerCase));
        } else {
            return skilledEmployees;
        }
    }, [query, skilledEmployees]);

    const qualifiedEmployees = useMemo(() => {
        if (onlyQualified) {
            return filteredEmployees.filter((employee) => {
                return employee.skills.some((skill) => skill.isPrimary);
            });
        } else {
            return filteredEmployees;
        }
    }, [filteredEmployees, onlyQualified]);

    const sortedEmployees = useMemo(() => sortEmployeesBySkillLevels(qualifiedEmployees), [qualifiedEmployees]);

    return sortedEmployees;
}
