import * as React from "react";
import { PersonalSchedule } from "../../../state/types/personal-schedule";
import Day from "./weekly-deployment-list/Day";

type WeeklyDeploymentListProps = {
    personalSchedule: PersonalSchedule
}

export default function WeeklyDeploymentList(props: WeeklyDeploymentListProps) {
    const { personalSchedule: { days } } = props;

    return (
        <div className="WorkHub__WeeklyDeploymentsList">
            {days.map(day => (
                <Day key={day.date} day={day} />
            ))}
        </div>
    );
}
