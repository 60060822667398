import * as React from "react";
import { createInitialState } from "./state";
import { useImmerReducer } from "use-immer";
import { reducer } from "./reducer";
import { StateContext } from "./StateContext";
import useDevTools from "../shared/useDevTools";
import { Action } from "./actions";

type StateProviderProps = {
    children: React.ReactNode
}

export default function StateContextProvider(props: StateProviderProps) {
    const [state, dispatch] = useImmerReducer(reducer, [], createInitialState);

    useDevTools<Action>(dispatch);

    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {props.children}
        </StateContext.Provider>
    );
}
