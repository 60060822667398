import * as React from "react";
import { formatNumber, FormatNumberOptions } from "./number";

type NumericValueProps = {
    value: string | number;
    options: FormatNumberOptions;
    colors?: boolean | "dark" | "light";
};

export function NumericValue(props: NumericValueProps) {
    const { value, options = {}, colors = true } = props;

    const num = Number(value);
    let color = "dark";
    if (colors) {
        if (colors === "dark") {
            color = num > 0 ? "white" : num < 0 ? "#FDA4AF" : "#ddd";
        } else {
            color = num > 0 ? "black" : num < 0 ? "#E11D48" : "#aaa";
        }
    }

    return <span style={{ color }}>{formatNumber(num, options)}</span>;
}
