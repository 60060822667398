import * as React from "react";
import { forwardRef } from "react";

type VideoProps = {
    scannerActive: boolean;
}

const Video = forwardRef<HTMLVideoElement, VideoProps>((props, ref) => {
    const { scannerActive } = props;

    return (<>
            <div className="d-flex justify-content-center align-items-center">
                <div className="rounded-3 overflow-hidden border border-2 border-white position-relative"
                     style={{ width: "400px", height: "225px" }}>
                    <video
                        ref={ref}
                        width="100%"
                        className="bg-dark"
                        style={{ aspectRatio: "1920/1080", width: "100%", height: "100%", objectFit: "cover" }}
                    />
                    {!scannerActive && (
                        <div
                            className="text-white bg-dark bg-opacity-50 p-2 d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100"
                            style={{ cursor: "pointer" }}
                        >
                            <div className="d-flex gap-3 align-items-center">
                                <div style={{ fontSize: "40px" }}>
                                    <i className="bi bi-hand-index"></i>
                                </div>
                                <div>
                                    <strong>Die Kamera ist inaktiv.</strong>
                                    <br />
                                    Berühre den Bildschirm um sie zu starten.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {scannerActive ? (
                <div className="d-flex justify-content-center align-items-center gap-2">
                    <span>Kommt kein Video?</span>
                    <button className="btn btn-outline-secondary" onClick={() => document.location.reload()}>
                        Starte die Kamera neu
                    </button>
                </div>
            ) : (
                <div style={{ height: "29px" }}></div>
            )}
        </>
    );
});

Video.displayName = "Video";
export default Video;
