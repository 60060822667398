import React from "react";
import PropTypes from "prop-types";

export default function NumericValue({ label = null, humanValue, robotValue, bold = false }) {
    const classNames = [];
    const styles = {};

    if (bold) {
        classNames.push("fw-bold");
    }

    robotValue = Math.round(1000 * Number(robotValue)) / 1000;

    if (robotValue < 0) {
        classNames.push("text-danger");
    } else if (robotValue === 0) {
        styles.color = "#aaa";
    }

    return (
        <span className={classNames.join(" ")} style={styles} aria-label={label}>
            {humanValue}
        </span>
    );
}

NumericValue.propTypes = {
    label: PropTypes.string,
    humanValue: PropTypes.string,
    robotValue: PropTypes.string,
    bold: PropTypes.bool,
};
