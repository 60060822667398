import * as React from "react";

type GroupNameProps = {
    name: string;
    selectGroupItems: () => unknown;
};

export default function GroupName(props: GroupNameProps) {
    const { name, selectGroupItems } = props;

    return (
        <button
            type="button"
            className="cursor-default select-none py-2 pl-4 pr-4 text-gray-500 bg-slate-100 w-full text-left"
            onClick={() => selectGroupItems()}
        >
            {name}
        </button>
    );
}
