import * as React from "react";
import { conditionalClassNames, joinClasses } from "../../../lib/dom-utils/class-names";

export function Hint(
    props: { muted?: boolean; dark?: boolean; children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>
) {
    const { muted = false, dark = false, children, ...divProps } = props;

    divProps.className = joinClasses(
        ...conditionalClassNames({
            EmployeeManager__Hint: true,
            "EmployeeManager__Hint--dark": dark,
            "EmployeeManager__Hint--muted": muted,
        }),
        divProps.className
    );

    return <div {...divProps}>{children}</div>;
}
