import React from "react";
import Cell from "../cell";
import cellWidths from "../cell-widths";
import TextPlaceholder from "../text-placeholder";

export default function WeekPlaceholderRow() {
    return (
        <tr>
            <Cell key={1} center={true} style={{ width: cellWidths.employee, height: "48px" }}>
                <TextPlaceholder text="Mustermann, Manfred" />
            </Cell>
            <Cell key={2} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={3} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={4} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={5} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={6} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={7} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={8} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={9} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={10} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={11} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={12} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={13} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={14} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={15} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={16} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={17} center={true} style={{ width: cellWidths.hours }}>
                <TextPlaceholder text="00:00" />
            </Cell>
            <Cell key={18} center={true} style={{ width: cellWidths.actions }} />
        </tr>
    );
}
