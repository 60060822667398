import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../lib/button/Button";
import Select from "../../../../lib/form/select/Select";
import { ScheduleIdContext } from "../../Scheduler";
import Loading from "../../../../lib/./empty-state/Loading";
import { AbsenceReason } from "../../types";
import { AbsenceEditorPayload } from "../types";
import { DataContext } from "../../slots/DataContext";
import CursorInspector from "../CursorInspector";
import { ApiAttributeErrors } from "../../slots/types";
import Icon from "../../../../lib/icon/Icon";

type AbsenceEditorProps = {
    currentCellPayload: AbsenceEditorPayload;
    busy: boolean;
    onBusyChange: (busy: boolean) => void;
    onDeploymentSuccessChanged: (success: boolean) => void;
};

export default function AbsenceEditor(props: AbsenceEditorProps) {
    const { currentCellPayload, onDeploymentSuccessChanged, busy, onBusyChange } = props;
    const scheduleId = useContext<string>(ScheduleIdContext);
    const { createAbsence, destroyAbsence } = useContext(DataContext);

    const [loading, setLoading] = useState<number>(0);
    const [reasons, setReasons] = useState<{ [key: string]: string }>(null);
    const [selectedReasonId, setSelectedReasonId] = useState<AbsenceReason["id"]>(null);

    const [errors, setErrors] = useState<ApiAttributeErrors | null>(null);

    const isMounted = useRef(true);

    useEffect(() => {
        onBusyChange(loading > 0);
    }, [loading]);

    useEffect(() => {
        setLoading((loading: number) => loading + 1);
        fetch(`/api/scheduler/schedules/${scheduleId}/absence_reasons`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Failed to fetch absence reasons. HTTP status: ${response.status}`);
                }
                return response.json();
            })
            .then((reasons: AbsenceReason[]) => {
                if (isMounted.current) {
                    setReasons(
                        reasons.reduce(
                            (memo, reason) => ({
                                ...memo,
                                [reason.id]: reason.name,
                            }),
                            {}
                        )
                    );
                    if (reasons.length > 0) {
                        setSelectedReasonId(reasons[0].id);
                    }
                    setLoading((loading: number) => loading - 1);
                }
            })
            .catch((error) => {
                if (isMounted.current) {
                    setLoading((loading: number) => loading - 1);
                }
                console.error("Error fetching absence reasons:", error);
                setErrors(["Fehler beim Laden der Abwesenheitsgründe"]);
            });
        return () => {
            isMounted.current = false;
        };
    }, [scheduleId]);

    function handleSave(): void {
        if (busy) {
            return;
        }
        setLoading((loading: number) => loading + 1);
        createAbsence(currentCellPayload.employeeId, selectedReasonId, currentCellPayload.dow).then((apiResult) => {
            setLoading((loading: number) => loading - 1);

            setErrors(apiResult.errors);

            if (apiResult.errors && Object.keys(apiResult.errors).length > 0) {
                onDeploymentSuccessChanged(false);
            } else {
                onDeploymentSuccessChanged(true);
            }
        });
    }

    return (
        <>
            <form
                className="d-block"
                onSubmit={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleSave();
                }}
            >
                {currentCellPayload.absences?.length ? (
                    currentCellPayload.absences.map((absence) => (
                        <div className="p-1" key={absence.id}>
                            <div className="mb-2">Der Mitarbeiter ist an diesem Tag abwesend:</div>
                            <div className="d-flex justify-content-between align-items-center">
                                {absence.approved ? (
                                    <Icon type="approved" className="text-success" title="Genehmigt" />
                                ) : (
                                    <Icon type="unapproved" className="text-danger" title="Ungenehmigt" />
                                )}

                                <a href={absence.edit_url} target="_blank" rel="noreferrer">
                                    {absence.reason} <i className="bi bi-arrow-up-right-square" />
                                </a>
                                <Button
                                    aria-label="Abwesenheit löschen"
                                    title="Abwesenheit löschen"
                                    type="button"
                                    icon="delete"
                                    theme="light"
                                    size="sm"
                                    disabled={busy}
                                    onClick={() => {
                                        setLoading((loading: number) => loading + 1);
                                        destroyAbsence(absence.id).then((apiResult) => {
                                            setLoading((loading: number) => loading - 1);
                                            if (apiResult.errors && Object.keys(apiResult.errors).length > 0) {
                                                onDeploymentSuccessChanged(false);
                                            } else {
                                                onDeploymentSuccessChanged(true);
                                            }
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="py-1">
                        <div className="mb-2">
                            {reasons ? (
                                <Select
                                    aria-label="Abwesenheitsgrund"
                                    autoFocus={true}
                                    options={reasons}
                                    includeBlank={false}
                                    value={selectedReasonId}
                                    onChange={(value) => setSelectedReasonId(value)}
                                    size="sm"
                                    disabled={busy}
                                />
                            ) : (
                                <Loading />
                            )}
                        </div>
                        <Button theme="primary" size="sm" caption="Ganztägige Abwesenheit anlegen" disabled={busy} />
                    </div>
                )}
            </form>
            <CursorInspector errors={errors} />
        </>
    );
}
