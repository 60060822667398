import * as React from "react";

export function LegendRect({ color }: { color: string }) {
    return (
        <span
            className="EmployeeManager__Factsheet__Absences__MetricsList__LegendRect"
            style={{ backgroundColor: color }}
        />
    );
}
