import * as React from "react";
import Icon from "../icon/Icon";

type LoadingErrorProps = {
    message?: string;
};

export function LoadingError(props: LoadingErrorProps) {
    const { message } = props;
    return (
        <div className="LoadingError">
            <Icon type="warning-circle" />
            <div>{message ?? "Ladefehler"}</div>
        </div>
    );
}
