import React from "react";
import cellWidths from "../cell-widths";

export default function MonthTableHeadRows() {
    return (
        <>
            <tr>
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.employee }}
                    className="border-bottom-0"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: "auto" }}
                    className="border-bottom-0 text-end"
                />
            </tr>
            <tr>
                <th colSpan={1} />
                <th
                    colSpan={4}
                    className="text-center"
                    style={{ verticalAlign: "middle", fontSize: "0.875rem", borderLeft: "2px solid #ccc" }}
                >
                    Abwesenheit
                </th>
                <th
                    colSpan={5}
                    className="text-center"
                    style={{ verticalAlign: "middle", fontSize: "0.875rem", borderLeft: "2px solid #ccc" }}
                >
                    Ergebnis
                    <br />
                    <span
                        className="text-muted font-monospace d-inline-block ms-2"
                        style={{ textAlign: "left", verticalAlign: "middle", fontSize: 12, fontWeight: "normal" }}
                    >
                        <div className="border-bottom">gemäß Wochenplan</div>
                        <div>Stundengutschrift</div>
                    </span>
                </th>
                <th
                    colSpan={2}
                    className="text-center"
                    style={{ verticalAlign: "middle", fontSize: "0.875rem", borderLeft: "2px solid #ccc" }}
                >
                    Saldo
                </th>
                <th style={{ borderLeft: "2px solid #ccc" }} />
            </tr>
            <tr>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0">
                    Mitarbeiter
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    bezahlt
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <abbr title="unbezahlt">unbez.</abbr>
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    <abbr title="mit Stundengutschrift">
                        mit
                        <br />
                        SG
                    </abbr>
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <abbr title="ohne Stundengutschrift">
                        ohne
                        <br />
                        SG
                    </abbr>
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                  <abbr title="geleistete Arbeitsstunden / angerechnete Arbeitsstunden">∑</abbr>
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <abbr title="Korrektur">Korr.</abbr>
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="text-end">
                  <abbr title="Korrigierte gleistete Arbeitsstunden / Finale Stundengutschrift">K/F∑</abbr>
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    Soll
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <abbr title="Differenz">Diff.</abbr>
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    <abbr title="Vormonat">Vormt.</abbr>
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    Monat
                </th>
                <th style={{ borderLeft: "2px solid #ccc" }} />
            </tr>
        </>
    );
}
