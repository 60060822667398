import * as React from "react";
import { useMemo } from "react";
import { useFilterParams } from "./useFilterParams";
import { buildReportUrl } from "./url";
import Icon from "../../../lib/icon/Icon";

export default function ExportButton() {
    const { unitIds, year } = useFilterParams();

    const url = useMemo(() => buildReportUrl(unitIds, year, "xlsx"), [unitIds, year]);

    return (
        <a href={url} download={"Urlaubsbericht.xlsx"} className="btn btn-primary">
            <Icon type="export-excel" />
            &nbsp; Exportieren
        </a>
    );
}
