import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../../../lib/dom-utils/class-names";

type TableProps = HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
};

export default function Table(props: TableProps) {
    const { children, ...otherProps } = props;
    otherProps.className = joinClasses("SchedulerTable", props.className);

    return <div {...otherProps}>{children}</div>;
}
