import * as React from "react";

import DurationStringField from "../../../lib/form/datetime/DurationStringField";
import Icon from "../../../lib/icon/Icon";
import { TimeSheetsAdjustment } from "../../../lib/model/TimeSheetsDay";

type TimeSheetsDayCorrectionProps = {
  adjustment?: TimeSheetsAdjustment;
  field: string;
  name: string;
  onChange: (value) => void;
  disabled: boolean;
};

export default function TimeSheetsDayCorrection(props: TimeSheetsDayCorrectionProps) {
    const { adjustment = {} as TimeSheetsAdjustment, field, name, onChange, disabled = false } = props

    return (
        <>
        <tr>
            <td>
                {name}
                <Icon
                    type="delete"
                    className={"TimeSheetsDay__Trash " + (disabled ? "" : "TimeSheetsDay__Pointer")}
                    onClick={() => !disabled && onChange({ adjustment: { ...adjustment, [`${field}_work_hours`]: null }})}
                />
            </td>
            <td>
            <DurationStringField
                onChange={(value) => onChange({ adjustment: { ...adjustment, [`${field}_work_hours`]: value || null }})}
                valueHuman={adjustment[`${field}_work_hours`]?.toString() || ""}
                human={true}
                className={`form-control form-control-sm ${disabled ? "bg-light" : "bg-white"}`}
                ariaLabel={name}
                disabled={disabled}
            />
            </td>
        </tr>
        <tr>
            <td colSpan={2}>
            <div>Korrekturbegründung</div>
            <textarea
                className={`TimeSheetsDay__CorrectionReason form-control ${disabled ? "bg-light" : "bg-white"}`}
                onChange={(e) => onChange({ adjustment: { ...adjustment, [`${field}_reason`]: e.target.value }})}
                value={adjustment[`${field}_reason`] || ""}
                rows={2}
                id={`${field}_reason`}
                disabled={disabled}
            />
            </td>
        </tr>
        </>
    )
};
