import * as React from "react";
import Hour from "./Hour";
import { useMemo } from "react";
import {
    compactDeploymentTimeObjects,
    DeploymentTime,
    deploymentTimesToObjects,
    getStatesByHour,
    Hour as HourOfDay,
} from "./deployment-times";
import { DayOfWeek } from "../../apps/scheduler/slots/types";

export type AllocationTimes = [string, string, string];

type DeploymentDayBarProps = {
    dow: DayOfWeek;
    firstHour: number;
    lastHour: number;
    deploymentTimes: DeploymentTime[];
    otherDepartmentDeploymentTimes: DeploymentTime[];
    allocationTimes: AllocationTimes[];
    suspended: boolean;
};

// TODO: Show suspension status depending on hour of day, currently it renders the
//  whole day suspended if a single deployment is suspended.
export default function DeploymentDayBar(props: DeploymentDayBarProps) {
    const { firstHour, lastHour, dow, deploymentTimes, otherDepartmentDeploymentTimes, suspended, allocationTimes } =
        props;

    const deploymentTimeObjects = useMemo(() => deploymentTimesToObjects(deploymentTimes), [deploymentTimes]);
    const sequences = useMemo(() => compactDeploymentTimeObjects(deploymentTimeObjects), [deploymentTimeObjects]);
    const deploymentStatesByHour = useMemo(() => getStatesByHour(sequences), [sequences]);

    const otherDepartmentDeploymentTimeObjects = useMemo(
        () => deploymentTimesToObjects(otherDepartmentDeploymentTimes),
        [otherDepartmentDeploymentTimes]
    );
    const otherDepartmentDeploymentStatesByHour = useMemo(
        () => getStatesByHour(otherDepartmentDeploymentTimeObjects),
        [otherDepartmentDeploymentTimeObjects]
    );

    const allocationTimeObjects = useMemo(
        () => deploymentTimesToObjects(allocationTimes.map((at) => [at[0], at[1]])),
        [allocationTimes]
    );
    const allocationStatesByHour = useMemo(() => getStatesByHour(allocationTimeObjects), [allocationTimeObjects]);

    const deploymentHourElements = [];
    for (let hour = firstHour; hour <= lastHour; hour++) {
        const state = deploymentStatesByHour[hour] ?? null;
        deploymentHourElements.push(
            <Hour
                key={hour}
                dow={dow}
                hour={hour as HourOfDay}
                state={state}
                type={suspended ? "suspended" : "default"}
            />
        );
    }

    const otherDepartmentDeploymentHourElements = [];
    for (let hour = firstHour; hour <= lastHour; hour++) {
        const state = otherDepartmentDeploymentStatesByHour[hour] ?? null;
        otherDepartmentDeploymentHourElements.push(
            <Hour key={hour} dow={dow} hour={hour as HourOfDay} state={state} type={"otherDepartment"} />
        );
    }
    const allocationHourElements = [];
    for (let hour = firstHour; hour <= lastHour; hour++) {
        const state = allocationStatesByHour[hour] ?? null;
        allocationHourElements.push(
            <Hour key={hour} dow={dow} hour={hour as HourOfDay} state={state} type={"allocated"} />
        );
    }

    return (
        <div className="SchedulerDeploymentDayBar" style={{ display: "flex", gap: 0 }}>
            <div className="SchedulerDeploymentDayBar__OtherDepartmentDeploymentHours">
                {otherDepartmentDeploymentHourElements}
            </div>
            <div className="SchedulerDeploymentDayBar__DeploymentHours">{deploymentHourElements}</div>
            <div className="SchedulerDeploymentDayBar__AllocationHours">{allocationHourElements}</div>
        </div>
    );
}
