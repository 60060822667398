import { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";

const VIDEO_TIMEOUT = 30_000;

type UseTokenScannerProps = {
    onAuthToken: (token: string) => void;
}

export function useAuthTokenScanner(props: UseTokenScannerProps) {
    const { onAuthToken } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const [scannerActive, setScannerActive] = useState(false);

    useEffect(() => {
        if (videoRef.current) {
            const qrScanner = new QrScanner(videoRef.current, result => {
                // For some unknown reason QrScanner seems to fire with an empty string from time to time.
                if (result.data) {
                    onAuthToken(result.data);
                }
            }, {
                highlightScanRegion: true,
                highlightCodeOutline: true
            });

            qrScanner
                .setCamera("user")
                .then(() => {
                    qrScanner.start();
                    setScannerActive(true);
                }).catch((error: Error) => {
                    console.warn("Error while starting QR scanner: ", error.message);
                }
            );

            const handleTimeOut = () => {
                console.debug("🪪 QR Code Scanner: timeout, stopping scanner");
                qrScanner.stop();
                setScannerActive(false);
            };

            let timeout = setTimeout(handleTimeOut, VIDEO_TIMEOUT);

            const handleUserInteraction = () => {
                console.debug(`🪪 QR Code Scanner: user interaction detected, restarting scanner/timeout, will timeout in ${VIDEO_TIMEOUT}ms`);
                clearTimeout(timeout);
                timeout = setTimeout(handleTimeOut, VIDEO_TIMEOUT);
                qrScanner.start();
                setScannerActive(true);
            };

            document.addEventListener("click", handleUserInteraction, { passive: false });
            document.addEventListener("touchstart", handleUserInteraction, { passive: false });

            console.debug(`🪪 QR Code Scanner: initialized, will timeout in ${VIDEO_TIMEOUT}ms`);

            return () => {
                console.debug("🪪 QR Code Scanner: cleaning up");
                qrScanner.destroy();
                setScannerActive(false);
                clearTimeout(timeout);
                document.removeEventListener("click", handleUserInteraction);
                document.removeEventListener("touchstart", handleUserInteraction);
            };
        }
    }, [videoRef.current, onAuthToken]);

    return { videoRef, scannerActive };
}
