export type TimeSheetFreezingWarningApiResponse = {
    data: {
        next_immutable_date: string;
        days_before_next_immutable_date: number;
        last_frozen_date: string;
    };
};

export async function fetchTimeSheetFreezingWarning(): Promise<TimeSheetFreezingWarningApiResponse> {
    return fetch("/api/dashboard/time_sheet_freezing_warning").then((response) => {
        if (!response.ok && response.status !== 422) {
            throw Error("Failed to load time freezing warning " + response.statusText);
        }
        return response.json();
    });
}
