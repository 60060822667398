import { Employee } from "../../../types";
import { BalancesContext } from "../../SlotsController";
import * as React from "react";
import { useContext } from "react";
import Balances, { EmployeeBalance } from "../../../../../lib/balances/Balances";
import { underscoreToCamelCase } from "../../../../../lib/object/objects";

type EmployeeBalancesProps = {
    employeeId: Employee["id"];
};

export default function EmployeeBalances({ employeeId }: EmployeeBalancesProps) {
    const { employeeBalances } = useContext(BalancesContext);

    return (
        <div className="SchedulerEmployeeWeek__Balances">
            <Balances
                balance={employeeBalances && (underscoreToCamelCase(employeeBalances[employeeId]) as EmployeeBalance)}
            />
        </div>
    );
}
