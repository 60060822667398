import { formatHours } from "../../../datetime/datetime";
import * as React from "react";
import { ReportPresence } from "../types";

type PresenceProps = {
    presence: ReportPresence;
};

export function PresenceRow(props: PresenceProps) {
    const { presence } = props;
    return (
        <tr>
            <td className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--presence-times">
                <div className="EttReportGrid__PresenceTable__Times">
                    <span>{presence.startTime}</span>
                    <span>-</span>
                    <span>{presence.endTime}</span>
                </div>
            </td>
            <td className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--break-times">
                {presence.breaks.length !== 0 && (
                    presence.breaks.map((_break, idx) => (
                        <div key={idx} className="EttReportGrid__PresenceTable__Times">
                            <span>{_break.startTime}</span>
                            <span>-</span>
                            <span>{_break.endTime}</span>
                        </div>
                    ))
                )}
            </td>
            <td className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--break-hours">
                {formatHours(presence.breakHours)} h
            </td>
            <td className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--work-hours">
                {formatHours(presence.workHours)} h
            </td>
            <td className="EttReportGrid__PresenceTable__Col EttReportGrid__PresenceTable__Col--spacer"></td>
        </tr>
    );
}
