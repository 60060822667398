import * as React from "react";
import { useContext } from "react";

import { Department } from "../../types";
import { CursorPositionContextType } from "../../cursor/types";
import { CursorPositionContext } from "../../cursor/CursorPositionContext";
import { CursorAnchorSetter } from "../../cursor/CursorNavigation";
import DepartmentBalances from "./DepartmentBalances";
import { joinClasses } from "../../../../lib/dom-utils/class-names";

type DeploymentSlotRowHeaderProps = {
    department: Department;
    renderRow: number;
    setCursorAnchorElement: CursorAnchorSetter;
};

export default function DeploymentSlotRowHeader(props: DeploymentSlotRowHeaderProps) {
    const { department, renderRow, setCursorAnchorElement } = props;

    const { cursorPosition } = useContext(CursorPositionContext);
    const isButtonActive = cursorPosition?.row === renderRow;

    const classNames = joinClasses(
        "SchedulerDeploymentSlot__Header",
        `SchedulerDeploymentSlot__Header--${department.color}`
    );

    return (
        <div className={classNames}>
            <div>
                <div className="SchedulerDeploymentSlot__Header__Start">
                    <div className="SchedulerDeploymentSlot__Name">{department.name}</div>
                    <DepartmentBalances department={department} />
                </div>
                <div className="SchedulerDeploymentSlot__Options">
                    <CursorPositionContext.Consumer>
                        {({ setCursorPosition, cursorPosition }: CursorPositionContextType) => (
                            <button
                                ref={isButtonActive ? setCursorAnchorElement : undefined}
                                type="button"
                                aria-label={`Mitarbeiter zu ${department.name} hinzufügen`}
                                className={`Button Button--${department.color} ${isButtonActive ? "cursor" : ""}`}
                                onClick={() =>
                                    setCursorPosition({
                                        ...cursorPosition,
                                        editorOpen: true,
                                        row: renderRow,
                                        col: 0,
                                        i: 0,
                                        tab: 0,
                                    })
                                }
                            >
                                <i className="bi bi-person-plus-fill" /> Mitarbeiter hinzufügen
                            </button>
                        )}
                    </CursorPositionContext.Consumer>
                </div>
            </div>
        </div>
    );
}
