import { Controller } from "stimulus";

export default class EventFormController extends Controller {
    static targets = [
        "recurringPane",
        "recurringCheckBox",
        "intervalCheckBox",
        "dayPane",
        "weekPane",
        "monthPane",
        "yearPane",
        "monthlyRecurrenceTypeSelect",
        "monthlyRecurrenceTypeDayOfMonthPane",
        "monthlyRecurrenceTypeDayOfWeekPane",
        "monthlyRecurrenceTypeWeekPane",
    ];

    connect() {
        if (!this.isRecurring()) {
            this.recurringPaneTarget.classList.add("d-none");
        }
        this.applyMonthlyRecurrenceType();
        this.applyInterval();
    }

    isRecurring() {
        if ("hidden" === this.recurringCheckBoxTarget.type) {
            return true; // when recurring is forced, there is no checkbox, just a hidden field
        } else {
            return this.recurringCheckBoxTarget.checked;
        }
    }

    toggleRecurring() {
        if (this.recurringPaneTarget.classList.contains("d-none")) {
            this.recurringPaneTarget.classList.remove("d-none");
        } else {
            this.recurringPaneTarget.classList.add("d-none");
        }
    }

    applyMonthlyRecurrenceType() {
        const value = this.monthlyRecurrenceTypeSelectTarget.value;

        if ("week" === value) {
            this.monthlyRecurrenceTypeWeekPaneTarget.classList.remove("d-none");
            this.monthlyRecurrenceTypeDayOfWeekPaneTarget.classList.add("d-none");
            this.monthlyRecurrenceTypeDayOfMonthPaneTarget.classList.add("d-none");
        } else if ("day_of_week" === value) {
            this.monthlyRecurrenceTypeWeekPaneTarget.classList.add("d-none");
            this.monthlyRecurrenceTypeDayOfWeekPaneTarget.classList.remove("d-none");
            this.monthlyRecurrenceTypeDayOfMonthPaneTarget.classList.add("d-none");
        } else {
            this.monthlyRecurrenceTypeWeekPaneTarget.classList.add("d-none");
            this.monthlyRecurrenceTypeDayOfWeekPaneTarget.classList.add("d-none");
            this.monthlyRecurrenceTypeDayOfMonthPaneTarget.classList.remove("d-none");
        }
    }

    applyInterval() {
        this.dayPaneTarget.classList.add("d-none");
        this.weekPaneTarget.classList.add("d-none");
        this.monthPaneTarget.classList.add("d-none");
        this.yearPaneTarget.classList.add("d-none");

        switch (this.selectedInterval) {
            case "day":
                this.dayPaneTarget.classList.remove("d-none");
                break;
            case "week":
                this.weekPaneTarget.classList.remove("d-none");
                break;
            case "month":
                this.monthPaneTarget.classList.remove("d-none");
                break;
            case "year":
                this.yearPaneTarget.classList.remove("d-none");
                break;
        }
    }

    get selectedInterval() {
        return this.intervalCheckBoxTarget.value;
    }
}
