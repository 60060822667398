import * as React from "react";

import Header from "./Header";
import Body from "./Body";
import SlotsController from "./slots/SlotsController";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import { HighlightedHourController } from "./slots/HighlightedHourController";
import UndeployedEmployeesPanel from "./UndeployedEmployeesPanel";

type SchedulerProps = {
    scheduleId: string;
};

export const ScheduleIdContext = React.createContext<string>(null);

export default function Scheduler({ scheduleId }: SchedulerProps) {
    return (
        <ErrorBoundary>
            <div className="Scheduler">
                <SlotsController scheduleId={scheduleId}>
                    {(slots, deploymentSequences, allocations, unit, undeployedEmployees, absentEmployees) => (
                        <ScheduleIdContext.Provider value={scheduleId}>
                            <Header />
                            <HighlightedHourController>
                                <ErrorBoundary>
                                    <Body
                                        slots={slots}
                                        deploymentSequences={deploymentSequences}
                                        allocations={allocations}
                                        unit={unit}
                                        absentEmployees={absentEmployees}
                                    />
                                </ErrorBoundary>
                            </HighlightedHourController>
                            {undeployedEmployees && (
                                <UndeployedEmployeesPanel undeployedEmployees={undeployedEmployees} />
                            )}
                        </ScheduleIdContext.Provider>
                    )}
                </SlotsController>
            </div>
        </ErrorBoundary>
    );
}
