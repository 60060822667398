export function padTimePart(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
}

export function secondsToHuman(secondsArg, options: { seconds?: boolean } = {}): string {
    const { hours, minutes, seconds } = secondsToObject(secondsArg);
    const parts = [];
    parts.push(hours);
    parts.push(padTimePart(minutes));
    if (options?.seconds ?? true) {
        parts.push(padTimePart(seconds));
    }
    return parts.join(":");
}

export function humanToSeconds(time: string) {
    return objectToSeconds(timeStringToObject(time));
}

export type Duration = {
    hours: number;
    minutes: number;
    seconds: number;
};

export function secondsToObject(seconds): Duration {
    if (null === seconds || isNaN(seconds)) {
        return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const secs = seconds - hours * 3600 - minutes * 60;
    return {
        hours,
        minutes,
        seconds: secs,
    };
}

export function objectToSeconds(obj: Duration) {
    return obj.hours * 3600 + obj.minutes * 60 + obj.seconds;
}

export function timeStringToObject(timeStr: string): Duration {
    const [hours, minutes] = timeStr.split(":").map(Number);
    return {
        hours: isNaN(hours) ? 0 : hours,
        minutes: isNaN(minutes) ? 0 : minutes,
        seconds: 0,
    };
}

export function mergeTimeOfDayParts(hours: number | string, minutes: number | string): string {
    const m = Math.min(parseInt(minutes.toString().slice(-2)), 59);
    const h = "string" === typeof hours ? parseInt(hours) : hours;
    return `${padTimePart(isNaN(h) ? 0 : h)}:${padTimePart(isNaN(m) ? 0 : m)}`;
}
