import * as React from "react";
import { conditionalClassNames } from "../dom-utils/class-names";

type TabProps = {
    children: JSX.Element | JSX.Element[] | string;
    active?: boolean;
    onClick: () => unknown;
};

export default function Tab({ onClick, active = false, children }: TabProps) {
    const classNames = conditionalClassNames({
        TabNav__Item: true,
        "TabNav__Item--active": active,
    }).join(" ");

    return (
        <button type="button" onClick={onClick} className={classNames} aria-current={active ? "page" : undefined}>
            {children}
        </button>
    );
}
