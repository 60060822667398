import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { State, useLayoutDispatch, useLayoutState } from "./state";
import { conditionalClassNames } from "../../../lib/dom-utils/class-names";
import { EmployeeManagerModeContext } from "../EmployeeManager";
import TabNav from "../../../lib/tab-nav/TabNav";
import Tab from "../../../lib/tab-nav/Tab";
import EmptyListMessage from "../../../lib/empty-state/EmptyListMessage";
import UnitBar from "./right-sidebar/UnitBar";
import SearchBar from "./right-sidebar/SearchBar";

export function RightSidebar() {
    const { filteredEmployees, loading } = useLayoutState();
    const groupedEmployees = groupEmployees(filteredEmployees);
    return (
        <div className="EmployeeManager__RightSidebar">
            <EmployeeListTabNav />
            <SearchBar />
            <UnitBar />
            <EmployeeList>
                {loading && <EmployeeListSkeleton />}
                {!loading && filteredEmployees.length === 0 && <EmptyState />}
                {!loading &&
                    Object.keys(groupedEmployees).map((ch) => (
                        <EmployeeListGroup key={ch} title={ch}>
                            {groupedEmployees[ch].map((employee) => (
                                <EmployeeListItem
                                    key={employee.id}
                                    name={`${employee.lastName}, ${employee.firstName}`}
                                    employeeId={employee.id}
                                />
                            ))}
                        </EmployeeListGroup>
                    ))}
            </EmployeeList>
            <RightSidebarFooter />
        </div>
    );
}

function groupEmployees(employees: State["employees"]): Record<string, State["employees"]> {
    return employees.reduce((acc, employee) => {
        const ch = employee.lastName[0].toUpperCase();
        acc[ch] ||= [];
        acc[ch].push(employee);
        return acc;
    }, {});
}

function RightSidebarFooter() {
    return (
        <div className="EmployeeManager__RightSidebar__Footer">
            <a href="/employees/new" className="btn btn-secondary btn-sm">
                Neuer Mitarbeiter
            </a>
        </div>
    );
}

function EmployeeList({ children }: { children: React.ReactNode }) {
    return <div className="EmployeeManager__EmployeeList">{children}</div>;
}

function EmployeeListGroup({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <>
            <div className="EmployeeManager__EmployeeList__Group">{title}</div>
            {children}
        </>
    );
}

function EmployeeListItem({ name, employeeId: employeeId }: { name: string; employeeId: string }) {
    const { employeeId: currentEmployeeId } = useParams();
    const isActive = employeeId === currentEmployeeId;

    const classNames = conditionalClassNames({
        EmployeeManager__EmployeeList__Item: true,
        "EmployeeManager__EmployeeList__Item--selected": isActive
    }).join(" ");

    const { standaloneSidebar } = useContext(EmployeeManagerModeContext);

    const ref = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (isActive) {
            ref.current?.scrollIntoView({ behavior: "instant", block: "nearest" });
        }
    }, [isActive]);

    if (standaloneSidebar) {
        return (
            <a href={`/employee_manager/${employeeId}`} className={classNames} ref={ref} data-turbolinks="false">
                {name}
            </a>
        );
    } else {
        return (
            <Link to={`/${employeeId}`} className={classNames} ref={ref}>
                {name}
            </Link>
        );
    }
}

function EmployeeListTabNav(): JSX.Element {
    const dispatch = useLayoutDispatch();
    const { selectedStatus } = useLayoutState();

    return (
        <TabNav size="sm" theme="dark">
            <Tab
                onClick={() => dispatch({ type: "selectStatus", payload: "active" })}
                active={"active" === selectedStatus}
            >
                Aktive
            </Tab>
            <Tab
                onClick={() => dispatch({ type: "selectStatus", payload: "inactive" })}
                active={"inactive" === selectedStatus}
            >
                Ausgeschiedene
            </Tab>
        </TabNav>
    );
}

function EmployeeListSkeleton() {
    return [1, 2, 3].map((i) => (
        <React.Fragment key={i}>
            <div className="EmployeeManager__EmployeeList__GroupSkeleton" />
            <div className="EmployeeManager__EmployeeList__ItemSkeleton" />
            <div className="EmployeeManager__EmployeeList__ItemSkeleton" />
            <div className="EmployeeManager__EmployeeList__ItemSkeleton" />
            <div className="EmployeeManager__EmployeeList__ItemSkeleton" />
            <div className="EmployeeManager__EmployeeList__ItemSkeleton" />
            <div className="EmployeeManager__EmployeeList__ItemSkeleton" />
        </React.Fragment>
    ));
}

function EmptyState() {
    const { query } = useLayoutState();
    const message = query.length > 0 ? "Keine übereinstimmenden Mitarbeiter gefunden" : "Keine Mitarbeiter vorhanden";

    return <EmptyListMessage text={message} />;
}
