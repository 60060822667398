export type ThemeColor =
    | "danger"
    | "success"
    | "warning"
    | "info"
    | "primary"
    | "secondary"
    | "light"
    | "dark"
    | "outline-secondary";
export type BgColor = ThemeColor | "white" | "transparent";

const brixIndigo50 = "#EEF2FF";
const brixIndigo100 = "#E0E7FF";
const brixIndigo200 = "#C7D2FE";
const brixIndigo300 = "#A5B4FC";
const brixIndigo400 = "#818CF8";
const brixIndigo500 = "#6366F1";
const brixIndigo600 = "#4F46E5";
const brixIndigo700 = "#4338CA";
const brixIndigo800 = "#3730A3";
const brixIndigo900 = "#312E81";
const brixIndigoContrast = "#ffffff";
const brixIndigo = brixIndigo500;
const brixIndigo300to500horizonal = `linear-gradient(-90deg, ${brixIndigo300} 0%, ${brixIndigo500} 100%)`;

const brixSlate50 = "#F8FAFC";
const brixSlate100 = "#F1F5F9";
const brixSlate200 = "#E2E8F0";
const brixSlate300 = "#CBD5E1";
const brixSlate400 = "#94A3B8";
const brixSlate500 = "#64748B";
const brixSlate600 = "#475569";
const brixSlate700 = "#334155";
const brixSlate800 = "#1E293B";
const brixSlate900 = "#0F172A";
const brixSlate = brixSlate500;
const brixSlate50to200 = `linear-gradient(180deg, ${brixSlate50} 0%, ${brixSlate200} 100%)`;
const brixSlate100to300 = `linear-gradient(180deg, ${brixSlate100} 0%, ${brixSlate300} 100%)`;
const brixSlate600to800 = `linear-gradient(180deg, ${brixSlate600} 0%, ${brixSlate800} 100%)`;

const brixGreen50 = "#F0FDF4";
const brixGreen100 = "#DCFCE7";
const brixGreen200 = "#BBF7D0";
const brixGreen300 = "#86EFAC";
const brixGreen400 = "#4ADE80";
const brixGreen500 = "#22C55E";
const brixGreen600 = "#16A34A";
const brixGreen700 = "#15803D";
const brixGreen800 = "#166534";
const brixGreen900 = "#14532D";
const brixGreen = brixGreen600;
const brixGreen100to300 = `linear-gradient(180deg, ${brixGreen100} 0%, ${brixGreen300} 100%)`;

const brixLime50 = "#f7fee7";
const brixLime100 = "#ecfccb";
const brixLime200 = "#d9f99d";
const brixLime300 = "#bef264";
const brixLime400 = "#a3e635";
const brixLime500 = "#84cc16";
const brixLime600 = "#65a30d";
const brixLime700 = "#4d7c0f";
const brixLime800 = "#3f6212";
const brixLime900 = "#365314";
const brixLime = brixLime600;
const brixLime50to200 = `linear-gradient(180deg, ${brixLime50} 0%, ${brixLime200} 100%)`;
const brixLime100to300 = `linear-gradient(180deg, ${brixLime100} 0%, ${brixLime300} 100%)`;
const brixLime600to800 = `linear-gradient(180deg, ${brixLime600} 0%, ${brixLime800} 100%)`;

const brixCyan50 = "#ecfeff";
const brixCyan100 = "#cffafe";
const brixCyan200 = "#a5f3fc";
const brixCyan300 = "#67e8f9";
const brixCyan400 = "#22d3ee";
const brixCyan500 = "#06b6d4";
const brixCyan600 = "#0891b2";
const brixCyan700 = "#0e7490";
const brixCyan800 = "#155e75";
const brixCyan900 = "#164e63";
const brixCyan = brixCyan600;

const brixBlue50 = "#eff6ff";
const brixBlue100 = "#dbeafe";
const brixBlue200 = "#bfdbfe";
const brixBlue300 = "#93c5fd";
const brixBlue400 = "#60a5fa";
const brixBlue500 = "#3b82f6";
const brixBlue600 = "#2563eb";
const brixBlue700 = "#1d4ed8";
const brixBlue800 = "#1e40af";
const brixBlue900 = "#1e3a8a";
const brixBlue = brixBlue600;

const brixPurple50 = "#faf5ff";
const brixPurple100 = "#f3e8ff";
const brixPurple200 = "#e9d5ff";
const brixPurple300 = "#d8b4fe";
const brixPurple400 = "#c084fc";
const brixPurple500 = "#a855f7";
const brixPurple600 = "#9333ea";
const brixPurple700 = "#7e22ce";
const brixPurple800 = "#6b21a8";
const brixPurple900 = "#581c87";
const brixPurple = brixPurple600;

const brixAmber50 = "#fffbeb";
const brixAmber100 = "#fef3c7";
const brixAmber200 = "#fde68a";
const brixAmber300 = "#fcd34d";
const brixAmber400 = "#fbbf24";
const brixAmber500 = "#f59e0b";
const brixAmber600 = "#d97706";
const brixAmber700 = "#b45309";
const brixAmber800 = "#92400e";
const brixAmber900 = "#78350f";
const brixAmber = brixAmber400;
const brixAmber50to100 = `linear-gradient(180deg, ${brixAmber50} 0%, ${brixAmber100} 100%)`;
const brixAmber100to300 = `linear-gradient(180deg, ${brixAmber100} 0%, ${brixAmber300} 100%)`;
const brixAmber600to800 = `linear-gradient(180deg, ${brixAmber600} 0%, ${brixAmber800} 100%)`;

const brixTeal50 = "#f0fdfa";
const brixTeal100 = "#ccfbf1";
const brixTeal200 = "#99f6e4";
const brixTeal300 = "#5eead4";
const brixTeal400 = "#2dd4bf";
const brixTeal500 = "#14b8a6";
const brixTeal600 = "#0d9488";
const brixTeal700 = "#0f766e";
const brixTeal800 = "#115e59";
const brixTeal900 = "#134e4a";
const brixTeal = brixTeal500;
const brixTeal100to300 = `linear-gradient(180deg, ${brixTeal100} 0%, ${brixTeal300} 100%)`;

const brixRose50 = "#FFF1F2";
const brixRose100 = "#FFE4E6";
const brixRose200 = "#FECDD3";
const brixRose300 = "#FDA4AF";
const brixRose400 = "#FB7185";
const brixRose500 = "#F43F5E";
const brixRose600 = "#E11D48";
const brixRose700 = "#BE123C";
const brixRose800 = "#9F1239";
const brixRose900 = "#881337";
const brixRose = brixRose500;

const white = "#fff";
const gray50 = "#F8FAFC";
const gray100 = "#F1F5F9";
const gray200 = "#E2E8F0";
const gray300 = "#CBD5E1";
const gray400 = "#94A3B8";
const gray500 = "#64748B";
const gray600 = "#475569";
const gray700 = "#334155";
const gray800 = "#343a40";
const gray900 = "#212529";
const black = "#000";

const blue = "#0d6efd";
const indigo = brixIndigo;
const purple = "#6f42c1";
const pink = "#d63384";
const red = brixRose;
const orange = "#fd7e14";
const yellow = brixAmber;
const green = brixGreen;
const teal = brixTeal;
const cyan = "#0dcaf0";

export const colors = {
    brixIndigo50,
    brixIndigo100,
    brixIndigo200,
    brixIndigo300,
    brixIndigo400,
    brixIndigo500,
    brixIndigo600,
    brixIndigo700,
    brixIndigo800,
    brixIndigo900,
    brixIndigoContrast,
    brixIndigo,
    brixIndigo300to500horizonal,
    brixSlate50,
    brixSlate100,
    brixSlate200,
    brixSlate300,
    brixSlate400,
    brixSlate500,
    brixSlate600,
    brixSlate700,
    brixSlate800,
    brixSlate900,
    brixSlate,
    brixSlate50to200,
    brixSlate100to300,
    brixSlate600to800,
    brixGreen50,
    brixGreen100,
    brixGreen200,
    brixGreen300,
    brixGreen400,
    brixGreen500,
    brixGreen600,
    brixGreen700,
    brixGreen800,
    brixGreen900,
    brixGreen,
    brixGreen100to300,
    brixLime50,
    brixLime100,
    brixLime200,
    brixLime300,
    brixLime400,
    brixLime500,
    brixLime600,
    brixLime700,
    brixLime800,
    brixLime900,
    brixLime,
    brixLime50to200,
    brixLime100to300,
    brixLime600to800,
    brixCyan50,
    brixCyan100,
    brixCyan200,
    brixCyan300,
    brixCyan400,
    brixCyan500,
    brixCyan600,
    brixCyan700,
    brixCyan800,
    brixCyan900,
    brixCyan,
    brixBlue50,
    brixBlue100,
    brixBlue200,
    brixBlue300,
    brixBlue400,
    brixBlue500,
    brixBlue600,
    brixBlue700,
    brixBlue800,
    brixBlue900,
    brixBlue,
    brixPurple50,
    brixPurple100,
    brixPurple200,
    brixPurple300,
    brixPurple400,
    brixPurple500,
    brixPurple600,
    brixPurple700,
    brixPurple800,
    brixPurple900,
    brixPurple,
    brixAmber50,
    brixAmber100,
    brixAmber200,
    brixAmber300,
    brixAmber400,
    brixAmber500,
    brixAmber600,
    brixAmber700,
    brixAmber800,
    brixAmber900,
    brixAmber,
    brixAmber50to100,
    brixAmber100to300,
    brixAmber600to800,
    brixTeal50,
    brixTeal100,
    brixTeal200,
    brixTeal300,
    brixTeal400,
    brixTeal500,
    brixTeal600,
    brixTeal700,
    brixTeal800,
    brixTeal900,
    brixTeal,
    brixTeal100to300,
    brixRose50,
    brixRose100,
    brixRose200,
    brixRose300,
    brixRose400,
    brixRose500,
    brixRose600,
    brixRose700,
    brixRose800,
    brixRose900,
    brixRose,
    white,
    gray50,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    gray600,
    gray700,
    gray800,
    gray900,
    black,
    blue,
    indigo,
    purple,
    pink,
    red,
    orange,
    yellow,
    green,
    teal,
    cyan,
};
