import { Dispatch, useEffect } from "react";

export default function useDevTools<Action>(dispatch: Dispatch<Action>) {
    useEffect(() => {
        window["setDebugDispatch"](dispatch);

        return () => {
            window["clearDebugDispatch"]();
        };
    }, [dispatch]);
}
