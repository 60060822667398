import { unitIdStorageKey, useLayoutDispatch, useLayoutState } from "../state";
import ComboBox, { GroupedOptions } from "../../../../lib/form/select/ComboBox";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";

export default function UnitBar() {
    const dispatch = useLayoutDispatch();
    const { unitId } = useLayoutState();

    function handleChange(unitIds: string[]) {
        localStorage.setItem(unitIdStorageKey, JSON.stringify(unitIds[0]));
        dispatch({
            type: "filterByUnit",
            payload: { unitId: unitIds[0] ?? null }
        });
    }

    const unitOptions = useUnitOptions();

    return (
        <div className="EmployeeManager__UnitBar">
            <ComboBox
                allLabel="Alle Filialen"
                multiselect={false}
                value={[unitId]}
                groupedOptions={unitOptions}
                onChange={handleChange}
            />
        </div>
    );
}

type UnitFromApi = {
    id: string;
    name: string;
};

function useUnitOptions(): GroupedOptions {
    const units = useUnits();

    return useMemo(() => {
        const sortedUnits = units.sort((a, b) => a.name.localeCompare(b.name));
        return {
            "Alle": [
                { value: null, label: "Alle Filialen" },
                ...sortedUnits.map((unit) => ({ value: unit.id, label: unit.name }))
            ]
        };
    }, [units.map((unit) => unit.id).join(",")]);
}

function useUnits() {
    const [units, setUnits] = useState<UnitFromApi[]>([]);

    useEffect(() => {
        fetchUnitsFromApi().then((units) => setUnits(units));
    }, []);

    return units;
}

async function fetchUnitsFromApi() {
    return fetch("/units.json")
        .then((response) => response.json() as Promise<UnitFromApi[]>);
}
