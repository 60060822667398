import * as React from "react";
import { MouseEventHandler, useContext } from "react";

import { DeploymentSequencesContext } from "../../../SlotsController";
import { secondsToHuman } from "../../../../../../lib/datetime/time";
import { Deployment } from "../../../../types";

type BreakTimeProps = {
    deployment: Deployment;
    onClick: MouseEventHandler<HTMLSpanElement>;
};
export default function BreakTime({ deployment, onClick }: BreakTimeProps) {
    const sequences = useContext(DeploymentSequencesContext);

    if (
        null !== sequences &&
        deployment.deployment_sequence_id in sequences &&
        sequences[deployment.deployment_sequence_id].break_seconds !== null
    ) {
        const breakClassNames = ["SchedulerDeployment__Time__Break"];

        if (sequences[deployment.deployment_sequence_id].manual_break_seconds !== null) {
            breakClassNames.push("SchedulerDeployment__Time__Break--Manual");
        }

        return (
            <span onClick={onClick} className={breakClassNames.join(" ")}>
                {secondsToHuman(sequences[deployment.deployment_sequence_id].break_seconds, { seconds: false })}
            </span>
        );
    }
    return null;
}
