import * as React from "react";
import { DashboardWidgetSuccess } from "../chrome/DashboardWidgetSuccess";
import Icon from "../../../../lib/icon/Icon";
import { useUnapprovedAbsences } from "./useUnapprovedAbsences";
import Kitchensink from "../../../../lib/kitchensink/Kitchensink";
import { formatDate } from "../../../../lib/datetime/date-format";
import { DataTable2 } from "../../../../lib/data-table/DataTable2";
import { WidgetLoading } from "../WidgetLoading";

export function UnapprovedAbsences() {
    const { unapprovedAbsences, loading, loadingFailed } = useUnapprovedAbsences();

    return (
        <Kitchensink.Item>
            <Kitchensink.ItemBody>
                <Kitchensink.ItemBodyTitle>Abwesenheiten ohne Genehmigung</Kitchensink.ItemBodyTitle>
                <Kitchensink.ItemBodyPart>
                    {loadingFailed && (
                        <div className="Badge Badge--warning">
                            <Icon type="error" /> Fehler beim Laden
                        </div>
                    )}

                    {!loadingFailed && loading && <WidgetLoading height={290} />}

                    {!loadingFailed && !loading && !unapprovedAbsences?.length && (
                        <DashboardWidgetSuccess text="Alles erledigt!" />
                    )}

                    {!loadingFailed && !loading && !!unapprovedAbsences?.length && (
                        <>
                            <DataTable2>
                                <DataTable2.Head>
                                    <DataTable2.HeadRow>
                                        <DataTable2.HeadCell>Mitarbeiter</DataTable2.HeadCell>
                                        <DataTable2.HeadCell>Abwesenheitsgrund</DataTable2.HeadCell>
                                        <DataTable2.HeadCell align="right">Beginn</DataTable2.HeadCell>
                                        <DataTable2.HeadCell align="right">Rückkehr</DataTable2.HeadCell>
                                        <DataTable2.HeadCell align="right"></DataTable2.HeadCell>
                                    </DataTable2.HeadRow>
                                </DataTable2.Head>
                                <DataTable2.Body>
                                    {unapprovedAbsences.map((absence) => (
                                        <DataTable2.Row key={absence.id}>
                                            <DataTable2.Cell primary>
                                                <span className="text-nowrap">{absence.employee.name}</span>
                                            </DataTable2.Cell>
                                            <DataTable2.Cell>
                                                <span className="text-nowrap">{absence.reason.name}</span>
                                            </DataTable2.Cell>
                                            <DataTable2.Cell align="right">
                                                <span className="text-nowrap">{formatDate(absence.startsOn)}</span>
                                            </DataTable2.Cell>
                                            <DataTable2.Cell align="right">
                                                <span className="text-nowrap">{formatDate(absence.endsOn)}</span>
                                            </DataTable2.Cell>
                                            <DataTable2.Cell align="right">
                                                <a
                                                    href={`/employees/${absence.employee.id}/absences/${absence.id}`}
                                                    data-turbolinks="false"
                                                    className="DashboardAbsence__Dates"
                                                >
                                                    Mehr <Icon type="follow-link" />
                                                </a>
                                            </DataTable2.Cell>
                                        </DataTable2.Row>
                                    ))}
                                </DataTable2.Body>
                            </DataTable2>
                        </>
                    )}
                </Kitchensink.ItemBodyPart>
            </Kitchensink.ItemBody>
        </Kitchensink.Item>
    );
}
