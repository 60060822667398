import * as React from "react";
import { conditionalClassNames, joinClasses } from "../dom-utils/class-names";
import { EmployeePageHeader } from "../../apps/employee-manager/EmployeePageHeader";

export function NavigationBar({
                                  children,
                                  ...divProps
                              }: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    divProps.className = joinClasses("Chrome__NavigationBar", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function BackLink({
                             children,
                             ...divProps
                         }: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    divProps.className = joinClasses("Chrome__BackLink", divProps.className);
    return <span {...divProps}>{children}</span>;
}

export function BackLinkSubject({
                                    children,
                                    href,
                                    ...aProps
                                }: {
    href: string;
    children: React.ReactNode;
} & React.HTMLAttributes<HTMLAnchorElement>) {
    aProps.className = joinClasses("Chrome__BackLinkSubject", aProps.className);
    return (
        <a {...aProps} href={href} data-turbolinks="false">
            {children}
        </a>
    );
}

export function DetailPageHeader({
                                     children,
                                     ...divProps
                                 }: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    divProps.className = joinClasses("Chrome__DetailPage__Header", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function DetailPageHeadeMain({
                                        children,
                                        ...divProps
                                    }: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    divProps.className = joinClasses("Chrome__DetailPage__Header__Main", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function DetailPageHeadeMeta({
                                        children,
                                        ...divProps
                                    }: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    divProps.className = joinClasses("Chrome__DetailPage__Header__Meta", divProps.className);
    return <div {...divProps}>{children}</div>;
}

export function DetailPagePageTitle({
                                        children,
                                        ...h1Props
                                    }: { children: React.ReactNode } & React.HTMLAttributes<HTMLHeadingElement>) {
    h1Props.className = joinClasses("Chrome__DetailPage__PageTitle", h1Props.className);
    return <h1 {...h1Props}>{children}</h1>;
}

export function DetailPagePageSupertitle({
                                             children,
                                             ...h1Props
                                         }: { children: React.ReactNode } & React.HTMLAttributes<HTMLHeadingElement>) {
    h1Props.className = joinClasses("Chrome__DetailPage__PageSupertitle", h1Props.className);
    return <h2 {...h1Props}>{children}</h2>;
}

export function ActionBar({
                              split = false,
                              right = false,
                              children,
                              ...div
                          }: {
    split?: boolean;
    right?: boolean;
    children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>) {
    div.className = joinClasses(
        ...conditionalClassNames({
            Chrome__DetailPage__ActionBar: true,
            "Chrome__DetailPage__ActionBar--split": split,
            "Chrome__DetailPage__ActionBar--right": right
        }),
        div.className
    );
    return <div {...div}>{children}</div>;
}

const Chrome = {
    NavigationBar,
    BackLink,
    BackLinkSubject,
    DetailPage: {
        Header: DetailPageHeader,
        HeaderMain: DetailPageHeadeMain,
        HeaderMeta: DetailPageHeadeMeta,
        PageTitle: DetailPagePageTitle,
        PageSupertitle: DetailPagePageSupertitle,
        EmployeePageHeader: EmployeePageHeader,
        ActionBar: ActionBar
    }
};

export default Chrome;
