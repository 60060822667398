import * as React from "react";
import Panel from "../../../layout/Panel";
import { EttReport } from "../../../state/types/ett-report";
import WeeklyEttReport from "./WeeklyEttReport";
import { useMemo } from "react";
import { DateTime } from "luxon";

type WeeklyPanelContentProps = {
    ettReport: EttReport
}

export default function WeeklyPanelContent(props: WeeklyPanelContentProps) {
    const { ettReport } = props;

    const startDt = useMemo(() => DateTime.fromISO(ettReport.meta.startsAt), [ettReport.meta.startsAt]);
    const endDt = useMemo(() => DateTime.fromISO(ettReport.meta.endsAt), [ettReport.meta.endsAt]);

    return (
        <>
            <Panel.SectionTitle text={`${startDt.toFormat("dd.MM.yyyy")} - ${endDt.toFormat("dd.MM.yyyy")}`} />
            <Panel.Divider />
            <WeeklyEttReport ettReport={ettReport} />
        </>
    );
}
