import * as React from "react";
import { HTMLAttributes } from "react";
import Icon from "../../../../lib/icon/Icon";

type DashboardWidgetSuccessProps = HTMLAttributes<HTMLDivElement> & {
    text: string;
};

export function DashboardWidgetSuccess(props: DashboardWidgetSuccessProps) {
    return (
        <div className="DashboardWidget__Success">
            <div className="DashboardWidget__Success__Icon">
                <Icon type="all-done" />
            </div>
            <div className="DashboardWidget__Success__Text">{props.text}</div>
        </div>
    );
}
