import { useImmerReducer } from "use-immer";
import { initialState, reducer } from "./reducer";
import { useEffect } from "react";
import { fetchAbsenceReasons } from "./api";

export function useAbsenceReasonOptions() {
    const [{ absenceReasonOptions, loading, loadingFailed }, dispatch] = useImmerReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: "load" });
        let ignore = false;

        fetchAbsenceReasons()
            .then((json) => {
                if (ignore) {
                    return;
                }
                dispatch({ type: "loadedJson", payload: json });
            })
            .catch((error) => {
                if (ignore) {
                    return;
                }
                console.error(error);
                dispatch({ type: "loadingFailed" });
            });
        return () => {
            ignore = false;
        };
    }, []);

    return { absenceReasonOptions, loading, loadingFailed };
}
