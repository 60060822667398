import { formatDate, formatDayOfWeekAbbreviated } from "../../../datetime/date-format";
import { DayBar } from "./day-bar/DayBar";
import { formatHours, isWeekendDay } from "../../../datetime/datetime";
import * as React from "react";
import { useState } from "react";
import Icon from "../../../icon/Icon";
import { conditionalClassNames } from "../../../dom-utils/class-names";
import { ReportDay } from "../types";
import { PresenceTable } from "./PresenceTable";

type DayProps = {
    day: ReportDay;
    expandable: boolean;
}

export function Day(props: DayProps) {
    const { day, expandable } = props;
    const [expanded, setExpanded] = useState(false);

    return (
        <div key={day.date.toISODate()} className={conditionalClassNames({
            "EttReportGrid__Row": true,
            "EttReportGrid__Row--expandable": expandable
        }).join(" ")}>
            {expandable && (
                <div className="EttReportGrid__Col EttReportGrid__Col--collapse-handle">
                    <div
                        className={`EttReportGrid__CollapseHandle EttReportGrid__CollapseHandle--${expanded ? "expanded" : "collapsed"}`}
                        onClick={() => setExpanded(!expanded)}
                    >
                        <Icon type={expanded ? "collapse" : "expand"} />
                    </div>
                </div>
            )}
            <div className={conditionalClassNames({
                "EttReportGrid__Col EttReportGrid__DayCol": true,
                "EttReportGrid__DayCol--weekend": isWeekendDay(day.date)
            }).join(" ")}>
                <div className="EttReportGrid__DayCol__DayOfWeek">
                    <div>{formatDayOfWeekAbbreviated(day.date)}</div>
                </div>
                <div className="EttReportGrid__DayCol__Date">
                    {formatDate(day.date)}
                </div>
            </div>
            <div className="EttReportGrid__Col">
                <DayBar day={day} />
                {expandable && expanded && <PresenceTable day={day} />}
            </div>
            <div className="EttReportGrid__Col EttReportGrid__Col--right EttReportGrid__Col--primary">
                {formatHours(day.workHours)} h
            </div>
            <div className="EttReportGrid__Col EttReportGrid__Col--right">
                {formatHours(day.breakHours)} h
            </div>
            <div className="EttReportGrid__Col">
            </div>
        </div>
    );
}
