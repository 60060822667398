import * as React from "react";
import { useFilterParams } from "./useFilterParams";
import ComboBox from "../../../lib/form/select/ComboBox";
import { useNavigateReport } from "./useNavigateReport";
import Select from "../../../lib/form/select/Select";
import { Interval } from "luxon";
import IntervalPickerField from "../../../lib/form/interval-picker/IntervalPickerField";
import { absenceReasonOptions } from "./absence-reasons";
import { AbsenceCategory, DUnit } from "../types";
import ExportButton from "./ExportButton";
import Chrome from "../../../lib/chrome/Chrome";

type FilterBarProps = {
    units: DUnit[];
};

export default function FilterBar(props: FilterBarProps) {
    const { units } = props;

    const { unitIds, startsOn, endsOn, categories, includePresent } = useFilterParams();

    const navigateReport = useNavigateReport();

    const unitOptions = {
        Alle: units.reduce((memo, unit) => {
            memo.push({ label: unit.name, value: unit.id });
            return memo;
        }, []),
    };

    const categoryOptions = {
        Alle: Object.keys(absenceReasonOptions).reduce((memo, category) => {
            memo.push({ label: absenceReasonOptions[category], value: category });
            return memo;
        }, []),
    };

    return (
        <Chrome.DetailPage.ActionBar split className="AbsenceReport__FilterBar">
            <div className="AbsenceReport__FilterBar__Units">
                <ComboBox
                    allLabel="Alle Filialen"
                    multiselect={true}
                    value={unitIds}
                    groupedOptions={unitOptions}
                    onChange={(newUnitIds) => navigateReport({ unitIds: newUnitIds as string[] })}
                />
            </div>
            <div className="AbsenceReport__FilterBar__Interval">
                {startsOn && endsOn && (
                    <IntervalPickerField
                        aria-label="Zeitraumsauswahl"
                        value={Interval.fromDateTimes(startsOn, endsOn)}
                        onChange={(newInterval: Interval) =>
                            navigateReport({
                                startsOn: newInterval.start,
                                endsOn: newInterval.end,
                            })
                        }
                    />
                )}
            </div>
            <div className="AbsenceReport__FilterBar__Category">
                <ComboBox
                    allLabel="Alle Kategorien"
                    multiselect={true}
                    value={categories}
                    groupedOptions={categoryOptions}
                    onChange={(newCategories) => navigateReport({ categories: newCategories as AbsenceCategory[] })}
                />
            </div>
            <div className="AbsenceReport__FilterBar__IncludePresent">
                <Select
                    aria-label="Anwesenheitsstatus"
                    options={{ true: "Alle Mitarbeiter", false: "Nur Abwesende" }}
                    value={includePresent ? "true" : "false"}
                    onChange={(newIncludePresent) => navigateReport({ includePresent: "true" === newIncludePresent })}
                    includeBlank={false}
                />
            </div>
            <div className="AbsenceReport__FilterBar__Export">
                <ExportButton />
            </div>
        </Chrome.DetailPage.ActionBar>
    );
}
