// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";

import { Toast, Tooltip } from "bootstrap";

import "@oddcamp/cocoon-vanilla-js";

// Hotwire
import "./controllers";

// Sheets
import React from "react";
import * as ReactDOM from "react-dom/client";
import Sheets from "./apps/sheets/sheets";

// Scheduler
import Scheduler from "./apps/scheduler/Scheduler";

// WorkTimeSetForm
import WorkTimeSetForm from "./apps/forms/work-time-set/WorkTimeSetForm";

// SkillForm
import SkillForm from "./apps/forms/skill/SkillForm";

// TimeSheetsDays
import TimeSheetsWeeksPerMonthForEmployee from "./apps/time-sheets-days/TimeSheetsWeeksPerMonthForEmployee";

// TimeSheetsDayForm
import TimeSheetsDayForm from "./apps/forms/time-sheets-day/TimeSheetsDayForm";

import { default as AbsenceReport } from "./apps/absence-report/App";
import { default as VacationReport } from "./apps/vacation-report/App";
import SchoolTimeSetForm from "./apps/forms/school-time-set/SchoolTimeSetForm";
import Auditor from "./apps/auditor/Auditor";
import { Dashboard } from "./apps/dashboard/Dashboard";
import { Shortcuts } from "./apps/shortcuts/Shortcuts";
import EmployeeManager from "./apps/employee-manager/EmployeeManager";
import WorkHub from "./apps/work-hub/WorkHub";
import Chrome from "./lib/chrome/Chrome";
import EttReport from "./apps/ett-report/EttReport";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Toasts
function initToasts() {
    document.querySelectorAll(".toast").forEach((toastTarget) => {
        return new Toast(toastTarget).show();
    });
}

document.addEventListener("turbolinks:load", initToasts);

// Tooltips
function initTooltips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll("[data-bs-toggle='tooltip']"));
    tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
    });
}

document.addEventListener("turbolinks:load", initTooltips);

const reactRoots = {};

// Ensure previously mounted apps are properly unmounted upon navigation.
// Spares yourself the debugging nightmares I had when I didn't.
function initReactApp(wrapperSelector, renderer) {
    if (reactRoots[wrapperSelector]) {
        reactRoots[wrapperSelector].unmount();
    }

    const wrapper = document.querySelector(wrapperSelector);
    if (wrapper) {
        reactRoots[wrapperSelector] = ReactDOM.createRoot(wrapper);
        renderer(reactRoots[wrapperSelector], wrapper);
    }
}

function initForm(name, Component) {
    initReactApp(`[data-brix-form='${name}']`, (root, wrapper) => {
        const formData = JSON.parse(wrapper.getAttribute("data-brix-form-data"));
        return root.render(<Component {...formData} />);
    });
}

document.addEventListener("turbolinks:load", () => {
    initReactApp(".DashboardWrapper", (root) => {
        return root.render(<Dashboard />);
    });

    initReactApp(".ShortcutsWrapper", (root) => {
        return root.render(<Shortcuts />);
    });

    initReactApp(".sheets", (root) => root.render(<Sheets />));

    initReactApp(".scheduler-wrapper", (root, wrapper) => {
        const scheduleId = wrapper.getAttribute("data-schedule-id");
        return root.render(<Scheduler scheduleId={scheduleId} />);
    });

    initReactApp(".EmployeeManagerWrapper", (root) => root.render(<EmployeeManager />));
    initReactApp(".EmployeeManagerSidebar", (root) => root.render(<EmployeeManager standaloneSidebar={true} />));
    initReactApp(".EmployeeManagerHeader", (root, wrapper) => {
        const employeeId = wrapper.getAttribute("data-employee-id");
        const hasDeleteEmployee = JSON.parse(wrapper.getAttribute("data-has-delete-employee"));
        const pageTitle = wrapper.getAttribute("data-page-title");
        const pageSupertitle = wrapper.getAttribute("data-page-supertitle");
        const backLinkParts = JSON.parse(wrapper.getAttribute("data-back-link-parts"));
        return root.render(
            <Chrome.DetailPage.EmployeePageHeader
                employeeId={employeeId}
                hasDeleteEmployee={hasDeleteEmployee}
                pageTitle={pageTitle}
                pageSupertitle={pageSupertitle}
                backLinkParts={backLinkParts}
            />
        );
    });

    initReactApp(".absence-report-wrapper", (root) => root.render(<AbsenceReport />));
    initReactApp(".vacation-report-wrapper", (root) => root.render(<VacationReport />));
    initReactApp(".auditor", (root) => root.render(<Auditor />));
    initReactApp(".WorkHubWrapper", (root, wrapper) => {
        const deviceId = wrapper.getAttribute("data-device-id");
        return root.render(<WorkHub deviceId={deviceId} />);
    });
    initReactApp(".EttReportWrapper", (root, wrapper) => {
        const employeeId = wrapper.getAttribute("data-employee-id");
        return root.render(<EttReport employeeId={employeeId} />);
    });

    initReactApp(".time-sheets-days-wrapper", (root, wrapper) => {
        const data = JSON.parse(wrapper.getAttribute("data-time-sheets-days"));
        return root.render(<TimeSheetsWeeksPerMonthForEmployee {...data} />);
    });

    initForm("work_time_set", WorkTimeSetForm);
    initForm("school_time_set", SchoolTimeSetForm);
    initForm("time_sheets_day", TimeSheetsDayForm);
    initForm("skill", SkillForm);
});

window.debugDispatchCallback = null;

window.debugDispatch = function() {
    if (window.debugDispatchCallback) {
        window.debugDispatchCallback(...arguments);
    } else {
        console.warn("No debug dispatcher registered yet");
    }
};

window.setDebugDispatch = function(callback) {
    if (window.debugDispatchCallback) {
        console.warn("Overwriting existing debug dispatcher");
    }
    window.debugDispatchCallback = callback;
    console.log("Debug dispatcher registered");
};

window.clearDebugDispatch = function() {
    window.debugDispatchCallback = null;
    console.log("Debug dispatcher cleared");
}
