import * as React from "react";
import { EttReport } from "../../../state/types/ett-report";
import Day from "./weekly-ett-report/Day";
import { useMemo } from "react";

type WeeklyEttReportProps = {
    ettReport: EttReport
}
export default function WeeklyEttReport(props: WeeklyEttReportProps) {
    const { ettReport: { days } } = props;

    const sortedDays = useMemo(() => {
        return [...days].sort((a, b) => a.date.toMillis() - b.date.toMillis());
    }, [days]);

    return (
        <div className="WorkHub__EttReport">
            {sortedDays.map((day) => (
                <Day key={day.date.toISODate()} day={day} />
            ))}
        </div>
    );
}
