import * as React from "react";
import { useMemo } from "react";
import useWorkHubState from "../../state/useWorkHubState";
import { LoadingSpinner } from "../../../../lib/empty-state/Loading";
import Panel from "../../layout/Panel";
import LogoutButton from "../../layout/LogoutButton";

export default function EttStatusTransitionResult() {
    const { ett: { transitioning, status, error } } = useWorkHubState();

    const [resultMessage, cheerMessage] = useMemo(() => {
        switch (status) {
            case "checked_in":
                return ["Du bist jetzt eingecheckt", "Schön, dass Du da bist!"];
            case "checked_out":
                return ["Du bist jetzt ausgecheckt", "Hab einen schönen Feierabend!"];
            case "on_break":
                return ["Du bist jetzt in der Pause", "Erhol Dich gut!"];
            default:
                return ["unbekannt", "unbekannt"];
        }
    }, [status]);

    if (transitioning) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div className="p-2 bg-danger text-white">{error}</div>;
    }

    return (
        <Panel>
            <Panel.Title text={resultMessage} />
            <Panel.Section>
                <div className="WorkHub__EttStatusTransitionResult__CheerMessage">
                    {cheerMessage}
                </div>
            </Panel.Section>
            <Panel.Section>
                <div className="WorkHub__EttStatusTransitionResult__ButtonRow">
                    <LogoutButton text="Weiter" icon={null} colorTheme="link" />
                </div>
            </Panel.Section>
        </Panel>
    );
}
