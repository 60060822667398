import * as React from "react";
import { useState } from "react";
import { conditionalClassNames } from "../../../lib/dom-utils/class-names";
import { DayHeaderContent } from "./DayHeaderContent";
import { DateTime, WeekdayNumbers } from "luxon";
import { DayContextActions } from "./DayContextActions";
import TableRowDay from "../table/TableRowDay";
import { PublicHolidaysInWeek } from "../slots/types";
import { SchedulerDailyNoteEditor } from "../schedule-daily-notes/SchedulerDailyNoteEditor";

type DayCellProps = {
    dow: WeekdayNumbers;
    scheduleStartsOn: DateTime | null;
    publicHolidays: PublicHolidaysInWeek;
};

export function DayCell(props: DayCellProps) {
    const { dow, scheduleStartsOn, publicHolidays } = props;

    const classNames = conditionalClassNames({
        SchedulerHeader__Dow: true,
        "SchedulerHeader__Dow--holiday": publicHolidays && publicHolidays[dow].length > 0,
    });

    const [isAddingScheduleDailyNote, setIsAddingScheduleDailyNote] = useState(false);
    let scheduleDailyNoteEditor = null;

    if (isAddingScheduleDailyNote) {
        scheduleDailyNoteEditor = (
            <SchedulerDailyNoteEditor
                dow={dow}
                scheduleDailyNoteId={null}
                onClose={() => setIsAddingScheduleDailyNote(false)}
            />
        );
    }

    return (
        <TableRowDay className={classNames.join(" ")} key={dow} role="group" aria-label={`Wochentag ${dow}`}>
            {scheduleDailyNoteEditor}
            <DayHeaderContent
                publicHolidays={publicHolidays}
                dow={dow as WeekdayNumbers}
                scheduleStartsOn={scheduleStartsOn}
            />
            <DayContextActions onAddScheduleDailyNote={() => setIsAddingScheduleDailyNote(true)} />
        </TableRowDay>
    );
}
