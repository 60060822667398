import { colors } from "../../../../../../../lib/theme/colors";
import { formatHours } from "../../../../../../../lib/datetime/datetime";
import { formatNumber } from "../../../../../../../lib/number/number";
import * as React from "react";

type CustomLabelProps = {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    index?: number;
    value?: number;
    data?: {
        category: string;
        hours: number;
        credited: boolean;
        absenceRate: number;
    }[];
};

export function CustomLabel(props: CustomLabelProps) {
    const { x, y, width, height, index, value, data } = props;
    const payload = data[index];

    return (
        <text
            x={x + width + 8}
            y={y + height / 2 + 4}
            fill={colors.brixSlate800}
            textAnchor="right"
            fontSize="12.25px"
            fontFamily="var(--bs-body-font-family)"
            fontWeight={500}
        >
            {formatHours(value)} h{" "}
            <tspan fontWeight={400} fill={colors.brixSlate400}>
                (
                {formatNumber(payload.absenceRate * 100, {
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                })}{" "}
                %)
            </tspan>
        </text>
    );
}
