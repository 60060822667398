import { useEffect } from "react";

type UseFinderKeyboardNavigationProps = {
    ref: React.RefObject<HTMLElement>;
    onNext: () => void;
    onPrevious: () => void;
    onClose: () => void;
    onCommit: () => void;
};

export default function useFinderKeyboardNavigation(props: UseFinderKeyboardNavigationProps) {
    const { ref, onNext, onPrevious, onClose, onCommit } = props;

    useEffect(() => {
        if (ref !== null && ref.current) {
            const element = ref.current as HTMLElement;

            const handleKeyDown = (e) => {
                e.stopPropagation();
                if (e.key === "ArrowDown") {
                    onNext();
                } else if (e.key === "ArrowUp") {
                    onPrevious();
                } else if (e.key === "Escape") {
                    onClose();
                } else if (e.key === "Enter") {
                    onCommit();
                }
            };

            element.addEventListener("keydown", handleKeyDown);
            return () => element.removeEventListener("keydown", handleKeyDown);
        }
    }, [ref, onNext, onPrevious, onClose, onCommit]);
}
