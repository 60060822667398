import * as React from "react";
import { CustomItemColor } from "../../apps/scheduler/types";

type ColorDotProps = {
    onClick: (e: React.MouseEvent) => unknown;
    type: CustomItemColor;
};

export default function ColorDot(props: ColorDotProps) {
    const { type, onClick } = props;
    return <div className={`ColorDot ColorDot--${type}`} onClick={onClick} />;
}
