import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../dom-utils/class-names";

type BadgeProps = HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    colorScheme?: "primadonna" | "secondary" | "warning" | "danger" | "success";
};

export default function Badge(props: BadgeProps) {
    const { children, className, colorScheme = "primadonna", ...otherProps } = props;
    const cnames = ["Badge"];
    cnames.push(`Badge--${colorScheme}`);
    const cname = joinClasses(className, ...cnames);

    return (
        <div className={cname} {...otherProps}>
            {children}
        </div>
    );
}
