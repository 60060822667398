import * as React from "react";
import { dateFormat } from "../../../lib/datetime/date-format";
import { useDatesFromFilterParams } from "./useDatesFromFilterParams";

export default function HeaderRow() {
    const dates = useDatesFromFilterParams();

    return (
        <div className="AbsenceReport__HeaderRow">
            <div className="AbsenceReport__HeaderRow__Name">Mitarbeiter</div>
            <div className="AbsenceReport__HeaderRow__Days">
                {dates.map((dt) => (
                    <div key={dt.toISODate()} className="AbsenceReport__HeaderRow__Days__Day">
                        <div className="AbsenceReport__HeaderRow__Days__Day__Dow">{dt.toFormat("ccc")}</div>
                        <div className="AbsenceReport__HeaderRow__Days__Day__Date">{dt.toFormat(dateFormat)}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
