import * as React from "react";
import { HTMLAttributes, ReactNode } from "react";
import { joinClasses } from "../../../lib/dom-utils/class-names";

export type BadgeProps = HTMLAttributes<HTMLSpanElement> & {
    colorTheme?: "slate" | "lime" | "amber" | "indigo" | "rose";
    children: ReactNode;
}
export default function Badge(props: BadgeProps) {
    const { children, colorTheme = "slate", ...rest } = props;

    rest.className = joinClasses(
        "WorkHub__Badge",
        `WorkHub__Badge--${colorTheme}`,
        rest.className
    );

    return (
        <span {...rest}>
            {children}
        </span>
    );
}
