import * as React from "react";
import { useContext, useEffect, useState } from "react";

import CursorInspector from "../CursorInspector";
import { DeploymentSequencesContext } from "../../slots/SlotsController";
import { DeploymentSequence } from "../../types";
import DurationField from "../../../../lib/form/datetime/DurationField";
import Button from "../../../../lib/button/Button";
import { ApiAttributeErrors, ApiResult } from "../../slots/types";
import { DeploymentSequenceEditorPayload } from "../types";
import { DataContext } from "../../slots/DataContext";
import { CursorPositionContext } from "../CursorPositionContext";

type DeploymentSequenceEditorProps = {
    currentCellPayload: DeploymentSequenceEditorPayload;
    busy: boolean;
    onBusyChange: (busy: boolean) => void;
    onDeploymentSuccessChanged: (success: boolean) => void;
};

export default function DeploymentSequenceEditor(props: DeploymentSequenceEditorProps) {
    const { currentCellPayload, busy, onBusyChange, onDeploymentSuccessChanged } = props;

    const { updateDeploymentSequence } = useContext(DataContext);
    const { cursorPosition } = useContext(CursorPositionContext);
    const [errors, setErrors] = useState<ApiAttributeErrors | null>(null);

    const sequences = useContext(DeploymentSequencesContext);
    const sequence = sequences[currentCellPayload.deploymentSequenceId];

    const [attributes, setAttributes] = useState<Partial<DeploymentSequence>>({
        manual_break_seconds: sequence?.manual_break_seconds,
    });
    useEffect(() => setAttributes({ manual_break_seconds: sequence?.manual_break_seconds }), [currentCellPayload]);

    const sendUpdate = (): void => {
        onBusyChange(true);

        const handleApiResult = (apiResult: ApiResult<DeploymentSequence>) => {
            onBusyChange(false);
            setErrors(apiResult.errors);

            if (apiResult.errors && Object.keys(apiResult.errors).length > 0) {
                onDeploymentSuccessChanged(false);
            } else {
                onDeploymentSuccessChanged(true);
            }
        };

        updateDeploymentSequence(currentCellPayload.deploymentSequenceId, attributes).then(handleApiResult);
    };

    const handleChange = (attrs: Partial<DeploymentSequence>): void => setAttributes({ ...attributes, ...attrs });

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            sendUpdate();
        }
    };
    const isAutoBreak = attributes.manual_break_seconds === null;

    const setAutoBreak = (autoBreak = true) => {
        if (autoBreak) {
            setAttributes({ manual_break_seconds: null });
        } else {
            setAttributes({ manual_break_seconds: sequence?.auto_break_seconds });
        }
    };

    useEffect(() => {
        setErrors(null);
    }, [cursorPosition]);

    return (
        <>
            <div className="d-flex flex-column gap-2 align-items-start pt-2">
                <div>
                    <label>
                        <input type="radio" checked={isAutoBreak} onChange={() => setAutoBreak(true)} />{" "}
                        <span>Nach Pausenregelung</span>
                    </label>
                </div>
                <div>
                    <label className="d-block">
                        <input type="radio" checked={!isAutoBreak} onChange={() => setAutoBreak(false)} />{" "}
                        <span>Von Pausenregelung abweichend</span>{" "}
                    </label>
                    <div style={{ marginLeft: "18px", marginTop: "5px" }}>
                        <DurationField
                            disabled={busy || isAutoBreak}
                            value={isAutoBreak ? sequence?.auto_break_seconds : attributes.manual_break_seconds}
                            onChange={(value) => handleChange({ manual_break_seconds: value })}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <Button
                    aria-label="OK"
                    title="OK"
                    size={"sm"}
                    disabled={busy}
                    icon="apply"
                    theme="primary"
                    className={"ms-2 align-self-end"}
                    onClick={sendUpdate}
                />
            </div>
            <CursorInspector errors={errors} warnings={[]} />
        </>
    );
}
