import * as React from "react";
import { useEffect, useState } from "react";

import EditorOverlay from "./editors/EditorOverlay";
import DeleteController from "./DeleteController";
import {
    AbsenceCellPayload,
    CellPayload,
    CursorAnchorElement,
    CursorPositionContextType,
    DeploymentCellPayload,
    EmployeeNoteCellPayload,
    NewDeploymentCellPayload,
    NewEmployeeWeekCellPayload,
} from "./types";
import NewEmployeeWeekFace from "./faces/NewEmployeeWeekFace";
import NewDeploymentFace from "./faces/NewDeploymentFace";
import AbsenceFace from "./faces/AbsenceFace";
import DeploymentFace from "./faces/DeploymentFace";
import EmployeeNoteFace from "./faces/EmployeeNoteFace";

type CursorProps = CursorPositionContextType & {
    cursorAnchorElement: CursorAnchorElement;
    currentCellPayload: CellPayload;
};

export default function Cursor(props: CursorProps) {
    const { cursorPosition, currentCellPayload, cursorAnchorElement } = props;

    const [busy, onBusyChange] = useState(false);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        if (success) {
            const timeout = setTimeout(() => setSuccess(null), 750);
            return () => clearTimeout(timeout);
        }
    }, [success]);

    useEffect(() => setSuccess(null), [cursorPosition]);

    useEffect(() => {
        onBusyChange(false);
    }, [currentCellPayload.type]);

    const handleDeploymentSuccessChange = (value) => {
        setSuccess(value);
        if (value) {
            props.setCursorPosition({ ...cursorPosition, editorOpen: false });
        }
    };

    let face: JSX.Element = null;

    const commonFaceProps = { busy, onBusyChange, onDeploymentSuccessChanged: handleDeploymentSuccessChange };

    switch (currentCellPayload.type) {
        case "newEmployeeWeek":
            face = (
                <NewEmployeeWeekFace
                    {...commonFaceProps}
                    currentCellPayload={currentCellPayload as NewEmployeeWeekCellPayload}
                />
            );
            break;

        case "newDeployment":
            face = (
                <NewDeploymentFace
                    {...commonFaceProps}
                    currentCellPayload={currentCellPayload as NewDeploymentCellPayload}
                />
            );
            break;

        case "absence":
            face = <AbsenceFace {...commonFaceProps} currentCellPayload={currentCellPayload as AbsenceCellPayload} />;
            break;

        case "deployment":
            face = (
                <DeploymentFace {...commonFaceProps} currentCellPayload={currentCellPayload as DeploymentCellPayload} />
            );
            break;

        case "employeeNote":
            face = (
                <EmployeeNoteFace
                    {...commonFaceProps}
                    currentCellPayload={currentCellPayload as EmployeeNoteCellPayload}
                />
            );
            break;
    }

    return (
        <DeleteController>
            {cursorPosition.editorOpen && (
                <EditorOverlay cursorAnchorElement={cursorAnchorElement} busy={busy} success={success}>
                    {face}
                </EditorOverlay>
            )}
        </DeleteController>
    );
}
