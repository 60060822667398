import * as React from "react";
import { useMemo } from "react";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from "react-router-dom";
import { EmployeeIdContext } from "./EmployeeIdContext";
import { ReportBrowser } from "./ReportBrowser";
import { DataFromApi } from "./DataFromApi";
import StateContextProvider from "./StateContextProvider";
import EffectsContextProvider from "./EffectsContextProvider";
import { ExternalFilterParams } from "./ExternalFilterParams";

type EttReportProps = {
    employeeId: string;
}

export default function EttReport(props: EttReportProps) {
    const { employeeId } = props;

    const router = useMemo(() => createBrowserRouter(
        createRoutesFromElements(
            <Route element={<RoutedApp employeeId={employeeId} />}>
                <Route path="/" element={<ReportBrowser />} />
            </Route>
        ), { basename: `/employees/${employeeId}/ett_report` }
    ), [employeeId]);

    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
}

type RoutedAppProps = {
    employeeId: string;
}

function RoutedApp(props: RoutedAppProps) {
    const { employeeId } = props;
    return (
        <ErrorBoundary>
            <EmployeeIdContext.Provider value={{ employeeId }}>
                <StateContextProvider>
                    <EffectsContextProvider>
                        <ExternalFilterParams>
                            <DataFromApi>
                                <Outlet />
                            </DataFromApi>
                        </ExternalFilterParams>
                    </EffectsContextProvider>
                </StateContextProvider>
            </EmployeeIdContext.Provider>
        </ErrorBoundary>
    );
}
