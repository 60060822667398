import * as React from "react";
import { useEffect, useState } from "react";
import { TimeSheetsWeek } from "./types";
import TimeSheetsWeekRow from "./TimeSheetsWeekRow"

type TimeSheetsWeeksPerMonthForEmployeeProps = {
    employeeId: string;
    unitId: string;
    month: string;
};

export default function TimeSheetsWeeksPerMonthForEmployee(props: TimeSheetsWeeksPerMonthForEmployeeProps) {
    const { employeeId, unitId, month } = props;
    const [weeksPerMonth, setWeeksPerMonth] = useState<TimeSheetsWeek[]>([])

    useEffect(() => {
        fetchWeeksPerMonth()
    }, [employeeId, unitId, month]);

    const fetchWeeksPerMonth = async () => {
        const url = `/api/employees/${employeeId}/time_sheets_days/${unitId}/${month ?? (new Date().toISOString().slice(0, 7))}`;
        const response = await fetch(url);
        const responseData = await response.json();

        setWeeksPerMonth(responseData.data)
    }

    return (
        <>
        <table className="table">
            <thead>
                <tr>
                    <th>KW</th>
                    <th>Stunden gemäß Wochenplan</th>
                    <th>korrigierte finale Stundengutschrift</th>
                    <th>Sollstunden</th>
                    <th>Diff</th>
                    <th>Saldo (Ende der Woche)</th>
                </tr>
            </thead>
            <tbody>
                {weeksPerMonth && weeksPerMonth.map((week) => <TimeSheetsWeekRow key={week.cw} week={week} />)}
            </tbody>
        </table>
        </>
    )
}
