import * as React from "react";
import { HTMLAttributes } from "react";
import { joinClasses } from "../../../lib/dom-utils/class-names";

type TableRowHeaderTextProps = HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
};

export default function TableRowHeaderText(props: TableRowHeaderTextProps) {
    const { children, ...otherProps } = props;
    otherProps.className = joinClasses("SchedulerTable__Row__Header__Text", props.className);

    return <div {...otherProps}>{children}</div>;
}
