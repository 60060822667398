import { DateTime, Interval } from "luxon";
import { useFilterParams } from "./useFilterParams";
import { useMemo } from "react";

export function useDatesFromFilterParams(): DateTime[] {
    const { startsOn, endsOn } = useFilterParams();

    const interval = useMemo(() => {
        return Interval.fromDateTimes(startsOn, endsOn.plus({ day: 1 }));
    }, [startsOn, endsOn]);

    return useMemo(() => {
        return interval.splitBy({ days: 1 }).map((interval) => interval.start);
    }, [interval]);
}
