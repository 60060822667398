import * as React from "react";

import { ApiAttributeErrors, ApiWarnings } from "../slots/types";

type CursorInspectorProps = {
    errors: ApiAttributeErrors | null;
    warnings?: ApiWarnings | null;
};

export default function CursorInspector({ errors }: CursorInspectorProps) {
    const visible = Object.keys(errors || {}).length > 0;

    if (!visible) {
        return null;
    }

    return (
        <div className="SchedulerCursor__Inspector">
            <div className="SchedulerCursor__InspectorHeadline">Dieser Eintrag konnte nicht gespeichert werden.</div>
            <div className="SchedulerCursor__InspectorSubHeadline SchedulerCursor__InspectorSubHeadline--danger">
                Ungültige Eingabe
            </div>
            <div className="SchedulerCursor__InspectorErrorList">
                {errors.map((error) => (
                    <div className="SchedulerCursor__InspectorError" key={error}>
                        {error}
                    </div>
                ))}
            </div>
        </div>
    );
}
