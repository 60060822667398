import * as React from "react";
import { HTMLAttributes } from "react";

import { Errors } from "../../api/adapter";

type FormErrorsProps = HTMLAttributes<HTMLDivElement> & {
    errors: Errors;
};

function FormErrors(props: FormErrorsProps) {
    const { errors } = props;

    if (!errors) {
        return null;
    }

    return (
        <div>
            <div>Bitte korrigieren Sie die folgenden Probleme:</div>

            {Object.keys(errors).map((key) => (
                <div key={errors[key]} className="alert alert-danger">
                    {errors[key]}
                </div>
            ))}
        </div>
    );
}

export default FormErrors;
