import * as React from "react";
import { joinClasses } from "../../dom-utils/class-names";

type FormStaticValueProps = React.HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
};

export default function FormStaticValue(props: FormStaticValueProps) {
    const { children, ...otherProps } = props;

    otherProps.className = joinClasses("FormStaticValue", otherProps.className);

    return <div {...otherProps}>{children}</div>;
}
