import * as React from "react";
import { useState } from "react";
import * as ReactDOM from "react-dom";

import { ThemeColor } from "../theme/colors";
import Toast from "./Toast";

type NotificationsProps = {
    children: (notify: (message: string, theme: ThemeColor) => void) => React.ReactNode | React.ReactNode[];
};

let nextId = 1;

function getNextId() {
    return nextId++;
}

function Notifications({ children }: NotificationsProps) {
    const [messages, setMessages] = useState([]);

    const deleteFromMessages = (messages, id: number) => messages.filter((m) => m.id !== id);

    const notify = (message: string, theme: ThemeColor) => {
        const id = getNextId();
        setMessages([...messages, { id, message, theme }]);
        setTimeout(() => setMessages((oldMessages) => deleteFromMessages(oldMessages, id)), 3000);
    };

    const portal = ReactDOM.createPortal(
        messages.map((message) => (
            <Toast
                key={message.id}
                message={message.message}
                theme={message.theme}
                onClose={() => setMessages((oldMessages) => deleteFromMessages(oldMessages, message.id))}
            />
        )),
        document.querySelector(".toast-container")
    );

    return (
        <>
            {portal}
            {children(notify)}
        </>
    );
}

export default Notifications;
