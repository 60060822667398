import React from "react";

import Cell from "../cell";
import cellWidths from "../cell-widths";
import NumericValue from "../numeric-value";
import EmployeeCell from "../table-commons/employee-cell";
import PropTypes from "prop-types";

export default function MonthBodyRow({ slot, unitId, period }) {
    const rebuilding = slot.builds.length > 0;

    return (
        <>
            {rebuilding ? (
                <tr>
                    <EmployeeCell employee={slot.employee} unitId={unitId} period={period} />
                    <Cell colSpan={11} style={{ borderLeft: "2px solid #ccc", height: "48px" }} center={true}>
                        <div className="text-center d-flex align-items-center justify-content-center">
                            <div className="progress me-3" style={{ width: "200px" }}>
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    style={{ width: "100%" }}
                                >
                                    Aktualisierung läuft
                                </div>
                            </div>
                        </div>
                    </Cell>
                    <td style={{ borderLeft: "2px solid #ccc" }} />
                </tr>
            ) : (
                <>
                    {slot.errors?.base && (
                        <tr>
                            <td colSpan={12} className="text-danger pt-4" style={{ fontSize: "0.875rem" }}>
                                <strong>Speichern fehlgeschlagen.</strong> Server meldet:
                                {slot.errors.base.map((message) => (
                                    <div key={message}>{message}</div>
                                ))}
                            </td>
                        </tr>
                    )}
                    <tr data-month-employee={slot.employee?.id}>
                        <EmployeeCell employee={slot.employee} unitId={unitId} period={period} />
                        <Cell
                            center={true}
                            style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }}
                            numeric={true}
                        >
                            <NumericValue
                                label="Bezahlte Abwesenheiten"
                                humanValue={slot.human.paid_absent_hours}
                                robotValue={slot.paid_absent_hours}
                            />
                        </Cell>
                        <Cell center={true} style={{ width: cellWidths.hours }} numeric={true}>
                            <NumericValue
                                label="Unbezahlte Abwesenheiten"
                                humanValue={slot.human.unpaid_absent_hours}
                                robotValue={slot.unpaid_absent_hours}
                            />
                        </Cell>
                        <Cell
                            center={true}
                            style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }}
                            numeric={true}
                        >
                            <NumericValue
                                label="Abwesenheiten mit Stundengutschrift"
                                humanValue={slot.human.credited_absent_hours}
                                robotValue={slot.credited_absent_hours}
                            />
                        </Cell>
                        <Cell center={true} style={{ width: cellWidths.hours }} numeric={true}>
                            <NumericValue
                                label="Abwesenheiten ohne Stundengutschrift"
                                humanValue={slot.human.uncredited_absent_hours}
                                robotValue={slot.uncredited_absent_hours}
                            />
                        </Cell>
                        <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                            <NumericValue
                                label="Geleistete Arbeitsstunden"
                                humanValue={slot.human.performed_work_hours}
                                robotValue={slot.performed_work_hours}
                            />
                            <br />
                            <NumericValue
                                label="Angerechnete Arbeitsstunden"
                                humanValue={slot.human.credited_work_hours}
                                robotValue={slot.credited_work_hours}
                            />
                        </Cell>
                        <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                            &nbsp;
                            <br />
                            <NumericValue
                                label="Korrekturwert"
                                humanValue={slot.human.adjustment_hours}
                                robotValue={slot.adjustment_hours}
                            />
                        </Cell>
                        <Cell style={{ width: cellWidths.hours }} numeric={true}>
                            <NumericValue
                                label="Geleistete Arbeitsstunden, korrigiert"
                                humanValue={slot.human.adjusted_performed_work_hours}
                                robotValue={slot.adjusted_performed_work_hours}
                            />
                            <br />
                            <NumericValue
                                label="Angerechnete Arbeitsstunden, final"
                                humanValue={slot.human.final_credited_work_hours}
                                robotValue={slot.final_credited_work_hours}
                            />
                        </Cell>
                        <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                            &nbsp;
                            <br />
                            <NumericValue
                                label="Soll-Arbeitsstunden"
                                humanValue={slot.human.target_hours}
                                robotValue={slot.target_hours}
                            />
                        </Cell>
                        <Cell style={{ width: cellWidths.hours }} numeric={true}>
                            &nbsp;
                            <br />
                            <NumericValue
                                label="Differenz"
                                humanValue={slot.human.diff_hours}
                                robotValue={slot.diff_hours}
                            />
                        </Cell>
                        <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                            &nbsp;
                            <br />
                            <NumericValue
                                label="Saldo Vormonat"
                                humanValue={slot.human.prev_month_balance}
                                robotValue={slot.prev_month_balance}
                            />
                        </Cell>
                        <Cell style={{ width: cellWidths.hours }} numeric={true}>
                            &nbsp;
                            <br />
                            <NumericValue
                                label="Saldo aktueller Monat"
                                humanValue={slot.human.current_month_balance}
                                robotValue={slot.current_month_balance}
                            />
                        </Cell>
                        <td style={{ borderLeft: "2px solid #ccc" }} />
                    </tr>
                </>
            )}
        </>
    );
}

MonthBodyRow.propTypes = {
    slot: PropTypes.any,
    unitId: PropTypes.string,
    period: PropTypes.string,
};
