import { useSearchParams } from "react-router-dom";
import { useFilterParams } from "./useFilterParams";
import { useCallback, useMemo } from "react";

type NavigateReportOptions = {
    unitIds?: string[];
    year?: number | string;
};
type NavigateReportType = (opts: NavigateReportOptions) => void;

export function useNavigateReport(): NavigateReportType {
    const filterParams = useFilterParams();
    const unitIdStrings = useMemo(() => filterParams.unitIds.map((id) => id.toString()), [filterParams.unitIds]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSearchParams] = useSearchParams();

    return useCallback(
        (props) => {
            const newParams = {
                unitIds: (props.unitIds || unitIdStrings).join(","),
                year: props.year?.toString() ?? new Date().getFullYear().toString(),
            };
            setSearchParams(newParams);
        },
        [unitIdStrings, filterParams.year, setSearchParams]
    );
}
