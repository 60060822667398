import * as React from "react";

export function Empty() {
    return (
        <div className="EttReport__Empty">
            <div className="EttReport__Empty__Pointer">
                <i className="bi-arrow-up"></i>
                Keine Daten im gewählten Zeitraum.<br />
            </div>
        </div>
    );
}
