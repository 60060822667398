import * as React from "react";
import { CSSProperties } from "react";
import { DateTime, Interval } from "luxon";
import MonthCalendar from "./MonthCalendar";
import Button from "../../button/Button";

type MonthCalendarListProps = {
    setCurrentFocusDt: React.Dispatch<React.SetStateAction<DateTime>>;
    currentFocusDt: DateTime;
    selection: Interval;
    onSelectDay: (val: DateTime) => unknown;
    onSelectInterval: (interval: Interval) => unknown;
};

export function MonthCalendarList(props: MonthCalendarListProps): JSX.Element {
    const { setCurrentFocusDt, currentFocusDt, selection, onSelectDay, onSelectInterval } = props;

    const wrapperStyle: CSSProperties = {
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        gap: "5px",
        fontSize: "0.825rem",
    };

    const calendarContainerStyle: CSSProperties = {
        display: "flex",
        flex: "1 1 100%",
        alignItems: "start",
        justifyContent: "space-between",
        gap: "5px",
        height: 212,
    };

    return (
        <div style={wrapperStyle}>
            <div style={{ flex: "none" }}>
                <Button
                    icon="previous"
                    size="md"
                    theme="secondary"
                    onClick={() => setCurrentFocusDt(currentFocusDt.minus({ month: 1 }))}
                />
            </div>
            <div style={calendarContainerStyle}>
                <MonthCalendar
                    date={currentFocusDt.minus({ month: 1 })}
                    selection={selection}
                    onSelectDay={onSelectDay}
                    onSelectInterval={onSelectInterval}
                />
                <MonthCalendar
                    date={currentFocusDt}
                    selection={selection}
                    onSelectDay={onSelectDay}
                    onSelectInterval={onSelectInterval}
                />
                <MonthCalendar
                    date={currentFocusDt.plus({ month: 1 })}
                    selection={selection}
                    onSelectDay={onSelectDay}
                    onSelectInterval={onSelectInterval}
                />
            </div>
            <div style={{ flex: "none" }}>
                <Button
                    icon="next"
                    size="md"
                    theme="secondary"
                    onClick={() => setCurrentFocusDt(currentFocusDt.plus({ month: 1 }))}
                />
            </div>
        </div>
    );
}

export default MonthCalendar;
