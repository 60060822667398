import * as React from "react";
import { createContext, Dispatch, useContext, useMemo } from "react";
import { Action } from "./state/actions";
import { useSearchParams } from "react-router-dom";
import { useReportDispatch } from "./StateContextProvider";
import { createEffects, Effects } from "./state/effects";

const EffectsContext = createContext<Effects>(undefined);

type EffectsContextProviderProps = {
    children: React.ReactNode;
}

export default function EffectsContextProvider(props: EffectsContextProviderProps) {
    const { children } = props;

    const dispatch = useReportDispatch();
    const effects = useEffectFunctions(dispatch);

    return (
        <EffectsContext.Provider value={effects}>
            {children}
        </EffectsContext.Provider>
    );
}

function useEffectFunctions(dispatch: Dispatch<Action>) {
    const [_searchParams, setSearchParams] = useSearchParams();
    return useMemo(() => createEffects(dispatch, setSearchParams), [dispatch]);
}

export function useReportEffects() {
    return useContext(EffectsContext);
}
