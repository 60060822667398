import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import * as React from "react";
import { Index } from "./Index";
import { Layout } from "./layout/Layout";
import { Factsheet } from "./factsheet/Factsheet";

export const defaultRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route index element={<Index />} />
            <Route path=":employeeId/*" element={<Factsheet />} />
        </Route>
    ),
    {
        basename: "/employee_manager",
    }
);

export const standaloneSidebarRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route index element={<Index />} />
            <Route path=":employeeId/*" element={<Factsheet />} />
        </Route>
    ),
    {
        basename: "/employees",
    }
);
