import * as React from "react";
import Icon from "../../../lib/icon/Icon";
import { conditionalClassNames, joinClasses } from "../../../lib/dom-utils/class-names";

export function Warning(props: { dark?: boolean; children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
    const { dark = false, children, ...divProps } = props;

    divProps.className = joinClasses(
        ...conditionalClassNames({
            EmployeeManager__Warning: true,
            "EmployeeManager__Warning--dark": dark,
        }),
        divProps.className
    );

    return (
        <div {...divProps}>
            <Icon type="warning-circle" /> {children}
        </div>
    );
}
