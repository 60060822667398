import { useEmployeeState } from "../../../state";
import Kitchensink from "../../../../../../lib/kitchensink/Kitchensink";
import { Hint } from "../../../Hint";
import * as React from "react";
import { AbsencesItem } from "./AbsenceItem";

export function Absences() {
    const {
        absences: { relevantAbsences },
    } = useEmployeeState();

    return (
        <Kitchensink.ItemBodyPart className="EmployeeManager__Factsheet__Absences__List">
            <Kitchensink.ItemBodyPartHeadline>
                Aktuelle Abwesenheiten (letzte 30 Tage und geplante)
            </Kitchensink.ItemBodyPartHeadline>
            {!relevantAbsences?.length && <Hint muted>Keine aktuellen Abwesenheiten</Hint>}
            {!!relevantAbsences?.length &&
                relevantAbsences.map((absence) => <AbsencesItem key={absence.id} absence={absence} />)}
        </Kitchensink.ItemBodyPart>
    );
}
