import {
    DayOfWeek,
    DeploymentSequenceAssignments,
    DeploymentSequenceMap,
    EmployeeNote,
    EmployeeNotesDirectory,
    SimpleDeployment,
} from "./types";
import {
    Absence,
    CustomItemColor,
    Day,
    Days,
    Department,
    Deployment,
    DeploymentSequence,
    DeploymentSlot,
    Employee,
    EmployeeDayConditions,
    Schedule,
    ScheduleDailyNote,
    ScheduleDailyNotesMap,
} from "../types";
import { DateTime } from "luxon";

function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function updateDeploymentSequences(
    prevSequences: DeploymentSequenceMap,
    affectedDeploymentSequences: DeploymentSequence[],
    deletedDeploymentSequenceIds: DeploymentSequence["id"][] = []
) {
    const newSequences = { ...prevSequences };
    affectedDeploymentSequences.forEach((seq) => (newSequences[seq.id] = { ...seq }));
    deletedDeploymentSequenceIds.forEach((id) => delete newSequences[id]);
    return newSequences;
}

export function updateDeploymentSequence(
    prevSequences: DeploymentSequenceMap,
    deploymentSequence: Partial<DeploymentSequence>
) {
    const newSequences = { ...prevSequences };
    newSequences[deploymentSequence.id] = { ...newSequences[deploymentSequence.id], ...deploymentSequence };
    return newSequences;
}

function applyDeploymentSequenceAssignments(
    prevSlots: DeploymentSlot[],
    deploymentSequenceAssignments: DeploymentSequenceAssignments = {}
): DeploymentSlot[] {
    return mapDeploymentsInSlots(prevSlots, (deployment) => {
        return {
            ...deployment,
            deployment_sequence_id:
                deployment.id in deploymentSequenceAssignments
                    ? deploymentSequenceAssignments[deployment.id]
                    : deployment.deployment_sequence_id,
        };
    });
}

export function updateDeploymentColorInSlots(
    prevSlots: DeploymentSlot[],
    id: string,
    color: CustomItemColor
): DeploymentSlot[] {
    return mapDeploymentsInSlots(prevSlots, (deployment) => ({
        ...deployment,
        color: id === deployment.id ? color : deployment.color,
        deployment_sequence_id: deployment.deployment_sequence_id,
    }));
}

function mapDeploymentsInSlots(
    prevSlots: DeploymentSlot[],
    callback: (
        deployment: Deployment,
        day: Day,
        employeeWeek: EmployeeDayConditions,
        slot: DeploymentSlot
    ) => Deployment
): DeploymentSlot[] {
    return mapDaysInSlots(prevSlots, (day, employeeWeek, slot) => ({
        ...day,
        deployments: day.deployments.map((deployment) => callback(deployment, day, employeeWeek, slot)),
    }));
}

function mapDaysInSlots(
    prevSlots: DeploymentSlot[],
    callback: (deployment: Day, employeeWeek: EmployeeDayConditions, slot: DeploymentSlot) => Day
): DeploymentSlot[] {
    const nextSlots = deepClone(prevSlots);
    for (const slot of nextSlots) {
        for (const employeeWeek of slot.employee_weeks) {
            for (const dow in employeeWeek.days) {
                employeeWeek.days[dow] = callback(employeeWeek.days[dow], employeeWeek, slot);
            }
        }
    }
    return nextSlots;
}

export function updateDeploymentInSlots(
    prevSlots: DeploymentSlot[],
    id: string,
    values: SimpleDeployment,
    deploymentSequenceAssignments: DeploymentSequenceAssignments = {}
): DeploymentSlot[] {
    let nextSlots;

    nextSlots = mapDeploymentsInSlots(prevSlots, (deployment) => {
        if (id === deployment.id) {
            return {
                ...deployment,
                id: values.id,
                color: values.color,
                start_time: values.start_time,
                end_time: values.end_time,
                warnings: values.warnings ?? [],
                edit_url: values.edit_url ?? null,
                deployment_sequence_id: values.deployment_sequence_id ?? null,
            };
        }
        return deployment;
    });

    nextSlots = applyDeploymentSequenceAssignments(nextSlots, deploymentSequenceAssignments);

    return nextSlots;
}

export function deleteDeploymentFromSlots(
    prevSlots: DeploymentSlot[],
    id: string,
    deploymentSequenceAssignments: DeploymentSequenceAssignments = {}
): DeploymentSlot[] {
    let nextSlots = deepClone(prevSlots);
    for (const slot of nextSlots) {
        for (const employeeWeek of slot.employee_weeks) {
            for (const dow in employeeWeek.days) {
                for (const deployment of (employeeWeek.days[dow] as Day).deployments) {
                    if (deployment.id === id) {
                        employeeWeek.days[dow].deployments = employeeWeek.days[dow].deployments.filter(
                            (d) => d.id !== id
                        );

                        (employeeWeek.days[dow] as Day).global_deployments = (
                            employeeWeek.days[dow] as Day
                        ).global_deployments.filter((gd) => gd.id !== id);

                        return nextSlots;
                    }
                }
            }
        }
    }
    nextSlots = applyDeploymentSequenceAssignments(nextSlots, deploymentSequenceAssignments);
    return nextSlots;
}

function purgeEmptyDeployments(prevSlots: DeploymentSlot[]): DeploymentSlot[] {
    const nextSlots = deepClone(prevSlots);
    for (const slot of nextSlots) {
        for (const employeeWeek of slot.employee_weeks) {
            for (const dow of Object.keys(employeeWeek.days)) {
                employeeWeek.days[dow].deployments = employeeWeek.days[dow].deployments.filter(
                    (deployment) => deployment.id
                );
            }
        }
    }
    return nextSlots;
}

export function insertDeploymentIntoSlots(
    prevSlots: DeploymentSlot[],
    departmentId: string,
    employeeId: string,
    dow: DayOfWeek,
    values: SimpleDeployment
): DeploymentSlot[] {
    const nextSlots = purgeEmptyDeployments(prevSlots);
    for (const slot of nextSlots) {
        if (slot.department.id === departmentId) {
            for (const employeeWeek of slot.employee_weeks) {
                if (employeeWeek.employee.id == employeeId) {
                    employeeWeek.days[String(dow)].deployments.push(values);
                    if (values.id) {
                        (employeeWeek.days[String(dow)] as Day).global_deployments.push({
                            id: values.id,
                            unit_name: "(selbe Filiale)",
                            department_name: slot.department.name,
                            end_time: values.end_time,
                            start_time: values.start_time,
                        });
                    }
                    return nextSlots;
                }
            }
        }
    }
    return nextSlots;
}

export function insertEmployeeWeekIntoSlots(
    prevSlots: DeploymentSlot[],
    departmentId: Department["id"],
    employee: Employee,
    conditions: EmployeeDayConditions
): DeploymentSlot[] {
    const nextSlots: DeploymentSlot[] = deepClone(prevSlots);
    for (const slot of nextSlots) {
        if (slot.department.id === departmentId) {
            for (const existingEmployeeWeek of slot.employee_weeks) {
                if (existingEmployeeWeek.employee.id === employee.id) {
                    return nextSlots;
                }
            }
            const days = ([1, 2, 3, 4, 5, 6, 7] as DayOfWeek[]).reduce((memo, dow) => {
                memo[String(dow)] = {
                    dow: dow,
                    deployments: [],
                    availabilities: conditions[dow].availabilities,
                    absences: conditions[dow].absences,
                    school: conditions[dow].school,
                    workplaces: conditions[dow].workplaces,
                    unit_assignment: conditions[dow].unit_assignment,
                    global_deployments: conditions[dow].deployments,
                };
                return memo;
            }, {});
            slot.employee_weeks.push({
                employee: employee,
                days: days as Days,
            });
        }
    }
    return nextSlots;
}

export function insertAbsenceIntoSlots(
    prevSlots: DeploymentSlot[],
    absence: Absence,
    employeeId: Employee["id"],
    dows: DayOfWeek[]
): DeploymentSlot[] {
    const nextSlots: DeploymentSlot[] = deepClone(prevSlots);
    for (const slot of nextSlots) {
        for (const existingEmployeeWeek of slot.employee_weeks) {
            if (existingEmployeeWeek.employee.id === employeeId) {
                for (const dow of dows) {
                    existingEmployeeWeek.days[dow].absences ||= [];
                    // Only push if absence is not already in list to prevent duplicate entries. This could happen
                    // after an auto-merge.
                    if (!existingEmployeeWeek.days[dow].absences.find((a) => a.id === absence.id)) {
                        existingEmployeeWeek.days[dow].absences.push(absence);
                    }
                }
            }
        }
    }
    return nextSlots;
}

export function updateAbsenceIntoSlots(
    prevSlots: DeploymentSlot[],
    absence: Absence,
    employeeId: Employee["id"], // this has probably become obsolete, now that employee_id has become part of absence data.
    dows: DayOfWeek[]
): DeploymentSlot[] {
    const nextSlots: DeploymentSlot[] = deepClone(prevSlots);
    for (const slot of nextSlots) {
        for (const existingEmployeeWeek of slot.employee_weeks) {
            if (existingEmployeeWeek.employee.id === employeeId) {
                for (const dow of dows) {
                    if (existingEmployeeWeek.days[dow].absences) {
                        existingEmployeeWeek.days[dow].absences = existingEmployeeWeek.days[dow].absences.map(
                            (origAbsence) => (origAbsence.id === absence.id ? absence : origAbsence)
                        );
                    }
                }
            }
        }
    }
    return nextSlots;
}

export function upsertAbsenceIntoSlots(
    prevSlots: DeploymentSlot[],
    absence: Absence,
    schedule: Schedule
): DeploymentSlot[] {
    const nextSlots: DeploymentSlot[] = deepClone(prevSlots);
    const scheduleStartsOn = DateTime.fromISO(schedule.starts_on);
    const absenceStartsOn = DateTime.fromISO(absence.starts_on);
    const absenceEndsOn = DateTime.fromISO(absence.ends_on);

    for (const slot of nextSlots) {
        for (const existingEmployeeWeek of slot.employee_weeks) {
            if (existingEmployeeWeek.employee.id === absence.employee_id) {
                for (const dow in existingEmployeeWeek.days) {
                    const dayOn = scheduleStartsOn.plus({ days: Number(dow) - 1 });

                    // Check if absenceStartsOn..absenceEndsOn overlaps with dayOn.
                    if (absenceStartsOn <= dayOn && dayOn < absenceEndsOn) {
                        existingEmployeeWeek.days[dow].absences ||= [];
                        const existingAbsence = existingEmployeeWeek.days[dow].absences.find(
                            (a) => a.id === absence.id
                        );
                        if (existingAbsence) {
                            Object.assign(existingAbsence, absence);
                        } else {
                            existingEmployeeWeek.days[dow].absences.push(absence);
                        }
                    }
                }
            }
        }
    }
    return nextSlots;
}

export function deleteAbsenceFromSlots(prevSlots: DeploymentSlot[], absenceId: Absence["id"]): DeploymentSlot[] {
    const nextSlots: DeploymentSlot[] = deepClone(prevSlots);
    for (const slot of nextSlots) {
        for (const employeeWeek of slot.employee_weeks) {
            for (const dow in employeeWeek.days) {
                (employeeWeek.days[dow] as Day).absences = (employeeWeek.days[dow] as Day).absences.filter(
                    (absence) => absence.id !== absenceId
                );
            }
        }
    }
    return nextSlots;
}

export function insertEmployeeNote(
    prevEmployeeNotesDirectory: EmployeeNotesDirectory,
    employeeId: Employee["id"],
    dow: DayOfWeek,
    employeeNote: EmployeeNote
): EmployeeNotesDirectory {
    const nextEmployeeNotes: EmployeeNotesDirectory = deepClone(prevEmployeeNotesDirectory);
    nextEmployeeNotes[employeeId] ||= {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
    };
    nextEmployeeNotes[employeeId][dow].push(employeeNote);
    return nextEmployeeNotes;
}

export function updateEmployeeNote(
    prevEmployeeNotesDirectory: EmployeeNotesDirectory,
    employeeNoteId: EmployeeNote["id"],
    values: Partial<EmployeeNote>
): EmployeeNotesDirectory {
    const nextEmployeeNotesDirectory: EmployeeNotesDirectory = deepClone(prevEmployeeNotesDirectory);

    for (const employeeId in nextEmployeeNotesDirectory) {
        for (const dow in nextEmployeeNotesDirectory[employeeId]) {
            const employeeNotesForDay = nextEmployeeNotesDirectory[employeeId][dow];
            for (const employeeNote of employeeNotesForDay) {
                if (employeeNote.id === employeeNoteId) {
                    Object.assign(employeeNote, values);
                }
            }
        }
    }

    return nextEmployeeNotesDirectory;
}

export function deleteEmployeeNote(
    prevEmployeeNotes: EmployeeNotesDirectory,
    employeeNoteId: EmployeeNote["id"]
): EmployeeNotesDirectory {
    const nextEmployeeNotes: EmployeeNotesDirectory = deepClone(prevEmployeeNotes);
    for (const employeeId in nextEmployeeNotes) {
        for (const dow in nextEmployeeNotes[employeeId]) {
            nextEmployeeNotes[employeeId][dow] = nextEmployeeNotes[employeeId][dow].filter(
                (employeeNote) => employeeNote.id !== employeeNoteId
            );
        }
    }
    return nextEmployeeNotes;
}

export function insertScheduleDailyNote(
    prevScheduleDailyNotesMap: ScheduleDailyNotesMap,
    scheduleDailyNote: ScheduleDailyNote
): ScheduleDailyNotesMap {
    const nextScheduleDailyNotes: ScheduleDailyNotesMap = deepClone(prevScheduleDailyNotesMap);
    nextScheduleDailyNotes[scheduleDailyNote.day_of_week] ||= [];
    nextScheduleDailyNotes[scheduleDailyNote.day_of_week].push(scheduleDailyNote);
    return nextScheduleDailyNotes;
}

export function updateScheduleDailyNote(
    prevScheduleDailyNotesMap: ScheduleDailyNotesMap,
    scheduleDailyNoteId: ScheduleDailyNote["id"],
    values: Partial<ScheduleDailyNote>
): ScheduleDailyNotesMap {
    const nextScheduleDailyNotesMap: ScheduleDailyNotesMap = deepClone(prevScheduleDailyNotesMap);
    for (const dow in nextScheduleDailyNotesMap) {
        const scheduleDailyNotesForDay = nextScheduleDailyNotesMap[dow];
        for (const idx in scheduleDailyNotesForDay) {
            if (scheduleDailyNotesForDay[idx].id === scheduleDailyNoteId) {
                scheduleDailyNotesForDay[idx] = {
                    ...scheduleDailyNotesForDay[idx],
                    ...values,
                };
            }
        }
    }
    return nextScheduleDailyNotesMap;
}

export function deleteScheduleDailyNote(
    prevScheduleDailyNotes: ScheduleDailyNotesMap,
    scheduleDailyNoteId: ScheduleDailyNote["id"]
): ScheduleDailyNotesMap {
    const nextScheduleDailyNotes: ScheduleDailyNotesMap = deepClone(prevScheduleDailyNotes);
    for (const dow in nextScheduleDailyNotes) {
        nextScheduleDailyNotes[dow] = nextScheduleDailyNotes[dow].filter(
            (scheduleDailyNote) => scheduleDailyNote.id !== scheduleDailyNoteId
        );
    }
    return nextScheduleDailyNotes;
}

export function buildIdMap(arr) {
    return arr.reduce((memo, item) => {
        memo[item.id] = item;
        return memo;
    }, {});
}
