import * as React from "react";
import { useState } from "react";
import TabNav from "../../../shared/TabNav";
import { WeekIdentifier } from "../../../shared/types";
import useWorkHubState from "../../../state/useWorkHubState";
import { LoadingSpinner } from "../../../../../lib/empty-state/Loading";
import Panel from "../../../layout/Panel";
import WeeklyPanelContent from "./WeeklyPanelContent";
import WeeklyEmptyMessage from "./WeeklyEmptyMessage";

type PersonalSchedulesProps = Record<string, never>;

export default function PersonalScheduleResultsPanel(_props: PersonalSchedulesProps) {
    const { personalSchedule: { personalScheduleSet, loading, loadingError: error } } = useWorkHubState();

    const [week, setWeek] = useState<WeekIdentifier>("current");

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div className="p-2 bg-danger text-white">Error: {JSON.stringify(error)}</div>;
    }

    const personalSchedule = personalScheduleSet[week] ?? null;

    return (
        <Panel>
            <Panel.Title text="Einsatzplan" />
            <TabNav onChange={setWeek} week={week} />
            <Panel.Divider />
            <Panel.Section>
                {null === personalSchedule ? (
                    <WeeklyEmptyMessage />
                ) : (<>
                    <WeeklyPanelContent personalSchedule={personalSchedule} />
                </>)}
            </Panel.Section>
        </Panel>
    );
}
