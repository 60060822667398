import * as React from "react";
import { CSSProperties, useMemo } from "react";
import { DaysInWeekDayValue } from "./types";
import { DateTime, Interval } from "luxon";

type DayCellProps = {
    value: DaysInWeekDayValue;
    selection: Interval;
    onClick: () => void;
};

export default function DayCell(props: DayCellProps) {
    const { value, selection, onClick } = props;
    const selectionForComparision = useMemo(
        () => Interval.fromDateTimes(selection.start.startOf("day"), selection.end.endOf("day")),
        [selection]
    );

    let overlapsSelection = false;
    if (selection && null !== value) {
        const dayInterval = Interval.fromDateTimes(value.startOf("day"), value.endOf("day"));

        if (dayInterval.overlaps(selectionForComparision)) {
            overlapsSelection = true;
        }
    }

    const styles: CSSProperties = {
        padding: "2px",
        cursor: "default",
        userSelect: "none",
        textAlign: "center",
        border: "1px solid #ccc",
    };

    if (null === value) {
        styles.backgroundColor = "#efefef";
    } else {
        const isToday = DateTime.local().startOf("day").equals(value.startOf("day"));

        if (overlapsSelection) {
            styles.backgroundColor = isToday ? "#69a1e0" : "#0066cc";
            styles.color = "#ffffff";
        } else {
            styles.backgroundColor = isToday ? "#fffaa3" : "#f9f9f9";
        }
    }

    const text = value?.day ?? "";

    return (
        <td style={styles} onClick={onClick}>
            {text}
        </td>
    );
}
