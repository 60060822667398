import React from "react";
import PropTypes from "prop-types";

export function TimeSheetTable({ headRows, loading, placeholderRows, bodyRows }) {
    return (
        <div className="" style={{ flex: "1 1 100%", overflow: "scroll", position: "relative" }}>
            <table className="table table-bordered table-striped mb-0 bg-light" style={{ tableLayout: "fixed" }}>
                <thead>{headRows}</thead>
                <tbody>
                    {loading && placeholderRows}
                    {!loading && bodyRows}
                </tbody>
            </table>
        </div>
    );
}

TimeSheetTable.propTypes = {
    headRows: PropTypes.any,
    loading: PropTypes.any,
    placeholderRows: PropTypes.any,
    bodyRows: PropTypes.any,
};
