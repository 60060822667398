import * as React from "react";
import { ReactNode } from "react";
import Loading from "./Loading";

type LoadingGuardProps = {
    loadingText?: string;
    loaded: () => boolean;
    children: () => ReactNode;
};

export default function LoadingGuard(props: LoadingGuardProps) {
    const { loadingText = "Laden...", loaded, children } = props;

    if (loaded()) {
        return <>{children()}</>;
    } else {
        return <Loading loadingText={loadingText} />;
    }
}
