import * as React from "react";
import { formatWeekNumber } from "../../datetime/date-format";
import { ReportDay } from "./types";
import { Day } from "./day/Day";

type EttReportGridProps = {
    daysByWeeks: Record<string, ReportDay[]>;
    expandable: boolean;
}

export function GridBody(_props: EttReportGridProps) {
    const { daysByWeeks, expandable } = _props;

    return (
        <div className="EttReportGrid__Body">
            {Object.keys(daysByWeeks).map(weekDate => (
                <div key={weekDate} className="EttReportGrid__Week">
                    <div className="EttReportGrid__Week__Name">
                        KW {formatWeekNumber(weekDate)}
                    </div>
                    <div className="EttReportGrid__Week__Days">
                        {daysByWeeks[weekDate].map(day => (
                            <Day
                                key={day.date.toISODate()}
                                day={day}
                                expandable={expandable}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
