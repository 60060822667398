import React from "react";
import cellWidths from "../cell-widths";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

export default function WeekTableHeadRows({ week, year }) {
    const date = DateTime.fromObject({ weekYear: year, weekNumber: week });

    const daysInMonth = {
        mo: date.day,
        di: date.plus({ days: 1 }).day,
        mi: date.plus({ days: 2 }).day,
        do: date.plus({ days: 3 }).day,
        fr: date.plus({ days: 4 }).day,
        sa: date.plus({ days: 5 }).day,
        so: date.plus({ days: 6 }).day,
    };

    return (
        <>
            <tr>
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.employee }}
                    className="border-bottom-0"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hoursExtended }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hoursExtended }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hoursExtended }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hoursExtended }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hoursExtended }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hoursExtended }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hoursExtended }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th
                    style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.hours }}
                    className="border-bottom-0 text-end"
                />
                <th style={{ border: 0, padding: 0, height: 0, overflow: "hidden", width: cellWidths.actions }} />
            </tr>
            <tr>
                <th colSpan={1} style={{ verticalAlign: "middle" }} />
                <th
                    colSpan={7}
                    className="text-center"
                    style={{ verticalAlign: "middle", fontSize: "0.875rem", borderLeft: "2px solid #ccc" }}
                >
                    Arbeitszeit
                    <br />
                    <span
                        className="text-muted font-monospace d-inline-block ms-2"
                        style={{ textAlign: "middle", verticalAlign: "middle", fontSize: 12, fontWeight: "normal" }}
                    >
                        <div className="border-bottom">gemäß Wochenplan</div>
                        <div>Stundengutschrift</div>
                    </span>
                </th>
                <th
                    colSpan={4}
                    className="text-center"
                    style={{ verticalAlign: "middle", fontSize: "0.875rem", borderLeft: "2px solid #ccc" }}
                >
                    Abwesenheit
                </th>
                <th
                    colSpan={3}
                    className="text-center"
                    style={{ verticalAlign: "middle", fontSize: "0.875rem", borderLeft: "2px solid #ccc" }}
                >
                    Ergebnis
                    <br />
                    <span
                        className="text-muted font-monospace d-inline-block ms-2"
                        style={{ textAlign: "middle", verticalAlign: "middle", fontSize: 12, fontWeight: "normal" }}
                    >
                        <div className="border-bottom">gemäß Wochenplan</div>
                        <div>finale Stundengutschrift</div>
                    </span>
                </th>
                <th
                    colSpan={2}
                    className="text-center"
                    style={{ verticalAlign: "middle", fontSize: "0.875rem", borderLeft: "2px solid #ccc" }}
                >
                    Saldo
                </th>
                <th colSpan={1} style={{ verticalAlign: "middle", borderLeft: "2px solid #ccc" }} />
            </tr>
            <tr>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0">
                    Mitarbeiter
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    <span className="text-muted fw-normal">{daysInMonth.mo}</span>
                    <br />
                    Mo
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <span className="text-muted fw-normal">{daysInMonth.di}</span>
                    <br />
                    Di
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <span className="text-muted fw-normal">{daysInMonth.mi}</span>
                    <br />
                    Mi
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <span className="text-muted fw-normal">{daysInMonth.do}</span>
                    <br />
                    Do
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <span className="text-muted fw-normal">{daysInMonth.fr}</span>
                    <br />
                    Fr
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <span className="text-muted fw-normal">{daysInMonth.sa}</span>
                    <br />
                    Sa
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <span className="text-muted fw-normal">{daysInMonth.so}</span>
                    <br />
                    So
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    bezahlt
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <abbr title="unbezahlt">unbez.</abbr>
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    <abbr title="mit Stundengutschrift">
                        mit
                        <br />
                        SG
                    </abbr>
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <abbr title="ohne Stundengutschrift">
                        ohne
                        <br />
                        SG
                    </abbr>
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    finale ∑
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    Soll
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    <abbr title="Differenz">Diff.</abbr>
                </th>
                <th style={{ fontSize: "0.875rem", borderLeft: "2px solid #ccc" }} className="border-bottom-0 text-end">
                    <abbr title="Vorwoche">Vorw.</abbr>
                </th>
                <th style={{ fontSize: "0.875rem" }} className="border-bottom-0 text-end">
                    Aktuell
                </th>
                <th style={{ borderLeft: "2px solid #ccc" }} />
            </tr>
        </>
    );
}

WeekTableHeadRows.propTypes = {
    week: PropTypes.number,
    year: PropTypes.number,
};
