import * as React from "react";
import WithReportFromApi from "./WithReportFromApi";
import FilterBar from "./FilterBar";
import WithUnitsFromApi from "./WithUnitsFromApi";
import { NumericValue } from "../../../lib/number/NumericValue";
import { DataTableColumn, DataTableRow } from "../../../lib/data-table/types";
import DataTable from "../../../lib/data-table/DataTable";
import { useFilterParams } from "./useFilterParams";
import Icon from "../../../lib/icon/Icon";

export default function Report() {
    const { year } = useFilterParams();
    const numberFormatOptions = { minimumFractionDigits: 1, maximumFractionDigits: 1 };
    const columns: DataTableColumn[] = [
        {
            name: "employee",
            title: "Mitarbeiter",
            value: (d: { id: string; name: string }) => (
                <div className="d-flex justify-content-between">
                    <span className="fw-bold">{d.name}</span>
                    <a
                        href={`/employees/${d.id}/vacation_entitlements`}
                        className="text-decoration-none"
                        data-turbolinks="false"
                    >
                        <Icon type="goto" />
                    </a>
                </div>
            ),
            totalValue: () => null,
            minWidth: 250,
            maxWidth: 250,
        },
        {
            name: "carry",
            title: "Übertrag",
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            name: "new",
            title: "Jährlicher Anspruch",
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            name: "special",
            title: (
                <span>
                    Sonderurlaub
                    <br />
                    <span className="fw-normal">{year}</span>
                </span>
            ),
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            name: "total",
            title: (
                <span>
                    Anspruch
                    <br />
                    <span className="fw-normal">{year}</span>
                </span>
            ),
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            name: "planned",
            title: (
                <span>
                    Geplant
                    <br />
                    <span className="fw-normal">{year}</span>
                </span>
            ),
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            name: "adjusted",
            title: (
                <span>
                    Korrektur
                    <br />
                    <span className="fw-normal">{year}</span>
                </span>
            ),
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            name: "open",
            title: (
                <span>
                    Offen
                    <br />
                    <span className="fw-normal">{year}</span>
                </span>
            ),
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
        },
        {
            name: "balance",
            title: (
                <span>
                    Saldo
                    <br />
                    <span className="fw-normal">{year}</span>
                </span>
            ),
            value: (d: string) => <NumericValue value={d} options={numberFormatOptions} />,
            totalValue: (d: string) => <NumericValue value={d} colors="dark" options={numberFormatOptions} />,
            align: "right",
            minWidth: 100,
            maxWidth: 150,
            highlight: true,
        },
        {
            name: "pad",
            title: "",
            value: () => "",
            minWidth: 0,
            maxWidth: "1.33fr",
        },
    ];

    return (
        <>
            <WithUnitsFromApi>
                {({ data: units }) => (
                    <>
                        <FilterBar units={units} />
                        <WithReportFromApi>
                            {({ data: report }) => (
                                <>
                                    <DataTable
                                        columns={columns}
                                        data={report.rows as DataTableRow[]}
                                        totals={report.totals as DataTableRow}
                                    />
                                </>
                            )}
                        </WithReportFromApi>
                    </>
                )}
            </WithUnitsFromApi>
        </>
    );
}
