import * as React from "react";
import { DataTableColumn, DataTableRow } from "./types";

type DataTableBodyProps = {
    columns: DataTableColumn[];
    data?: DataTableRow[];
};

export default function DataTableBody(props: DataTableBodyProps) {
    const { columns, data } = props;
    return (
        <tbody style={{ display: "contents" }}>
            {[...Array(1)].map((_, i) => (
                <React.Fragment key={i}>
                    {data.map((row, index) => (
                        <tr className="DataTable__Row" style={{ display: "contents" }} key={index}>
                            {columns.map((column) => (
                                <td
                                    key={column.name}
                                    style={{ textAlign: column.align ?? "left" }}
                                    className={`DataTable__Row__Cell ${
                                        column.highlight ? "DataTable__Row__Cell--highlight" : ""
                                    } px-3 py-1 border-bottom`}
                                >
                                    {column.value(row[column.name], row)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </React.Fragment>
            ))}
        </tbody>
    );
}
