import * as React from "react";
import { useContext, useEffect } from "react";

import { CursorPositionContext } from "./CursorPositionContext";
import { DataContext } from "../slots/DataContext";
import { BalancesContext } from "../slots/SlotsController";

type DeleteControllerProps = {
    children: JSX.Element;
};

export default function DeleteController({ children }: DeleteControllerProps) {
    const { cursorPosition, currentCellPayload } = useContext(CursorPositionContext);
    const { deleteDeployment, destroyEmployeeNote } = useContext(DataContext);
    const { invalidate: invalidateBalances } = useContext(BalancesContext);

    useEffect(() => {
        const handleKeyDown = (keyEvent) => {
            if (
                true !== cursorPosition.editorOpen &&
                currentCellPayload.type === "deployment" &&
                keyEvent.key === "Delete"
            ) {
                invalidateBalances(currentCellPayload.employeeId);
                deleteDeployment(currentCellPayload.deploymentId).then(() => undefined);
            } else if (
                true !== cursorPosition.editorOpen &&
                currentCellPayload.type === "employeeNote" &&
                keyEvent.key === "Delete"
            ) {
                destroyEmployeeNote(currentCellPayload.employeeNote.id).then(() => undefined);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [cursorPosition.editorOpen, currentCellPayload]);

    return children || <></>;
}
