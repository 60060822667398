import Badge from "../../../../lib/badge/Badge";
import Icon from "../../../../lib/icon/Icon";
import * as React from "react";
import { useEmployeesOptions } from "./useEmployeesOptions";
import Combobox from "../../../../lib/form/combobox/Combobox";
import ValidationError from "../../../../lib/form/validation/ValidationError";

type EmployeePickerProps = React.HTMLAttributes<HTMLDivElement> & {
    value: string;
    onChange: (value: string) => unknown;
    onValidation?: (isValid: boolean) => unknown;
    forceValidation?: boolean;
    errors?: string[];
    additionalValidation?: (value: string) => string | null;
    autoFocus?: boolean;
    disabled?: boolean;
};

export function EmployeePicker(props: EmployeePickerProps) {
    const { value, onChange, autoFocus, disabled, errors, ...comboboxProps } = props;

    const { employeeOptions, loading, loadingFailed } = useEmployeesOptions();

    return (
        <>
            {loadingFailed ? (
                <Badge colorScheme="warning">
                    <Icon type="error" /> Fehler beim Laden der Mitarbeiter
                </Badge>
            ) : (
                <Combobox
                    {...comboboxProps}
                    invalid={errors?.length > 0}
                    autoFocus={autoFocus}
                    value={value ?? ""}
                    onChange={onChange}
                    options={employeeOptions ?? {}}
                    disabled={disabled || loading}
                    placeholder="Mitarbeiter auswählen"
                    emptyMessage="Keine übereinstimmenden Mitarbeiter gefunden"
                />
            )}
            {errors?.map((error) => (
                <ValidationError key={error} message={error} />
            ))}
        </>
    );
}
