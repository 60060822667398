import * as React from "react";

export type FinderWindowProps = {
    children: React.ReactNode;
};

export default function FinderWindow(props: FinderWindowProps) {
    const { children } = props;

    return <div className="FinderWindow">{children}</div>;
}
