import * as React from "react";
import { HTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import Select from "../select/Select";

type DateSelectProps = HTMLAttributes<HTMLDivElement> & {
    value: string;
    from: string;
    to: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
};

const dayOptions = Array.from(Array(31).keys()).reduce((memo, i) => {
    memo[(i + 1).toString()] = (i + 1).toString();
    return memo;
}, {});

const monthOptions = {
    "1": "Januar",
    "2": "Februar",
    "3": "März",
    "4": "April",
    "5": "Mai",
    "6": "Juni",
    "7": "Juli",
    "8": "August",
    "9": "September",
    "10": "Oktober",
    "11": "November",
    "12": "Dezember",
};

const buildOptions = (from: DateTime, to: DateTime) => {
    const diffInYears = to.diff(from, "years").toObject().years;

    return {
        months: monthOptions,
        days: dayOptions,
        years: Array.from(Array(diffInYears + 1).keys()).reduce((memo, i) => {
            const year = from.plus({ years: i }).toFormat("yyyy");
            memo[year] = year;
            return memo;
        }, {}),
    };
};

function normalizeValue(value) {
    return DateTime.fromISO(value);
}

type RawDateInputValues = {
    day: string;
    month: string;
    year: string;
};

export default function DateSelect(props: PropsWithChildren<DateSelectProps>) {
    const { value, from, to, onChange, disabled = false, ...otherProps } = props;

    const [valueDt, setValueDt] = useState(normalizeValue(value));
    useEffect(() => setValueDt(normalizeValue(value)), [value]);

    const [options, setOptions] = useState(buildOptions(normalizeValue(from), normalizeValue(to)));
    useEffect(() => setOptions(buildOptions(normalizeValue(from), normalizeValue(to))), [from, to]);

    const dayRef = useRef<HTMLSelectElement>(null);
    const monthRef = useRef<HTMLSelectElement>(null);
    const yearRef = useRef<HTMLSelectElement>(null);

    const handleChange = () => {
        const day = dayRef.current.value;
        const month = monthRef.current.value;
        const year = yearRef.current.value;

        setInput({ day, month, year });

        if (onChange) {
            if (day && month && year) {
                const newValue = DateTime.fromObject({
                    day: parseInt(day),
                    month: parseInt(month),
                    year: parseInt(year),
                });
                onChange(newValue.toISO());
            }
        }
    };

    const buildInputFromValueDt = (valueDt) => ({
        day: valueDt?.day ?? "",
        month: valueDt?.month ?? "",
        year: valueDt?.year ?? "",
    });

    const [input, setInput] = useState<RawDateInputValues>(buildInputFromValueDt(valueDt));
    useEffect(() => setInput(buildInputFromValueDt(valueDt)), [valueDt]);

    return (
        <div role="group" className="d-flex gap-1" {...otherProps}>
            <div style={{ flex: "0 0 75px" }}>
                <Select
                    value={input.day}
                    options={options.days}
                    ref={dayRef}
                    onChange={handleChange}
                    disabled={disabled}
                    aria-label="Tag"
                />
            </div>
            <div style={{ flex: "0 0 120px" }}>
                <Select
                    value={input.month}
                    options={options.months}
                    ref={monthRef}
                    onChange={handleChange}
                    disabled={disabled}
                    aria-label="Monat"
                />
            </div>
            <div style={{ flex: "0 0 100px" }}>
                <Select
                    value={input.year}
                    options={options.years}
                    ref={yearRef}
                    onChange={handleChange}
                    disabled={disabled}
                    aria-label="Jahr"
                />
            </div>
        </div>
    );
}
