import * as React from "react";
import { useEffect, useState } from "react";
import { ShortEmployeeReference } from "./slots/types";
import Icon from "../../lib/icon/Icon";
import { useOutsideClickHandler } from "../../lib/dom-utils/useOutsideClickHandler";
import Badge from "../../lib/badge/Badge";

type UndeployedEmployeesPanelProps = {
    undeployedEmployees: ShortEmployeeReference[];
};

const expandedStorageKey = "scheduler__undeployedEmployeesPanelExpanded";

export default function UndeployedEmployeesPanel(props: UndeployedEmployeesPanelProps) {
    const { undeployedEmployees } = props;
    const [expanded, setExpanded] = useState<boolean>(sessionStorage.getItem(expandedStorageKey) === "true");

    useEffect(() => {
        sessionStorage.setItem(expandedStorageKey, expanded.toString());
    }, [expanded]);

    const ref = React.useRef(null);

    useOutsideClickHandler(ref, () => {
        setExpanded(false);
    });

    return (
        <div className="SchedulerUndeployedEmployeesPanel" ref={ref}>
            {expanded && (
                <div className="SchedulerUndeployedEmployeesPanel__Body">
                    {undeployedEmployees.map((employee) => (
                        <div key={employee.id} className="SchedulerUndeployedEmployeesPanel__Employee">
                            {employee.name}
                        </div>
                    ))}
                </div>
            )}
            <div className="SchedulerUndeployedEmployeesPanel__Header" onClick={() => setExpanded(!expanded)}>
                {undeployedEmployees.length > 0 ? (
                    <>
                        <div className="SchedulerUndeployedEmployeesPanel__Header__Title">
                            <Badge colorScheme="danger">{undeployedEmployees.length}</Badge>
                            <div className="SchedulerUndeployedEmployeesPanel__Header__Title__Text">
                                Nicht eingesetzte Mitarbeiter
                            </div>
                        </div>
                        <div className="SchedulerUndeployedEmployeesPanel__Header__Handle">
                            <Icon
                                type={expanded ? "collapse" : "expand"}
                                title={expanded ? "Zuklappen" : "Aufklappen"}
                            />
                        </div>
                    </>
                ) : (
                    <div className="SchedulerUndeployedEmployeesPanel__Header__Title">
                        <Badge colorScheme="success">
                            <Icon type="done" />
                        </Badge>
                        <div className="SchedulerUndeployedEmployeesPanel__Header__Title__Text">
                            Alle Mitarbeiter wurden eingesetzt
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
