import * as React from "react";
import { ReactNode, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import { useReportEffects } from "./EffectsContextProvider";
import { getFilterFromStorage } from "./filters";

export function ExternalFilterParams(props: { children: ReactNode }) {
    useExternalFilterParams();
    return <>{props.children}</>;
}

function useExternalFilterParams() {
    const { setDateRange } = useReportEffects();

    const valueFromParams = useFilterParamsFromUrl();
    const valueFromStorage = useDateRangeFromStorage();

    useEffect(() => {
        if (valueFromParams) {
            setDateRange(valueFromParams.startsAt, valueFromParams.endsAt, false);
        } else if (valueFromStorage) {
            setDateRange(valueFromStorage.startsAt, valueFromStorage.endsAt, true, true);
        } else {
            // Set defaults
            const endsAt = DateTime.local().startOf("day");
            const startsAt = endsAt.minus({ days: 7 });

            setDateRange(startsAt, endsAt, false);
        }
    }, [valueFromParams, valueFromStorage]);
}

function useFilterParamsFromUrl() {
    const [searchParams, _setSearchParams] = useSearchParams();

    const rawStartsAt = searchParams.get("starts_at");
    const rawEndsAt = searchParams.get("ends_at");

    return useMemo(() => {
        if (rawStartsAt && rawEndsAt) {
            const startsAt = DateTime.fromISO(rawStartsAt);
            const endsAt = DateTime.fromISO(rawEndsAt);

            return { startsAt, endsAt };
        }
    }, [rawStartsAt, rawEndsAt]);
}

function useDateRangeFromStorage() {
    const rawStartsAt = getFilterFromStorage("starts-at");
    const rawEndsAt = getFilterFromStorage("ends-at");

    return useMemo(() => {
        if (rawStartsAt && rawEndsAt) {
            const startsAt = DateTime.fromISO(rawStartsAt);
            const endsAt = DateTime.fromISO(rawEndsAt);

            return { startsAt, endsAt };
        }
    }, [rawStartsAt, rawEndsAt]);
}
