import * as React from "react";
import { formatHours } from "../datetime/datetime";
import { useContext } from "react";
import { HighlightedHourContext } from "../../apps/scheduler/slots/HighlightedHourContext";
import { Hour as HourOfDay } from "./deployment-times";
import { DayOfWeek } from "../../apps/scheduler/slots/types";

type HourProps = {
    dow: DayOfWeek;
    hour: number;
    state: null | "partial" | "full";
    type: "default" | "suspended" | "allocated" | "otherDepartment";
};

export default function Hour(props: HourProps) {
    const { state, hour, dow, type } = props;
    const { highlightedHour, highlightedDow, setHighlightedHour } = useContext(HighlightedHourContext);
    const highlighted = highlightedHour === hour && dow === highlightedDow;

    const classNames = ["SchedulerDeploymentDayBar__Hour"];

    if (highlighted) {
        classNames.push("SchedulerDeploymentDayBar__Hour--highlighted");
    }

    if ("partial" === state) {
        classNames.push("SchedulerDeploymentDayBar__Hour--partial");
    } else if (state === "full") {
        classNames.push("SchedulerDeploymentDayBar__Hour--full");
    }

    if ("suspended" === type) {
        classNames.push("SchedulerDeploymentDayBar__Hour--suspended");
    } else if ("allocated" === type) {
        classNames.push("SchedulerDeploymentDayBar__Hour--allocated");
    } else if ("otherDepartment" === type) {
        classNames.push("SchedulerDeploymentDayBar__Hour--other-department");
    }

    return (
        <div
            className={classNames.join(" ")}
            title={`${formatHours(highlightedHour)} – ${formatHours(highlightedHour + 1)}`}
            onMouseOver={() => setHighlightedHour(hour as HourOfDay, dow)}
            onMouseOut={() => setHighlightedHour(null, null)}
        />
    );
}
