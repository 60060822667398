import * as React from "react";
import { DEmployeeRow } from "../types";
import Day from "./Day";
import { useDatesFromFilterParams } from "./useDatesFromFilterParams";
import { formatDays, formatHours } from "../../../lib/datetime/datetime";

type EmployeeRowProps = {
    employeeRow: DEmployeeRow;
};

export default function EmployeeRow(props: EmployeeRowProps) {
    const { employeeRow } = props;
    const dates = useDatesFromFilterParams();

    let totalDays = 0;
    let creditedDays = 0;
    let uncreditedDays = 0;

    let totalHours = 0;
    let creditedHours = 0;
    let uncreditedHours = 0;

    Object.keys(employeeRow.days).forEach((day) => {
        employeeRow.days[day].absences.forEach((absence) => {
            const hours = Number(absence.hours);

            const creditedDaysOfAbsence = Number(absence.credited_days);
            totalDays += creditedDaysOfAbsence;
            totalHours += hours;

            if (absence.credit) {
                creditedDays += creditedDaysOfAbsence;
                creditedHours += hours;
            } else {
                uncreditedDays += creditedDaysOfAbsence;
                uncreditedHours += hours;
            }
        });
    });

    return (
        <div className="AbsenceReport__EmployeeRow">
            <div className="AbsenceReport__EmployeeRow__Name">
                <div className="AbsenceReport__EmployeeRow__Name__Name">
                    <a href={`/employees/${employeeRow.employee_id}/absences`} data-turbolinks="false">
                        {employeeRow.employee_name}
                    </a>
                </div>
                <div className="AbsenceReport__EmployeeRow__Name__Summary">
                    <div className="AbsenceReport__EmployeeRow__Name__Summary__Cell" title="Gesamt">
                        {formatDays(totalDays)}
                    </div>
                    <div className="AbsenceReport__EmployeeRow__Name__Summary__Cell" title="Mit Stundengutschrift">
                        {formatDays(creditedDays)}
                    </div>
                    <div className="AbsenceReport__EmployeeRow__Name__Summary__Cell" title="Ohne Stundengutschrift">
                        {formatDays(uncreditedDays)}
                    </div>
                </div>
                <div className="AbsenceReport__EmployeeRow__Name__Summary">
                    <div className="AbsenceReport__EmployeeRow__Name__Summary__Cell" title="Gesamt">
                        {formatHours(totalHours)} h
                    </div>
                    <div className="AbsenceReport__EmployeeRow__Name__Summary__Cell" title="Mit Stundengutschrift">
                        {formatHours(creditedHours)} h
                    </div>
                    <div className="AbsenceReport__EmployeeRow__Name__Summary__Cell" title="Ohne Stundengutschrift">
                        {formatHours(uncreditedHours)} h
                    </div>
                </div>
            </div>
            <div className="AbsenceReport__EmployeeRow__Days">
                {dates.map((dt) => (
                    <Day key={dt.toISODate()} data={employeeRow.days[dt.toISODate()]} />
                ))}
            </div>
        </div>
    );
}
