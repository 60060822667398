import * as React from "react";
import { GroupedOptions, Option, OptionValue } from "../form/select/ComboBox";
import { default as OptionElement } from "./Option";
import GroupName from "./GroupName";
import Badge from "../badge/Badge";
import { useMemo } from "react";

type OptionsProps = {
    options: GroupedOptions;
    selectGroupItems: (groupName: string) => void;
    onSelectOption: (value: Option["value"]) => void;
    onAddOptionsToSelection: (value: Option["value"][]) => void;
    selection: Set<OptionValue>;
    multiselect: boolean;
};

export default function Options(props: OptionsProps) {
    const { options, selectGroupItems, onSelectOption, onAddOptionsToSelection, selection, multiselect } =
        props;

    const handleSelectAll = () => {
        const allValues = Object.values(options)
            .flatMap((options) => options.map((option) => option.value))
            .filter((value) => !selection.has(value))
        ;
        onAddOptionsToSelection(allValues);
    };

    const onAddSingleOptionToSelection = (value: Option["value"]) => {
        onAddOptionsToSelection([value]);
    };

    const allSelected = useMemo(() => selection.size === Object.values(options).flat().length, [selection, options]);

    return (
        <div className="position-absolute mt-1 bg-white shadow" style={{ zIndex: 10, top: 34, width: "100%" }}>
            <div className="overflow-auto list-group" style={{ maxHeight: "60rem" }}>
                {Object.keys(options).map((groupName) => (
                    <div key={groupName}>
                        {Object.keys(options).length > 1 && (
                            <GroupName
                                name={groupName}
                                selectGroupItems={() => multiselect && selectGroupItems(groupName)}
                            />
                        )}
                        {options[groupName].map((option) => (
                            <OptionElement
                                key={option.value}
                                option={option}
                                selected={selection.has(option.value)}
                                onSelectOption={() => onSelectOption(option.value)}
                                onAddOptionToSelection={() => onAddSingleOptionToSelection(option.value)}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {multiselect &&
                <div className="py-2 px-4 border-top bg-light d-flex align-items-center justify-content-between"
                     style={{ gap: "15px" }}>
                    <div>
                        <Badge colorScheme={selection.size === 0 ? "primadonna" : "secondary"}>
                            {selection.size} ausgewählt
                        </Badge>
                    </div>
                    <button disabled={allSelected}
                            className={`btn btn-link text-end pe-0 btn-sm text-${allSelected ? "secondary" : "primary"} text-decoration-none`}
                            onClick={handleSelectAll}>
                        Alle auswählen
                    </button>
                </div>}
        </div>
    );
}
