import * as React from "react";
import ActionsPage from "./actions-page/ActionsPage";
import ResultsPage from "./results-page/ResultsPage";
import useWorkHubState from "../state/useWorkHubState";
import useAutomaticUILock from "./useAutomaticUILock";

export function TimeTracker() {
    const { ui: { actionInProgress } } = useWorkHubState();

    useAutomaticUILock();

    if (!actionInProgress) {
        return <ActionsPage />;
    } else {
        return <ResultsPage />;
    }
}
