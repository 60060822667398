import { Skill, SkillPlusLevel } from "../../../../../lib/types/skills";
import { SkilledEmployee } from "../../../../../lib/types/employees";

export function sortSkills(skills: (Skill | SkillPlusLevel)[]) {
    const sortedSkills = [...skills];

    sortedSkills.sort((skillOne, skillTwo) => {
        if (skillOne.isPrimary && !skillTwo.isPrimary) {
            return -1;
        } else if (!skillOne.isPrimary && skillTwo.isPrimary) {
            return 1;
        }

        return skillOne.name.localeCompare(skillTwo.name);
    });

    return sortedSkills;
}

export function sortEmployeesBySkillLevels(employees: SkilledEmployee[]) {
    return employees.sort((employee1, employee2) => {
        const primarySumE1 = sumEmployeesSkillLevel(employee1.skills);
        const primarySumE2 = sumEmployeesSkillLevel(employee2.skills);

        if (primarySumE1 > primarySumE2) return -1;
        if (primarySumE1 < primarySumE2) return 1;

        const secondarySumE1 = sumEmployeesSkillLevel(employee1.skills, false);
        const secondarySumE2 = sumEmployeesSkillLevel(employee2.skills, false);

        if (secondarySumE1 > secondarySumE2) return -1;
        if (secondarySumE1 < secondarySumE2) return 1;

        if (employee1.name > employee2.name) return 1;
        if (employee1.name < employee2.name) return -1;
    });
}

export function sumEmployeesSkillLevel(skills: SkillPlusLevel[], primary = true) {
    return skills.reduce((sum, s) => {
        if ((primary && s.isPrimary) || (!primary && !s.isPrimary)) {
            return sum + s.level;
        }
        return sum;
    }, 0);
}
