import * as React from "react";
import Icon from "../../../lib/icon/Icon";

type TimeFrameProps = {
    startTime: string;
    endTime: string;
}
export default function TimeFrame(props: TimeFrameProps) {
    const { startTime, endTime } = props;
    return (
        <div className="WorkHub__TimeFrame">
            <span className="WorkHub__TimeFrame__Icon">
                <Icon type="time-frame" />
            </span>
            <span className="WorkHub__TimeFrame__StartTime">
                {startTime}
            </span>
            <span className="WorkHub__TimeFrame__Separator">
                <Icon type="time-frame-separator" />
            </span>
            <span className="WorkHub__TimeFrame__EndTime">
                {endTime}
            </span>
        </div>
    );
}
