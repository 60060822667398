import * as React from "react";
import { useState } from "react";
import FormGroup from "../../../lib/form/form/FormGroup";
import Button from "../../../lib/button/Button";
import { EmployeePicker } from "../lib/employee/EmployeePicker";
import { AbsenceReasonPicker } from "../lib/reason/AbsenceReasonPicker";
import { DatePickerField } from "../../../lib/form/datetime/date-picker-field/DatePickerField";
import { DateTime } from "luxon";
import ValidationError from "../../../lib/form/validation/ValidationError";
import Modal3 from "../../../lib/modal/Modal3";

type FormData = {
    employeeId: string;
    startsOn: string;
    endsOn: string;
    absenceReasonId: string;
};

const initialFormData: FormData = {
    employeeId: "",
    startsOn: DateTime.local().toISODate(),
    endsOn: DateTime.local().plus({ days: 1 }).toISODate(),
    absenceReasonId: "",
};

type ValidFields<T> = {
    [K in keyof T]?: boolean;
};

type ServerSideValidationErrors = {
    [key: string]: string[];
};

function makeRequiredValidator(emptyMessage: string) {
    return (value: unknown) => (value ? null : emptyMessage);
}

export type AbsenceResponseData = {
    id: string;
    employee: {
        id: string;
        name: string;
    };
    starts_on: string;
    ends_on: string;
    absence_reason: {
        id: string;
        name: string;
    };
};

type NewAbsenceFormProps = {
    onCancel: () => unknown;
    onSuccess: (data: AbsenceResponseData) => unknown;
};

export function NewAbsenceForm(props: NewAbsenceFormProps) {
    const { onSuccess } = props;

    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [validFields, setValidFields] = useState<ValidFields<FormData>>({});
    const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [serverSideValidationErrors, setServerSideValidationErrors] = useState<ServerSideValidationErrors>(null);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (field: string, value: unknown) => {
        setFormData({ ...formData, [field]: value });
        setHasTriedToSubmit(false);
    };

    const handleValidation = (field, isValid) => {
        setValidFields({ ...validFields, [field]: isValid });
    };

    const validateAllFields = () => {
        setHasTriedToSubmit(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        validateAllFields();

        const everyFieldIsValid = Object.keys(validFields).every((key) => validFields[key]);
        if (!everyFieldIsValid) {
            console.log("Form is invalid", validFields);
            return;
        }

        setLoading(true);
        fetch("/api/shortcuts/absences/absences", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                absence: {
                    employee_id: formData.employeeId,
                    starts_on: formData.startsOn,
                    ends_on: formData.endsOn,
                    absence_reason_id: formData.absenceReasonId,
                },
            }),
        })
            .then((response) => {
                if (!response.ok && response.status !== 422) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((json) => {
                if (json.errors) {
                    setServerSideValidationErrors(json.errors);
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    onSuccess(json.data);
                }
            })
            .catch((err) => {
                console.error(err);
                setSaveError(err);
                setLoading(false);
            });
    };

    const fieldNames = ["employee_id", "starts_on", "ends_on", "absence_reason_id", "remarks"];
    const serverSideValidationErrorsWithoutAssociatedField: ServerSideValidationErrors = serverSideValidationErrors
        ? Object.keys(serverSideValidationErrors).reduce((acc, key) => {
              if (-1 === fieldNames.indexOf(key)) {
                  acc[key] = serverSideValidationErrors[key];
              }
              return acc;
          }, {})
        : null;

    const employeeIdAdditionalValidation = makeRequiredValidator("Bitte wählen Sie einen Mitarbeiter aus");
    const startsOnAdditionalValidation = makeRequiredValidator("Bitte wählen Sie ein Datum aus");
    const endsOnAdditionalValidation = makeRequiredValidator("Bitte wählen Sie ein Datum aus");
    const absenceReasonIdAdditionalValidation = makeRequiredValidator("Bitte wählen Sie einen Grund aus");

    return (
        <form onSubmit={handleSubmit} style={{ display: "contents" }}>
            <Modal3.Body>
                {serverSideValidationErrorsWithoutAssociatedField &&
                    Object.keys(serverSideValidationErrorsWithoutAssociatedField).length > 0 && (
                        <div className="mb-3">
                            {Object.keys(serverSideValidationErrorsWithoutAssociatedField).map((key) =>
                                serverSideValidationErrorsWithoutAssociatedField[key].map((error) => (
                                    <ValidationError key={error} message={error} />
                                ))
                            )}
                        </div>
                    )}
                <FormGroup label="Mitarbeiter" required={true}>
                    <EmployeePicker
                        aria-label="Mitarbeiter"
                        disabled={loading}
                        autoFocus={true}
                        value={formData.employeeId}
                        onChange={(value) => handleInputChange("employeeId", value)}
                        onValidation={(isValid) => handleValidation("employeeId", isValid)}
                        forceValidation={hasTriedToSubmit}
                        errors={serverSideValidationErrors?.employee_id}
                        additionalValidation={employeeIdAdditionalValidation}
                    />
                </FormGroup>
                <div className="d-flex gap-2">
                    <FormGroup label="Abwesend ab" required={true} style={{ flex: "1 1 50%" }}>
                        <DatePickerField
                            aria-label="Abwesend ab"
                            disabled={loading}
                            value={formData.startsOn}
                            onChange={(value) => handleInputChange("startsOn", value)}
                            onValidation={(isValid) => handleValidation("startsOn", isValid)}
                            forceValidation={hasTriedToSubmit}
                            errors={serverSideValidationErrors?.starts_on}
                            additionalValidation={startsOnAdditionalValidation}
                        />
                    </FormGroup>
                    <FormGroup label="Voraussichtliche Rückkehr am" style={{ flex: "1 1 50%" }} required={true}>
                        <DatePickerField
                            aria-label="Voraussichtliche Rückkehr am"
                            disabled={loading}
                            value={formData.endsOn}
                            onChange={(value) => handleInputChange("endsOn", value)}
                            onValidation={(isValid) => handleValidation("endsOn", isValid)}
                            forceValidation={hasTriedToSubmit}
                            errors={serverSideValidationErrors?.ends_on}
                            additionalValidation={endsOnAdditionalValidation}
                        />
                    </FormGroup>
                </div>
                <FormGroup label="Grund" required={true}>
                    <AbsenceReasonPicker
                        aria-label="Grund"
                        disabled={loading}
                        value={formData.absenceReasonId}
                        onChange={(value) => handleInputChange("absenceReasonId", value)}
                        onValidation={(isValid) => handleValidation("absenceReasonId", isValid)}
                        forceValidation={hasTriedToSubmit}
                        errors={serverSideValidationErrors?.absence_reason_id}
                        additionalValidation={absenceReasonIdAdditionalValidation}
                    />
                </FormGroup>
            </Modal3.Body>

            <Modal3.Footer>
                {saveError && (
                    <div className="mb-3">
                        <ValidationError message={`Fehler beim Speichern: ${saveError.message}`} />
                    </div>
                )}

                <div className="d-flex justify-content-end align-items-center">
                    <Button
                        theme="primary"
                        size="md"
                        caption={loading ? "Lade..." : "Speichern"}
                        type="submit"
                        disabled={loading}
                    />
                </div>
            </Modal3.Footer>
        </form>
    );
}
