import React, { useEffect, useState } from "react";
import Cell from "../cell";
import cellWidths from "../cell-widths";
import { buildTimeSheet } from "../api";
import NumericValue from "../numeric-value";
import EmployeeCell from "../table-commons/employee-cell";
import { AdjustmentInfo } from "./adjustment-info";
import PropTypes from "prop-types";

export default function WeekBodyRow({ slot, unitId, week, year }) {
    const [rebuilding, setRebuilding] = useState(slot.builds.length > 0);
    const [triggerRebuild, setTriggerRebuild] = useState(false);

    useEffect(() => {
        if (triggerRebuild) {
            setRebuilding(true);
            buildTimeSheet({ employeeId: slot.employee.id, unitId, week, year });
        }
    }, [triggerRebuild]);

    return (
        <tr className="bg-body" data-week-employee={slot.employee?.id}>
            <EmployeeCell employee={slot.employee} unitId={unitId} period={`${year}-W${("0" + week).slice(-2)}`} />
            {rebuilding ? (
                <>
                    <Cell colSpan={16} style={{ borderLeft: "2px solid #ccc", height: "48px" }} center={true}>
                        <div className="text-center d-flex align-items-center justify-content-center">
                            <div className="progress me-3" style={{ width: "200px" }}>
                                <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    style={{ width: "100%" }}
                                >
                                    Aktualisierung läuft
                                </div>
                            </div>
                        </div>
                    </Cell>
                </>
            ) : slot.week ? (
                <>
                    {[1, 2, 3, 4, 5, 6, 7].map((wday) => (
                        <Cell
                            key={wday}
                            style={{
                                width: cellWidths.hoursExtended,
                                borderLeft: wday === 1 ? "2px solid #ccc" : null,
                            }}
                            numeric={true}
                        >
                            <NumericValue
                                label={`Geleistete Arbeitsstunden Wochentag ${wday}`}
                                humanValue={slot.days[wday].human.performed_work_hours}
                                robotValue={slot.days[wday].performed_work_hours}
                            />
                            <br />
                            <NumericValue
                                label={`Angerechnete Arbeitsstunden Wochentag ${wday}`}
                                humanValue={slot.days[wday].human.credited_work_hours}
                                robotValue={slot.days[wday].credited_work_hours}
                            />
                            {slot.days[wday].adjustment_hours !== "0.0" && (
                                <>
                                    <br />
                                    <AdjustmentInfo
                                        robot={slot.days[wday].adjustment_hours}
                                        human={slot.days[wday].human.adjustment_hours}
                                    />
                                </>
                            )}
                        </Cell>
                    ))}
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        <NumericValue
                            label="Bezahlte Abwesenheiten"
                            humanValue={slot.week.human.paid_absent_hours}
                            robotValue={slot.week.paid_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        <NumericValue
                            label="Unbezahlte Abwesenheiten"
                            humanValue={slot.week.human.unpaid_absent_hours}
                            robotValue={slot.week.unpaid_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        <NumericValue
                            label="Abwesenheiten mit Stundengutschrift"
                            humanValue={slot.week.human.credited_absent_hours}
                            robotValue={slot.week.credited_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        <NumericValue
                            label="Abwesenheiten ohne Stundengutschrift"
                            humanValue={slot.week.human.uncredited_absent_hours}
                            robotValue={slot.week.uncredited_absent_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        <NumericValue
                            label="Geleistete Arbeitsstunden"
                            humanValue={slot.week.human.performed_work_hours}
                            robotValue={slot.week.performed_work_hours}
                        />
                        <br />
                        <NumericValue
                            label="Angerechnete Arbeitsstunden"
                            humanValue={slot.week.human.final_credited_work_hours}
                            robotValue={slot.week.final_credited_work_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Soll-Arbeitsstunden"
                            humanValue={slot.week.human.target_hours}
                            robotValue={slot.week.target_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Differenz"
                            humanValue={slot.week.human.diff_hours}
                            robotValue={slot.week.diff_hours}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours, borderLeft: "2px solid #ccc" }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Saldo Vorwoche"
                            humanValue={slot.week.human.prev_week_balance}
                            robotValue={slot.week.prev_week_balance}
                        />
                    </Cell>
                    <Cell style={{ width: cellWidths.hours }} numeric={true}>
                        &nbsp;
                        <br />
                        <NumericValue
                            label="Saldo aktuelle Woche"
                            humanValue={slot.week.human.this_week_balance}
                            robotValue={slot.week.this_week_balance}
                        />
                    </Cell>
                </>
            ) : (
                <>
                    <Cell style={{ borderLeft: "2px solid #ccc" }} colSpan={16}>
                        <div className="text-muted text-center">
                            <small>Wochenergebnisse noch nicht bereit</small>
                        </div>
                    </Cell>
                </>
            )}

            <Cell style={{ width: cellWidths.actions, borderLeft: "2px solid #ccc" }} className="text-end">
                <span style={{ cursor: "pointer" }} onClick={() => setTriggerRebuild(true)}>
                    <i className="bi-arrow-repeat" />
                </span>
            </Cell>
        </tr>
    );
}

WeekBodyRow.propTypes = {
    slot: PropTypes.any,
    unitId: PropTypes.string,
    week: PropTypes.number,
    year: PropTypes.number,
};
