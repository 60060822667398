import * as React from "react";
import { useEffect, useState } from "react";
import { DateTime, Interval } from "luxon";
import { Button } from "../../button/Button";
import { safeInterval } from "../../datetime/datetime";
import IntervalField from "./IntervalField";
import { MonthCalendarList } from "./MonthCalendarList";

type ClickState = "primaryStart" | "primaryEnd";
const sequence: ClickState[] = ["primaryEnd", "primaryStart"];

type IntervalPickerProps = {
    value: Interval;
    onPick: (value: Interval) => unknown;
    onCancel?: () => unknown;
};

export function IntervalPicker(props: IntervalPickerProps): JSX.Element {
    const { onPick, onCancel, value } = props;

    const [selection, setSelection] = useState<Interval>(value);
    const [currentClickStateIndex, setCurrentClickStateIndex] = useState<number>(0);

    const [currentFocusDt, setCurrentFocusDt] = useState<DateTime>(value.start);

    useEffect(() => {
        setSelection(value);
        setCurrentFocusDt(value.start.startOf("day"));
    }, [value]);

    useEffect(() => {
        const visibleBounds = Interval.fromDateTimes(
            currentFocusDt.minus({ month: 1 }).startOf("month"),
            currentFocusDt.plus({ month: 1 }).endOf("month")
        );
        if (!visibleBounds.overlaps(selection)) {
            setCurrentFocusDt(selection.start);
        }
    }, [selection]);

    function handleSelectDay(selectedDay: DateTime) {
        const targetClickStateIndex = (currentClickStateIndex + 1) % sequence.length;
        const targetClickState = sequence[targetClickStateIndex];
        setCurrentClickStateIndex(targetClickStateIndex);

        switch (targetClickState) {
            case "primaryStart":
                setSelection(safeInterval(selectedDay.startOf("day"), selectedDay.endOf("day")));
                break;
            case "primaryEnd":
                setSelection(safeInterval(selection.start, selectedDay.endOf("day")));
                break;
        }
    }

    function handleSelectInterval(interval: Interval) {
        setSelection(interval);
        setCurrentClickStateIndex(0);
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    marginBottom: "15px",
                }}
            >
                <Button
                    icon="previous"
                    caption={(currentFocusDt.year - 1).toString()}
                    size="md"
                    theme="secondary"
                    onClick={() => setCurrentFocusDt(currentFocusDt.minus({ year: 1 }))}
                />
                <Button caption="Heute" size="md" theme="secondary" onClick={() => setCurrentFocusDt(DateTime.now())} />
                <Button
                    caption="Auswahl"
                    size="md"
                    theme="secondary"
                    onClick={() => setCurrentFocusDt(selection.start)}
                />
                <Button
                    icon="next"
                    caption={(currentFocusDt.year + 1).toString()}
                    size="md"
                    theme="secondary"
                    onClick={() => setCurrentFocusDt(currentFocusDt.plus({ year: 1 }))}
                />
            </div>

            <MonthCalendarList
                setCurrentFocusDt={setCurrentFocusDt}
                currentFocusDt={currentFocusDt}
                selection={selection}
                onSelectDay={handleSelectDay}
                onSelectInterval={handleSelectInterval}
            />

            <div>
                <div>
                    <IntervalField value={selection} onChange={(value) => setSelection(value)} />
                </div>
            </div>
            <div
                className="mt-3"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                }}
            >
                <Button icon="cancel" caption="Abbrechen" theme="secondary" onClick={onCancel} />
                <Button icon="save" theme="primary" caption="Übernehmen" onClick={() => onPick(selection)} />
            </div>
        </div>
    );
}
