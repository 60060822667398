// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";

import AbsenceFormController from "./absence_form_controller";
import EventFormController from "./event_form_controller";

const application = Application.start();

application.register("absence-form", AbsenceFormController);
application.register("event-form", EventFormController);
