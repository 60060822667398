import * as React from "react";
import { useContext, useEffect, useState } from "react";

import CursorInspector from "../CursorInspector";
import TimeSpanControls from "./TimeSpanControls";
import { Deployment } from "../../types";
import { DeploymentEditorPayload } from "../types";
import { ApiAttributeErrors, ApiResult, ApiWarnings } from "../../slots/types";
import { DataContext } from "../../slots/DataContext";
import { CursorPositionContext } from "../CursorPositionContext";
import Button from "../../../../lib/button/Button";
import { BalancesContext, ScheduleContext } from "../../slots/SlotsController";

type DeploymentEditorProps = {
    currentCellPayload: DeploymentEditorPayload;
    busy: boolean;
    onBusyChange: (busy: boolean) => void;
    onDeploymentSuccessChanged: (success: boolean) => void;
};

export default function DeploymentEditor(props: DeploymentEditorProps) {
    const { currentCellPayload, busy, onBusyChange, onDeploymentSuccessChanged } = props;
    const { cursorPosition } = useContext(CursorPositionContext);
    const { unit: currentUnit } = useContext(ScheduleContext);

    const { updateDeployment, createDeployment, deleteDeployment } = useContext(DataContext);

    const [errors, setErrors] = useState<ApiAttributeErrors | null>(null);
    const [warnings, setWarnings] = useState<ApiWarnings | null>(null);

    const { invalidate: invalidateBalances } = useContext(BalancesContext);

    const handleTimesUpdates = (startTime, endTime) => {
        onBusyChange(true);

        const handleApiResult = (apiResult: ApiResult<Deployment>) => {
            onBusyChange(false);
            setErrors(apiResult.errors);
            setWarnings(apiResult.warnings);

            const hasErrors = Object.keys(apiResult.errors).length > 0;
            const unignoredWarningKeys = Object.keys(apiResult.warnings).filter(
                (key) => !apiResult.warnings[key].ignored
            );

            if (hasErrors || unignoredWarningKeys.length > 0) {
                onDeploymentSuccessChanged(false);
            } else {
                onDeploymentSuccessChanged(true);
                invalidateBalances(currentCellPayload.employeeId);
            }
        };

        if (null === currentCellPayload.deploymentId) {
            createDeployment(currentCellPayload.departmentId, currentCellPayload.employeeId, currentCellPayload.dow, {
                start_time: startTime,
                end_time: endTime,
            }).then(handleApiResult);
        } else {
            updateDeployment(currentCellPayload.deploymentId, {
                id: currentCellPayload.deploymentId,
                start_time: startTime,
                end_time: endTime,
            }).then(handleApiResult);
        }
    };
    const handleDelete = () => {
        deleteDeployment(currentCellPayload.deploymentId);
    };
    useEffect(() => {
        setErrors(null);
        setWarnings(null);
    }, [cursorPosition]);

    return (
        <>
            <div className="SchedulerTimeSpanControl">
                <TimeSpanControls
                    disabled={busy}
                    onSubmit={(startTime, endTime) => handleTimesUpdates(startTime, endTime)}
                    startTime={currentCellPayload.startTime}
                    endTime={currentCellPayload.endTime}
                >
                    {currentCellPayload.deploymentId ? (
                        <Button
                            size={"sm"}
                            aria-label="Löschen"
                            disabled={false}
                            onClick={handleDelete}
                            theme="light"
                            icon="delete"
                        />
                    ) : null}
                </TimeSpanControls>
            </div>
            <div
                className={`SchedulerCursor__Conditions SchedulerCursor__Conditions--${
                    cursorPosition.col < 4 ? "right" : "left"
                }`}
            >
                {null === currentCellPayload.unitAssignment && (
                    <section
                        className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--danger"
                        role="region"
                        aria-label="Verfügbarkeiten"
                    >
                        <strong>
                            <i className="bi bi-person-x-fill" /> Mitarbeiter hat keinen Anstellungsort.
                        </strong>
                    </section>
                )}

                {currentCellPayload.availabilities.length > 0 ? (
                    <section
                        className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--success"
                        role="region"
                        aria-label="Verfügbarkeiten"
                    >
                        <strong>Verfügbarkeiten</strong>
                        {currentCellPayload.availabilities.map(({ start_time, end_time }) => (
                            <div key={[start_time, end_time].join(" ")}>
                                {start_time} - {end_time}
                            </div>
                        ))}
                    </section>
                ) : (
                    <section
                        className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--danger"
                        role="region"
                        aria-label="Verfügbarkeiten"
                    >
                        <strong>
                            <i className="bi bi-person-x-fill" /> Nicht verfügbar.
                        </strong>
                    </section>
                )}
                {currentCellPayload.absences.length > 0 && (
                    <section
                        className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--warning"
                        role="region"
                        aria-label="Abwesenheiten"
                    >
                        <strong>Abwesenheiten</strong>
                        {currentCellPayload.absences.map(({ id, simple_fraction, reason }) => (
                            <div key={id}>
                                {reason},{" "}
                                {
                                    /* prettier-ignore */ "half_day" === simple_fraction
                                    ? "halbtag"
                                    : "full_day" === simple_fraction
                                        ? "ganztag"
                                        : "multiple_days" === simple_fraction
                                            ? "mehrere Tage"
                                            : `„${simple_fraction}”`
                                }
                            </div>
                        ))}
                    </section>
                )}
                {currentCellPayload.warnings.length > 0 && (
                    <section
                        className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--danger"
                        role="region"
                        aria-label="Warnungen"
                    >
                        <strong>Warnungen</strong>
                        {currentCellPayload.warnings.map((message) => (
                            <div key={message}>{message}</div>
                        ))}
                    </section>
                )}

                <section
                    className={`SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--${
                        currentCellPayload?.unitAssignment?.unit_id !== currentUnit.id ? "warning" : "info"
                    }`}
                >
                    <strong>Anstellungsort</strong>
                    <div>
                        {currentCellPayload.unitAssignment ? currentCellPayload.unitAssignment.unit_name : "(ohne)"}
                    </div>
                </section>

                <section className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--info">
                    <strong>Arbeitsorte</strong>
                    {currentCellPayload.workplaces.length > 0 ? (
                        currentCellPayload.workplaces.map((workplace) => (
                            <div key={workplace.unit_id}>{workplace.unit_name}</div>
                        ))
                    ) : (
                        <div>(keine)</div>
                    )}
                </section>

                <section className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--info">
                    <strong>Einsätze</strong>
                    {currentCellPayload.globalDeployments.length > 0 ? (
                        currentCellPayload.globalDeployments.map((deploymentInfo) => (
                            <div key={deploymentInfo.start_time}>
                                {deploymentInfo.start_time} – {deploymentInfo.end_time}: {deploymentInfo.unit_name},{" "}
                                {deploymentInfo.department_name}
                            </div>
                        ))
                    ) : (
                        <div>(keine)</div>
                    )}
                </section>

                {null !== currentCellPayload.school && (
                    <section
                        className="SchedulerCursor__ConditionsBlock SchedulerCursor__ConditionsBlock--info"
                        role="region"
                        aria-label="Schulzeiten"
                    >
                        <strong>Schulzeit</strong>
                        <div>
                            {currentCellPayload.school.start_time} – {currentCellPayload.school.end_time}
                        </div>
                    </section>
                )}
            </div>
            <CursorInspector errors={errors} warnings={warnings} />
        </>
    );
}
