import * as React from "react";
import { useRef, useState } from "react";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import { NewAbsenceModal } from "./new-absence/NewAbsenceModal";
import Icon from "../../lib/icon/Icon";
import { useOutsideClickHandler } from "../../lib/dom-utils/useOutsideClickHandler";
import { conditionalClassNames } from "../../lib/dom-utils/class-names";
import { NewAbsenceCertificateModal } from "./new-absence-certificate/NewAbsenceCertificateModal";

enum ModalType {
    NewAbsence,
    NewAbsenceCertificate,
}

export function Shortcuts() {
    const [open, setOpen] = useState(false);

    const [openModal, setOpenModal] = useState<ModalType | null>(null);

    const dropdownRef = useRef(null);
    useOutsideClickHandler(dropdownRef, () => setOpen(false));

    function handleClick(modalType: ModalType) {
        setOpen(false);
        setOpenModal(modalType);
    }

    return (
        <React.StrictMode>
            <ErrorBoundary>
                <div className="SiteShortcuts">
                    <button
                        title="Schnellaktionen"
                        className={conditionalClassNames({
                            SiteShortcuts__Trigger: true,
                            "SiteShortcuts__Trigger--open": open,
                        }).join(" ")}
                        onClick={() => setOpen(!open)}
                    >
                        <Icon type="shortcuts" />
                    </button>
                    {open && (
                        <div className="SiteShortcuts__Dropdown" ref={dropdownRef}>
                            <div className="SiteShortcuts__Dropdown__Title">Schnellaktionen</div>
                            <button
                                className="SiteShortcuts__Dropdown__Item"
                                onClick={() => handleClick(ModalType.NewAbsence)}
                            >
                                <div>Abwesenheit erfassen</div>
                            </button>
                            <button
                                className="SiteShortcuts__Dropdown__Item"
                                onClick={() => handleClick(ModalType.NewAbsenceCertificate)}
                            >
                                <div>Abwesenheitsnachweis erfassen</div>
                            </button>
                            <button disabled={true} className="SiteShortcuts__Dropdown__Item" onClick={() => null}>
                                <div>Mitarbeiter anlegen</div>
                            </button>
                            <button disabled={true} className="SiteShortcuts__Dropdown__Item" onClick={() => null}>
                                <div>Mitarbeiter verleihen</div>
                            </button>
                        </div>
                    )}
                </div>

                <NewAbsenceModal open={ModalType.NewAbsence === openModal} onClose={() => setOpenModal(null)} />
                <NewAbsenceCertificateModal
                    open={ModalType.NewAbsenceCertificate === openModal}
                    onClose={() => setOpenModal(null)}
                />
            </ErrorBoundary>
        </React.StrictMode>
    );
}
