import { State, useEmployeeState } from "../../../state";
import { useMemo } from "react";

type AbsenceStatsForTimeFrame = [
    number,
    number,
    number,
    number,
    State["absences"]["statistics"]["absentHoursByReasonCategoryPastThreeYears"]
];

export function useAbsenceStatsForTimeFrame(timeFrame: "last-3-years" | "last-year"): AbsenceStatsForTimeFrame {
    const {
        employee,
        absences: { statistics },
    } = useEmployeeState();

    const [creditedRate, uncreditedRate, creditedHours, uncreditedHours, absentHoursByReason] = useMemo(() => {
        switch (timeFrame) {
            case "last-3-years":
                return [
                    statistics.creditedAbsenceRatePastThreeYears,
                    statistics.uncreditedAbsenceRatePastThreeYears,
                    statistics.creditedAbsentHoursPastThreeYears,
                    statistics.uncreditedAbsentHoursPastThreeYears,
                    statistics.absentHoursByReasonCategoryPastThreeYears,
                ];
            case "last-year":
                return [
                    statistics.creditedAbsenceRatePastYear,
                    statistics.uncreditedAbsenceRatePastYear,
                    statistics.creditedAbsentHoursPastYear,
                    statistics.uncreditedAbsentHoursPastYear,
                    statistics.absentHoursByReasonCategoryPastYear,
                ];
            default:
                return [0, 0, 0];
        }
    }, [
        employee.id,
        timeFrame,
        statistics.creditedAbsentHoursPastYear,
        statistics.creditedAbsentHoursPastThreeYears,
        statistics.uncreditedAbsentHoursPastYear,
        statistics.uncreditedAbsentHoursPastThreeYears,
        statistics.uncreditedAbsenceRatePastThreeYears,
        statistics.uncreditedAbsenceRatePastYear,
        statistics.creditedAbsenceRatePastThreeYears,
        statistics.creditedAbsenceRatePastYear,
        statistics.absentHoursByReasonCategoryPastThreeYears,
        statistics.absentHoursByReasonCategoryPastYear,
    ]);

    return [creditedRate, uncreditedRate, creditedHours, uncreditedHours, absentHoursByReason];
}
