import * as React from "react";
import { HTMLAttributes } from "react";
import SchoolTimeSet from "../../../lib/model/SchoolTimeSet";
import SchoolWeekFieldSetHeader from "./SchoolWeekFieldSetHeader";
import SchoolWeekFieldSetDay from "./SchoolWeekFieldSetDay";

export type SchoolWeekAttributes = {
    credited_hours_1: SchoolTimeSet["credited_hours_1"];
    credited_hours_2: SchoolTimeSet["credited_hours_2"];
    credited_hours_3: SchoolTimeSet["credited_hours_3"];
    credited_hours_4: SchoolTimeSet["credited_hours_4"];
    credited_hours_5: SchoolTimeSet["credited_hours_5"];
    credited_hours_6: SchoolTimeSet["credited_hours_6"];
    credited_hours_7: SchoolTimeSet["credited_hours_7"];

    attends_school_on_1: SchoolTimeSet["attends_school_on_1"];
    attends_school_on_2: SchoolTimeSet["attends_school_on_2"];
    attends_school_on_3: SchoolTimeSet["attends_school_on_3"];
    attends_school_on_4: SchoolTimeSet["attends_school_on_4"];
    attends_school_on_5: SchoolTimeSet["attends_school_on_5"];
    attends_school_on_6: SchoolTimeSet["attends_school_on_6"];
    attends_school_on_7: SchoolTimeSet["attends_school_on_7"];

    absence_start_time_1: SchoolTimeSet["absence_start_time_1"];
    absence_start_time_2: SchoolTimeSet["absence_start_time_2"];
    absence_start_time_3: SchoolTimeSet["absence_start_time_3"];
    absence_start_time_4: SchoolTimeSet["absence_start_time_4"];
    absence_start_time_5: SchoolTimeSet["absence_start_time_5"];
    absence_start_time_6: SchoolTimeSet["absence_start_time_6"];
    absence_start_time_7: SchoolTimeSet["absence_start_time_7"];

    absence_end_time_1: SchoolTimeSet["absence_end_time_1"];
    absence_end_time_2: SchoolTimeSet["absence_end_time_2"];
    absence_end_time_3: SchoolTimeSet["absence_end_time_3"];
    absence_end_time_4: SchoolTimeSet["absence_end_time_4"];
    absence_end_time_5: SchoolTimeSet["absence_end_time_5"];
    absence_end_time_6: SchoolTimeSet["absence_end_time_6"];
    absence_end_time_7: SchoolTimeSet["absence_end_time_7"];
};

const days = {
    Montag: 1,
    Dienstag: 2,
    Mittwoch: 3,
    Donnerstag: 4,
    Freitag: 5,
    Samstag: 6,
    Sonntag: 7,
};

type SchoolWeekFieldSetProps = Omit<HTMLAttributes<HTMLSelectElement>, "onChange"> & {
    value?: SchoolWeekAttributes;
    onChange?: (value: SchoolWeekAttributes) => void;
    disabled?: boolean;
};

const SchoolWeekFieldSet = (props: SchoolWeekFieldSetProps) => {
    const { value = null, onChange, disabled = false } = props;

    return (
        <>
            <div className="list-group">
                <SchoolWeekFieldSetHeader />
                {Object.keys(days).map((day) => (
                    <SchoolWeekFieldSetDay
                        key={day}
                        dow={days[day]}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                    />
                ))}
            </div>
        </>
    );
};

SchoolWeekFieldSet.displayName = "SchoolWeekFieldSet";

export default SchoolWeekFieldSet;
