import { colors } from "../theme/colors";

export const defaultYAxisStyles = {
    fontSize: "12.25px",
    fontFamily: "var(--bs-body-font-family)",
    fontWeight: 400,
    fill: colors.brixSlate400,
};

export const defaultXAxisStyles = {
    fontSize: "12.25px",
    fontFamily: "var(--bs-body-font-family)",
    fontWeight: 400,
    fill: colors.brixSlate400,
};

export const defaultCartesianGridProps = {
    stroke: colors.brixSlate200,
};

export const defaultTooltipContentStyles = {
    border: `1px solid ${colors.brixSlate100}`,
    fontSize: "12.25px",
    fontFamily: "var(--bs-body-font-family)",
    padding: "0.25rem",
    backgroundColor: colors.brixSlate50,
    boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)",
    borderRadius: "0.5rem",
    // fill: "var(--bs-gray-500)",
    fill: colors.brixSlate400,
};

export const defaultTooltipLabelStyles = {
    fontWeight: 500,
};
