import * as React from "react";
import { DataTableColumn } from "./types";

type DataTableHeaderProps = {
    columns: DataTableColumn[];
};

export default function DataTableHeader(props: DataTableHeaderProps) {
    const { columns } = props;
    return (
        <thead style={{ display: "contents" }}>
            <tr style={{ display: "contents" }}>
                {columns.map((column) => (
                    <th
                        key={column.name}
                        style={{ textAlign: column.align ?? "left" }}
                        className="px-3 py-1 border-bottom bg-light text-muted"
                    >
                        {column.title}
                    </th>
                ))}
            </tr>
        </thead>
    );
}
