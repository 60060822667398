import * as React from "react";
import { ReactNode, useCallback } from "react";
import { useFilterParams } from "./useFilterParams";
import { DReport } from "../types";
import WithDataFromApi, { WithDataFromApiChildrenArgs } from "../../../lib/api/WithDataFromApi";
import { buildReportUrl } from "./url";

async function fetchData(unitIds: string[], year: number, signal): Promise<DReport> {
    const url = buildReportUrl(unitIds, year, "json");
    const response = await fetch(url, { signal });
    return await response.json();
}

type WithReportFromApiProps = {
    children: (args: WithDataFromApiChildrenArgs<DReport>) => ReactNode;
};

export default function WithReportFromApi(props: WithReportFromApiProps) {
    const { children } = props;
    const { unitIds, year } = useFilterParams();

    const fetchDataCallback = useCallback((signal) => fetchData(unitIds, year, signal), [unitIds, year]);

    return (
        <WithDataFromApi<DReport> hot={null !== year} fetchData={fetchDataCallback}>
            {children}
        </WithDataFromApi>
    );
}
