import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import { conditionalClassNames } from "../../dom-utils/class-names";
import Icon from "../../icon/Icon";
import { useComboboxDispatch, useComboboxState } from "./state";
import { FloatingContext } from "./FloatingContext";

type OptionsProps = {
    onSelect: (value: string) => unknown;
    emptyMessage: string;
};

export default function Options(props: OptionsProps) {
    const { onSelect, emptyMessage } = props;
    const state = useComboboxState();
    const dispatch = useComboboxDispatch();

    const activeRef = useRef<HTMLDivElement>(null);

    const {
        refs: { setFloating: ref },
        floatingStyles: styles,
    } = useContext(FloatingContext);

    useEffect(() => {
        if (activeRef.current) {
            activeRef.current.scrollIntoView({ block: "nearest" });
        }
    }, [state.activeValue]);

    return (
        <div className="Combobox2__Options" role="listbox" style={styles} ref={ref}>
            {state.filteredOptionKeys.length === 0 && (
                <div className="Combobox2__Options__EmptyMessage">{emptyMessage}</div>
            )}
            {state.filteredOptionKeys.map((key) => {
                const selected = key === state.selectedValue;
                const active = key === state.activeValue;
                const classNames = conditionalClassNames({
                    Combobox2__Options__Option: true,
                    "Combobox2__Options__Option--selected": selected,
                    "Combobox2__Options__Option--active": active,
                }).join(" ");

                return (
                    <div
                        key={key}
                        role="option"
                        aria-selected={selected}
                        className={classNames}
                        onClick={() => {
                            dispatch({ type: "select", payload: key });
                            dispatch({ type: "focus", payload: key });
                            dispatch({ type: "close" });
                            onSelect(key);
                        }}
                        ref={active ? activeRef : null}
                    >
                        {selected && (
                            <span className="Combobox2__Options__Option__Icon">
                                <Icon type="selected" />
                            </span>
                        )}
                        {state.options[key]}
                    </div>
                );
            })}
        </div>
    );
}
