import { InputHTMLAttributes, TextareaHTMLAttributes } from "react";

type Props =
    | Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">
    | Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange">;

export default function buildInputProps(userProps: Props, { invalid }: { invalid: boolean }): Props {
    const inputProps = { ...userProps };
    inputProps.className = inputProps.className ?? "";
    inputProps.className += " form-control form-control-sm fs-6";

    if (invalid) {
        inputProps["aria-invalid"] = true;
        inputProps.className +=
            " pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";
    }

    return inputProps;
}
