import * as React from "react";
import { useContext, useEffect, useMemo, useRef } from "react";

import { Day, DeploymentSlot as DeploymentSlotType, Employee } from "../../../../types";
import DeploymentList from "./DeploymentList";
import {
    AllocationsContext,
    EmployeeNotesContext,
    PublicHolidaysContext,
    ScheduleContext
} from "../../../SlotsController";
import { CursorPositionContext } from "../../../../cursor/CursorPositionContext";
import { CursorAnchorSetter } from "../../../../cursor/CursorNavigation";
import Absence from "./Absence";
import { default as EmployeeNoteElement } from "./EmployeeNote";
import Indicator from "../../../../../../lib/indicator/Indicator";
import SchoolAttendance from "./SchoolAttendance";
import DeploymentDayBar, { AllocationTimes } from "../../../../../../lib/deployment-bar/DeploymentDayBar";
import TableRowDay from "../../../../table/TableRowDay";
import { buildAllocationTimes } from "./allocations";
import { conditionalClassNames } from "../../../../../../lib/dom-utils/class-names";
import { isActiveCursorPosition } from "../../../../cursor/grid";
import { EmployeeAllocations } from "../../../types";
import {
    DeploymentTime,
    firstBusinessHour,
    lastBusinessHour
} from "../../../../../../lib/deployment-bar/deployment-times";
import Icon from "../../../../../../lib/icon/Icon";

type EmployeeWeekRowDayProps = {
    day: Day;
    setCursorAnchorElement: CursorAnchorSetter;
    renderRow: number;
    renderCol: number;
    employeeId: Employee["id"];
    slot: DeploymentSlotType;
};

function buildAllocationTimesForDay(
    allocations: EmployeeAllocations[],
    employeeId,
    dow,
    scheduleUnitId
): AllocationTimes[] {
    const allocationWorkplacesOnDay = allocations.filter((a) => a.employee.id === employeeId).map((a) => a.days[dow]);
    return buildAllocationTimes(allocationWorkplacesOnDay, scheduleUnitId);
}

export default function EmployeeWeekRowDay(props: EmployeeWeekRowDayProps) {
    const { day, setCursorAnchorElement, renderRow, renderCol, employeeId, slot } = props;

    const employeeNotesDirectory = useContext(EmployeeNotesContext);
    const allocations = useContext(AllocationsContext);
    const { unit: scheduleUnit } = useContext(ScheduleContext);
    const { cursorPosition, setCursorPosition } = useContext(CursorPositionContext);
    const publicHolidays = useContext(PublicHolidaysContext);

    const plusButtonRef = useRef<HTMLButtonElement>();

    const employeeNotes = employeeNotesDirectory?.[employeeId]?.[day.dow] ?? [];
    const buttonSubRow = day.deployments.length + day.absences.length + employeeNotes.length;
    const isButtonActive = isActiveCursorPosition(cursorPosition, renderRow, renderCol, buttonSubRow);
    const showUnavailable = day.availabilities.length === 0 || null === day.unit_assignment;
    const showDangerousDay = day.availabilities.length === 0 && day.deployments.length > 0;
    const showButton = true; // Beware, you cannot just toggle this. When the button is not available, cursor navigation will break without further changes.
    const isSelected = cursorPosition && renderRow == cursorPosition.row && renderCol == cursorPosition.col;
    const isDifferentCostUnit = day.unit_assignment && scheduleUnit.id !== day.unit_assignment.unit_id;
    const hasMultipleWorkplaces = day.workplaces.length > 1;
    const anySuspendedDeployment = day.deployments.some((deployment) => deployment.suspended);
    const allocationTimes = buildAllocationTimesForDay(allocations, employeeId, day.dow, scheduleUnit.id);

    useEffect(() => {
        if (isButtonActive) {
            plusButtonRef.current.focus();
        } else {
            plusButtonRef.current.blur();
        }
    }, [isButtonActive]);

    const classNames = conditionalClassNames({
        SchedulerEmployeeWeek__Day: true,
        "SchedulerEmployeeWeek__Day--dangerous": showDangerousDay,
        "SchedulerEmployeeWeek__Day--unavailable": showUnavailable,
        "SchedulerEmployeeWeek__Day--cursor": isSelected,
        "SchedulerEmployeeWeek__Day--holiday": publicHolidays && publicHolidays[day.dow].length > 0
    });

    const deploymentTimes = useMemo(
        () => day.deployments.map((deployment) => [deployment.start_time, deployment.end_time] as [string, string]),
        [day.deployments]
    );

    const otherDepartmentDeploymentTimes: DeploymentTime[] = useMemo(
        () =>
            day.global_deployments
                .filter((deployment) => deployment.unit_name === scheduleUnit.name)
                .filter((deployment) => deployment.department_name !== slot.department.name)
                .map((deployment) => [deployment.start_time, deployment.end_time]),
        [day.global_deployments, scheduleUnit.name, slot.department.name]
    );

    const handlePlusButtonClick = () => {
        setCursorPosition({
            ...cursorPosition,
            row: renderRow,
            col: renderCol,
            i: buttonSubRow,
            tab: 0,
            editorOpen: true
        });
    };

    return (
        <TableRowDay className={classNames.join(" ")} role="region" aria-label={`Wochentag ${day.dow}`}>
            <div>
                <div className="mb-1">
                    <DeploymentDayBar
                        dow={day.dow}
                        firstHour={firstBusinessHour}
                        lastHour={lastBusinessHour}
                        deploymentTimes={deploymentTimes}
                        otherDepartmentDeploymentTimes={otherDepartmentDeploymentTimes}
                        allocationTimes={allocationTimes}
                        suspended={anySuspendedDeployment}
                    />
                </div>
                <div className="SchedulerEmployeeWeek__Day__IndicatorBar">
                    {isDifferentCostUnit && (
                        <div
                            className="SchedulerEmployeeWeek__Day__DifferentCostUnitIndicator"
                            title="Abweichender Hauptarbeitsort"
                        >
                            <Indicator type="different-cost-unit" colorScheme={"secondary"} />
                        </div>
                    )}

                    {hasMultipleWorkplaces && (
                        <div
                            className="SchedulerEmployeeWeek__Day__MultipleWorkplacesIndicator"
                            title="Mehrere Arbeitsorte"
                        >
                            <Indicator type="workplace" text={day.workplaces.length} colorScheme={"secondary"} />
                        </div>
                    )}
                </div>

                {employeeNotes.map((employeeNote, employeeNotesIdx) => (
                    <EmployeeNoteElement
                        key={employeeNote.id}
                        setCursorAnchorElement={setCursorAnchorElement}
                        renderRow={renderRow}
                        renderCol={renderCol}
                        renderSubRow={employeeNotesIdx}
                        employeeNote={employeeNote}
                    />
                ))}

                {day.absences.map((absence, absencesIdx) => (
                    <Absence
                        key={absence.id}
                        setCursorAnchorElement={setCursorAnchorElement}
                        absence={absence}
                        renderRow={renderRow}
                        renderCol={renderCol}
                        day={day}
                        renderSubRow={employeeNotes.length + absencesIdx}
                    />
                ))}

                {day.school && <SchoolAttendance range={day.school} />}

                <DeploymentList
                    setCursorAnchorElement={setCursorAnchorElement}
                    renderRow={renderRow}
                    renderCol={renderCol}
                    renderSubRowStart={employeeNotes.length + day.absences.length}
                    day={day}
                />
            </div>
            {showButton && (
                <div ref={isButtonActive ? setCursorAnchorElement : null}>
                    <button
                        ref={plusButtonRef}
                        aria-label={"Einsatz hinzufügen"}
                        className={`SchedulerEmployeeWeek__Day__NewButton btn btn-sm btn-secondary ${
                            isButtonActive ? "SchedulerEmployeeWeek__Day__NewButton--active" : ""
                        }`}
                        onClick={handlePlusButtonClick}
                    >
                        <Icon type={"new-alt"} />
                    </button>
                </div>
            )}
        </TableRowDay>
    );
}
