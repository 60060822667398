import * as React from "react";
import { conditionalClassNames, joinClasses } from "../../../lib/dom-utils/class-names";

function Metric(
    props: {
        type?: "positive" | "negative" | "neutral" | "ghost";
        size?: "md" | "lg";
        align?: "left" | "center" | "right";
        children?: React.ReactNode;
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const { type = "neutral", size = "md", align = "left", children, ...divProps } = props;
    const classNames = joinClasses(
        ...conditionalClassNames({
            EmployeeManager__Factsheet__Metric: true,
            [`EmployeeManager__Factsheet__Metric--align-${align}`]: true,
            [`EmployeeManager__Factsheet__Metric--${type}`]: true,
            [`EmployeeManager__Factsheet__Metric--${size}`]: true,
        }),
        divProps.className
    );

    return (
        <div {...divProps} className={classNames}>
            {children}
        </div>
    );
}

function Label(props: React.HTMLAttributes<HTMLSpanElement>) {
    const { children, ...spanProps } = props;
    const classNames = joinClasses("EmployeeManager__Factsheet__Metric__Label", spanProps.className);

    return (
        <span {...spanProps} className={classNames}>
            {children}
        </span>
    );
}

function Value(props: React.HTMLAttributes<HTMLSpanElement>) {
    const { children, ...spanProps } = props;
    const classNames = joinClasses("EmployeeManager__Factsheet__Metric__Value", spanProps.className);

    return (
        <span {...spanProps} className={classNames}>
            {children}
        </span>
    );
}

function EmptyState(props: React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...divProps } = props;
    const classNames = joinClasses("EmployeeManager__Factsheet__Metric__EmptyState", divProps.className);

    return (
        <div {...divProps} className={classNames}>
            {children}
        </div>
    );
}

Metric.Label = Label;
Metric.Value = Value;
Metric.EmptyState = EmptyState;

export default Metric;
