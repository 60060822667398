import { CreateOrUpdateResult, FormAdapter, GetResult } from "../adapter";
import WorkTimeSet from "../../model/WorkTimeSet";
import { patch, post, get } from "../api";

function createWorkTimeSetPath(urlParams: { employeeId: string }) {
    return `/api/employees/${urlParams.employeeId}/work_time_sets`;
}

function updateWorkTimeSetPath(urlParams: { employeeId: string; id: string }) {
    return `/api/employees/${urlParams.employeeId}/work_time_sets/${urlParams.id}`;
}

function readWorkTimeSetPath(urlParams: { employeeId: string; id: string }) {
    return `/api/employees/${urlParams.employeeId}/work_time_sets/${urlParams.id}`;
}

function read(urlParams): Promise<GetResult<WorkTimeSet>> {
    return get(readWorkTimeSetPath(urlParams));
}

function save(urlParams, data): Promise<CreateOrUpdateResult<WorkTimeSet>> {
    if (urlParams.id) {
        return update(urlParams, data);
    } else {
        return create(urlParams, data);
    }
}

function create(urlParams, data): Promise<CreateOrUpdateResult<WorkTimeSet>> {
    return post(createWorkTimeSetPath(urlParams), data);
}

function update(urlParams, data): Promise<CreateOrUpdateResult<WorkTimeSet>> {
    return patch(updateWorkTimeSetPath(urlParams), data);
}

export const workTimeSetAdapter: FormAdapter<WorkTimeSet> = {
    read,
    save,
    create,
    update,
};

export default workTimeSetAdapter;
