import { createContext, useContext } from "react";

type ComboboxState = {
    selectedValue: string;
    activeValue: string;
    query: string;
    normalizedQuery: string;
    open: boolean;
    options: Record<string, string>;
    filteredOptionKeys: string[];
};

export function createInitialState(value: string, options: Record<string, string>): ComboboxState {
    const verifiedOptions = options ?? {};
    const verifiedValue = -1 !== Object.keys(verifiedOptions).indexOf(value) ? value : null;
    const query = verifiedOptions[verifiedValue] ?? "";
    return {
        selectedValue: verifiedValue,
        activeValue: null,
        query: query,
        normalizedQuery: normalizeQuery(query),
        open: false,
        options: verifiedOptions,
        filteredOptionKeys: Object.keys(verifiedOptions),
    };
}

function normalizeQuery(q: string): string {
    if (q === undefined) {
        return "";
    }
    return q.toString().trim().toLowerCase();
}

type SelectAction = {
    type: "select";
    payload: string;
};

type FocusAction = {
    type: "focus";
    payload: string;
};

type SetQueryAction = {
    type: "setQuery";
    payload: string;
};

type OpenAction = {
    type: "open";
};

type CloseAction = {
    type: "close";
};

type SetOptionsAction = {
    type: "init";
    payload: {
        options: Record<string, string>;
        value: string;
    };
};

type MoveByAction = {
    type: "moveBy";
    payload: number;
};
type BlurAction = {
    type: "blur";
};

type Action =
    | SelectAction
    | FocusAction
    | SetQueryAction
    | OpenAction
    | CloseAction
    | SetOptionsAction
    | MoveByAction
    | BlurAction;

export function reducer(draft: ComboboxState, action: Action) {
    switch (action.type) {
        case "select": {
            if (undefined !== action.payload && null !== action.payload) {
                draft.selectedValue = action.payload as string;
            }
            draft.query = draft.options[draft.selectedValue] ?? "";
            draft.normalizedQuery = normalizeQuery(draft.query);
            draft.filteredOptionKeys = getFilteredOptionKeys(draft);
            draft.open = false;
            return;
        }
        case "focus": {
            draft.activeValue = action.payload as string;
            return;
        }
        case "setQuery": {
            draft.query = (action.payload as string) ?? "";
            draft.normalizedQuery = normalizeQuery(draft.query);
            draft.filteredOptionKeys = getFilteredOptionKeys(draft);
            return;
        }
        case "open": {
            draft.open = true;
            draft.activeValue =
                -1 !== draft.filteredOptionKeys.indexOf(draft.selectedValue)
                    ? draft.selectedValue
                    : draft.filteredOptionKeys[0];
            draft.filteredOptionKeys = Object.keys(draft.options);
            return;
        }
        case "close": {
            draft.open = false;
            draft.filteredOptionKeys = Object.keys(draft.options);
            draft.activeValue = null;
            return;
        }
        case "init": {
            const verifiedOptions = action.payload.options ?? {};
            const verifiedValue =
                -1 !== Object.keys(verifiedOptions).indexOf(action.payload.value) ? action.payload.value : null;
            const query = verifiedOptions[verifiedValue] ?? "";

            draft.selectedValue = verifiedValue;
            draft.activeValue = null;
            draft.options = verifiedOptions as Record<string, string>;
            draft.query = query;
            draft.normalizedQuery = normalizeQuery(query);
            draft.filteredOptionKeys = Object.keys(draft.options);
            return;
        }
        case "moveBy": {
            draft.open = true;

            const delta = action.payload as number;
            const currentIndex = draft.filteredOptionKeys.indexOf(draft.activeValue);
            const nextIndex = currentIndex + delta;
            const nextValue = draft.filteredOptionKeys[nextIndex];
            if (nextValue !== undefined) {
                draft.activeValue = nextValue;
            }
            return;
        }
        case "blur": {
            draft.open = false;
            draft.query = draft.options[draft.selectedValue] ?? "";
            draft.normalizedQuery = normalizeQuery(draft.query);
            draft.filteredOptionKeys = getFilteredOptionKeys(draft);
            return;
        }
    }
}

function getFilteredOptionKeys(state: ComboboxState): string[] {
    return Object.keys(state.options).filter(
        (key) =>
            key.toLowerCase().includes(state.normalizedQuery) ||
            state.options[key].toLowerCase().includes(state.normalizedQuery)
    );
}

export const ComboboxContext = createContext(null);
export const ComboboxDispatchContext = createContext(null);

export function useComboboxState(): ComboboxState {
    return useContext(ComboboxContext);
}

export function useComboboxDispatch() {
    return useContext(ComboboxDispatchContext);
}
